
import React from 'react';
import './splash.css';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {ChangeCompany,ChangeBranch} from '../../redux/actions/CompanyAction'

//import firebase from 'firebase';
import {userToken,setUser} from '../../redux/actions/AuthActions'
import  allStrings from '../../assets/strings'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'

class Splash extends React.Component {

    state = {
        loading: true,
    };
  

  constructor(props){
    super(props)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }
  /*
  getFirebaseToken = async () => {
    try {
  
      const messaging = firebase.messaging();
      await messaging.requestPermission();
      const token = await messaging.getToken();
      this.checkToken(token);
      //console.log('user token: ', token);
  
      return token;
    } catch (error) {
      //console.error(error);
    }
  }*/

      checkToken =  (token) => {
        const t = localStorage.getItem('@USERQSAT')
          if(t){
              this.props.userToken(t);
          }else{
            localStorage.setItem('@USERQSATToken',token)
              this.props.userToken(token)
          }
          console.log(token)
      }

      checkLogin =  () => {      
        const userJSON = localStorage.getItem('@USERQSAT');
        if(userJSON){
            const userInfo = JSON.parse(userJSON);
            console.log(userInfo)
          this.props.setUser(userInfo);
          console.log(userInfo.user.type)
          if(userInfo.user.type == "ADMIN" ){
            this.props.history.push('/Home')  
          }
          if(userInfo.user.type == "SUB-ADMIN" && userInfo.user.gineralSubAdmin == true){
            this.props.history.push('/Home')  
          }
          if(userInfo.user.type == "COMPANY" ){
            this.props.history.push('/CompanyHome')  
          }  
          if(userInfo.user.type == "SUB-ADMIN" && userInfo.user.gineralSubAdmin == false){
            this.props.history.push('/CompanyHome')  
          }
          }else{
            this.props.history.push('/Login')
          }
      }

      checkLanguage =  () => {      
      const lang = localStorage.getItem('@lang');
      if(lang){
            allStrings.setLanguage(lang) 
            if(lang==='ar'){
            this.props.ChangeLanguage(true)  
            }else{
            this.props.ChangeLanguage(false)
            }
          
        }else{
          allStrings.setLanguage('ar') 
          this.props.ChangeLanguage(true)  
        }
      }
      checkCompany =  () => {      
        const company = localStorage.getItem('@company');
          if(company){
              if(company==false){
              this.props.ChangeCompany(false)  
              }else{
              this.props.ChangeCompany(company)
              }
            
          }
        }


   
     componentDidMount(){
      //this.getFirebaseToken()
      this.checkLanguage()
      this.checkCompany()
      setTimeout(()=>{this.checkLogin()},1000)
     }
     
    
    render() {
      const { loading } = this.state;
      return (
        <div className="splash">
        <div class="frame">
          <div class="center">
              <div class="dot-1"></div>
              <div class="dot-2"></div>
              <div class="dot-3"></div>
          </div>
       </div>
      </div> 
      );
    }
  }

  
  const mapToStateProps = state => ({
    isRTL: state.lang.isRTL,
    company:state.company
  })
  
  const mapDispatchToProps = {
    userToken,
    setUser,
    ChangeLanguage,
    ChangeCompany
  }

  export default  withRouter(connect(mapToStateProps,mapDispatchToProps)(Splash));

