/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React from "react";
import "./adsForm.css";
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon } from "mdbreact";
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { setUser } from "../../redux/actions/AuthActions";
import { hasValue } from "../../validations/validations";
import { message, TreeSelect, DatePicker, Checkbox } from "antd";
import { NavLink } from "react-router-dom";
import { ChangeLanguage } from "../../redux/actions/LanguageAction";
import moment from "moment";
import "moment/locale/ar";
const { TreeNode } = TreeSelect;
const { SHOW_PARENT } = TreeSelect;

let start;
class AdsForm extends React.Component {
  state = {
    name: this.props.history.location.state
      ? this.props.history.location.state.data.name
      : "",
    duration: this.props.history.location.state
      ? this.props.history.location.state.data.duration
      : "",
    category: this.props.history.location.state
      ? this.props.history.location.state.data.category
      : [],
    product: this.props.history.location.state
      ? this.props.history.location.state.data.product
      : [],
    company: this.props.history.location.state
      ? this.props.history.location.state.data.company
      : [],
    start: this.props.history.location.state
      ? this.props.history.location.state.data.start
      : moment(new Date(), "YYYY/MM/DD"),
    link: this.props.history.location.state
      ? this.props.history.location.state.data.link
      : "",
    owner: this.props.history.location.state
      ? this.props.history.location.state.data.owner
      : "",
    cost: this.props.history.location.state
      ? this.props.history.location.state.data.cost
      : "",
    phone: this.props.history.location.state
      ? this.props.history.location.state.data.phone
      : "",
    visible: false,
    requestType: this.props.history.location.state ? "put" : "post",
    products: [],
    img: this.props.history.location.state
      ? this.props.history.location.state.data.img[0]
      : null,
    imgs: null,
    imgType: this.props.history.location.state ? "url" : "data",
    categories: [],
    companies: [],
    add: this.props.history.location.state
      ? this.props.history.location.state.data
      : [],
  };

  constructor(props) {
    super(props);
    console.log("isr   ", this.props.history.location.state);
    console.log(this.props);
    if (this.props.isRTL) {
      allStrings.setLanguage("ar");
    } else {
      allStrings.setLanguage("en");
    }
    window.scrollTo(0, 0);
  }

  getCategories = () => {
    axios
      .get(`${BASE_END_POINT}categories/get/Normal`)
      .then((response) => {
        console.log(response.data);
        this.setState({ categories: response.data.data });
      })
      .catch((error) => {
        console.log("ALL Categories ERROR");
        console.log(error.response);
      });
  };
  getProducts = () => {
    axios
      .get(`${BASE_END_POINT}products/withoutPagenation/get`, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log(response);
        this.setState({ products: response.data.data });
      })
      .catch((error) => {
        console.log("ALL products ERROR");
        console.log(error.response);
      });
  };
  getCompanies = () => {
    axios
      .get(`${BASE_END_POINT}withoutPagenation/get?type=COMPANY`)
      .then((response) => {
        console.log(response.data.users);
        this.setState({ companies: response.data.users });
      })
      .catch((error) => {
        console.log("ALL companies ERROR");
        console.log(error.response);
      });
  };
  addAds = () => {
    const {
      name,
      duration,
      img,
      company,
      category,
      product,
      visible,
      link,
      phone,
      start,
      owner,
      cost,
    } = this.state;

    if (hasValue(name) && hasValue(duration) && img != null) {
      let l = message.loading("Wait..", 2.5);
      var data = new FormData();
      data.append("name", name);
      data.append("duration", duration);
      data.append("img", img);
      data.append("start", moment(start).locale("en").format("YYYY-MM-DD"));
      console.log(start);
      if (category.length > 0) {
        console.log(JSON.stringify(category));
        data.append("category", JSON.stringify(category));
      }
      if (product.length > 0) {
        console.log(JSON.parse(JSON.stringify(product)));
        data.append("product", JSON.stringify(product));
      }
      if (company.length > 0) {
        console.log(JSON.parse(JSON.stringify(company)));
        data.append("company", JSON.stringify(company));
      }
      data.append("visible", visible);

      if (link !== "") {
        data.append("link", link);
      }
      if (owner !== "") {
        data.append("owner", owner);
      }
      if (cost !== "") {
        data.append("cost", cost);
      }
      if (phone !== "") {
        data.append("phone", phone);
      }
      console.log(data);

      axios
        .post(`${BASE_END_POINT}ads`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        })
        .then((response) => {
          l.then(() => message.success(allStrings.done, 2.5));
          this.props.history.push("/ads");
        })
        .catch((error) => {
          console.log(error.response);
          l.then(() => message.error("Error", 2.5));
        });
    }
  };
  updateAds = () => {
    const {
      name,
      duration,
      img,
      company,
      category,
      product,
      visible,
      link,
      phone,
      start,
      owner,
      cost,
    } = this.state;
    console.log(visible);
    if (hasValue(name) && img != null) {
      let l = message.loading("Wait..", 2.5);
      var data = new FormData();
      data.append("name", name);
      data.append("duration", duration);
      if (this.state.imgType == "data") {
        data.append("img", img);
      }

      data.append("start", moment(start).locale("en").format("YYYY-MM-DD"));
      console.log(start);
      if (category.length > 0) {
        data.append("category", JSON.stringify(category));
      }
      if (product.length > 0) {
        data.append("product", JSON.stringify(product));
      }
      if (company.length > 0) {
        data.append("company", JSON.stringify(company));
      }
      data.append("visible", visible);

      if (link !== "") {
        data.append("link", link);
      }
      if (owner !== "") {
        data.append("owner", owner);
      }
      if (cost !== "") {
        data.append("cost", cost);
      }
      if (phone !== "") {
        data.append("phone", phone);
      }
      console.log(Array.from(data));

      axios
        .put(`${BASE_END_POINT}ads/${this.state.add.id}`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        })
        .then((response) => {
          l.then(() => message.success(allStrings.done, 2.5));
          this.props.history.push("/ads");
        })
        .catch((error) => {
          console.log(error.response);
          l.then(() => message.error("Error", 2.5));
        });
    }
  };

  componentDidMount() {
    this.getCategories();
    this.getProducts();
    this.getCompanies();
    //this.props.ChangeLanguage(false)
  }
  fileHandler = (event) => {
    this.setState({
      img: event.target.files[0],
      imgType: "data",
    });
  };
  removeImg = () => {
    this.setState({
      img: null,
    });
  };
  changeHandler = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  addSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.addAds();
  };
  updateSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.updateAds();
  };

  onChangeDate = (date, dateString) => {
    console.log(date, dateString);
    if (this.props.isRTL) {
      this.setState({ start: moment(dateString).format("YYYY/MM/DD") });
    } else {
      this.setState({
        start: moment(dateString).locale("en").format("YYYY/MM/DD"),
      });
    }
    start = moment(dateString).locale("en").format("YYYY-MM-DD");
  };

  render() {
    return (
      <div className="adsForm">
        <MDBContainer>
          <MDBRow>
            <div className="top" style={{ width: "100%", textAlign: "center" }}>
              <NavLink to="/ads">
                <MDBIcon icon="times" className="closeIcon" />
              </NavLink>
              <span className="stockTitle">
                {this.state.requestType === "post"
                  ? allStrings.completeData
                  : allStrings.edit}
              </span>
            </div>
          </MDBRow>
          <form
            className="needs-validation"
            onSubmit={
              this.state.requestType === "post"
                ? this.addSubmitHandler
                : this.updateSubmitHandler
            }
            noValidate
            style={{ marginLeft: "2rem" }}
          >
            <MDBRow>
              <MDBContainer>
                <MDBRow>
                  <MDBCol md="9">
                    <input
                      name="name"
                      onChange={this.changeHandler}
                      type="text"
                      id="defaultFormRegisterNameEx1"
                      required
                      className="form-control"
                      placeholder={allStrings.enterName}
                      style={{
                        direction: this.props.isRTL ? "rtl" : "ltr",
                        marginBottom: "10px",
                      }}
                      defaultValue={this.state.add.name}
                    />
                  </MDBCol>
                  <MDBCol md="3">
                    <label
                      htmlFor="defaultFormRegisterNameEx1"
                      className="grey-text formLabel"
                    >
                      {allStrings.name}
                    </label>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="9">
                    {this.state.img == null && (
                      <div className="smallUploadContainer">
                        <img
                          alt=""
                          name="img"
                          className="smallUploderImg"
                          src={require("../../assets/images/Path 343.png")}
                        ></img>
                        <input
                          onChange={this.fileHandler}
                          type="file"
                          id="defaultFormRegisterNameEx3"
                          required
                          className="smallUploader"
                        />
                        <MDBBtn
                          color="primary"
                          size="sm"
                          className="smallUploaderBtn"
                        >
                          {allStrings.chooseFile}
                        </MDBBtn>
                        <p
                          style={{
                            fontSize: "14px",
                            marginTop: "3px 15px",
                            float: "right",
                            paddingTop: "4px",
                          }}
                          className="grey-text"
                        >
                          {allStrings.dropHere}
                        </p>
                      </div>
                    )}
                    <div className="preview">
                      {this.state.img != null && (
                        <div
                          style={{
                            display: "inline-block",
                            border: "1px solid #43425D",
                            float: "right",
                            marginBottom: "1rem",
                          }}
                        >
                          {this.state.add.dataType == "video" ? (
                            <video
                              style={{
                                width: "220px",
                                height: "180px",
                              }}
                              src={
                                this.state.imgType == "data"
                                  ? URL.createObjectURL(this.state.img)
                                  : this.state.img
                              }
                            ></video>
                          ) : (
                            <img
                              alt=""
                              width="220px"
                              src={
                                this.state.imgType == "data"
                                  ? URL.createObjectURL(this.state.img)
                                  : this.state.img
                              }
                            />
                          )}

                          <MDBIcon
                            icon="times"
                            className="mr-2 removeImg"
                            onClick={() => this.removeImg()}
                          ></MDBIcon>
                        </div>
                      )}
                    </div>
                  </MDBCol>
                  <MDBCol md="3">
                    <label
                      htmlFor="defaultFormRegisterNameEx3"
                      className="grey-text formLabel"
                    >
                      {allStrings.imgOrVideo}
                    </label>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="9">
                    <input
                      name="duration"
                      onChange={this.changeHandler}
                      type="text"
                      id="defaultFormRegisterNameEx1"
                      required
                      className="form-control"
                      placeholder={allStrings.enterDuration}
                      style={{ direction: this.props.isRTL ? "rtl" : "ltr" }}
                      defaultValue={this.state.add.duration}
                    />
                  </MDBCol>
                  <MDBCol md="3">
                    <label
                      htmlFor="defaultFormRegisterNameEx1"
                      className="grey-text formLabel"
                    >
                      {allStrings.duration}
                    </label>
                  </MDBCol>
                </MDBRow>
                <MDBRow style={{ marginBottom: "6px", marginTop: "6px" }}>
                  <MDBCol md="9">
                    <input
                      name="phone"
                      onChange={this.changeHandler}
                      type="text"
                      id="defaultFormRegisterNameEx1"
                      className="form-control"
                      placeholder={allStrings.enterPhone}
                      style={{ direction: this.props.isRTL ? "rtl" : "ltr" }}
                      defaultValue={this.state.add.phone}
                    />
                  </MDBCol>
                  <MDBCol md="3">
                    <label
                      htmlFor="defaultFormRegisterNameEx1"
                      className="grey-text formLabel"
                    >
                      {allStrings.phone}
                    </label>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="9">
                    <input
                      name="link"
                      onChange={this.changeHandler}
                      type="text"
                      id="defaultFormRegisterNameEx1"
                      className="form-control"
                      placeholder={allStrings.enterLink}
                      style={{ direction: this.props.isRTL ? "rtl" : "ltr" }}
                      defaultValue={this.state.add.link}
                    />
                  </MDBCol>
                  <MDBCol md="3">
                    <label
                      htmlFor="defaultFormRegisterNameEx1"
                      className="grey-text formLabel"
                    >
                      {allStrings.link}
                    </label>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="9">
                    <input
                      name="owner"
                      onChange={this.changeHandler}
                      type="text"
                      id="defaultFormRisterNameEx1"
                      className="form-control"
                      placeholder={allStrings.enterOwner}
                      style={{ direction: this.props.isRTL ? "rtl" : "ltr" }}
                      defaultValue={this.state.add.owner}
                    />
                  </MDBCol>
                  <MDBCol md="3">
                    <label
                      htmlFor="defaultFormRisterNameEx1"
                      className="grey-text formLabel"
                    >
                      {allStrings.owner}
                    </label>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="9">
                    <input
                      name="cost"
                      onChange={this.changeHandler}
                      type="text"
                      id="defaultFormRisterName"
                      className="form-control"
                      placeholder={allStrings.cost}
                      style={{ direction: this.props.isRTL ? "rtl" : "ltr" }}
                      defaultValue={this.state.add.cost}
                    />
                  </MDBCol>
                  <MDBCol md="3">
                    <label
                      htmlFor="defaultFormRisterName"
                      className="grey-text formLabel"
                    >
                      {allStrings.cost}
                    </label>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="9">
                    <div style={{ marginBottom: "10px", textAlign: "right" }}>
                      <div
                        style={{
                          display: "inline-block",
                          textAlign: "center",
                          marginLeft: this.props.isRTL ? "35px" : "-93px",
                        }}
                      >
                        <DatePicker
                          defaultValue={
                            this.state.start
                              ? moment(moment(this.state.start), "YYYY/MM/DD")
                              : moment(new Date(), "YYYY/MM/DD")
                          }
                          onChange={this.onChangeDate}
                        />
                        <span className="newSpanM">
                          <img
                            alt=""
                            style={{ marginRight: "4px" }}
                            src={require("../../assets/images/icons8_edit_property.png")}
                          ></img>
                          {this.state.start
                            ? moment(this.state.start).format("YYYY/MM/DD")
                            : moment().format("YYYY/MM/DD")}
                        </span>
                      </div>
                    </div>
                  </MDBCol>
                  <MDBCol md="3">
                    <label
                      htmlFor="defaultFormRegisterNameEx1"
                      className="grey-text formLabel"
                    >
                      {allStrings.startDate}
                    </label>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="9">
                    <TreeSelect
                      value={this.state.category}
                      showSearch={true}
                      style={{
                        width: "100%",
                        textAlign: this.props.isRTL ? "right" : "left",
                      }}
                      treeNodeFilterProp="title"
                      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                      placeholder={allStrings.chooseCategory}
                      treeCheckable={true}
                      showCheckedStrategy={SHOW_PARENT}
                      onChange={(value) => {
                        this.setState({ category: value });
                        console.log(this.state.category);
                      }}
                    >
                      {/*<TreeNode style={{background:"antiquewhite",color:'#fff !important'}} value="newClient" title={allStrings.newClient} key={allStrings.newClient} />*/}
                      {this.state.categories.map((val) => (
                        <TreeNode
                          value={val.id}
                          title={
                            this.props.isRTL
                              ? val.categoryName_ar
                              : val.categoryName_en
                          }
                          key={val.id}
                        />
                      ))}
                    </TreeSelect>
                  </MDBCol>
                  <MDBCol md="3">
                    <label
                      htmlFor="defaultFormRegisterNameEx1"
                      className="grey-text formLabel"
                    >
                      {allStrings.categories}
                    </label>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="9">
                    <TreeSelect
                      value={this.state.product}
                      showSearch={true}
                      style={{
                        width: "100%",
                        textAlign: this.props.isRTL ? "right" : "left",
                      }}
                      treeNodeFilterProp="title"
                      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                      placeholder={allStrings.chooseProducts}
                      treeCheckable={true}
                      showCheckedStrategy={SHOW_PARENT}
                      onChange={(value) => {
                        this.setState({ product: value });
                        console.log(this.state.product);
                      }}
                    >
                      {/*<TreeNode style={{background:"antiquewhite",color:'#fff !important'}} value="newClient" title={allStrings.newClient} key={allStrings.newClient} />*/}
                      {this.state.products.map((val) => (
                        <TreeNode
                          value={val.id}
                          title={this.props.isRTL ? val.name_ar : val.name_en}
                          key={val.id}
                        />
                      ))}
                    </TreeSelect>
                  </MDBCol>
                  <MDBCol md="3">
                    <label
                      htmlFor="defaultFormRegisterNameEx1"
                      className="grey-text formLabel"
                    >
                      {allStrings.products}
                    </label>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="9">
                    <TreeSelect
                      value={this.state.company}
                      showSearch={true}
                      style={{
                        width: "100%",
                        textAlign: this.props.isRTL ? "right" : "left",
                      }}
                      treeNodeFilterProp="title"
                      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                      placeholder={allStrings.chooseCompanys}
                      treeCheckable={true}
                      showCheckedStrategy={SHOW_PARENT}
                      onChange={(value) => {
                        this.setState({ company: value });
                        console.log(this.state.company);
                      }}
                    >
                      {/*<TreeNode style={{background:"antiquewhite",color:'#fff !important'}} value="newClient" title={allStrings.newClient} key={allStrings.newClient} />*/}
                      {this.state.companies.map((val) => (
                        <TreeNode
                          value={val.id}
                          title={val.fullname}
                          key={val.id}
                        />
                      ))}
                    </TreeSelect>
                  </MDBCol>
                  <MDBCol md="3">
                    <label
                      htmlFor="defaultFormRegisterNameEx1"
                      className="grey-text formLabel"
                    >
                      {allStrings.companies}
                    </label>
                  </MDBCol>
                </MDBRow>
                <br></br>
                <MDBRow>
                  <MDBCol md="9">
                    <Checkbox
                      style={{ float: "right", marginTop: "5px" }}
                      defaultChecked={
                        this.state.add.visible ? this.state.add.visible : false
                      }
                      onChange={(e) => {
                        console.log(e.target.checked);
                        this.setState({ visible: e.target.checked });
                      }}
                    ></Checkbox>
                  </MDBCol>
                  <MDBCol md="3">
                    <label
                      htmlFor="defaultFormRegisterNameEx1"
                      className="grey-text formLabel"
                    >
                      {allStrings.visable}
                    </label>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBRow>
            <MDBRow>
              <MDBBtn
                type="submit"
                style={{
                  margin: "1rem",
                  width: "150px",
                  padding: "9px",
                  height: "35px",
                }}
                className="btn z-depth-1a "
                color="primary"
              >
                {this.state.requestType == "post"
                  ? allStrings.add
                  : allStrings.edit}
              </MDBBtn>
            </MDBRow>
          </form>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = (state) => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
});

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
};

export default withRouter(
  connect(mapToStateProps, mapDispatchToProps)(AdsForm)
);
