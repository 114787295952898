/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React from "react";
import "./reports.css";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { setUser } from "../../redux/actions/AuthActions";
import { DatePicker, ConfigProvider } from "antd";
import { Redirect } from "react-router-dom";
import { ChangeLanguage } from "../../redux/actions/LanguageAction";
import { ChangeCompany, ChangeBranch } from "../../redux/actions/CompanyAction";

import Skeleton from "react-loading-skeleton";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import locale_ar from "antd/lib/locale/ar_EG";
import locale_en from "antd/lib/locale/en_GB";
import "moment/locale/ar";
import ReactToPrint from "react-to-print";

let fromDate;
let toDate;
class ComponentToPrint extends React.Component {
  render() {
    console.log(this.props);
    return (
      <form class="col s12">
        <div className="tabelDev">
          <div class="row">
            <table class="table table-borderless">
              <thead class="thead-light">
                {this.props.tablePrintType == "stock" && (
                  <tr>
                    <th scope="col">{allStrings.name}</th>
                    <th scope="col">{allStrings.cashPrice}</th>
                    <th scope="col">{allStrings.quantity}</th>
                    <th scope="col">{allStrings.salesNumber}</th>
                  </tr>
                )}
                {this.props.tablePrintType == "order" && (
                  <tr>
                    <th scope="col">{allStrings.date}</th>
                    <th scope="col">{allStrings.cost}</th>
                    <th scope="col">{allStrings.client}</th>
                    <th scope="col">{allStrings.product}</th>
                  </tr>
                )}
                {this.props.tablePrintType == "clients" && (
                  <tr>
                    <th scope="col">{allStrings.name}</th>
                    <th scope="col">{allStrings.phone}</th>
                    <th scope="col">{allStrings.anotherPhone}</th>
                    <th scope="col">{allStrings.cardNum}</th>
                    <th scope="col">{allStrings.orders}</th>
                  </tr>
                )}
                {this.props.tablePrintType == "salesMen" && (
                  <tr>
                    <th scope="col">{allStrings.name}</th>
                    <th scope="col">{allStrings.phone}</th>
                    <th scope="col">{allStrings.anotherPhone}</th>
                    <th scope="col">{allStrings.cardNum}</th>
                    <th scope="col">{allStrings.balance}</th>
                    <th scope="col">{allStrings.debt}</th>
                    <th scope="col">{allStrings.salesNumber}</th>
                    <th scope="col">{allStrings.recievedProducts}</th>
                    <th scope="col">{allStrings.additionCollectaibles}</th>
                  </tr>
                )}
              </thead>

              <tbody>
                {this.props.data.length > 0 ? (
                  this.props.data.map((val) =>
                    this.props.tablePrintType == "stock" ? (
                      <tr>
                        <td>{this.props.isRTL ? val.name_ar : val.name_en}</td>
                        <td>{val.cashPrice ? val.cashPrice : "--"}</td>
                        <td>{val.quantity ? val.quantity : "--"}</td>
                        <td>{val.salesNumber ? val.salesNumber : 0}</td>
                      </tr>
                    ) : this.props.tablePrintType == "clients" ? (
                      <tr>
                        <td>{val.user.fullname ? val.user.fullname : ""}</td>
                        <td>{val.user.phone ? val.user.phone : ""}</td>
                        <td>
                          {val.user.anotherPhone ? val.user.anotherPhone : ""}
                        </td>
                        <td>{val.user.cardNum ? val.user.cardNum : ""}</td>
                        <td>{val.ordersCount ? val.ordersCount : 0}</td>
                      </tr>
                    ) : this.props.tablePrintType == "salesMen" ? (
                      <tr>
                        <td>{val.user.fullname ? val.user.fullname : ""}</td>
                        <td>{val.user.phone ? val.user.phone : ""}</td>
                        <td>
                          {val.user.anotherPhone ? val.user.anotherPhone : ""}
                        </td>
                        <td>{val.user.cardNum ? val.user.cardNum : ""}</td>
                        <td>{val.user.balance ? val.user.balance : 0}</td>
                        <td>{val.user.debt ? val.user.debt : 0}</td>
                        <td>{val.user.saleCount ? val.user.saleCount : 0}</td>
                        <td>
                          {val.recievedProducts ? val.recievedProducts : 0}
                        </td>
                        <td>
                          {val.additionCollectaibles
                            ? val.additionCollectaibles
                            : 0}
                        </td>
                      </tr>
                    ) : this.props.tablePrintType == "order" ? (
                      <tr>
                        <td>{val.createdAt.substring(0, 10)}</td>
                        <td>{val.total ? val.total : "--"}</td>
                        <td>{val.client.fullname}</td>
                        <td>
                          {val.product
                            ? this.props.isRTL
                              ? val.product.name_ar
                              : val.product.name_en
                            : ""}
                        </td>
                      </tr>
                    ) : (
                      <tr></tr>
                    )
                  )
                ) : (
                  <tr style={{ height: "200px" }}>
                    <th
                      colspan={
                        this.props.tablePrintType == "salesMen" ? "9" : "4"
                      }
                      className="noData"
                    >
                      <p style={{ marginTop: "4rem" }}>{allStrings.noData}</p>
                    </th>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </form>
    );
  }
}
class Reports extends React.Component {
  state = {
    tableData: [],
    dataToPrint: [],
    loading: true,
    refresh: false,
    page: 1,
    pages: 1,
    tableType: "order",
    tablePrintType: "salesMen",
    from: this.props.isRTL
      ? moment().startOf("month").format("YYYY/MM/DD")
      : moment().startOf("month").locale("en").format("YYYY/MM/DD"),
    to: this.props.isRTL
      ? moment().endOf("month").format("YYYY/MM/DD")
      : moment().endOf("month").locale("en").format("YYYY/MM/DD"),
  };
  getSalesMenAdditionWithoutPagenation = () => {
    let url = ``;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}withoutPagenation/additionGet?type=SALES-MAN`;
      } else {
        url = `${BASE_END_POINT}withoutPagenation/additionGet?type=SALES-MAN&company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}withoutPagenation/additionGet?type=SALES-MAN&company=${this.props.currentUser.user.id}`;
    }
    axios
      .get(url)
      .then((response) => {
        console.log(response.data.users);
        this.setState({
          dataToPrint: response.data.users,
          tablePrintType: "salesMen",
        });
      })
      .catch((error) => {
        console.log("ALL salesMen ERROR");
        console.log(error.response);
      });
  };
  getClientsAdditionWithoutPagenation = () => {
    let url = ``;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}clients`;
      } else {
        url = `${BASE_END_POINT}clients?company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}clients?company=${this.props.currentUser.user.id}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("Clients", response.data.users);
        this.setState({
          dataToPrint: response.data.data,
          tablePrintType: "clients",
        });
      })
      .catch((error) => {
        console.log("ALL clients ERROR");
        console.log(error.response);
      });
  };
  getProductsWithoutPagenation = () => {
    let url = ``;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}products/withoutPagenation/get`;
      } else {
        url = `${BASE_END_POINT}products/withoutPagenation/get?company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}products/withoutPagenation/get?company=${this.props.currentUser.user.id}`;
    }
    axios
      .get(url)
      .then((response) => {
        console.log(response.data.products);
        this.setState({
          dataToPrint: response.data.products,
          tablePrintType: "stock",
        });
      })
      .catch((error) => {
        console.log("ALL stock ERROR");
        console.log(error.response);
      });
  };
  getOrdersWithoutPagenation = () => {
    let url = ``;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}orders/withoutPagenation/get`;
      } else {
        url = `${BASE_END_POINT}orders/withoutPagenation/get?company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}orders/withoutPagenation/get?company=${this.props.currentUser.user.id}`;
    }
    axios
      .get(url)
      .then((response) => {
        console.log(response.data.orders);
        this.setState({
          dataToPrint: response.data.orders,
          tablePrintType: "order",
        });
      })
      .catch((error) => {
        console.log("ALL orders ERROR");
        console.log(error.response);
      });
  };
  constructor(props) {
    super(props);
    this.getDuration();
    console.log("isr   ", this.props.history);

    if (this.props.isRTL) {
      allStrings.setLanguage("ar");
      moment.locale("ar");
    } else {
      allStrings.setLanguage("en");
      moment.locale("en");
    }
    window.scrollTo(0, 0);
  }
  getDuration = () => {
    //first day
    let date = new Date();
    let newDate = date.toISOString().slice(0, 8);
    let firstDay = newDate + "01";
    //get days in month
    function daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    }

    let DaysInCurrentMonth = daysInMonth(
      date.getMonth() + 1,
      date.getFullYear()
    ); //number of day in month
    //last day
    let lastDay = newDate + DaysInCurrentMonth;
    console.log(firstDay);
    console.log(lastDay);
    if (this.props.isRTL) {
      this.setState({
        from: moment(firstDay).format("YYYY/MM/DD"),
        to: moment(lastDay).format("YYYY/MM/DD"),
      });
    } else {
      this.setState({
        from: moment(firstDay).locale("en").format("YYYY/MM/DD"),
        to: moment(lastDay).locale("en").format("YYYY/MM/DD"),
      });
    }

    fromDate = firstDay;
    toDate = lastDay;
  };
  componentDidMount() {
    //this.props.ChangeLanguage(false)
    this.getDuration();
    this.getOrders(1, false);
  }
  getClientAdditionPagenation = (page, refresh) => {
    this.setState({ loading: true, tableType: "clients" });
    let url = ``;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}clients`;
      } else {
        url = `${BASE_END_POINT}clients?company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}clients?company=${this.props.currentUser.user.id}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        this.setState({
          loading: false,
          refresh: false,
          tableData: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
        this.getClientsAdditionWithoutPagenation();
      })
      .catch((error) => {
        console.log("ALL clients ERROR");
        console.log(error.response);
        this.setState({ loading: false });
      });
  };
  getSalesMenAdditionPagenation = (page, refresh) => {
    this.setState({ loading: true, tableType: "salesMen" });
    let url = ``;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}additionGet?type=SALES-MAN&page=${page}`;
      } else {
        url = `${BASE_END_POINT}additionGet?type=SALES-MAN&page=${page}&company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}additionGet?type=SALES-MAN&company=${this.props.currentUser.user.id}&page=${page}`;
    }
    axios
      .get(url)
      .then((response) => {
        console.log("Clients22", response.data.data);
        this.setState({
          loading: false,
          refresh: false,
          tableData: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
        this.getSalesMenAdditionWithoutPagenation();
      })
      .catch((error) => {
        console.log("ALL salesMen ERROR");
        console.log(error.response);
        this.setState({ loading: false });
      });
  };

  getProducts = (page, refresh) => {
    this.setState({ loading: true, tableType: "stock" });
    let url = ``;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}products?page=${page}`;
      } else {
        url = `${BASE_END_POINT}products?page=${page}&company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}products?page=${page}&company=${this.props.currentUser.user.id}`;
    }

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("Products   ", response.data);
        this.setState({
          loading: false,
          refresh: false,
          tableData: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
        this.getProductsWithoutPagenation();
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading: false });
      });
  };
  getOrders = (page, refresh) => {
    this.setState({ tableType: "order", loading: true });
    let url = ``;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}orders?page=${page}`;
      } else {
        url = `${BASE_END_POINT}orders?page=${page}&company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}orders?company=${this.props.currentUser.user.id}&page=${page}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("orders   ", response.data);
        this.setState({
          loading: false,
          refresh: false,
          tableData: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
        this.getOrdersWithoutPagenation();
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading: false });
      });
  };

  onChangeFrom = (date, dateString) => {
    if (this.props.isRTL) {
      this.setState({ from: moment(dateString).format("YYYY/MM/DD") });
    } else {
      this.setState({
        from: moment(dateString).locale("en").format("YYYY/MM/DD"),
      });
    }
    fromDate = moment(dateString).locale("en").format("YYYY-MM-DD");
  };
  onChangeTo = (date, dateString) => {
    if (this.props.isRTL) {
      this.setState({ to: moment(dateString).format("YYYY/MM/DD") });
    } else {
      this.setState({
        to: moment(dateString).locale("en").format("YYYY/MM/DD"),
      });
    }
    toDate = moment(dateString).locale("en").format("YYYY-MM-DD");
    if (this.state.tableType == "order") {
      this.getOrders(1, false);
    }
    if (this.state.tableType == "clients") {
      this.getClientAdditionPagenation(1, false);
    }
    if (this.state.tableType == "salesMen") {
      this.getSalesMenAdditionPagenation(1, false);
    }
    if (this.state.tableType == "stock") {
      this.getProducts(1, false);
    }
  };

  render() {
    if (
      this.props.currentUser.user.type == "SUB-ADMIN" &&
      !this.props.currentUser.user.pagesPermission.includes("REPORTS")
    )
      return <Redirect to="/Home" />;

    return (
      <div className="Reports">
        <MDBContainer>
          <MDBRow>
            <MDBCol xl="4" lg="4" md="12" sm="12" style={{ paddingRight: "0" }}>
              <ReactToPrint
                trigger={() => {
                  return (
                    <div style={{ width: "100%", margin: "10px 0px" }}>
                      <img
                        alt=""
                        style={{ cursor: "pointer" }}
                        src={require("../../assets/images/icons8_pdf.png")}
                      ></img>
                      <span
                        style={{
                          color: "#FF3B40",
                          fontWeight: "600",
                          padding: "10px",
                          fontSize: "20px",
                          position: "absolute",
                          cursor: "pointer",
                        }}
                      >
                        {allStrings.print}
                      </span>
                    </div>
                  );
                }}
                content={() => this.componentRef}
              />
              <div style={{ display: "none" }}>
                <ComponentToPrint
                  data={this.state.dataToPrint}
                  tablePrintType={this.state.tablePrintType}
                  ref={(el) => (this.componentRef = el)}
                />
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol xl="8" lg="8" md="8" sm="8">
              <table class="table table-borderless">
                <thead class="thead-light">
                  {this.state.tableType == "stock" && (
                    <tr>
                      <th scope="col">{allStrings.name}</th>
                      <th scope="col">{allStrings.cashPrice}</th>
                      <th scope="col">{allStrings.purchasePrice}</th>
                      <th scope="col">{allStrings.category}</th>
                      <th scope="col">{allStrings.quantity}</th>
                      <th scope="col">{allStrings.salesNumber}</th>
                      <th scope="col">{allStrings.commission}</th>
                      <th scope="col">{allStrings.buyType}</th>
                    </tr>
                  )}
                  {this.state.tableType == "order" && (
                    <tr>
                      <th scope="col">{allStrings.date}</th>
                      <th scope="col">{allStrings.cost}</th>
                      <th scope="col">{allStrings.client}</th>
                      <th scope="col">{allStrings.product}</th>
                    </tr>
                  )}
                  {this.state.tableType == "clients" && (
                    <tr>
                      <th scope="col">{allStrings.name}</th>
                      <th scope="col">{allStrings.phone}</th>
                      <th scope="col">{allStrings.anotherPhone}</th>
                      <th scope="col">{allStrings.cardNum}</th>
                      <th scope="col">{allStrings.orders}</th>
                    </tr>
                  )}
                  {this.state.tableType == "salesMen" && (
                    <tr>
                      <th scope="col">{allStrings.name}</th>
                      <th scope="col">{allStrings.phone}</th>
                      <th scope="col">{allStrings.anotherPhone}</th>
                      <th scope="col">{allStrings.cardNum}</th>
                      <th scope="col">{allStrings.balance}</th>
                      <th scope="col">{allStrings.debt}</th>
                      <th scope="col">{allStrings.salesNumber}</th>
                      <th scope="col">{allStrings.recievedProducts}</th>
                      <th scope="col">{allStrings.additionCollectaibles}</th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {this.state.loading ? (
                    <tr style={{ height: "200px" }}>
                      <th
                        colspan={
                          this.state.tableType == "salesMen"
                            ? "9"
                            : this.state.tableType == "stock"
                            ? "8"
                            : "4"
                        }
                        className="noData"
                      >
                        <Skeleton height={400} />
                      </th>
                    </tr>
                  ) : this.state.tableData.length > 0 ? (
                    this.state.tableData.map((val) =>
                      this.state.tableType == "stock" ? (
                        <tr>
                          <td>
                            {val.name_ar
                              ? this.props.isRTL
                                ? val.name_ar
                                : val.name_en
                              : ""}
                          </td>
                          <td>{val.cashPrice ? val.cashPrice : ""}</td>
                          <td>{val.purchasePrice ? val.purchasePrice : ""}</td>
                          <td>
                            {val.category
                              ? this.props.isRTL
                                ? val.category.categoryName_ar
                                : val.category.categoryName_en
                              : ""}
                          </td>
                          <td>{val.quantity ? val.quantity : ""}</td>
                          <td>{val.salesNumber ? val.salesNumber : 0}</td>
                          <td>{val.commission ? val.commission : ""}</td>
                          <td>
                            {val.buyType
                              ? val.buyType == "CASH"
                                ? allStrings.cash
                                : val.buyType == "PREMIUM"
                                ? allStrings.installment
                                : allStrings.cashAndInstallment
                              : ""}
                          </td>
                        </tr>
                      ) : this.state.tableType == "clients" ? (
                        <tr>
                          <td>{val.fullname ? val.fullname : ""}</td>
                          <td>{val.phone ? val.phone : ""}</td>
                          <td>{val.anotherPhone ? val.anotherPhone : ""}</td>
                          <td>{val.cardNum ? val.cardNum : ""}</td>
                          <td>{val.ordersCount ? val.ordersCount : 0}</td>
                        </tr>
                      ) : this.state.tableType == "salesMen" ? (
                        <tr>
                          <td>{val.user.fullname ? val.user.fullname : ""}</td>
                          <td>{val.user.phone ? val.user.phone : ""}</td>
                          <td>
                            {val.user.anotherPhone ? val.user.anotherPhone : ""}
                          </td>
                          <td>{val.user.cardNum ? val.user.cardNum : ""}</td>
                          <td>{val.user.balance ? val.user.balance : 0}</td>
                          <td>{val.user.debt ? val.user.debt : 0}</td>
                          <td>{val.user.saleCount ? val.user.saleCount : 0}</td>
                          <td>
                            {val.recievedProducts ? val.recievedProducts : 0}
                          </td>
                          <td>
                            {val.additionCollectaibles
                              ? val.additionCollectaibles
                              : 0}
                          </td>
                        </tr>
                      ) : this.state.tableType == "order" ? (
                        <tr>
                          <td>{val.createdAt.substring(0, 10)}</td>
                          <td>{val.total ? val.total : "--"}</td>
                          <td>{val.client.fullname}</td>
                          <td>
                            {val.product
                              ? this.props.isRTL
                                ? val.product.name_ar
                                : val.product.name_en
                              : ""}
                          </td>
                        </tr>
                      ) : (
                        <tr></tr>
                      )
                    )
                  ) : (
                    <tr style={{ height: "200px" }}>
                      <th
                        colspan={
                          this.state.tableType == "salesMen"
                            ? "9"
                            : this.state.tableType == "stock"
                            ? "8"
                            : "4"
                        }
                        className="noData"
                      >
                        <p style={{ marginTop: "4rem" }}>{allStrings.noData}</p>
                      </th>
                    </tr>
                  )}
                  <th
                    colspan={
                      this.state.tableType == "salesMen"
                        ? "9"
                        : this.state.tableType == "stock"
                        ? "8"
                        : "4"
                    }
                  >
                    <MDBRow>
                      {this.state.tableData.length > 0 && (
                        <div
                          style={{
                            textAlign: "center",
                            margin: "auto",
                            marginTop: "3rem",
                          }}
                        >
                          <Pagination
                            onChange={(event, page) => {
                              console.log("page   ", page);
                              if (page != this.state.page) {
                                if (this.state.tableType == "order") {
                                  this.getOrders(page, false);
                                }
                                if (this.state.tableType == "clients") {
                                  this.getClientAdditionPagenation(page, false);
                                }
                                if (this.state.tableType == "salesMen") {
                                  this.getSalesMenAdditionPagenation(
                                    page,
                                    false
                                  );
                                }
                                if (this.state.tableType == "stock") {
                                  this.getProducts(page, false);
                                }

                                this.setState({ page: page });
                              }
                            }}
                            defaultPage={1}
                            page={this.state.page}
                            count={this.state.pages}
                            style={{ color: `blue !important` }}
                          />
                        </div>
                      )}
                    </MDBRow>
                  </th>
                </tbody>
              </table>
            </MDBCol>
            <MDBCol xl="4" lg="4" md="4" sm="4">
              <div style={{ paddingtop: "15px" }}>
                <div style={{ marginBottom: "10px", textAlign: "center" }}>
                  <span
                    className="fromSpan"
                    style={{
                      float: this.props.isRTL ? "right" : "left",
                      marginRight: "25%",
                    }}
                  >
                    {allStrings.from}
                  </span>
                  <div
                    style={{
                      display: "inline-block",
                      textAlign: "center",
                      marginLeft: this.props.isRTL ? "35px" : "-93px",
                    }}
                  >
                    <ConfigProvider
                      locale={this.props.isRTL ? locale_ar : locale_en}
                    >
                      <DatePicker
                        defaultValue={moment(this.state.from, "YYYY/MM/DD")}
                        onChange={this.onChangeFrom}
                      />
                    </ConfigProvider>
                    <span className="newSpan">
                      <img
                        alt=""
                        style={{ marginRight: "4px" }}
                        src={require("../../assets/images/icons8_edit_property.png")}
                      ></img>
                      {this.state.from}
                    </span>
                  </div>
                </div>
                <div style={{ marginBottom: "10px", textAlign: "center" }}>
                  <span
                    className="fromSpan"
                    style={{
                      float: this.props.isRTL ? "right" : "left",
                      marginRight: "25%",
                    }}
                  >
                    {allStrings.to}
                  </span>
                  <div
                    style={{
                      display: "inline-block",
                      textAlign: "center",
                      marginLeft: this.props.isRTL ? "35px" : "-93px",
                    }}
                  >
                    <ConfigProvider
                      locale={this.props.isRTL ? locale_ar : locale_en}
                    >
                      <DatePicker
                        defaultValue={moment(this.state.to, "YYYY/MM/DD")}
                        onChange={this.onChangeTo}
                      />
                    </ConfigProvider>
                    <span className="newSpan">
                      <img
                        alt=""
                        style={{ marginRight: "4px" }}
                        src={require("../../assets/images/icons8_edit_property.png")}
                      ></img>
                      {this.state.to}
                    </span>
                  </div>
                </div>
              </div>
              <br></br>
              <br></br>
              <div style={{ textAlign: "center", paddingtop: "15px" }}>
                {/*<img alt=""  width="50px" src={require('../../assets/images/icons8_calculate.png')}/>*/}
              </div>
              <br></br>
              <div style={{ textAlign: "center", marginBottom: "4rem" }}>
                <MDBBtn
                  type="submit"
                  style={{
                    margin: "1rem",
                    width: "145px",
                    padding: "9px",
                    height: "35px",
                  }}
                  className="btn z-depth-1a "
                  color="primary"
                  onClick={() => this.getProducts(1, false)}
                >
                  {allStrings.stock}
                </MDBBtn>
                <MDBBtn
                  type="submit"
                  style={{
                    margin: "1rem",
                    width: "145px",
                    padding: "9px",
                    height: "35px",
                  }}
                  className="btn z-depth-1a "
                  color="primary"
                  onClick={() => this.getSalesMenAdditionPagenation(1, false)}
                >
                  {allStrings.salesMen}
                </MDBBtn>
                <MDBBtn
                  type="submit"
                  style={{
                    margin: "1rem",
                    width: "145px",
                    padding: "9px",
                    height: "35px",
                  }}
                  className="btn z-depth-1a "
                  color="primary"
                  onClick={() => this.getClientAdditionPagenation(1, false)}
                >
                  {allStrings.clients}
                </MDBBtn>
                <MDBBtn
                  type="submit"
                  style={{
                    margin: "1rem",
                    width: "145px",
                    padding: "9px",
                    height: "35px",
                  }}
                  className="btn z-depth-1a "
                  color="primary"
                  onClick={() => this.getOrders(1, false)}
                >
                  {allStrings.orders}
                </MDBBtn>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = (state) => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  branch: state.company.branch,
  company: state.company.company,
});

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
};
export default withRouter(
  connect(mapToStateProps, mapDispatchToProps)(Reports)
);
