/* eslint-disable eqeqeq */

import React from 'react';
import allStrings from '../../assets/strings'
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import './orders.css'
import { Tabs,TreeSelect,DatePicker,message} from 'antd';
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import { setUser } from "../../redux/actions/AuthActions";
import Pagination from '@material-ui/lab/Pagination';
import 'antd/dist/antd.css';
import { MDBContainer ,MDBRow,MDBCol,MDBBtn,MDBModal, MDBModalBody, MDBModalHeader} from 'mdbreact';
import Skeleton from 'react-loading-skeleton';
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import moment from 'moment'
import {ChangeCompany,ChangeBranch} from '../../redux/actions/CompanyAction'
import { Redirect } from 'react-router-dom'

const { TabPane } = Tabs;
const {TreeNode} = TreeSelect
let paidDate
class Orders extends React.Component {
  constructor(props){
    super(props)
    console.log("isr   ",this.props.isRTL)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
  }

  state ={
    orders:[],
    loading:false,
    page:1,
    pages:1,
    status:'pending',
    modal:this.props.location.state?this.props.location.state.modal:false,
    order:this.props.location.state?this.props.location.state.data:[],
    salesMen:[],
    paidDate:"",
    salesMan:null,
    buySalesMan:null,
    
  }

  componentDidMount(){
    this.getSalesMen()
    this.getPendingOrders(1,false)
  }
  getSalesMen = () => {
    let url =``;
    if(this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN" ){
      if(this.props.company == false){
        url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN`
      }else{
        url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN&company=${this.props.company}`

      }
    } else{
      url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN&company=${this.props.currentUser.user.id}`
    }
    axios.get(url)
    .then(response=>{
      console.log(response.data.users)
      this.setState({salesMen:response.data.users})
    })
    .catch(error=>{
      console.log("ALL salesMen ERROR")
      console.log(error.response)
    })
  }
  getPendingOrders = (page,refresh) =>{
    this.setState({loading:true})
    let url
    if(this.props.currentUser.user.type =="ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN"){
      url =`${BASE_END_POINT}orders?page=${page}&status=PENDING`
      if(this.props.company == false){
        url =`${BASE_END_POINT}orders?page=${page}&status=PENDING`
      }else{
        url =`${BASE_END_POINT}orders?page=${page}&status=PENDING&company=${this.props.company}`
      }
    }else{
      url = `${BASE_END_POINT}orders?page=${page}company=${this.props.currentUser.user.id}&status=PENDING`
    }
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("orders   ",response.data)
      this.setState({
        loading:false,
        refresh:false,
        orders:response.data.data,
        page:response.data.page,
        pages:response.data.pageCount,
        status:'pending'
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })

  }
  getOnwayOrders = (page,refresh) =>{
    this.setState({loading:true})
    let url
    if(this.props.currentUser.user.type =="ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN"){
      url =`${BASE_END_POINT}orders?page=${page}&status=ON_THE_WAY`
      if(this.props.company == false){
        url =`${BASE_END_POINT}orders?page=${page}&status=ON_THE_WAY`
      }else{
        url =`${BASE_END_POINT}orders?page=${page}&status=ON_THE_WAY&company=${this.props.company}`
      }
    }else{
      url = `${BASE_END_POINT}orders?page=${page}company=${this.props.currentUser.user.id}&status=ON_THE_WAY`
    }
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("orders   ",response.data)
      this.setState({
        loading:false,
        refresh:false,
        orders:response.data.data,
        page:response.data.page,
        pages:response.data.pageCount,
        status:'onWay'
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
  getDeliverdOrders = (page,refresh) =>{
    this.setState({loading:true})
    let url
    if(this.props.currentUser.user.type =="ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN"){
      if(this.props.company == false){
        url =`${BASE_END_POINT}orders?page=${page}&status=DELIVERED`
      }else{
        url =`${BASE_END_POINT}orders?page=${page}&status=DELIVERED&company=${this.props.company}`
      }
    }else{
      url = `${BASE_END_POINT}orders?page=${page}company=${this.props.currentUser.user.id}&status=DELIVERED`
    }
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("orders   ",response.data)
      this.setState({
        loading:false,
        refresh:false,
        orders:response.data.data,
        page:response.data.page,
        pages:response.data.pageCount,
        status:'delivered'
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
  callback = (key) =>{
    console.log(key);
    if(key == 1){
      this.getDeliverdOrders(1,false)
    }
    if(key == 2){
      this.getOnwayOrders(1,false)
    }
    if(key == 3){
      this.getPendingOrders(1,false)
    }
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  }
  openOrder = (order) =>{
    this.setState({
      modal: !this.state.modal,
      order:order,
      
    });
    if(order.paidDate){
      this.setState({paidDate:moment(order.paidDate).format("YYYY/MM/DD")})
    } else{
      this.setState({paidDate:moment().format("YYYY/MM/DD")})
    }
  }
  onChangePaidDate = (date,dateString) => { 

    console.log(date, dateString)
    if(this.props.isRTL){
      this.setState({paidDate:moment(dateString).format("YYYY/MM/DD") })
    }else{
      this.setState({paidDate:moment(dateString).locale('en').format("YYYY/MM/DD") })
    }
    paidDate = moment(dateString).locale('en').format("YYYY-MM-DD")

    console.log(paidDate)
  }
  changeStatus = (status) => {
    const {buySalesMan,salesMan,paidDate} = this.state;
    let l = message.loading('Wait..', 2.5)
      let data ={
        "status":status,
        "salesMan":salesMan,
        "buySalesMan":buySalesMan,
      }
      if(paidDate == ""){
        data.paidDate = moment(new Date()).locale('en').format("YYYY-MM-DD") 
      } else{
        data.paidDate = moment(paidDate).locale('en').format("YYYY-MM-DD")  
      }
      if(status != "ACCEPTED"){
        data = {
          "status":status,
        }
      }
      console.log(data)
      axios.put(`${BASE_END_POINT}orders/${this.state.order.id}/status`,data,{
        headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`
        },
        })
        .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
          this.toggle()
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error(allStrings.chooseSalesMan, 2.5))
        })
  }
  modal = () =>(
    <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
      <MDBModalHeader toggle={this.toggle}>{allStrings.orderInfo}</MDBModalHeader>
      <MDBModalBody>
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12">
              <form style={{marginLeft: '15%'}}
                className="needs-validation"
                noValidate
              >
                <MDBContainer>
                  <MDBRow>
                    <MDBCol md = "2">
                      <div style={{paddingTop:'5px',cursor:'pointer'}} onClick={() => this.props.history.push('/clientForm',{data:this.state.order.client})} >
                        <span style={{
                          fontSize: '17px',
                          color: '#478DFF',
                          fontWeight: '600',
                          margin: '5px'
                        }}>{allStrings.showD}</span>
                        <img alt=""  width="20px" src={require('../../assets/images/icons8_external_link_480px_2.png')}></img>
                      </div>
                    </MDBCol>
                    <MDBCol md="7">
                      <input name="branchName" type="text" id="defaultFormRegisteNameEx1" required
                        disabled placeholder={allStrings.client} className="form-control" style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.order.client?this.state.order.client.fullname:''} />
                    </MDBCol>
                    <MDBCol md="3">
                      <label  htmlFor="defaultFormRegisteNameEx1" className="grey-text formLabel">
                      {allStrings.client}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md = "2">
                      
                    </MDBCol>
                    <MDBCol md="7">
                      <input name="address" type="text" id="defaultFormRegisterameEx" required
                        disabled placeholder={allStrings.address} className="form-control" style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.order.client?this.state.order.client.address:''} />
                    </MDBCol>
                    <MDBCol md="3">
                      <label  htmlFor="defaultFormRegisterameEx" className="grey-text formLabel">
                      {allStrings.address}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md = "2">
                      <div style={{paddingTop:'5px',cursor:'pointer'}} onClick={() => this.props.history.push('/stockForm',{data:this.state.order.product})} >
                        <span style={{
                          fontSize: '17px',
                          color: '#478DFF',
                          fontWeight: '600',
                          margin: '5px'
                        }}>{allStrings.showD}</span>
                        <img alt=""  width="20px" src={require('../../assets/images/icons8_external_link_480px_2.png')}></img>
                      </div>
                    </MDBCol>
                    <MDBCol md="7">
                      <input name="product" type="text" id="defaultFormRegisterNameEx" required
                        disabled placeholder={allStrings.product} className="form-control" style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.order.product?this.props.isRTL?this.state.order.product.name_ar:this.state.order.product.name_en:''} />
                    </MDBCol>
                    <MDBCol md="3">
                      <label  htmlFor="defaultFormRegisterNameEx" className="grey-text formLabel">
                      {allStrings.product}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md = "2">
                     
                    </MDBCol>
                    <MDBCol md="7">
                      <div className="orderType">
                         <p>{this.state.order.paymentSystem=="cash"?allStrings.cash:allStrings.installment}</p>
                      </div>
                      {this.state.order.paymentSystem !="cash" &&
                      <div className="orderPackage">
                        <MDBContainer>
                          <MDBRow>
                            <MDBCol md="3" sm="3" xs="3" className="noPadding" style={{textAlign:'right'}}>
                              <span className="orderPackageLabel">{allStrings.pound}</span>
                            </MDBCol>
                            <MDBCol md="6" sm="6" xs="6">
                            <input name="firstPaid" disabled type="text" id="defaultFormRegisterameEx1" required
                            className="form-control orderPackageInput" style={{direction:this.props.isRTL?'rtl':'ltr'}}  defaultValue={this.state.order.firstPaid}/>
                            </MDBCol>
                            <MDBCol md="3" sm="3" xs="3" className="noPadding" style={{textAlign:'left'}}>
                              <span className="orderPackageLabel">{allStrings.firstPaid}</span>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol md="3" sm="3" xs="3" className="noPadding" style={{textAlign:'right'}}>
                              <span className="orderPackageLabel">{allStrings.months}</span>
                            </MDBCol>
                            <MDBCol md="6" sm="6" xs="6">
                            <input name="months" disabled type="text" id="defaultFormegisterNameEx1" required
                            className="form-control orderPackageInput" style={{direction:this.props.isRTL?'rtl':'ltr'}}  defaultValue={this.state.order.monthCount}/>
                            </MDBCol>
                            <MDBCol md="3" sm="3" xs="3" className="noPadding" style={{textAlign:'left'}}>
                              <span className="orderPackageLabel">{allStrings.theMonths}</span>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol md="3" sm="3" xs="3" className="noPadding" style={{textAlign:'right'}}>
                              <span className="orderPackageLabel">{allStrings.pound}</span>
                            </MDBCol>
                            <MDBCol md="6" sm="6" xs="6" style={{textAlign:'center'}}>
                            <input name="installment" disabled type="text" id="deaultFormRegisterNameEx1" required
                              className="form-control orderPackageInput" defaultValue={this.state.order.costPerMonth}/>
                            </MDBCol>
                            <MDBCol md="3" sm="3" xs="3" className="noPadding" style={{textAlign:'left'}}>
                              <span className="orderPackageLabel">{allStrings.theInstallment}</span>
                            </MDBCol>
                          </MDBRow>
                        </MDBContainer>
                      </div>
                      }
                      <MDBBtn style={{ margin: '1rem 0rem ',width:'130px',padding:'11px 20px',height:'35px', }}
                          className="btn z-depth-1a "
                          color="green"
                          disabled={this.state.order.status != 'PENDING'?true:false}
                          onClick={()=> this.changeStatus('ACCEPTED')}
                        >
                          {allStrings.accept}
                      </MDBBtn>
                      <MDBBtn style={{ margin: '1rem 0rem ',width:'130px',padding:'11px 20px',height:'35px',float:'right' }}
                        className="btn z-depth-1a "
                        color="red" 
                        disabled={this.state.order.status != 'PENDING'?true:false}
                        onClick={()=> this.changeStatus('REFUSED')}
                      >
                        {allStrings.refuse}
                      </MDBBtn>
                    </MDBCol>
                    <MDBCol md="3">
                      <label  htmlFor="defaultFormRegisterNameEx" className="grey-text formLabel">
                      {allStrings.orderType}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md = "2">
                    </MDBCol>
                    <MDBCol md="7" sm="7">
                    <TreeSelect
                        defaultValue={this.state.order.salesMan?this.state.order.salesMan.id:[]}
                        showDSearch={false} 
                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left'}} 
                        treeCheckable = {false}         
                        treeNodeFilterProp="title"                      
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder={allStrings.chooseSalesMan}
                        disabled={this.state.order.status != 'PENDING'?true:false}
                        onChange={(value) => {
                          console.log(value)
                          this.setState({buySalesMan:value});
                        }} 
                      
                      >
                      {/*<TreeNode style={{background:"antiquewhite",color:'#fff !important'}} value="newClient" title={allStrings.newClient} key={allStrings.newClient} />*/}
                      {this.state.salesMen.map(val=>(
                          <TreeNode value={val.id} title={val.fullname} key={val.id} />
                      ))}
                      </TreeSelect>
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx1" className="grey-text formLabel">
                      {allStrings.buySalesMan}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md = "2">
                    </MDBCol>
                    <MDBCol md="7" sm="7">
                    <TreeSelect
                        showDSearch={true} 
                        defaultValue={this.state.order.salesMan?this.state.order.salesMan.id:[]}
                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left'}} 
                        treeCheckable = {false}         
                        treeNodeFilterProp="title"                      
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder={allStrings.chooseSalesMan}
                        disabled={this.state.order.status != 'PENDING'?true:false}
                        onChange={(value) => {
                          console.log(value)
                          this.setState({salesMan:value});
                        }} 
                      
                      >
                      {/*<TreeNode style={{background:"antiquewhite",color:'#fff !important'}} value="newClient" title={allStrings.newClient} key={allStrings.newClient} />*/}
                      {this.state.salesMen.map(val=>(
                          <TreeNode value={val.id} title={val.fullname} key={val.id} />
                      ))}
                      </TreeSelect>
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx1" className="grey-text formLabel">
                      {allStrings.collectSalesMan}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                    <div style={{marginBottom:'10px',textAlign:'right'}}>
                      <div style={{display:'inline-block',textAlign:'center',marginLeft:this.props.isRTL? '35px':"-93px"}}>
                      <DatePicker  defaultValue={this.state.paidDate?moment(moment(this.state.paidDate), 'YYYY/MM/DD'):moment(new Date(),'YYYY/MM/DD')} onChange={this.onChangePaidDate}/>
                      <span className="newSpanM"><img alt=""  style={{marginRight:'4px'}} src={require('../../assets/images/icons8_edit_property.png')}></img>{this.state.paidDate?moment(this.state.paidDate).format('YYYY/MM/DD'):moment().format('YYYY/MM/DD')}</span>
                      </div>
                    </div>
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx2" className="grey-text formLabel">
                      {allStrings.paidDate}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  {/*<MDBRow>
                    <MDBBtn type="submit" style={{ margin: '3rem 1rem ',width:'170px',padding:'11px 20px',height:'35px', }}
                        className="btn z-depth-1a "
                        color="primary"
                      >
                        {allStrings.done}
                    </MDBBtn>
                  </MDBRow>*/}
                </MDBContainer>
              </form>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBModalBody>
    </MDBModal>
   );


  render(){
    if (this.props.currentUser.user.type == "SUB-ADMIN" && !this.props.currentUser.user.pagesPermission.includes('ORDERS')) return <Redirect to='/Home' /> 
  
  return (
    <div className="Orders">
      <MDBContainer>
        {this.modal()}
        <MDBRow>
          <MDBCol md = "12">
            <div className="orderTabs">
              <Tabs onChange={this.callback} type="card" defaultActiveKey="3">
                <TabPane tab={allStrings.deliverd} key="1" >
                  <div className="orders">
                    {this.state.loading?
                      <div>
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                      </div>
                    
                    :
                    this.state.orders.length > 0 ?
                    this.state.orders.map((val) => (
                      <div className="order" >
                        <div className="orderInfoRight"> 
                            <p style={{fontSize: '18px',marginBottom: '6px',fontWeight: '500'}}>
                              {allStrings.productOrder}</p>
                            <p style={{
                              fontWeight: '400',
                              color: '#908F9A',
                              fontSize: '15px',
                            }}>
                            <span> ({val.client.fullname} )  </span> 
                            <span>{allStrings.order}</span><span> ({val.product?this.props.isRTL?val.product.name_ar:val.product.name_en:''}) </span>  
                            <span>{allStrings.from}</span>
                            <span> ({val.client.city?this.props.isRTL?val.client.city.cityName_ar:val.client.city.cityName_en:''}) </span> 
                            <span>{allStrings.from}</span>
                            </p>
                        </div>
                        <div className="orderInfoLeft"> 
                          <span>{moment(val.createdAt).calendar()} </span>
                        </div>
                      </div>
                    )) :
                    <div className="noData">{allStrings.noData}</div>
                    }
                  </div>
                </TabPane>
                <TabPane tab={allStrings.onWay} key="2">
                <div className="orders">
                    {this.state.loading?
                      <div>
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                      </div>
                    
                    :
                    this.state.orders.length > 0 ?
                    this.state.orders.map((val) => (
                      <div className="order" >
                        <div className="orderInfoRight"> 
                            <p style={{fontSize: '18px',marginBottom: '6px',fontWeight: '500'}}>
                              {allStrings.productOrder}</p>
                            <p style={{
                              fontWeight: '400',
                              color: '#908F9A',
                              fontSize: '15px',
                            }}>
                            <span> ({val.client.fullname} )  </span> 
                            <span>{allStrings.order}</span><span> ({val.product?this.props.isRTL?val.product.name_ar:val.product.name_en:''}) </span>  
                            <span>{allStrings.from}</span>
                            <span> ({val.client.city?this.props.isRTL?val.client.city.cityName_ar:val.client.city.cityName_en:''}) </span> 
                            <span>{allStrings.from}</span>
                            </p>
                        </div>
                        <div className="orderInfoLeft"> 
                          <span>{moment(val.createdAt).calendar()} </span>
                        </div>
                      </div>
                    )) :
                    <div className="noData">{allStrings.noData}</div>
                    }
                  </div>
                </TabPane>
                <TabPane tab={allStrings.newOrder} key="3" >
                <div className="orders">
                    {this.state.loading?
                      <div>
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                      </div>
                    
                    :
                    this.state.orders.length > 0 ?
                    this.state.orders.map((val) => (
                      <div className="order" onClick={()=>this.openOrder(val)}>
                        <div className="orderInfoRight"> 
                            <p style={{fontSize: '18px',marginBottom: '6px',fontWeight: '500'}}>
                              {allStrings.productOrder}</p>
                            <p style={{
                              fontWeight: '400',
                              color: '#908F9A',
                              fontSize: '15px',
                            }}>
                            <span> ({val.client.fullname} )  </span> 
                            <span>{allStrings.order}</span><span> ({val.product?this.props.isRTL?val.product.name_ar:val.product.name_en:''}) </span>  
                            <span>{allStrings.from}</span>
                            <span> ({val.client.city?this.props.isRTL?val.client.city.cityName_ar:val.client.city.cityName_en:''}) </span> 
                            <span>{allStrings.from}</span>
                            </p>
                        </div>
                        <div className="orderInfoLeft"> 
                          <span>{moment(val.createdAt).calendar()} </span>
                        </div>
                      </div>
                    )) :
                    <div className="noData">{allStrings.noData}</div>
                    }
                  </div>
                </TabPane>
              </Tabs>
              
            </div>
            
          </MDBCol>
          
        </MDBRow>
        <MDBRow>
          {this.state.orders.length > 0 &&
            <div style={{textAlign:'center',margin:'auto',marginTop:'3rem'}}>
              <Pagination
                onChange={(event,page)=>{
                console.log("page   ",page)
                if(page!=this.state.page){
                  if(this.state.status == 'pending'){
                    this.getPendingOrders(page)
                  }
                  if(this.state.status == 'onWay'){
                    this.getOnwayOrders(page)
                  }
                  if(this.state.status == 'delivered'){
                    this.getDeliverdOrders(page)
                  }
                  this.setState({page:page})
                }
                
                }} 
                defaultPage={1} page={this.state.page}  count={this.state.pages} style={{color:`blue !important`}} />
            </div>
          }
          </MDBRow>
      </MDBContainer>
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  branch: state.company.branch,
  company: state.company.company,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
}
export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(Orders)
);
