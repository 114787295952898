/* eslint-disable eqeqeq */

import React from 'react';
import allStrings from '../../assets/strings'
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import './notifs.css'
import { Tabs,message,Checkbox} from 'antd';
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import { setUser } from "../../redux/actions/AuthActions";
import Pagination from '@material-ui/lab/Pagination';
import 'antd/dist/antd.css';
import {hasValue} from '../../validations/validations'
import { MDBContainer ,MDBRow,MDBCol,MDBBtn,MDBModal,MDBIcon, MDBModalBody, MDBModalHeader} from 'mdbreact';
import Skeleton from 'react-loading-skeleton';
import {BASE_END_POINT,SOCKET_BASE_END} from '../../urls'
import axios from 'axios'
import moment from 'moment'
import io from 'socket.io-client';

const { TabPane } = Tabs;

class notifs extends React.Component {
  constructor(props){
    super(props)
    console.log("isr   ",this.props.isRTL)
    this.socket = io(SOCKET_BASE_END,{ query: `id=${this.props.currentUser.user.id}`} ); 
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
  }

  state ={
    notifs:[],
    loading:false,
    loading2:true,
    page:1,
    pages:1,
    type:'pending',
    modal:false,
    users:[],
    usersId:[],
    paidDate:"",
    usersType:'ALL',
    modalStep:"1",
    message:'',
    checkedUsers:[]
    
  }

  componentDidMount(){
    this.getClientsNotifs(1,false)
  }
  getUsers = (type) => {
    let url =``;
    if(this.props.currentUser.user.type == "ADMIN"){
      if(type=="ALL"){
        url = `${BASE_END_POINT}withoutPagenation/get`
      }else{
        url = `${BASE_END_POINT}withoutPagenation/get?type=${type}`
      }
      
    } else{
      if(type=="ALL"){
        url = `${BASE_END_POINT}withoutPagenation/get?company=${this.props.currentUser.user.id}`
      }else{
        url = `${BASE_END_POINT}withoutPagenation/get?type=${type}&company=${this.props.currentUser.user.id}`
      }
    }
    axios.get(url)
    .then(response=>{
      console.log(response.data.users)
      let usersId = []
      response.data.users.forEach(user => {
        usersId.push(user.id)
      });
      this.setState({users:response.data.users,loading2:false,usersId:usersId})
    })
    .catch(error=>{
      console.log("ALL users ERROR")
      console.log(error.response)
    })
  }
  
  getClientsNotifs = (page,refresh) =>{
    this.setState({loading:true})
    axios.get(`${BASE_END_POINT}notif?page=${page}&type=clients`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("notifs   ",response.data)
      this.setState({
        loading:false,
        refresh:false,
        notifs:response.data.data,
        page:response.data.page,
        pages:response.data.pageCount,
        type:'pending'
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })

  }
  getSalesMenNotifs = (page,refresh) =>{
    this.setState({loading:true})
    axios.get(`${BASE_END_POINT}notif?page=${page}&type=salesMan`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("notifs   ",response.data)
      this.setState({
        loading:false,
        refresh:false,
        notifs:response.data.data,
        page:response.data.page,
        pages:response.data.pageCount,
        type:'onWay'
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
  getStockNotifs = (page,refresh) =>{
    this.setState({loading:true})
    axios.get(`${BASE_END_POINT}notif?page=${page}&type=stock`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("notifs   ",response.data)
      this.setState({
        loading:false,
        refresh:false,
        notifs:response.data.data,
        page:response.data.page,
        pages:response.data.pageCount,
        type:'delivered'
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
  getPartnerNotifs = (page,refresh) =>{
    this.setState({loading:true})
    axios.get(`${BASE_END_POINT}notif?page=${page}&type=partner`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("notifs   ",response.data)
      this.setState({
        loading:false,
        refresh:false,
        notifs:response.data.data,
        page:response.data.page,
        pages:response.data.pageCount,
        type:'delivered'
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
  callback = (key) =>{
    console.log(key);
    if(key == 1){
      this.getPartnerNotifs(1,false)
    }
    if(key == 2){
      this.getSalesMenNotifs(1,false)
    }
    if(key == 3){
      this.getClientsNotifs(1,false)
    }
    if(key == 4){
      this.getStockNotifs(1,false)
    }
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      modalStep:'1',
      checkedUsers:[]
    });
  }
  openNotif = () =>{
    this.setState({
      modalReq:'notifs',
      modal: !this.state.modal,
    })
  }
  openSms = () =>{
    this.setState({
      modalReq:'sms',
      modal: !this.state.modal,
    })
  }
  openMsg = () =>{
    this.setState({
      modalReq:'msg',
      modal: !this.state.modal,
    })
  }
  send = () => {
    const msg = this.state.message
    console.log(msg)
    if(hasValue(msg)){
        let l = message.loading('Wait..', 2.5)
        if(this.state.modalReq !="msg"){
          let data ={
            "description":msg,
            "users":this.state.checkedUsers
          }
          let url = ``;
          if(this.state.modalReq == 'notifs'){
            url = `${BASE_END_POINT}sendnotif`
          }
          if(this.state.modalReq == 'sms'){
            url = `${BASE_END_POINT}send-sms`
          }
          axios.post(url,data,{
          headers: {
          'Content-Type': 'application/json',
          'Authorization':`Bearer ${this.props.currentUser.token}`
          },
          })
          .then(response=>{
          l.then(() => message.success(allStrings.done, 2.5) )
            this.setState({modalStep:'1',checkedUsers:[]})
            this.toggle()
          })
          .catch(error=>{
              console.log(error.response)
              l.then(() => message.error('Error', 2.5))
          })
        }else{
          let data = {
            text: msg,
            user:{
                _id:this.props.currentUser.user.id
            },
          }        
          this.state.checkedUsers.forEach(user => {
            this.socket.emit("newMessage",{
              toId: user,
              data: data
            });
          });
          
          l.then(() => message.success(allStrings.done, 2.5) )
          this.setState({modalStep:'1',checkedUsers:[]})
          this.toggle()
        }
    }
  }
  changeHandler = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
 
  ckeckAll =() =>{
    let {usersId} = this.state
    this.setState({checkedUsers:usersId})
  }
  modal = () =>(
    <MDBModal isOpen={this.state.modal} toggle={this.toggle} size={this.state.modalStep=="1"?"md":"lg"}>
      <MDBModalHeader toggle={this.toggle}>
      {this.state.modalStep=="2" &&
        <div>
          <MDBBtn style={{background:"crimson",color:'#fff' ,margin: '0rem 5rem ',width:'100px',padding:'9px 20px',height:'33px',borderRadius:'22px'}}
            className="btn z-depth-1a "
            color="crimson"
            onClick={this.ckeckAll}
          >
            {allStrings.all}
          </MDBBtn>
          <p style={{display: 'inline-block',color: 'green',float: 'right'}}>اكتب النص</p>
        </div>
      }
      </MDBModalHeader>
      <MDBModalBody>
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12">
              {this.state.modalStep =="1" &&
                <div style={{textAlign:'center',padding:'2rem 0rem'}}> 
                  <MDBBtn style={{ margin: '1rem 1rem ',width:'160px',padding:'11px 20px',height:'55px',
                    fontSize: '22px',
                    fontWeight: '600',
                    fontFamily:' system-ui',color:'#fff' ,background:'#4F73AC' }}
                    className="btn z-depth-1a "
                    color="#4F73AC"
                    onClick={() => {
                      this.setState({usersType:'CLIENT',modalStep:"2"})
                      this.getUsers('CLIENT')
                    }} 
                  >
                    {allStrings.clients}
                  </MDBBtn>
                  <MDBBtn type="submit" style={{ margin: '1rem 1rem ',width:'160px',padding:'11px 20px',height:'55px',
                    fontSize: '22px',
                    fontWeight: '600',
                    fontFamily:' system-ui' ,background:'#4F73AC',color:'#fff' }}
                    className="btn z-depth-1a "
                    color="#4F73AC"
                    onClick={() => {
                      this.setState({usersType:'SALES-MAN',modalStep:"2"})
                      this.getUsers('SALES-MAN')
                    }} 
                  >
                    {allStrings.salesMen}
                  </MDBBtn>
                  <MDBBtn type="submit" style={{ margin: '1rem 1rem ',width:'160px',padding:'11px 20px',height:'55px',
                    fontSize: '22px',
                    fontWeight: '600',
                    fontFamily:' system-ui' ,background:'#4F73AC',color:'#fff' }}
                    className="btn z-depth-1a "
                    color="#4F73AC"
                    onClick={() => {
                      this.setState({usersType:'COMPANY',modalStep:"2"})
                      this.getUsers('COMPANY')
                    }} 
                  >
                    {allStrings.companies}
                  </MDBBtn>
                  <MDBBtn type="submit" style={{ margin: '1rem 1rem ',width:'160px',padding:'11px 20px',height:'55px', 
                    fontSize: '22px',
                    fontWeight: '600',
                    fontFamily:' system-ui',background:'#4F73AC',color:'#fff'  }}
                    className="btn z-depth-1a "
                    color="#4F73AC"
                    onClick={() => {
                      this.setState({usersType:'PARTNER',modalStep:"2"})
                      this.getUsers('PARTNER')
                    }} 
                  >
                    {allStrings.partners}
                  </MDBBtn>
                  <MDBBtn type="submit" style={{ margin: '1rem 1rem ',width:'354px',padding:'11px 20px',height:'55px', 
                    fontSize: '22px',
                    fontWeight: '600',
                    fontFamily:' system-ui',background:'#4F73AC',color:'#fff'  }}
                    className="btn z-depth-1a "
                    color="#4F73AC"
                    onClick={() => {
                      this.setState({usersType:'ALL',modalStep:"2"})
                      this.getUsers('ALL')
                    }} 
                  >
                    {allStrings.all}
                  </MDBBtn>
                </div>
                }
                {this.state.modalStep =="2" &&
                <div className="usersCheck">
                  <textarea name="message" onChange={this.changeHandler} type="text" id="defaultFormRegisterNamx1" required
                      placeholder={allStrings.message} className="form-control"
                      style={{width: '91%',marginBottom:'1rem',
                      float: 'right',direction:this.props.isRTL?'rtl':'ltr'}} />
                  <div>
                  <Checkbox.Group style={{ width: '100%' }} value={this.state.checkedUsers} onChange={this.onChange}>
                  <MDBRow>
                    
                    {this.state.loading2?
                      <div style={{height:"200px",width: '100%',margin:'0rem 1rem 0rem 5rem'}}>
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                      </div>
                    :
                    this.state.users.length > 0 ?
                    this.state.users.map((val) => (
                      <MDBCol md ="4" style={{textAlign: 'right',marginTop:'1rem'}}>
                        <Checkbox value={val.id}>{val.fullname}</Checkbox>
                      </MDBCol>
                    )) :
                    <div className="noData">{allStrings.noData}</div>
                    }
                    
                  </MDBRow>
                  </Checkbox.Group>
                  </div>

                  <MDBBtn  style={{ margin: '3rem 1rem ',width:'170px',padding:'11px 20px',height:'35px', 
                    background:'#4F73AC',color:'#fff'  }}
                    className="btn z-depth-1a "
                    color="#4F73AC"
                    onClick={() => {
                      this.send()
                    }} 
                  >
                    {allStrings.send}
                  </MDBBtn>
                </div>
                }
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBModalBody>
    </MDBModal>
   );
   onChange = (checkedValues) =>{
     this.setState({checkedUsers:checkedValues})
    console.log(`checkedValues = ${checkedValues}`);
  }


  render(){
    
  return (
    <div className="Notifs">
      <div className="categoryTop" style={{marginBottom:'3rem'}}>
      <MDBContainer> 
        <MDBRow>
         
          <MDBCol xl="4" lg="4" md="4" sm="12">
            <div className="addButton" onClick={this.openSms}>
              <MDBIcon icon="plus" />
            </div>
            <span
            onClick={this.openSms}
            style={{
              float: 'right',
              color: '#43425D',
              fontWeight: '600',
              padding: '11px 2px',
              fontSize: '20px',
              cursor:'pointer',
            }}>{allStrings.sendSms}</span>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="4" sm="12">
            <div className="addButton"onClick={this.openMsg} >
              <MDBIcon icon="plus" />
            </div>
            <span
            onClick={this.openMsg}
            style={{
              float: 'right',
              color: '#43425D',
              fontWeight: '600',
              padding: '11px 2px',
              fontSize: '20px',
              cursor:'pointer',
            }}>{allStrings.sendMessage}</span>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="4" sm="12">
            <div className="addButton"  onClick={this.openNotif}>
              <MDBIcon icon="plus" />
            </div>
            <span
            onClick={this.openNotif}
            style={{
              float: 'right',
              color: '#43425D',
              fontWeight: '600',
              padding: '11px 2px',
              fontSize: '20px',
              cursor:'pointer',
            }}>{allStrings.sendNotif}</span>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
        </div>
      <MDBContainer>
        {this.modal()}
        <MDBRow>
          <MDBCol md = "12">
            <div className="theNotifsTabs">
              <Tabs onChange={this.callback} type="card" defaultActiveKey="3">
                <TabPane tab={allStrings.partners} key="1" >
                  <div className="theNotifss">
                    {this.state.loading?
                      <div>
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                      </div>
                    
                    :
                    this.state.notifs.length > 0 ?
                    this.state.notifs.map((val) => (
                      <div className="theNotifs" >
                        <div className="theNotifsInfoRight"> 
                            <p style={{fontSize: '18px',marginBottom: '6px',fontWeight: '500'}}>
                              {allStrings.productnotifs}</p>
                            <p style={{
                              fontWeight: '400',
                              color: '#908F9A',
                              fontSize: '15px',
                            }}>
                            <span> ({val.resource.fullname} )  </span> 
                            <span>{allStrings.notifs}</span><span> ({val.product?this.props.isRTL?val.product.name_ar:val.product.name_en:''}) </span>  
                            <span>{allStrings.from}</span>
                            <span> ({val.resource.city?this.props.isRTL?val.resource.city.cityName_ar:val.resource.city.cityName_en:''}) </span> 
                            <span>{allStrings.from}</span>
                            </p>
                        </div>
                        <div className="theNotifsInfoLeft"> 
                          <span>{moment(val.createdAt).calendar()} </span>
                        </div>
                      </div>
                    )) :
                    <div className="noData">{allStrings.noData}</div>
                    }
                  </div>
                </TabPane>
                <TabPane tab={allStrings.salesMen} key="2">
                <div className="theNotifss">
                    {this.state.loading?
                      <div>
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                      </div>
                    
                    :
                    this.state.notifs.length > 0 ?
                    this.state.notifs.map((val) => (
                      <div className="theNotifs" >
                        <div className="theNotifsInfoRight"> 
                            <p style={{fontSize: '18px',marginBottom: '6px',fontWeight: '500'}}>
                              {allStrings.productnotifs}</p>
                            <p style={{
                              fontWeight: '400',
                              color: '#908F9A',
                              fontSize: '15px',
                            }}>
                            <span>{val.description}</span> 
                            </p>
                        </div>
                        <div className="theNotifsInfoLeft"> 
                          <span>{moment(val.createdAt).calendar()} </span>
                        </div>
                      </div>
                    )) :
                    <div className="noData">{allStrings.noData}</div>
                    }
                  </div>
                </TabPane>
                <TabPane tab={allStrings.clients} key="3">
                <div className="theNotifss">
                    {this.state.loading?
                      <div>
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                      </div>
                    
                    :
                    this.state.notifs.length > 0 ?
                    this.state.notifs.map((val) => (
                      <div className="theNotifs" >
                        <div className="theNotifsInfoRight"> 
                            <p style={{fontSize: '18px',marginBottom: '6px',fontWeight: '500'}}>
                              {allStrings.productnotifs}</p>
                            <p style={{
                              fontWeight: '400',
                              color: '#908F9A',
                              fontSize: '15px',
                            }}>
                            <span>{val.description}</span> 
                            </p>
                        </div>
                        <div className="theNotifsInfoLeft"> 
                          <span>{moment(val.createdAt).calendar()} </span>
                        </div>
                      </div>
                    )) :
                    <div className="noData">{allStrings.noData}</div>
                    }
                  </div>
                </TabPane>
                <TabPane tab={allStrings.stock} key="4">
                <div className="theNotifss">
                    {this.state.loading?
                      <div>
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                        <Skeleton height={90} />
                      </div>
                    
                    :
                    this.state.notifs.length > 0 ?
                    this.state.notifs.map((val) => (
                      <div className="theNotifs" >
                        <div className="theNotifsInfoRight"> 
                            <p style={{fontSize: '18px',marginBottom: '6px',fontWeight: '500'}}>
                              {allStrings.productnotifs}</p>
                            <p style={{
                              fontWeight: '400',
                              color: '#908F9A',
                              fontSize: '15px',
                            }}>
                            <span>{val.description}</span> 
                            </p>
                        </div>
                        <div className="theNotifsInfoLeft"> 
                          <span>{moment(val.createdAt).calendar()} </span>
                        </div>
                      </div>
                    )) :
                    <div className="noData">{allStrings.noData}</div>
                    }
                  </div>
                </TabPane>
              </Tabs>
              
              
            </div>
            
          </MDBCol>
          
        </MDBRow>
        <MDBRow>
          {this.state.notifs.length > 0 &&
            <div style={{textAlign:'center',margin:'auto',marginTop:'3rem'}}>
              <Pagination
                onChange={(event,page)=>{
                console.log("page   ",page)
                if(page!=this.state.page){
                  if(this.state.type == 'clients'){
                    this.getClientsNotifs(page)
                  }
                  if(this.state.type == 'salesMan'){
                    this.getSalesMenNotifs(page)
                  }
                  if(this.state.type == 'partner'){
                    this.getPartnerNotifs(page)
                  }
                  if(this.state.type == 'stock'){
                    this.getStockNotifs(page)
                  }
                  this.setState({page:page})
                }
                
                }} 
                defaultPage={1} page={this.state.page}  count={this.state.pages} style={{color:`blue !important`}} />
            </div>
          }
          </MDBRow>
      </MDBContainer>
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,

})

const mapDispatchToProps = {
  ChangeLanguage,
  setUser,
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(notifs)
);
