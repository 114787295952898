
import {NOTIFICATIONS,UNREADED_NOTIFICATION} from './types'
import { BASE_END_POINT } from "../../urls";
import axios from "axios";


export function getNotifications(page,refresh,token) {
    console.log('un seen messages')
    return dispatch => {
        axios.get(`${BASE_END_POINT}notif?page=${page}&limit=20`,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
          })
        .then(response=>{
          console.log("NOTIFCATIONS =>  ",response.data.data)
          dispatch({type:NOTIFICATIONS,payload:response.data.data,page:page,pages:response.data.pageCount, refresh:refresh})       
        })
        .catch(error=>{
          console.log("UN SEEN ERROR")
          console.log(error.response)
        })
      
    }
}

export function getUnreadNotificationsCount(token) {
  return dispatch => {
      axios.get(`${BASE_END_POINT}notif/unreadCount`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`  
          },
      })
      .then(response=>{
          console.log('noti count 22')
          console.log(response.data);
          dispatch({type:UNREADED_NOTIFICATION,payload:response.data.unread})
      }).catch(error=>{
          console.log('noti count error 22')
          console.log(error.response);
      })
  }
}