/* eslint-disable no-unused-vars */
import React from 'react';
import moment from 'moment'
import locale_ar from 'antd/lib/locale/ar_EG';
import locale_en from 'antd/lib/locale/en_GB';
import 'moment/locale/ar';
import { connect } from 'react-redux'
import {MDBContainer,MDBRow,MDBCol} from "mdbreact";
import "./cash bill.css";
import allStrings from '../../assets/strings'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import {ChangeCompany,ChangeBranch} from '../../redux/actions/CompanyAction'
import {setUser} from '../../redux/actions/AuthActions'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
class CashBill extends React.Component {
  state = {
    productsCount:0,
    advertisementUrl:''
  }
  constructor(props){
    super(props)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
   
  }
  getSetting = () =>{
    this.setState({loading:true})
    axios.get(`${BASE_END_POINT}setting`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      console.log("Setting   ",response.data)
      this.setState({
        advertisementUrl:response.data.advertisementUrl,
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
  componentDidMount() {
    //this.getSetting()
    console.log("data",this.props)
    let products = this.props.data.multiProducts
    let productsCount = 0
    products.forEach(element => {
      productsCount = productsCount +element.count
    });
    console.log("productsCount",productsCount)
    this.setState({productsCount: productsCount})
  }

  render(){
  const {data,advertisementUrl} = this.props
  console.log("advertisementUrl",advertisementUrl)
      return ( 
        <div style={{textAlign:'center',background:'#FFFFFF',fontFamily:'Roboto'}}>
          {/* فاتوره مبيعات  */}
          <MDBContainer>
            <MDBRow>
              <MDBCol size="12">
                  <MDBRow>
                    <MDBCol size="7"  style={{paddingRight:'0px'}}>
                      <p style={{background: '#DADADA',padding: '5px',marginBottom:'0px',
                        fontWeight: '600',fontSize: '20px',border:'2px solid ',borderBottom:'none',borderRight:'none'}}>{advertisementUrl?advertisementUrl.substring(0,40):"لايوجد"}</p>
                    </MDBCol>
                    <MDBCol size="5"  style={{paddingLeft:'0px'}}>
                    <p style={{background: '#DADADA',padding: '5px',marginBottom:'0px',
                        fontWeight: '600',fontSize: '20px',border:'2px solid ',borderBottom:'none',borderLeft:'none'}}> فاتوره مبيعات</p>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                  <MDBCol size="5" style={{paddingRight:'0px'}}>
                    <div style={{textAlign: 'end',padding: '10px',height: '140px',fontWeight: '600',
                        fontSize: '20px',border:'2px solid ',borderRight:'none'}}>
                      <p>
                        <span> فاتوره رقم    </span>
                        <span style={{margin:'20%'}}> {data?data.id:""} </span>
                      </p>
                      <p>
                        <span> التاريخ / </span>
                        <span style={{margin:'18%'}}>{data?moment(data.createdAt).format("YYYY/MM/DD"):""}</span>
                      </p>
                    </div>
                  </MDBCol>
                  <MDBCol size="7" style={{paddingLeft:'0px'}}>
                    <div style={{textAlign: 'end',padding: '10px',height: '140px',
                        fontWeight: '600',fontSize: '30px',border:'2px solid '}}>
                      <p style={{textAlign:'center'}}>{data?data.company.fullname.substring(0, 20):"لايوجد"}</p>
                      <p>{data?data.company.companySpecialization.substring(0, 40):"شاشات - لابات - اجهزه كهربائيه - موبايلات"}</p>
                    </div>
                  </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol size="6"  style={{paddingRight:'0px'}}>
                      <div style={{textAlign: 'end',padding: '10px',height: '140px',fontWeight: '600',
                          fontSize: '20px',border:'2px solid ',borderRight:'none',borderTop:'none'}}>
                        <p>
                          <span> مندوب البيع :  </span>
                          <span> {data?data.buySalesMan.fullname.substring(0, 20):"لايوجد"}</span>
                        </p>
                      </div>
                    </MDBCol>
                    <MDBCol size="6" style={{paddingLeft:'0px'}}>
                      <div style={{textAlign: 'end',padding: '10px',height: '140px',fontWeight: '600',
                          fontSize: '20px',border:'2px solid ',borderLeft:'none',borderTop:'none'}}>
                        <p>
                          <span> كود العميل / </span>
                          {/* <span>{data?data.client.id:"لايوجد"}</span> */}
                          <span>{data.client.clientCompanyIds.find(e => e.company === data.company.id)?data.client.clientCompanyIds.find(e => e.company === data.company.id).value:"لايوجد"}</span>
                        </p>
                        <p>
                          <span> اسم العميل :  </span>
                          <span> {data?data.client.fullname.substring(0, 20):"لايوجد"}</span>
                        </p>
                        <p>
                          <span>  تليفون العميل :  </span>
                          <span style={{margin:'20%'}}> {data?data.client.phone.substring(0, 20):"لايوجد"} </span>
                        </p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <br></br>
                  <MDBRow>
                    <MDBCol size="12">
                      <div className='tabelOrders'>     
                          <table class="table table-bordered table-sm">
                              <thead>
                                  <tr class="thead-light" >
                                    <th>القيمه </th>
                                    <th> السعر</th>
                                    <th>العدد  </th>
                                    <th> الصنف</th>
                                    <th>كود المنتج</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {data?data.multiProducts.map(val=>(
                                    <tr>
                                      <th scope="row">{val.cost?val.cost:'لايوجد'}</th>
                                      <th scope="row">{val.price?val.price:'0'}</th>
                                      <th scope="row">{val.count?val.count:'لايوجد'}</th>
                                      <th scope="row">{val.product?val.product.name_ar:'لايوجد'}</th>
                                      <th scope="row">{val.product?val.product.id:'لايوجد'}</th>
                                    </tr>
                                  )):""}
                                    <tr>
                                      <th scope="row"></th>
                                      <th scope="row"></th>
                                      <th scope="row">{this.state.productsCount}</th>
                                      <th scope="row"></th>
                                      <th scope="row"></th>
                                    </tr>
                                    <tr>
                                      <th scope="row">{data?data.total:""}</th>
                                      <th colspan="4" style={{textAlign:'end'}}>اجمالى الفاتوره</th>
                                    </tr>
                                    <tr>
                                      <th colspan="5" style={{textAlign:'end'}}>
                                        <p>
                                        {data?data.company.contactDetails?data.company.contactDetails.substring(0, 280):"لايوجد":"لايوجد"}
                                        </p>
                                      </th>
                                    </tr>
                                    
                              </tbody>
                          </table>
                      </div> 
                    </MDBCol>
                  </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      );
    }
  }

  const mapToStateProps = state => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
    branch: state.company.branch,
    company: state.company.company,
  })
  
  const mapDispatchToProps = {
    setUser,
    ChangeLanguage,
    ChangeCompany,
    ChangeBranch,
  }

  export default  connect(mapToStateProps,mapDispatchToProps)(CashBill);
