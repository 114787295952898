/* eslint-disable eqeqeq */
import React from 'react';
import './search.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn } from "mdbreact";
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import {setUser} from '../../redux/actions/AuthActions'
import { Input,Checkbox} from 'antd';
import LoadCard from '../../component/load card/load card'
import { SearchOutlined  } from '@ant-design/icons';
import {ChangeCompany,ChangeBranch} from '../../redux/actions/CompanyAction'

class Search extends React.Component {
  
  state = {
    clients:[],
    Products:[],
    salesMen:[],
    loading:true,
    refresh:false,
    page:1,
    pages:1,
    showStock:true,
    showSalesMen:true,
    showClients:true,
    value:this.props.history.location.state?this.props.history.location.state.data:'',
    checkedValues:["stock","clients","salesMen"]
  }
  constructor(props){
    super(props)
    console.log("isr   ",this.props.history)
    this.getClientWithoutPagenation()
    this.getProductsWithoutPagenation()
    this.getSalesMen()
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
    window.scrollTo(0,0)
  }
  getProductsWithoutPagenation = () => {
    this.setState({loading:true})
    let value = this.state.value
    console.log(value)
    let url =``;
    if(value != ''){
      if(this.props.currentUser.user.type === "ADMIN" || this.props.currentUser.user.type === "SUB-ADMIN"){
        if(this.props.company === false){
          url = `${BASE_END_POINT}products/withoutPagenation/get?name=${value}`
        }else{
          url = `${BASE_END_POINT}products/withoutPagenation/get?name=${value}&company=${this.props.company}`
        }
      } else{
        url = `${BASE_END_POINT}products/withoutPagenation/get?company=${this.props.currentUser.user.id}&name=${value}`
      }
    }else{
      if(this.props.currentUser.user.type === "ADMIN" || this.props.currentUser.user.type === "SUB-ADMIN"){
        if(this.props.company === false){
          url = `${BASE_END_POINT}products/withoutPagenation/get`
        }else{
          url = `${BASE_END_POINT}products/withoutPagenation/get&company=${this.props.company}`
        }
      } else{
        url = `${BASE_END_POINT}products/withoutPagenation/get?company=${this.props.currentUser.user.id}`
      }
    }
    
    axios.get(url)
    .then(response=>{
      console.log(response.data.products)
      this.setState({Products:response.data.products})
    })
    .catch(error=>{
      console.log("ALL salesMen ERROR")
      console.log(error.response)
    })
  }
  getClientWithoutPagenation = () => {
    this.setState({loading:true})
    let value = this.state.value
    let url =``;
    if(value != ''){
      if(this.props.currentUser.user.type === "ADMIN" || this.props.currentUser.user.type === "SUB-ADMIN"){
        if(this.props.company === false){
          url = `${BASE_END_POINT}withoutPagenation/get?type=CLIENT&name=${value}`
        }else{
          url = `${BASE_END_POINT}withoutPagenation/get?type=CLIENT&name=${value}&company=${this.props.company}`
        }
      } else{
        url = `${BASE_END_POINT}withoutPagenation/get?type=CLIENT&company=${this.props.currentUser.user.id}&name=${value}`
      }
    }else{
      if(this.props.currentUser.user.type === "ADMIN" || this.props.currentUser.user.type === "SUB-ADMIN"){
        if(this.props.company === false){
          url = `${BASE_END_POINT}withoutPagenation/get?type=CLIENT`
        }else{
          url = `${BASE_END_POINT}withoutPagenation/get?type=CLIENT&company=${this.props.company}`
        }
      } else{
        url = `${BASE_END_POINT}withoutPagenation/get?type=CLIENT&company=${this.props.currentUser.user.id}`
      }
    }
    
    axios.get(url)
    .then(response=>{
      console.log(response.data.users)
      this.setState({clients:response.data.users,})
    })
    .catch(error=>{
      console.log("ALL salesMen ERROR")
      console.log(error.response)
    })
  }
  getSalesMen = () => {
    this.setState({loading:true})
    let value = this.state.value
    let url =``;
    if(value != ''){
      if(this.props.currentUser.user.type === "ADMIN" || this.props.currentUser.user.type === "SUB-ADMIN"){
        if(this.props.company === false){
          url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN&name=${value}`
        }else{
          url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN&name=${value}&company=${this.props.company}`
        }
      } else{
        url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN&company=${this.props.currentUser.user.id}&name=${value}`
      }
    }else{
      if(this.props.currentUser.user.type === "ADMIN" || this.props.currentUser.user.type === "SUB-ADMIN"){
        if(this.props.company === false){
          url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN`
        }else{
          url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN&company=${this.props.company}`
        }
      } else{
        url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN&company=${this.props.currentUser.user.id}`
      }
    }
    axios.get(url)
    .then(response=>{
      console.log(response.data.users)
      this.setState({salesMen:response.data.users,loading:false})
    })
    .catch(error=>{
      console.log("ALL salesMen ERROR")
      console.log(error.response)
    })
  }
  componentDidMount(){
  }
  onChange = (checkedValues) => {
    console.log('checked = ', checkedValues);
    let stock = checkedValues.indexOf("stock");
    let clients = checkedValues.indexOf("clients");
    let salesMen = checkedValues.indexOf("salesMen");
    console.log(salesMen)
    if(stock != -1){
      this.setState({showStock:true})
    }else{
      this.setState({showStock:false})
    }
    if(clients != -1){
      this.setState({showClients:true})
    }else{
      this.setState({showClients:false})
    }
    if(salesMen != -1){
      this.setState({showSalesMen:true})
    }else{
      this.setState({showSalesMen:false})
    }
  }
 
  render(){
  return (
    <div className="Category search">
      <div className="filters" style={{marginBottom: "2rem"}}>
        <MDBContainer> 
          <MDBRow>
            <MDBCol xl="4" lg="4" md="4" sm="12">
              <MDBBtn  
              onClick={() => {
                this.getClientWithoutPagenation()
                this.getSalesMen()
                this.getProductsWithoutPagenation()
                document.getElementById('theSearch').value = "";
              }} 
                style={{width:'150px',padding:'9px',height:'40px',float:'right',margin:0 }}
                    className="btn z-depth-1a "
                    color="primary"
                >
                  {allStrings.search}
              </MDBBtn>
            </MDBCol>
            <MDBCol xl="8" lg="8" md="8" sm="12">
              <Input 
              id="theSearch"
                onKeyUp={(e)=>{
                      console.log("ENTER   ",e.target.value)
                      this.setState({value:e.target.value})
                }} 
                placeholder={allStrings.search}
                size="large"  prefix={<SearchOutlined />} />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <Checkbox.Group defaultValue={this.state.checkedValues}
         style={{ width: '100%',textAlign: 'end',padding: '10px 30px' }}  onChange={this.onChange}>
          <Checkbox value="stock">{allStrings.stock}</Checkbox>
          <Checkbox value="salesMen">{allStrings.salesMen}</Checkbox>
          <Checkbox value="clients">{allStrings.clients}</Checkbox>
        </Checkbox.Group>
      </div>
      <MDBContainer> 
        {this.state.showStock === true &&
        <MDBRow>
          <MDBCol md="12">
            <label  htmlFor="defaultFormRegisterNameEx2"
            style={{
              float:'right',
              color: 'slategray',
              fontSize: '18px',
              marginBottom: '10px',
            }}>
            {allStrings.stock}
            </label>
          </MDBCol>
        </MDBRow>
        }
        {this.state.showStock === true &&
        <div className="searchCountainer">
          <MDBRow>
            {this.state.loading?
              <LoadCard/>
              :
              this.state.Products.length > 0 ?
                this.state.Products.map(product=>(
                  <MDBCol xl="4" lg="6" md="12" sm="12">
                  <div className="categoryCard">
                  <MDBContainer> 
                    <MDBRow>
                      <MDBCol xl="2" lg="2" md="2" sm="2">
                      </MDBCol>
                      <MDBCol xl="6" lg="6" md="6" sm="6" className="noPadding">
                        <div className="categoryInfo" onClick={() => this.props.history.push('/stockForm',{data:product})}>
                          <p style={{
                            fontWeight: '600',
                            color:'#43425D',
                            marginBottom: '6px',
                            fontSize: '22px'
      
                          }}>{product.name_ar}</p>
                          <p style={{
                          color: '#A4A3B0',
                          fontSize: '14px',
                          marginBottom: '8px'}}>{product.name_en}</p>
                          <p style={{
                          color: '#43425D',
                          fontSize: '16px',
                          margin: 0,
                          fontWeight: '400', marginBottom: '10px'}}>{product.id}</p>
                        </div>
                      </MDBCol>
                      <MDBCol xl="4" lg="4" md="4" sm="4">
                      
                        <div className="categoryImg" onClick={() => this.props.history.push('/stockForm',{data:product})}>
                          <img alt=""  src={product.mainImg}></img>
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer> 
                  </div>
                  </MDBCol>
                ))
                :
                <div className="noData">{allStrings.noData}</div>
            }
            </MDBRow>
        </div>
      }{this.state.showClients === true &&
        <MDBRow>
          <MDBCol md="12">
            <label  htmlFor="defaultFormRegisterNameEx2"
            style={{
              float:'right',
              color: 'slategray',
              fontSize: '18px',
              marginBottom: '10px',
            }}>
            {allStrings.clients}
            </label>
          </MDBCol>
        </MDBRow>
       }{this.state.showClients === true &&
        <div className="searchCountainer">
          <MDBRow>
          {this.state.loading?
            <LoadCard/>
            :
            this.state.clients.length > 0 ?
              this.state.clients.map(client=>(
                <MDBCol xl="4" lg="6" md="12" sm="12">
                <div className="categoryCard" >
                <MDBContainer> 
                  <MDBRow>
                    <MDBCol xl="2" lg="2" md="2" sm="2">
                    </MDBCol>
                    <MDBCol xl="6" lg="6" md="6" sm="6" className="noPadding">
                      <div className="categoryInfo" onClick={() => this.props.history.push('/clientForm',{data:client})} >
                        <p style={{
                          fontWeight: '600',
                          color:'#43425D',
                          marginBottom: '6px',
                          fontSize: '1.4rem'
    
                        }}>{client.fullname.length>10?client.fullname.substring(0, 11) + "..":client.fullname}</p>
                      
                        <p style={{
                        color: '#43425D',
                        fontSize: '16px',
                        marginBottom: '8px',
                        display:'inline-block'}}>{client.phone} </p>
                        <p style={{
                        color: '#43425D',
                        fontSize: '16px',
                        margin: 0,
                        marginBottom: '10px'}}>{client.anotherPhone}</p>
                        
                      </div>
                    </MDBCol>
                    <MDBCol xl="4" lg="4" md="4" sm="4">
                      <div className="categoryImg" >
                        <img alt=""  style={{width: '85px',
                        height: '85px',
                        borderRadius: '100%'}} src={client.img?client.img:require('../../assets/images/profileicon.jpg')}></img>
                      </div>
                    </MDBCol>
                  </MDBRow>
                
                </MDBContainer> 
                </div>
                </MDBCol>
              ))
              :
              <div className="noData">{allStrings.noData}</div>
          }
          </MDBRow>
        </div>
        }{this.state.showSalesMen === true &&
        <MDBRow>
          <MDBCol md="12">
            <label  htmlFor="defaultFormRegisterNameEx2"
            style={{
              float:'right',
              color: 'slategray',
              fontSize: '18px',
              marginBottom: '10px',
            }}>
            {allStrings.salesMen}
            </label>
          </MDBCol>
        </MDBRow>
        }{this.state.showSalesMen === true &&
        <div className="searchCountainer">
          <MDBRow>
          {this.state.loading?
            <LoadCard/>
            :
            this.state.salesMen.length > 0 ?
              this.state.salesMen.map(salesMan=>(
                <MDBCol xl="4" lg="6" md="12" sm="12">
                <div className="categoryCard" >
                <MDBContainer> 
                  <MDBRow>
                    <MDBCol xl="2" lg="2" md="2" sm="2">
                    </MDBCol>
                    <MDBCol xl="6" lg="6" md="6" sm="6" className="noPadding">
                      <div className="categoryInfo" onClick={() => this.props.history.push('/updateSalesMan',{data:salesMan})} >
                        <p style={{
                          fontWeight: '600',
                          color:'#43425D',
                          marginBottom: '6px',
                          fontSize: '1.4rem'
    
                        }}>{salesMan.fullname.length>10?salesMan.fullname.substring(0, 11) + "..":salesMan.fullname}</p>
                      
                        <p style={{
                        color: '#43425D',
                        fontSize: '16px',
                        marginBottom: '8px',
                        display:'inline-block'}}>{salesMan.phone} </p>
                        <p style={{
                        color: '#43425D',
                        fontSize: '16px',
                        margin: 0,
                        marginBottom: '10px'}}>{salesMan.anotherPhone}</p>
                        
                      </div>
                    </MDBCol>
                    <MDBCol xl="4" lg="4" md="4" sm="4">
                      <div className="categoryImg" >
                        <img alt=""  style={{width: '85px',
                        height: '85px',
                        borderRadius: '100%'}} src={salesMan.img?salesMan.img:require('../../assets/images/profileicon.jpg')}></img>
                      </div>
                    </MDBCol>
                  </MDBRow>
                
                </MDBContainer> 
                </div>
                </MDBCol>
              ))
              :
              <div className="noData">{allStrings.noData}</div>
          }
          </MDBRow>
        </div>
      }
      </MDBContainer> 
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  branch: state.company.branch,
  company: state.company.company,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
}

export default  withRouter( connect(mapToStateProps,mapDispatchToProps) (Search))
