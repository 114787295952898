import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux'

class LoadCard extends React.Component {
  
    render() {      
      return ( 
       
            <div style={{width:"100%"}}>
                  <Skeleton height={200} />
                 <div>
                 <Skeleton height={20} />
                 <Skeleton height={20} />
                 </div>
             </div>
       
      );
    }
  }

  const mapToStateProps = state => ({
   
  })
  
  const mapDispatchToProps = {
    
  }

  export default  connect(mapToStateProps,mapDispatchToProps)(LoadCard);
