
import { message} from 'antd';
import "antd/dist/antd.css";
import axios from 'axios';
import {
  LOGIN_REQUEST,LOGIN_SUCCESS,USER_TOKEN,CURRENT_USER
   } from './types';
import { BASE_END_POINT } from '../../urls';


//

export function login(user, FB_token, history) {
  // console.log(FB_token)
   return (dispatch) => {    
     dispatch({ type: LOGIN_REQUEST });
     let l = message.loading('Wait..', 2.5)
     axios.post(`${BASE_END_POINT}signin`, JSON.stringify(user), {
       headers: {
         'Content-Type': 'application/json',
       },
     }).then(res => {
       axios.post(`${BASE_END_POINT}addToken`, JSON.stringify({
         token: FB_token 
       }), {
       headers: {
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${res.data.token}`
       },
     }).then(response => {
       l.then(() => message.success('Welcome', 2.5))
      
       console.log('login Done  ',res.data);   
       localStorage.setItem('@USERQSAT', JSON.stringify(res.data));  
       dispatch({ type: LOGIN_SUCCESS, payload: res.data});  
         history.push('/Home')       
     }).catch(error => {
       l.then(() => message.error('Error', 2.5))
       //.log('inner');
         //console.log(error);
     });
     
     })
       .catch(error => {
         l.then(() => message.error('Email Or Password is incorrect', 2.5))
         //console.log('outer');
         //console.log(error.response);
       });
   };
 }
 

export function userToken(token){
  //console.log("hi  "+token)
  return dispatch => {
    dispatch({type:USER_TOKEN,payload:token})
  }
}

export function setUser(user){
  return dispatch => {
    dispatch({ type: CURRENT_USER, payload: user });
  }
}

/*export function userLocation(postion) {
  return dispatch => {
    console.log(postion)
    dispatch({type:USER_LOCATION,payload:postion})
  }
  
}
*/


