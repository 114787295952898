import React from "react";
import "./salesMenClients.css";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { setUser } from "../../redux/actions/AuthActions";
import { ChangeLanguage } from "../../redux/actions/LanguageAction";
import LoadCard from "../../component/load card/load card";
import Pagination from "@material-ui/lab/Pagination";
import { ChangeCompany, ChangeBranch } from "../../redux/actions/CompanyAction";

class salesMenClients extends React.Component {
  state = {
    count: "",
    loading: true,
    refresh: false,
    page: 1,
    pages: 1,
    modal: false,
    SalesManClients: [],
    salesMan: this.props.location.state ? this.props.location.state.data : [],
  };

  constructor(props) {
    super(props);
    console.log("isr   ", this.props.history);
    if (this.props.isRTL) {
      allStrings.setLanguage("ar");
    } else {
      allStrings.setLanguage("en");
    }
    window.scrollTo(0, 0);
  }

  getSalesManClients = (page, refresh) => {
    this.setState({ loading: true });

    let url = ``;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}clients/findAllForMobile?salesMan=${this.state.salesMan.id}&limit=20&page=${page}`;
      } else {
        url = `${BASE_END_POINT}clients/findAllForMobile?salesMan=${this.state.salesMan.id}&company=${this.props.company}&limit=20&page=${page}`;
      }
    } else {
      url = `${BASE_END_POINT}clients/findAllForMobile?salesMan=${this.state.salesMan.id}&company=${this.props.currentUser.user.id}&limit=20&page=${page}`;
    }

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("salesManClients   ", response.data);
        this.setState({
          loading: false,
          refresh: false,
          SalesManClients: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading: false });
      });
  };

  componentDidMount() {
    this.getSalesManClients(1, false);
    //this.props.ChangeLanguage(false)
  }

  render() {
    return (
      <div className="salesMenClients">
        <MDBContainer>
          <MDBRow>
            <MDBCol md="4" sm="4" xs="4">
              <div
                className="top"
                style={{ width: "100%", textAlign: "center" }}
                onClick={() => this.props.history.goBack()}
              >
                <MDBIcon icon="times" className="closeIcon" />
              </div>
            </MDBCol>
            <MDBCol md="4" sm="4" xs="4">
              <label
                htmlFor="defaultFormRegisterNameE"
                style={{
                  fontWeight: "800",
                  color: "#43425D",
                  fontSize: "20px",
                  margin: "1rem",
                  textAlign: "center",
                  display: "block",
                }}
              >
                {allStrings.collectedClients}
              </label>
            </MDBCol>
            <MDBCol md="4" sm="4" xs="4"></MDBCol>
          </MDBRow>
          <MDBRow>
            {this.state.loading ? (
              <div>
                <LoadCard />
                <LoadCard />
              </div>
            ) : this.state.SalesManClients.length > 0 ? (
              this.state.SalesManClients.map((val) => (
                <MDBCol xl="3" lg="6" md="6" sm="12">
                  <div
                    className="salesManRecProduct"
                    onClick={() =>
                      this.props.history.push("/clientForm", { data: val })
                    }
                  >
                    <img
                      alt=""
                      src={
                        val.img
                          ? val.img
                          : require("../../assets/images/profileicon.jpg")
                      }
                      width="100px"
                      height="120px"
                    />
                    <span
                      style={{
                        color: "rgb(59, 134, 255)",
                        fontWeight: 400,
                        position: "relative",
                        top: "-130px",
                        right: "20px",
                        background: "#fff",
                        borderRadius: "50%",
                        fontSize: "14px",
                        padding: "6px",
                        width: "35px",
                        height: "35px",
                        display: "inline-block",
                      }}
                    >
                      {val.id}
                    </span>
                  </div>

                  <div
                    onClick={() =>
                      this.props.history.push("/clientForm", { data: val.id })
                    }
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                      }}
                    >
                      {val.fullname}
                    </p>
                  </div>
                </MDBCol>
              ))
            ) : (
              <div className="noData">{allStrings.noData}</div>
            )}
          </MDBRow>
          <MDBRow>
            {this.state.SalesManClients.length > 0 && (
              <div
                style={{
                  textAlign: "center",
                  margin: "auto",
                  marginTop: "3rem",
                }}
              >
                <Pagination
                  onChange={(event, page) => {
                    console.log("page   ", page);
                    if (page !== this.state.page) {
                      this.getSalesManClients(page);
                      this.setState({ page: page });
                    }
                  }}
                  defaultPage={1}
                  page={this.state.page}
                  count={this.state.pages}
                  style={{ color: `blue !important` }}
                />
              </div>
            )}
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = (state) => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  branch: state.company.branch,
  company: state.company.company,
});

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
};
export default withRouter(
  connect(mapToStateProps, mapDispatchToProps)(salesMenClients)
);
