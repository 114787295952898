/* eslint-disable eqeqeq */
import React from "react";
import "./profile.css";
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon } from "mdbreact";
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { DatePicker, ConfigProvider } from "antd";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { setUser } from "../../redux/actions/AuthActions";
import { hasValue } from "../../validations/validations";
import { NavLink } from "react-router-dom";
import { ChangeLanguage } from "../../redux/actions/LanguageAction";
import { TreeSelect, message } from "antd";
import moment from "moment";
import locale_ar from "antd/lib/locale/ar_EG";
import locale_en from "antd/lib/locale/en_GB";
import "moment/locale/ar";
import TableDatePicker from "../../component/datepicker/datepicker.js";
const { TreeNode } = TreeSelect;

let fromDate;
let toDate;
class profile extends React.Component {
  state = {
    requestType: "put",
    img: this.props.location.state ? this.props.location.state.data.img : null,
    imgType: "url",
    fullname: this.props.location.state
      ? this.props.location.state.data.fullname
      : "",
    phone: this.props.location.state
      ? this.props.location.state.data.phone
      : "",
    anotherPhone: this.props.location.state
      ? this.props.location.state.data.anotherPhone
      : "",
    cardNum: this.props.location.state
      ? this.props.location.state.data.cardNum
      : "",
    password: "",
    user: [],
    contactDetails: this.props.location.state
      ? this.props.location.state.data.contactDetails
      : "",
    companySpecialization: this.props.location.state
      ? this.props.location.state.data.companySpecialization
      : "",
    language: this.props.isRTL,
    companyOwner: this.props.location.state
      ? this.props.location.state.data.companyOwner
      : "",
    advertisementUrl: "",
    appRation: "",
    setting: [],
    loading: true,
    refresh: false,
    from: null,
    to: null,
  };

  constructor(props) {
    super(props);
    if (this.state.img) {
      this.setState({ imgType: "url" });
    }
    console.log(
      "isr   ",
      this.props,
      this.props.history,
      "imag",
      this.state.imgType
    );
    this.getById();
    if (this.props.isRTL) {
      allStrings.setLanguage("ar");
    } else {
      allStrings.setLanguage("en");
    }
    window.scrollTo(0, 0);
  }
  getById = () => {
    let url = `${BASE_END_POINT}${this.props.currentUser.user.id}/findById`;
    console.log("OUF", localStorage.getItem("@COMPANY"));
    if (
      this.props.currentUser.user.type === "ADMIN" ||
      this.props.currentUser.user.type === "SUB-ADMIN"
    ) {
      url = `${BASE_END_POINT}${localStorage.getItem("@COMPANY")}/findById`;
    }
    axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("USER", response.data.user);

        console.log("LKJ", this.state.from, this.state.to);
        if (response.data.user.monthStartDay < response.data.user.monthEndDay) {
          let now = new Date();
          if (this.props.isRTL) {
            this.setState({
              from: moment(
                now.setDate(response.data.user.monthStartDay)
              ).format("YYYY/MM/DD"),
              to: moment(now.setDate(response.data.user.monthEndDay)).format(
                "YYYY/MM/DD"
              ),
            });
          } else {
            this.setState({
              from: moment(now.setDate(response.data.user.monthEndDay))
                .locale("en")
                .format("YYYY/MM/DD"),
              to: moment(now.setDate(response.data.user.monthEndDay))
                .locale("en")
                .format("YYYY/MM/DD"),
            });
          }
        } else {
          let now = new Date();
          let start = now.setDate(response.data.user.monthStartDay);
          let end;
          if (now.getMonth() == 11) {
            end = new Date(
              now.getFullYear() + 1,
              0,
              response.data.user.monthEndDay
            );
          } else {
            end = new Date(
              now.getFullYear(),
              now.getMonth() + 1,
              response.data.user.monthEndDay
            );
          }
          if (this.props.isRTL) {
            this.setState({
              from: moment(start).format("YYYY/MM/DD"),
              to: moment(end).format("YYYY/MM/DD"),
            });
          } else {
            this.setState({
              from: moment(start).locale("en").format("YYYY/MM/DD"),
              to: moment(end).locale("en").format("YYYY/MM/DD"),
            });

            console.log(this.state.from, this.state.to);
          }
        }

        this.setState({
          user: response.data.user,
          img: response.data.user.img,
          companyOwner: response.data.user.companyOwner,
          companySpecialization: response.data.user.companySpecialization,
          contactDetails: response.data.user.contactDetails,
        });
      })
      .catch((error) => {
        console.log("ALL get by id ERROR");
        console.log(error);
      });
  };
  updateProfile = () => {
    const {
      fullname,
      img,
      anotherPhone,
      phone,
      cardNum,
      password,
      imgType,
      contactDetails,
      companyOwner,
      companySpecialization,
    } = this.state;

    if (hasValue(fullname) && hasValue(cardNum) && hasValue(phone)) {
      let l = message.loading("Wait..", 2.5);
      var data = new FormData();
      data.append("fullname", fullname);
      data.append("cardNum", cardNum);
      data.append("phone", phone);
      data.append("type", this.props.currentUser.user.type);
      if (imgType == "data") {
        data.append("img", img);
      }
      if (anotherPhone !== "") {
        data.append("anotherPhone", anotherPhone);
      }
      if (companyOwner !== "" && companyOwner != undefined) {
        data.append("companyOwner", companyOwner);
      }
      if (contactDetails !== "" && contactDetails != undefined) {
        data.append("contactDetails", contactDetails);
      }
      if (companySpecialization !== "" && companySpecialization != undefined) {
        console.log("here", companySpecialization);
        data.append("companySpecialization", companySpecialization);
      }
      if (password !== "") {
        data.append("password", password);
      }

      axios
        .put(`${BASE_END_POINT}user/${this.state.user.id}/updateInfo`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        })
        .then((response) => {
          l.then(() => message.success(allStrings.done, 2.5));
          console.log(response.data, "updates");
          localStorage.setItem("@USERQSAT", JSON.stringify(response.data));
          this.props.history.goBack();
        })
        .catch((error) => {
          console.log(error.response);
          l.then(() => message.error("Error", 2.5));
        });
    }
  };
  onChangeFrom = (date, dateString) => {
    console.log("what", date, dateString);
    if (this.props.isRTL) {
      this.setState({ from: moment(dateString).format("YYYY/MM/DD") });
    } else {
      this.setState({
        from: moment(dateString).locale("en").format("YYYY/MM/DD"),
      });
    }
    fromDate = moment(dateString).locale("en").format("YYYY-MM-DD");
  };
  onChangeTo = (date, dateString) => {
    console.log("what", date, dateString);
    if (this.props.isRTL) {
      this.setState({ to: moment(dateString).format("YYYY/MM/DD") });
    } else {
      this.setState({
        to: moment(dateString).locale("en").format("YYYY/MM/DD"),
      });
    }
    toDate = moment(dateString).locale("en").format("YYYY-MM-DD");
  };
  componentDidMount() {
    this.getSetting();
    //this.props.ChangeLanguage(false)
  }
  getSetting = () => {
    this.setState({ loading: true });
    axios
      .get(`${BASE_END_POINT}setting`, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
          "Accept-Language": this.props.isRTL ? "ar" : "en",
        },
      })
      .then((response) => {
        console.log(
          "Setting   ",
          response.data,
          response.data.data.advertisementUrl
        );
        this.setState({
          loading: false,
          setting: response.data.data,
          advertisementUrl: response.data.data.advertisementUrl,
          appRation: response.data.data.appRation,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading: false });
      });
  };
  update = () => {
    const { advertisementUrl, appRation } = this.state;
    let l = message.loading("Wait..", 2.5);
    const data = {
      advertisementUrl: advertisementUrl,
      appRation: appRation,
    };
    console.log("data", data);
    axios
      .put(`${BASE_END_POINT}setting/${this.state.setting.id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.currentUser.token}`,
          "Accept-Language": this.props.isRTL ? "ar" : "en",
        },
      })
      .then((response) => {
        console.log("Setting UPDATED  ", response.data);
        l.then(() => message.success(allStrings.done, 2.5));
        this.props.history.goBack();
      })
      .catch((error) => {
        console.log(error.response);
        l.then(() => message.error(allStrings.error, 2.5));
      });
  };
  fileHandler = (event) => {
    this.setState({
      img: event.target.files[0],
      imgType: "data",
    });
  };
  removeImg = () => {
    this.setState({
      img: null,
    });
  };

  changeHandler = (event) => {
    console.log(event.target.value, event.target.name);
    this.setState({ [event.target.name]: event.target.value });
  };
  updateSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.updateProfile();
  };
  render() {
    return (
      <div className="profile">
        <MDBContainer>
          <MDBRow>
            <div className="top" style={{ width: "100%", textAlign: "center" }}>
              <NavLink
                to={
                  this.props.currentUser.user.type === "ADMIN"
                    ? "/Home"
                    : "/CompanyHome"
                }
              >
                <MDBIcon icon="times" className="closeIcon" />
              </NavLink>
              <span className="stockTitle">{allStrings.edit}</span>
            </div>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6">
              <div className="settings">
                <MDBContainer>
                  <MDBRow>
                    <MDBCol md="6" sm="6"></MDBCol>
                    <MDBCol md="6" sm="6" style={{ marginTop: "2rem" }}>
                      <MDBRow
                        style={{
                          flexDirection: this.props.isRTL
                            ? "row"
                            : "row-reverse",
                        }}
                      >
                        <MDBCol md="9" sm="9">
                          <TreeSelect
                            value={this.state.language}
                            showSearch={false}
                            style={{
                              width: "100%",
                              textAlign: this.props.isRTL ? "right" : "left",
                              height: "40px",
                            }}
                            treeCheckable={false}
                            treeNodeFilterProp="title"
                            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                            placeholder={allStrings.chooseLang}
                            onChange={(value) => {
                              console.log(value);
                              this.setState({ language: value });
                              this.props.ChangeLanguage(value);
                              if (value == true) {
                                allStrings.setLanguage("ar");
                                localStorage.setItem("@LANG", "ar");
                              } else {
                                allStrings.setLanguage("en");
                                localStorage.setItem("@LANG", "en");
                              }
                            }}
                          >
                            <TreeNode
                              value={true}
                              title="اللغه العربيه"
                              key={true}
                            />
                            <TreeNode
                              value={false}
                              title="English"
                              key={false}
                            />
                          </TreeSelect>
                        </MDBCol>
                        <MDBCol md="3" sm="3">
                          <label
                            className="grey-text formLabel"
                            style={{ padding: "10px" }}
                          >
                            {allStrings.lang}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  {this.props.currentUser.user.type === "ADMIN" ? (
                    <MDBRow>
                      <MDBCol md="6" sm="6"></MDBCol>
                      <MDBCol md="6" sm="6">
                        <MDBRow
                          style={{
                            flexDirection: this.props.isRTL
                              ? "row"
                              : "row-reverse",
                          }}
                        >
                          <MDBCol md="9" sm="9">
                            <input
                              name="advertisementUrl"
                              onChange={this.changeHandler}
                              type="text"
                              id="defaultFormRegisterNameEx5"
                              required
                              className="form-control"
                              placeholder={allStrings.advertisementUrl}
                              defaultValue={this.state.advertisementUrl}
                              style={{
                                direction: this.props.isRTL ? "rtl" : "ltr",
                                marginBottom: "10px",
                              }}
                            />
                          </MDBCol>
                          <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                            <label
                              htmlFor="defaultFormRegisterNameEx5"
                              className="grey-text formLabel"
                            >
                              {allStrings.advertisementUrl}
                            </label>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                  ) : (
                    ""
                  )}

                  <MDBRow>
                    <MDBCol md="6" sm="6"></MDBCol>
                    <MDBCol md="6" sm="6">
                      <MDBBtn
                        onClick={() => this.update()}
                        style={{
                          margin: "1rem 8rem",
                          width: "150px",
                          padding: "9px",
                          height: "35px",
                          float: "right",
                        }}
                        className="btn z-depth-1a "
                        color="primary"
                      >
                        {allStrings.done}
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                  {this.state.from && (
                    <MDBRow>
                      <div id="" style={{ paddingtop: "15px" }}>
                        <div
                          style={{
                            marginBottom: "10px",
                            textAlign: "center",
                          }}
                        >
                          <span
                            className="fromSpan"
                            style={{
                              float: this.props.isRTL ? "right" : "left",
                              marginRight: "25%",
                            }}
                          >
                            {allStrings.from}
                          </span>
                          <div
                            style={{
                              display: "inline-block",
                              textAlign: "center",
                              marginLeft: this.props.isRTL ? "35px" : "-93px",
                            }}
                          >
                            <ConfigProvider
                              locale={this.props.isRTL ? locale_ar : locale_en}
                            >
                              <DatePicker
                                value={moment(this.state.from, "YYYY/MM/DD")}
                                onChange={this.onChangeFrom}
                              />
                            </ConfigProvider>
                            <span className="newSpan">
                              <img
                                alt=""
                                style={{ marginRight: "4px" }}
                                src={require("../../assets/images/icons8_edit_property.png")}
                              ></img>
                              {this.state.from}
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            marginBottom: "10px",
                            textAlign: "center",
                          }}
                        >
                          <span
                            className="fromSpan"
                            style={{
                              float: this.props.isRTL ? "right" : "left",
                              marginRight: "25%",
                            }}
                          >
                            {allStrings.to}
                          </span>
                          <div
                            style={{
                              display: "inline-block",
                              textAlign: "center",
                              marginLeft: this.props.isRTL ? "35px" : "-93px",
                            }}
                          >
                            <ConfigProvider
                              locale={this.props.isRTL ? locale_ar : locale_en}
                            >
                              <DatePicker
                                value={moment(this.state.to, "YYYY/MM/DD")}
                                onChange={this.onChangeTo}
                              />
                            </ConfigProvider>
                            <span className="newSpan">
                              <img
                                alt=""
                                style={{ marginRight: "4px" }}
                                src={require("../../assets/images/icons8_edit_property.png")}
                              ></img>
                              {this.state.to}
                            </span>
                          </div>
                        </div>
                      </div>
                    </MDBRow>
                  )}
                </MDBContainer>
              </div>
            </MDBCol>
            <MDBCol md="6">
              <form
                className="needs-validation"
                onSubmit={this.updateSubmitHandler}
                noValidate
                style={{ marginLeft: "2rem" }}
              >
                <MDBRow>
                  <MDBContainer>
                    <MDBRow>
                      <MDBCol md="9" sm="9">
                        <input
                          name="fullname"
                          onChange={this.changeHandler}
                          type="text"
                          id="defaultFormRegisterNameEx5"
                          required
                          className="form-control"
                          placeholder={allStrings.enterName}
                          defaultValue={this.state.user.fullname}
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                            marginBottom: "10px",
                          }}
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx5"
                          className="grey-text formLabel"
                        >
                          {allStrings.name}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="9" sm="9">
                        <input
                          name="password"
                          onChange={this.changeHandler}
                          type="password"
                          id="defaultFormRegisteameEx5"
                          className="form-control"
                          placeholder={allStrings.enterPassword}
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                            marginBottom: "10px",
                          }}
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisteameEx5"
                          className="grey-text formLabel"
                        >
                          {allStrings.password}
                        </label>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="9" sm="9">
                        {this.state.img == null && (
                          <div className="smallUploadContainer">
                            <img
                              alt=""
                              name="img"
                              className="smallUploderImg"
                              src={require("../../assets/images/Path 343.png")}
                            ></img>
                            <input
                              onChange={this.fileHandler}
                              type="file"
                              id="defaultFormRegisterNameEx6"
                              required
                              className="smallUploader"
                            />
                            <MDBBtn
                              color="primary"
                              size="sm"
                              className="smallUploaderBtn"
                            >
                              {allStrings.chooseFile}
                            </MDBBtn>
                            <p
                              style={{
                                fontSize: "14px",
                                marginTop: "3px 15px",
                                float: "right",
                                paddingTop: "4px",
                              }}
                              className="grey-text"
                            >
                              {allStrings.dropHere}
                            </p>
                          </div>
                        )}
                        <div className="previewRaduis">
                          {this.state.img != null && (
                            <div
                              style={{
                                display: "inline-block",
                                float: "right",
                                marginBottom: "1rem",
                              }}
                            >
                              {this.state.img ? (
                                <img
                                  alt=""
                                  src={
                                    this.state.imgType == "url"
                                      ? this.state.img
                                      : URL.createObjectURL(this.state.img)
                                  }
                                />
                              ) : (
                                ""
                              )}

                              <MDBIcon
                                icon="times"
                                className="mr-2 removeImg"
                                onClick={() => this.removeImg()}
                              ></MDBIcon>
                            </div>
                          )}
                        </div>
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx7"
                          className="grey-text formLabel"
                        >
                          {allStrings.profileImg}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow style={{ marginBottom: "6px", marginTop: "6px" }}>
                      <MDBCol md="9" sm="9">
                        <input
                          name="cardNum"
                          disabled
                          type="text"
                          id="defaultFormRegisterNameEx12"
                          defaultValue={this.state.user.cardNum}
                          className="form-control"
                          placeholder={allStrings.enterCardNum}
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                          required
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx12"
                          className="grey-text formLabel"
                        >
                          {allStrings.cardNum}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="9" sm="9">
                        <input
                          name="companyOwner"
                          onChange={this.changeHandler}
                          type="text"
                          id="defaultFormRegisterNam5"
                          required
                          className="form-control"
                          placeholder={allStrings.enterCompanyOwner}
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                            marginBottom: "10px",
                          }}
                          defaultValue={this.state.companyOwner}
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNam5"
                          className="grey-text formLabel"
                        >
                          {allStrings.companyOwner}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="9" sm="9">
                        <textarea
                          name="contactDetails"
                          onChange={this.changeHandler}
                          type="text"
                          id="defaultFormRegisterNam5"
                          required
                          className="form-control"
                          placeholder={allStrings.contactDetails}
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                            marginBottom: "10px",
                          }}
                          defaultValue={this.state.contactDetails}
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNam5"
                          className="grey-text formLabel"
                        >
                          {allStrings.contactDetails}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="9" sm="9">
                        <textarea
                          name="companySpecialization"
                          onChange={this.changeHandler}
                          type="text"
                          id="defaultFormRegisterNam5"
                          required
                          className="form-control"
                          placeholder={allStrings.companySpecialization}
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                            marginBottom: "10px",
                          }}
                          defaultValue={this.state.companySpecialization}
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNam5"
                          className="grey-text formLabel"
                        >
                          {allStrings.companySpecialization}
                        </label>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow style={{ marginBottom: "6px", marginTop: "6px" }}>
                      <MDBCol md="9" sm="9">
                        {this.props.currentUser.user.type === "ADMIN" ? (
                          <input
                            name="phone"
                            onChange={this.changeHandler}
                            type="text"
                            id="defaultFormRegisterNameEx15"
                            defaultValue={this.state.user.phone}
                            className="form-control"
                            placeholder={allStrings.enterPhone}
                            style={{
                              direction: this.props.isRTL ? "rtl" : "ltr",
                            }}
                            required
                          />
                        ) : (
                          <input
                            name="phone"
                            disabled
                            type="text"
                            id="defaultFormRegisterNameEx15"
                            defaultValue={this.state.user.phone}
                            className="form-control"
                            placeholder={allStrings.enterPhone}
                            style={{
                              direction: this.props.isRTL ? "rtl" : "ltr",
                            }}
                            required
                          />
                        )}
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx15"
                          className="grey-text formLabel"
                        >
                          {allStrings.phone}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow style={{ marginBottom: "6px", marginTop: "6px" }}>
                      <MDBCol md="9" sm="9">
                        <input
                          name="anotherPhone"
                          onChange={this.changeHandler}
                          type="text"
                          id="defaultFormRegisterNameEx16"
                          defaultValue={this.state.user.anotherPhone}
                          className="form-control"
                          placeholder={allStrings.EnterAnotherPhone}
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx16"
                          className="grey-text formLabel"
                        >
                          {allStrings.anotherPhone}
                        </label>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </MDBRow>
                <MDBRow>
                  <MDBBtn
                    type="submit"
                    style={{
                      margin: "1rem",
                      width: "150px",
                      padding: "9px",
                      height: "35px",
                    }}
                    className="btn z-depth-1a "
                    color="primary"
                  >
                    {this.state.requestType == "post"
                      ? allStrings.add
                      : allStrings.edit}
                  </MDBBtn>
                </MDBRow>
              </form>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = (state) => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
});

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
};

export default withRouter(
  connect(mapToStateProps, mapDispatchToProps)(profile)
);
