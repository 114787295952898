/* eslint-disable eqeqeq */
import React from "react";
import "./cities.css";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import { ChangeLanguage } from "../../redux/actions/LanguageAction";
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { setUser } from "../../redux/actions/AuthActions";
import { Popconfirm, message } from "antd";
import Pagination from "@material-ui/lab/Pagination";
import LoadCard from "../../component/load card/load card";
import { hasValue } from "../../validations/validations";
import { Redirect } from "react-router-dom";

class City extends React.Component {
  state = {
    Cities: [],
    City: [],
    loading: true,
    refresh: false,
    page: 1,
    pages: 1,
    modal: false,
    cityName_ar: "",
    cityName_en: "",
    requestType: "post",
    selectedCity: null,
    filterType: null,
    value: "",
    cities: [],
  };
  constructor(props) {
    super(props);
    console.log("isr   ", this.props.history);
    if (this.props.isRTL) {
      allStrings.setLanguage("ar");
    } else {
      allStrings.setLanguage("en");
    }
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    this.getCities(1, false);
    //this.props.ChangeLanguage(false)
  }
  getCities = (page, refresh) => {
    this.setState({ loading: true });
    let url = `${BASE_END_POINT}cities/pagenation/get?page=${page}`;

    axios
      .get(
        url,
        {
          headers: {
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        },
        {}
      )
      .then((response) => {
        console.log("Cities   ", response.data);
        this.setState({
          loading: false,
          refresh: false,
          Cities: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
          citiesCount: response.data.totalCount,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading: false });
      });
  };
  selectCityId = (e) => {
    e.preventDefault();
    let id = e.target.id.substring(4);
    console.log("IDDD", id);
    this.setState({ selectedCity: id });
  };
  deleteCity = (id) => {
    let l = message.loading("Wait..", 2.5);
    console.log(this.props.currentUser.user.type);
    axios
      .delete(`${BASE_END_POINT}cities/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("City DELETED");
        l.then(() => message.success(allStrings.done, 2));
        this.getCities(this.state.page);
      })
      .catch((error) => {
        console.log(error.response);
        l.then(() => message.error("Error", 2.5));
      });
  };
  updateCity = () => {
    const { cityName_ar, cityName_en } = this.state;
    if (hasValue(cityName_ar) && hasValue(cityName_en)) {
      let l = message.loading("Wait..", 2.5);
      let data = {
        cityName_ar: cityName_ar,
        cityName_en: cityName_en,
      };
      axios
        .put(`${BASE_END_POINT}cities/${this.state.selectedCity}`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        })
        .then((response) => {
          l.then(() => message.success(allStrings.done, 2.5));
          this.setState({ loading: true });
          this.toggle();
          this.getCities(1, false);
        })
        .catch((error) => {
          console.log(error.response);
          l.then(() => message.error("Error", 2.5));
        });
    }
  };
  addCity = () => {
    const { cityName_ar, cityName_en } = this.state;
    if (hasValue(cityName_ar) && hasValue(cityName_en)) {
      let l = message.loading("Wait..", 2.5);
      let data = {
        cityName_ar: cityName_ar,
        cityName_en: cityName_en,
      };

      axios
        .post(`${BASE_END_POINT}cities`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        })
        .then((response) => {
          l.then(() => message.success(allStrings.done, 2.5));
          this.setState({ loading: true });
          this.toggle();
          this.getCities(1, false);
        })
        .catch((error) => {
          console.log(error.response);
          l.then(() => message.error("Error", 2.5));
        });
    }
  };

  changeHandler = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  addSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.addCity();
  };
  openToAdd = () => {
    this.setState({ requestType: "post" });
    this.toggle();
  };
  selectCity = (e) => {
    e.preventDefault();
    let id = e.target.value;
    console.log(e.target.value);
    axios
      .get(`${BASE_END_POINT}cities/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        this.toggle();
        this.setState({
          selectedCity: id,
          requestType: "put",
          City: response.data.data,
          cityName_ar: response.data.cityName_ar,
          cityName_en: response.data.cityName_en,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  updateSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.updateCity();
  };

  OKBUTTON = (e) => {
    this.deleteCity(this.state.selectedCity);
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      requestType: "post",
      City: [],
      cityName_ar: "",
      cityName_en: "",
    });
  };
  modal = () => (
    <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
      <MDBModalHeader toggle={this.toggle}>
        {this.state.requestType == "post"
          ? allStrings.completeData
          : allStrings.edit}
      </MDBModalHeader>
      <MDBModalBody>
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12">
              <form
                style={{ marginLeft: "15%" }}
                className="needs-validation"
                onSubmit={
                  this.state.requestType === "post"
                    ? this.addSubmitHandler
                    : this.updateSubmitHandler
                }
                noValidate
              >
                <MDBContainer>
                  <MDBRow>
                    <MDBCol md="9">
                      <input
                        name="cityName_ar"
                        onChange={this.changeHandler}
                        type="text"
                        id="defaultFormRegisterNameEx1"
                        required
                        placeholder={allStrings.cityName_ar}
                        className="form-control"
                        style={{ direction: this.props.isRTL ? "rtl" : "ltr" }}
                        defaultValue={this.state.City.cityName_ar}
                      />
                    </MDBCol>
                    <MDBCol md="3">
                      <label
                        htmlFor="defaultFormRegisterNameEx1"
                        className="grey-text formLabel"
                      >
                        {allStrings.cityName_ar}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="9">
                      <input
                        name="cityName_en"
                        onChange={this.changeHandler}
                        type="text"
                        id="defaultFormRegisterNamex1"
                        required
                        placeholder={allStrings.cityName_en}
                        className="form-control"
                        style={{ direction: this.props.isRTL ? "rtl" : "ltr" }}
                        defaultValue={this.state.City.cityName_en}
                      />
                    </MDBCol>
                    <MDBCol md="3">
                      <label
                        htmlFor="defaultFormRegisterNamex1"
                        className="grey-text formLabel"
                      >
                        {allStrings.cityName_en}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBBtn
                      type="submit"
                      style={{
                        margin: "3rem 1rem ",
                        width: "170px",
                        padding: "11px 20px",
                        height: "35px",
                      }}
                      className="btn z-depth-1a "
                      color="primary"
                    >
                      {this.state.requestType == "post"
                        ? allStrings.add
                        : allStrings.edit}
                    </MDBBtn>
                  </MDBRow>
                </MDBContainer>
              </form>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBModalBody>
    </MDBModal>
  );

  render() {
    console.log(this.state.requestType);
    if (this.props.currentUser.user.type == "COMPANY")
      return <Redirect to="/Home" />;
    if (
      this.props.currentUser.user.type == "SUB-ADMIN" &&
      !this.props.currentUser.user.pagesPermission.includes("CITIES")
    )
      return <Redirect to="/Home" />;

    return (
      <div className="City">
        {this.modal()}
        <MDBContainer>
          <MDBRow>
            <MDBCol xl="3" lg="3" md="3" sm="12">
              <span
                style={{
                  color: "mediumblue",
                  fontWeight: "600",
                  fontSize: "30px",
                  padding: "5px 25px",
                }}
              >
                {this.state.citiesCount}
              </span>
              <p
                style={{
                  color: "mediumblue",
                  fontSize: "14px",
                  marginTop: "6px",
                  marginBottom: 0,
                  fontWeight: "600",
                }}
              >
                {allStrings.citiesCount}
              </p>
            </MDBCol>
            <MDBCol
              xl="6"
              lg="6"
              md="6"
              sm="12"
              style={{ textAlign: "center" }}
            ></MDBCol>
            <MDBCol xl="3" lg="3" md="3" sm="12">
              <div className="addButton" onClick={this.toggle}>
                <MDBIcon icon="plus" />
              </div>
              <span
                onClick={this.toggle}
                style={{
                  float: "right",
                  color: "#43425D",
                  fontWeight: "600",
                  padding: "11px 2px",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              >
                {allStrings.addCity}
              </span>
            </MDBCol>
          </MDBRow>
          <br></br>
          <br></br>
          <MDBRow>
            {this.state.loading ? (
              <LoadCard />
            ) : this.state.Cities.length > 0 ? (
              this.state.Cities.map((City) => (
                <MDBCol xl="4" lg="6" md="12" sm="12">
                  <div className="CityCard">
                    <button
                      className="hiddenButton"
                      onClick={this.selectCity}
                      value={City.id}
                    ></button>
                    <MDBContainer>
                      <MDBRow>
                        <MDBCol xl="2" lg="2" md="2" sm="2">
                          <div className="icons">
                            <Popconfirm
                              title={allStrings.areYouSure}
                              onConfirm={this.OKBUTTON}
                              onCancel={this.fCANCELBUTTON}
                              okText={allStrings.ok}
                              cancelText={allStrings.cancel}
                            >
                              <p
                                style={{
                                  fontSize: "22px",
                                  color: "#A4A3B0",
                                  marginBottom: "4px",
                                  cursor: "pointer",
                                  zIndex: "4",
                                  position: "relative",
                                }}
                                onClick={this.selectCityId}
                                id={"City" + City.id}
                              >
                                <MDBIcon
                                  icon="times"
                                  onClick={this.selectCityId}
                                  id={"City" + City.id}
                                />
                              </p>
                              <p
                                style={{
                                  fontSize: "16px",
                                  color: "#A4A3B0",
                                  marginBottom: "4px",
                                  cursor: "pointer",
                                }}
                              >
                                <MDBIcon icon="pen" />
                              </p>
                            </Popconfirm>
                          </div>
                        </MDBCol>
                        <MDBCol
                          xl="10"
                          lg="10"
                          md="10"
                          sm="10"
                          className="noPadding"
                        >
                          <div className="CityInfo">
                            <p
                              style={{
                                fontWeight: "600",
                                color: "#43425D",
                                marginBottom: "6px",
                                fontSize: "22px",
                                textAlign: "center",
                              }}
                            >
                              {this.props.isRTL
                                ? City.cityName_ar
                                : City.cityName_en}
                            </p>
                            <p
                              style={{
                                fontWeight: "600",
                                color: "#43425D",
                                marginBottom: "6px",
                                fontSize: "22px",
                                textAlign: "center",
                              }}
                            >
                              {City.companyCount}{" "}
                            </p>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                  </div>
                </MDBCol>
              ))
            ) : (
              <div className="noData">{allStrings.noData}</div>
            )}
          </MDBRow>
          <MDBRow>
            {this.state.Cities.length > 0 && (
              <div
                style={{
                  textAlign: "center",
                  margin: "auto",
                  marginTop: "3rem",
                }}
              >
                <Pagination
                  onChange={(event, page) => {
                    console.log("page   ", page);
                    if (page != this.state.page) {
                      this.getCities(
                        page,
                        false,
                        this.state.filterType,
                        this.state.value
                      );
                      this.setState({ page: page });
                    }
                  }}
                  defaultPage={1}
                  page={this.state.page}
                  count={this.state.pages}
                  style={{ color: `blue !important` }}
                />
              </div>
            )}
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = (state) => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
});

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
};

export default withRouter(connect(mapToStateProps, mapDispatchToProps)(City));
