/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React from "react";
import "./blockList.css";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { setUser } from "../../redux/actions/AuthActions";
import {
  Popconfirm,
  message,
  Select,
  Input,
  ConfigProvider,
  DatePicker,
} from "antd";
import { Redirect } from "react-router-dom";
import { ChangeLanguage } from "../../redux/actions/LanguageAction";
import { ChangeCompany, ChangeBranch } from "../../redux/actions/CompanyAction";
import { hasValue } from "../../validations/validations";
import { SearchOutlined } from "@ant-design/icons";

import Skeleton from "react-loading-skeleton";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import locale_ar from "antd/lib/locale/ar_EG";
import locale_en from "antd/lib/locale/en_GB";
import "moment/locale/ar";
import ReactToPrint from "react-to-print";

let fromDate;
let toDate;
class ComponentToPrint extends React.Component {
  render() {
    console.log(this.props);
    return (
      <form class="col s12">
        <div className="tabelDev">
          <div class="row">
            <table class="table table-borderless">
              <thead class="thead-light">
                {this.props.tablePrintType == "stock" && (
                  <tr>
                    <th scope="col">{allStrings.name}</th>
                    <th scope="col">{allStrings.cashPrice}</th>
                    <th scope="col">{allStrings.quantity}</th>
                    <th scope="col">{allStrings.salesNumber}</th>
                  </tr>
                )}
                {this.props.tablePrintType == "order" && (
                  <tr>
                    <th scope="col">{allStrings.date}</th>
                    <th scope="col">{allStrings.cost}</th>
                    <th scope="col">{allStrings.client}</th>
                    <th scope="col">{allStrings.product}</th>
                  </tr>
                )}
                {this.props.tablePrintType == "clients" && (
                  <tr>
                    <th scope="col">{allStrings.name}</th>
                    <th scope="col">{allStrings.phone}</th>
                    <th scope="col">{allStrings.anotherPhone}</th>
                    <th scope="col">{allStrings.cardNum}</th>
                    <th scope="col">{allStrings.orders}</th>
                  </tr>
                )}
                {this.props.tablePrintType == "salesMen" && (
                  <tr>
                    <th scope="col">{allStrings.name}</th>
                    <th scope="col">{allStrings.phone}</th>
                    <th scope="col">{allStrings.anotherPhone}</th>
                    <th scope="col">{allStrings.cardNum}</th>
                    <th scope="col">{allStrings.balance}</th>
                    <th scope="col">{allStrings.debt}</th>
                    <th scope="col">{allStrings.salesNumber}</th>
                    <th scope="col">{allStrings.recievedProducts}</th>
                    <th scope="col">{allStrings.additionCollectaibles}</th>
                  </tr>
                )}
              </thead>

              <tbody>
                {this.props.data.length > 0 ? (
                  this.props.data.map((val) =>
                    this.props.tablePrintType == "stock" ? (
                      <tr>
                        <td>{this.props.isRTL ? val.name_ar : val.name_en}</td>
                        <td>{val.cashPrice ? val.cashPrice : "--"}</td>
                        <td>{val.quantity ? val.quantity : "--"}</td>
                        <td>{val.salesNumber ? val.salesNumber : 0}</td>
                      </tr>
                    ) : this.props.tablePrintType == "clients" ? (
                      <tr>
                        <td>{val.user.fullname ? val.user.fullname : ""}</td>
                        <td>{val.user.phone ? val.user.phone : ""}</td>
                        <td>
                          {val.user.anotherPhone ? val.user.anotherPhone : ""}
                        </td>
                        <td>{val.user.cardNum ? val.user.cardNum : ""}</td>
                        <td>{val.ordersCount ? val.ordersCount : 0}</td>
                      </tr>
                    ) : this.props.tablePrintType == "salesMen" ? (
                      <tr>
                        <td>{val.user.fullname ? val.user.fullname : ""}</td>
                        <td>{val.user.phone ? val.user.phone : ""}</td>
                        <td>
                          {val.user.anotherPhone ? val.user.anotherPhone : ""}
                        </td>
                        <td>{val.user.cardNum ? val.user.cardNum : ""}</td>
                        <td>{val.user.balance ? val.user.balance : 0}</td>
                        <td>{val.user.debt ? val.user.debt : 0}</td>
                        <td>{val.user.saleCount ? val.user.saleCount : 0}</td>
                        <td>
                          {val.recievedProducts ? val.recievedProducts : 0}
                        </td>
                        <td>
                          {val.additionCollectaibles
                            ? val.additionCollectaibles
                            : 0}
                        </td>
                      </tr>
                    ) : this.props.tablePrintType == "order" ? (
                      <tr>
                        <td>{val.createdAt.substring(0, 10)}</td>
                        <td>{val.total ? val.total : "--"}</td>
                        <td>{val.client.fullname}</td>
                        <td>
                          {val.product
                            ? this.props.isRTL
                              ? val.product.name_ar
                              : val.product.name_en
                            : ""}
                        </td>
                      </tr>
                    ) : (
                      <tr></tr>
                    )
                  )
                ) : (
                  <tr style={{ height: "200px" }}>
                    <th
                      colspan={
                        this.props.tablePrintType == "salesMen" ? "9" : "4"
                      }
                      className="noData"
                    >
                      <p style={{ marginTop: "4rem" }}>{allStrings.noData}</p>
                    </th>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </form>
    );
  }
}
class BlockList extends React.Component {
  state = {
    tableData: [],
    dataToPrint: [],
    loading: true,
    refresh: false,
    page: 1,
    pages: 1,
    tableType: "order",
    tablePrintType: "salesMen",
    from: this.props.isRTL
      ? moment().startOf("month").format("YYYY/MM/DD")
      : moment().startOf("month").locale("en").format("YYYY/MM/DD"),
    to: this.props.isRTL
      ? moment().endOf("month").format("YYYY/MM/DD")
      : moment().endOf("month").locale("en").format("YYYY/MM/DD"),
  };

  constructor(props) {
    super(props);
    this.getDuration();
    console.log("isr   ", this.props.history);

    if (this.props.isRTL) {
      allStrings.setLanguage("ar");
      moment.locale("ar");
    } else {
      allStrings.setLanguage("en");
      moment.locale("en");
    }
    this.getProducts();
    window.scrollTo(0, 0);
  }
  getDuration = () => {
    //first day
    let date = new Date();
    let newDate = date.toISOString().slice(0, 8);
    let firstDay = newDate + "01";
    //get days in month
    function daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    }

    let DaysInCurrentMonth = daysInMonth(
      date.getMonth() + 1,
      date.getFullYear()
    ); //number of day in month
    //last day
    let lastDay = newDate + DaysInCurrentMonth;
    console.log(firstDay);
    console.log(lastDay);
    if (this.props.isRTL) {
      this.setState({
        from: moment(firstDay).format("YYYY/MM/DD"),
        to: moment(lastDay).format("YYYY/MM/DD"),
      });
    } else {
      this.setState({
        from: moment(firstDay).locale("en").format("YYYY/MM/DD"),
        to: moment(lastDay).locale("en").format("YYYY/MM/DD"),
      });
    }

    fromDate = firstDay;
    toDate = lastDay;
  };
  componentDidMount() {
    //this.props.ChangeLanguage(false)
    this.getDuration();
    //this.getOrders(1, false);
  }

  getProducts = (page, refresh) => {
    this.setState({ loading: true, tableType: "stock" });
    let url = ``;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      url = `${BASE_END_POINT}block/withoutPagenation/get`;
    }

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("Products   ", response.data);
        this.setState({
          loading: false,
          refresh: false,
          tableData: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
        //this.getProductsWithoutPagenation();
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading: false });
      });
  };
  getProductsWithoutPagenation = () => {};
  onChangeFrom = (date, dateString) => {
    if (this.props.isRTL) {
      this.setState({ from: moment(dateString).format("YYYY/MM/DD") });
    } else {
      this.setState({
        from: moment(dateString).locale("en").format("YYYY/MM/DD"),
      });
    }
    fromDate = moment(dateString).locale("en").format("YYYY-MM-DD");
  };
  onChangeTo = (date, dateString) => {
    if (this.props.isRTL) {
      this.setState({ to: moment(dateString).format("YYYY/MM/DD") });
    } else {
      this.setState({
        to: moment(dateString).locale("en").format("YYYY/MM/DD"),
      });
    }
    toDate = moment(dateString).locale("en").format("YYYY-MM-DD");
    if (this.state.tableType == "order") {
      this.getOrders(1, false);
    }
    if (this.state.tableType == "clients") {
      this.getClientAdditionPagenation(1, false);
    }
    if (this.state.tableType == "salesMen") {
      this.getSalesMenAdditionPagenation(1, false);
    }
    if (this.state.tableType == "stock") {
      this.getProducts(1, false);
    }
  };
  deleteBlock = (id) => {
    let l = message.loading("Wait..", 2.5);
    axios
      .delete(`${BASE_END_POINT}block/${id}/removeAppBlock`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("Block DELETED", response);
        l.then(() => message.success(allStrings.done, 2));
        this.props.history.push("/blocklist");
      })
      .catch((error) => {
        console.log("err", error);
        l.then(() => message.error("Error", 2.5));
      });
  };
  deleteBlockComp = (id, company) => {
    let l = message.loading("Wait..", 2.5);
    console.log(this.props.currentUser.token, this.props.company);
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.props.currentUser.token}`,
    };
    let data = {
      company: company,
    };
    axios
      .delete(`${BASE_END_POINT}block/${id}/removeCompanyBlock`, {
        headers,
        data,
      })
      .then((response) => {
        console.log("Block DELETED");
        l.then(() => message.success(allStrings.done, 2));
        this.props.history.push("/blocklist");
      })
      .catch((error) => {
        console.log("er", error);
        l.then(() => message.error("Error", 2.5));
      });
  };
  render() {
    if (
      this.props.currentUser.user.type == "SUB-ADMIN" &&
      !this.props.currentUser.user.pagesPermission.includes("REPORTS")
    )
      return <Redirect to="/Home" />;

    return (
      <div className="Reports" style={{ padding: "1rem" }}>
        <div className="filters" style={{ margin: "2rem" }}>
          <MDBContainer>
            <MDBRow>
              <MDBCol xl="4" lg="4" md="4" sm="4">
                <Input
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      if (hasValue("" + e.target.value)) {
                        console.log("ENTER   ", e.target.value);
                        this.setState({
                          filterType: "name",
                          value: e.target.value,
                        });
                        this.getClients(1, false, "name", e.target.value);
                      } else {
                        message.warning(allStrings.noValue);
                        console.log("ENTER NO VALUE   ", e.target.value);
                      }
                    }
                  }}
                  placeholder={allStrings.nameFilter}
                  size="large"
                  prefix={<SearchOutlined />}
                />
              </MDBCol>
              <MDBCol xl="4" lg="4" md="4" sm="4"></MDBCol>
              <MDBCol xl="4" lg="4" md="4" sm="4"></MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
        <MDBContainer>
          <MDBRow>
            <MDBCol xl="12" lg="12" md="12" sm="12">
              <table class="table table-borderless">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">ذهاب</th>
                    <th scope="col">فك الحظر</th>
                    <th scope="col">{allStrings.date}</th>
                    <th scope="col">الاسم</th>
                    <th scope="col">النوع</th>
                    <th scope="col">الكود</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.loading ? (
                    <tr style={{ height: "200px" }}>
                      <th
                        colspan={
                          this.state.tableType == "salesMen"
                            ? "9"
                            : this.state.tableType == "stock"
                            ? "8"
                            : "4"
                        }
                        className="noData"
                      >
                        <Skeleton height={400} />
                      </th>
                    </tr>
                  ) : this.state.tableData.length > 0 ? (
                    this.state.tableData.map((val) => (
                      <tr>
                        <td>
                          {" "}
                          <MDBBtn
                            className="btn z-depth-1a hash-btn "
                            color="#37598c"
                          >
                            ذهاب
                          </MDBBtn>
                        </td>
                        <td>
                          <MDBBtn
                            onClick={() => {
                              val.type == "APP"
                                ? this.deleteBlock(val.user.id)
                                : this.deleteBlockComp(
                                    val.user.id,
                                    val.company.id
                                  );
                            }}
                            className="btn z-depth-1a hash-btn unblock"
                            color="#4F73AC"
                          >
                            فك الحظر
                          </MDBBtn>
                        </td>
                        <td>{val.createdAt.substring(0, 10)}</td>
                        <td>{val.user.fullname}</td>
                        <td>{val.user.type}</td>
                        <td>{val.user.id}</td>
                      </tr>
                    ))
                  ) : (
                    <tr style={{ height: "200px" }}>
                      <th colspan="6" className="noData">
                        <p style={{ marginTop: "4rem" }}>{allStrings.noData}</p>
                      </th>
                    </tr>
                  )}
                  <th colspan="6">
                    <MDBRow>
                      {this.state.tableData.length > 0 && (
                        <div
                          style={{
                            textAlign: "center",
                            margin: "auto",
                            marginTop: "3rem",
                          }}
                        >
                          <Pagination
                            onChange={(event, page) => {
                              console.log("page   ", page);
                              if (page != this.state.page) {
                                this.getProducts(page, false);

                                this.setState({ page: page });
                              }
                            }}
                            defaultPage={1}
                            page={this.state.page}
                            count={this.state.pages}
                            style={{ color: `blue !important` }}
                          />
                        </div>
                      )}
                    </MDBRow>
                  </th>
                </tbody>
              </table>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = (state) => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  branch: state.company.branch,
  company: state.company.company,
});

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
};
export default withRouter(
  connect(mapToStateProps, mapDispatchToProps)(BlockList)
);
