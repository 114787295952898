/* eslint-disable eqeqeq */
import React from "react";
import "./clientProducts.css";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { setUser } from "../../redux/actions/AuthActions";
//import {hasValue} from '../../validations/validations'
import { message, Radio, TreeSelect, DatePicker, ConfigProvider } from "antd";
//import {NavLink} from 'react-router-dom';
import { ChangeLanguage } from "../../redux/actions/LanguageAction";
import LoadCard from "../../component/load card/load card";
import CashBill from "../../component/cash bill/cash bill";
import PrintGuarantor from "../../component/print guarantor/print guarantor";
import PrintReceipt2 from "../../component/receipt2/receipt2";
import PrintReceipt1 from "../../component/receipt1/receipt1";

import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import locale_ar from "antd/lib/locale/ar_EG";
import locale_en from "antd/lib/locale/en_GB";
import "moment/locale/ar";
import { ChangeCompany, ChangeBranch } from "../../redux/actions/CompanyAction";
import ReactToPrint from "react-to-print";

const { TreeNode } = TreeSelect;
let paidDate, purchaseDate;

class ComponentToPrint extends React.Component {
  render() {
    console.log(this.props);
    return (
      <div>
        {this.props.printType == "1" &&
          this.props.data.map((val, i) => (
            <PrintReceipt1
              data={val}
              marginBottom={i % 3 === 2 ? "203px" : "16px"}
              index={i}
              theLength={this.props.data.length}
            />
          ))}
        {this.props.printType == "2" && (
          <CashBill
            data={this.props.data}
            advertisementUrl={this.props.advertisementUrl}
          />
        )}
        {this.props.printType == "3" && (
          <PrintGuarantor data={this.props.data} order={this.props.order} />
        )}
        {this.props.printType == "4" &&
          this.props.data.map((val, i) => (
            <PrintReceipt2
              data={val}
              marginBottom={i % 3 === 2 ? "203px" : "16px"}
              index={i}
              theLength={this.props.data.length}
              advertisementUrl={this.props.advertisementUrl}
            />
          ))}
      </div>
    );
  }
}
class ClientProducts extends React.Component {
  state = {
    count: 1,
    curOID: 0,
    cashPrice: 0,
    url: null,
    paymentSystem: "cash",
    selectedPackage: null,
    products: [],
    product: null,
    packages: [],
    orders: [],
    salesMen: [],
    loadin: true,
    refresh: false,
    page: 1,
    pages: 1,
    modal: false,
    modal2: false,
    client: this.props.location.state ? this.props.location.state.data : [],
    paidDate: "",
    purchaseDate: "",
    salesMan: null,
    buySalesMan: null,
    productDetails: [],
    premium: [],
    toPrintId: null,
    totalSelectedPackage: null,
    cashMoney: 0,
    selectedOrder: null,
    showMuliSalesMen: false,
    visibleSalesMenOrders: [],
    printType: 2,
    guarantor: null,
    printGuarantor: false,
    advertisementUrl: "",
  };

  constructor(props) {
    super(props);
    this.getSalesMen();
    console.log("isr   ", this.props.history);
    if (this.props.isRTL) {
      allStrings.setLanguage("ar");
    } else {
      allStrings.setLanguage("en");
    }
    window.scrollTo(0, 0);
  }
  returnNormal = () => {
    return new Promise((resolve, reject) => {
      resolve(window.open(this.state.url));
    });

    //this.state.url = ''
  };
  getSetting = () => {
    this.setState({ loading: true });
    axios
      .get(`${BASE_END_POINT}setting`, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
          "Accept-Language": this.props.isRTL ? "ar" : "en",
        },
      })
      .then((response) => {
        console.log("Setting   ", response.data);
        this.setState({
          advertisementUrl: response.data.advertisementUrl,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading: false });
      });
  };
  getPremuims = (orderId, print = false) => {
    let url = ``;
    this.setState({
      url: null,
    });
    if (print) {
      //document.getElementById('redy').style.display = 'block'
      //document.getElementById('btnPdf').style.display = 'none'
      url = `${BASE_END_POINT}premiums/withoutPagenation/get?order=${orderId}&print=${print}&typePDF=0`;
    } else {
      url = `${BASE_END_POINT}premiums/withoutPagenation/get?order=${orderId}`;
    }

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("premium   ", response.data);
        if (print) {
          this.setState({
            toPrint: true,
            toPrintId: orderId,
          });
          setTimeout(() => {
            this.setState({
              premium: response.data.premiums,

              url: response.data.url,
            });
            //document.getElementById('redy').style.display = 'none'
          }, 1555);
        } else {
          this.setState({
            premium: response.data,
            toPrint: true,
            toPrintId: orderId,
          });
        }
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
      });
  };
  getGuarantor = (orderId) => {
    let url = ``;
    url = `${BASE_END_POINT}/user/${this.state.client.id}/getAllguarantors?order=${orderId}`;

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("guarantor  ", response.data);
        this.setState({
          guarantor: response.data.data[0],
          toPrint: true,
          toPrintId: orderId,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
      });
  };
  payCashHandelSubmit = (event) => {
    event.preventDefault();
    console.log(event.target);
    event.target.className += " was-validated";
    this.payCash();
  };

  changeHandlerPayCash = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  payCash = () => {
    let { cashMoney, selectedOrder } = this.state;
    if (selectedOrder != null) {
      let l = message.loading("Wait..", 2.5);
      let data = {
        money: parseFloat(cashMoney),
      };
      axios
        .put(
          `${BASE_END_POINT}orders/${selectedOrder}/payCash`,
          JSON.stringify(data),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.currentUser.token}`,
            },
          }
        )
        .then((response) => {
          l.then(() => message.success(allStrings.done, 2.5));
          this.getOrders();
          this.toggle2();
        })
        .catch((error) => {
          console.log(error.response);
          l.then(() => message.error("Error", 2.5));
        });
    }
  };
  Retrieve = (orderId) => {
    let l = message.loading("Wait..", 2.5);
    axios
      .put(
        `${BASE_END_POINT}orders/${orderId}/acceptRetrieve`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        }
      )
      .then((response) => {
        l.then(() => message.success(allStrings.done, 2.5));
        this.getOrders();
      })
      .catch((error) => {
        console.log(error.response);
        l.then(() => message.error("Error", 2.5));
      });
  };
  getSalesMen = () => {
    let url = ``;
    if (
      this.props.currentUser.user.type === "ADMIN" ||
      this.props.currentUser.user.type === "SUB-ADMIN"
    ) {
      if (this.props.company === false) {
        url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN`;
      } else {
        url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN&company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN&company=${this.props.currentUser.user.id}`;
    }
    axios
      .get(url)
      .then((response) => {
        console.log(response.data.users);
        this.setState({ salesMen: response.data.users });
      })
      .catch((error) => {
        console.log("ALL salesMen ERROR");
        console.log(error.response);
      });
  };
  getProducts = () => {
    let url = ``;
    if (
      this.props.currentUser.user.type === "ADMIN" ||
      this.props.currentUser.user.type === "SUB-ADMIN"
    ) {
      if (this.props.company === false) {
        url = `${BASE_END_POINT}products/withoutPagenation/get`;
      } else {
        url = `${BASE_END_POINT}products/withoutPagenation/get?company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}products/withoutPagenation/get?company=${this.props.currentUser.user.id}`;
    }
    axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log(response);
        this.setState({ products: response.data.data });
      })
      .catch((error) => {
        console.log("ALL products ERROR");
        console.log(error);
      });
  };
  getProductById = (id) => {
    axios
      .get(`${BASE_END_POINT}products/${id}`)
      .then((response) => {
        console.log(response);
        console.log("cash", response.data.data.cashPrice * this.state.count);
        console.log("packages", response.data);
        this.setState({
          packages: response.data.data.package,
          productDetails: response.data.data,
          cashPrice: response.data.data.cashPrice * this.state.count,
        });
      })
      .catch((error) => {
        console.log("ALL products ERROR");
        console.log(error);
      });
  };
  getOrders = (page, refresh) => {
    this.setState({ loading: true });

    axios
      .get(
        `${BASE_END_POINT}orders?page=${page}&client=${
          this.state.client.user.id
        }&company=${
          this.props.currentUser.user.type == "ADMIN"
            ? this.props.company
              ? this.props.company
              : this.props.currentUser.user.company[0].id
            : this.props.currentUser.user.id
        }&accept=true`,
        {
          headers: {
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        }
      )
      .then((response) => {
        console.log("orders   ", response.data, this.state.client.id);
        this.setState({
          loading: false,
          refresh: false,
          orders: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading: false });
      });
  };
  addOrder = () => {
    const {
      product,
      buySalesMan,
      salesMan,
      count,
      cashPrice,
      selectedPackage,
      packages,
      paymentSystem,
      purchaseDate,
    } = this.state;

    if (product != null && buySalesMan != null) {
      let l = message.loading("Wait..", 2.5);
      var data = new FormData();
      data.append("destination", JSON.stringify([1.3, 1.5]));
      data.append("product", product);
      data.append("count", count);
      data.append("paymentSystem", paymentSystem);
      data.append("status", "ACCEPTED");
      data.append(
        "paidDate",
        moment(paidDate).locale("en").format("YYYY-MM-DD")
      );
      data.append("salesMan", salesMan);
      data.append("buySalesMan", buySalesMan);
      let multiBuySalesMan = [];
      multiBuySalesMan.push(buySalesMan);
      data.append("multiBuySalesMan", JSON.stringify(multiBuySalesMan));
      if (
        this.props.currentUser.user.type === "ADMIN" ||
        this.props.currentUser.user.type === "SUB-ADMIN"
      ) {
        if (this.props.company === false) {
          data.append("company", this.props.currentUser.user.company[0].id);
        } else {
          data.append("company", this.props.company);
        }
      } else {
        data.append("company", this.props.currentUser.user.id);
      }

      if (paymentSystem !== "cash") {
        let thePackage = packages[selectedPackage];
        console.log(thePackage);
        data.append("installmentPrice", thePackage.installmentPrice);
        data.append("firstPaid", thePackage.firstPaid);
        data.append("monthCount", thePackage.months);
        data.append("costPerMonth", thePackage.costPerMonth);
        //data.salesMan = salesMan
      } else {
        data.append("cashPrice", cashPrice);
      }
      data.append(
        "startDate",
        moment(purchaseDate).locale("en").format("YYYY-MM-DD")
      );
      console.log(Array.from(data));
      axios
        .post(
          `${BASE_END_POINT}orders/${this.state.client.user.id}/sale`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${this.props.currentUser.token}`,
            },
          }
        )
        .then((response) => {
          l.then(() => message.success(allStrings.done, 2.5));
          this.getOrders();
          this.toggle();
        })
        .catch((error) => {
          console.log(error.response);
          if (
            error.response.data.errors === "the product count is not available"
          ) {
            l.then(() => message.error(allStrings.productNotAvailable, 2.5));
          } else {
            l.then(() => message.error(allStrings.error, 2.5));
          }
        });
    }
  };

  componentDidMount() {
    this.getOrders(1, false);
    this.getProducts();
    this.getSetting();
    console.log(moment().format("YYYY/MM/DD"));
    this.setState({ paidDate: moment().format("YYYY/MM/DD") });
    if (this.props.isRTL) {
      this.setState({ paidDate: moment().format("YYYY/MM/DD") });
    } else {
      this.setState({ paidDate: moment().locale("en").format("YYYY/MM/DD") });
    }
    paidDate = moment().locale("en").format("YYYY-MM-DD");
    //purchaseDate
    this.setState({ purchaseDate: moment().format("YYYY/MM/DD") });
    if (this.props.isRTL) {
      this.setState({ purchaseDate: moment().format("YYYY/MM/DD") });
    } else {
      this.setState({
        purchaseDate: moment().locale("en").format("YYYY/MM/DD"),
      });
    }
    purchaseDate = moment().locale("en").format("YYYY-MM-DD");
    //this.props.ChangeLanguage(false)
  }

  submitHandler = (event) => {
    event.preventDefault();
    console.log(event.target);
    event.target.className += " was-validated";
    this.addOrder();
  };

  changeHandler = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  changeCount = (event) => {
    console.log(event.target.value);
    let cashPrice = event.target.value * this.state.cashPrice;
    if (this.state.productDetails.cashPrice) {
      cashPrice = event.target.value * this.state.productDetails.cashPrice;
    }
    if (this.state.selectedPackage != null) {
      let thePackage = this.state.packages[this.state.selectedPackage];
      console.log(thePackage);
      let packagee = {
        ratio: thePackage.ratio,
        firstPaid: thePackage.firstPaid * event.target.value,
        months: thePackage.months,
        costPerMonth: thePackage.costPerMonth * event.target.value,
        installmentPrice: thePackage.installmentPrice * event.target.value,
      };
      this.setState({ totalSelectedPackage: packagee });
    }

    this.setState({
      [event.target.name]: event.target.value,
      cashPrice: cashPrice,
      // packages:packages
    });
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  toggle2 = () => {
    this.setState({
      modal2: !this.state.modal2,
    });
  };
  onChange = (e) => {
    this.setState({
      paymentSystem: e.target.value,
    });
    if (e.target.value === "installment") {
      this.setState({ cashPrice: 0 });
    }
  };
  selectedPackage = (e) => {
    this.setState({ selectedPackage: e.target.value });
    console.log(this.state.selectedPackage);
    //
    let thePackage = this.state.packages[e.target.value];
    console.log(thePackage);
    console.log(this.state.total);
    let packagee = {
      ratio: thePackage.ratio,
      firstPaid: thePackage.firstPaid * this.state.count,
      months: thePackage.months,
      costPerMonth: thePackage.costPerMonth * this.state.count,
      installmentPrice: thePackage.installmentPrice * this.state.count,
    };
    this.setState({ totalSelectedPackage: packagee });
  };
  onChangePaidDate = (date, dateString) => {
    if (this.props.isRTL) {
      this.setState({ paidDate: moment(dateString).format("YYYY/MM/DD") });
    } else {
      this.setState({
        paidDate: moment(dateString).locale("en").format("YYYY/MM/DD"),
      });
    }
    paidDate = moment(dateString).locale("en").format("YYYY-MM-DD");
    console.log(paidDate);
  };
  onChangePurchaseDate = (date, dateString) => {
    if (this.props.isRTL) {
      this.setState({ purchaseDate: moment(dateString).format("YYYY/MM/DD") });
    } else {
      this.setState({
        purchaseDate: moment(dateString).locale("en").format("YYYY/MM/DD"),
      });
    }
    purchaseDate = moment(dateString).locale("en").format("YYYY-MM-DD");
    console.log(purchaseDate);
  };
  modal = () => (
    <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
      <MDBModalHeader toggle={this.toggle}>
        {allStrings.completeData}
      </MDBModalHeader>
      <MDBModalBody>
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12">
              <form
                style={{ marginLeft: "4rem" }}
                className="needs-validation"
                onSubmit={this.submitHandler}
                noValidate
              >
                <MDBContainer>
                  <MDBRow style={{ marginBottom: "6px", marginTop: "6px" }}>
                    <MDBCol md="9" sm="9">
                      <TreeSelect
                        value={this.state.product}
                        showSearch={true}
                        style={{
                          width: "100%",
                          textAlign: this.props.isRTL ? "right" : "left",
                        }}
                        treeCheckable={false}
                        treeNodeFilterProp="title"
                        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                        placeholder={allStrings.chooseProduct}
                        onChange={(value) => {
                          console.log(value);
                          this.setState({ product: value });
                          this.getProductById(value);
                        }}
                      >
                        {/*<TreeNode style={{background:"antiquewhite",color:'#fff !important'}} value="newClient" title={allStrings.newClient} key={allStrings.newClient} />*/}
                        {this.state.products.map((val) => (
                          <TreeNode
                            value={val.id}
                            title={this.props.isRTL ? val.name_ar : val.name_en}
                            key={val.id}
                          />
                        ))}
                      </TreeSelect>
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                      <label
                        htmlFor="defaultFormRegisterNameEx1"
                        className="grey-text formLabel"
                      >
                        {allStrings.chooseProduct}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{ marginBottom: "6px", marginTop: "6px" }}>
                    <MDBCol md="9" sm="9">
                      <input
                        name="count"
                        onChange={this.changeCount}
                        min={1}
                        type="number"
                        id="defaultFormRegisterNameEx2"
                        className="form-control"
                        value={this.state.count}
                        placeholder={allStrings.count}
                        style={{ direction: this.props.isRTL ? "rtl" : "ltr" }}
                        defaultValue="1"
                      />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                      <label
                        htmlFor="defaultFormRegisterNameEx2"
                        className="grey-text formLabel"
                      >
                        {allStrings.count}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{ marginBottom: "6px", marginTop: "6px" }}>
                    <MDBCol md="9" sm="9">
                      <input
                        name="client"
                        disabled
                        type="text"
                        id="defaultFormRegisterName"
                        className="form-control"
                        style={{ direction: this.props.isRTL ? "rtl" : "ltr" }}
                        defaultValue={
                          this.state.client ? this.state.client.fullname : ""
                        }
                      />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                      <label
                        htmlFor="defaultFormRegisterName"
                        className="grey-text formLabel"
                      >
                        {allStrings.client}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{ marginBottom: "6px", marginTop: "6px" }}>
                    <MDBCol md="9" sm="9">
                      <TreeSelect
                        showSearch={true}
                        style={{
                          width: "100%",
                          textAlign: this.props.isRTL ? "right" : "left",
                        }}
                        treeCheckable={false}
                        treeNodeFilterProp="title"
                        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                        placeholder={allStrings.chooseSalesMan}
                        onChange={(value) => {
                          console.log(value);
                          this.setState({ buySalesMan: value });
                        }}
                      >
                        {/*<TreeNode style={{background:"antiquewhite",color:'#fff !important'}} value="newClient" title={allStrings.newClient} key={allStrings.newClient} />*/}
                        {this.state.salesMen.map((val) => (
                          <TreeNode
                            value={val.id}
                            title={val.fullname}
                            key={val.id}
                          />
                        ))}
                      </TreeSelect>
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                      <label
                        htmlFor="defaultFormRegisterNameEx1"
                        className="grey-text formLabel"
                      >
                        {allStrings.buySalesMan}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{ marginBottom: "6px", marginTop: "6px" }}>
                    <MDBCol md="9" sm="9">
                      <TreeSelect
                        showSearch={true}
                        style={{
                          width: "100%",
                          textAlign: this.props.isRTL ? "right" : "left",
                        }}
                        treeCheckable={false}
                        treeNodeFilterProp="title"
                        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                        placeholder={allStrings.chooseSalesMan}
                        onChange={(value) => {
                          console.log(value);
                          this.setState({ salesMan: value });
                        }}
                      >
                        {/*<TreeNode style={{background:"antiquewhite",color:'#fff !important'}} value="newClient" title={allStrings.newClient} key={allStrings.newClient} />*/}
                        {this.state.salesMen.map((val) => (
                          <TreeNode
                            value={val.id}
                            title={val.fullname}
                            key={val.id}
                          />
                        ))}
                      </TreeSelect>
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                      <label
                        htmlFor="defaultFormRegisterNameEx1"
                        className="grey-text formLabel"
                      >
                        {allStrings.collectSalesMan}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{ marginBottom: "6px", marginTop: "6px" }}>
                    <MDBCol md="9" sm="9">
                      <div style={{ marginBottom: "10px", textAlign: "right" }}>
                        <div
                          style={{
                            display: "inline-block",
                            textAlign: "center",
                            marginLeft: this.props.isRTL ? "35px" : "-93px",
                          }}
                        >
                          <ConfigProvider
                            locale={this.props.isRTL ? locale_ar : locale_en}
                          >
                            <DatePicker
                              defaultValue={moment(
                                this.state.purchaseDate,
                                "YYYY/MM/DD"
                              )}
                              onChange={this.onChangePurchaseDate}
                            />
                          </ConfigProvider>
                          <span className="newSpanM">
                            <img
                              alt=""
                              style={{ marginRight: "4px" }}
                              src={require("../../assets/images/icons8_edit_property.png")}
                            ></img>
                            {this.state.purchaseDate}
                          </span>
                        </div>
                      </div>
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                      <label
                        htmlFor="defaultFormRegisterNam2"
                        className="grey-text formLabel"
                      >
                        {allStrings.purchaseDate}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{ marginBottom: "6px", marginTop: "6px" }}>
                    <MDBCol md="9" sm="9">
                      <div style={{ marginBottom: "10px", textAlign: "right" }}>
                        <div
                          style={{
                            display: "inline-block",
                            textAlign: "center",
                            marginLeft: this.props.isRTL ? "35px" : "-93px",
                          }}
                        >
                          <ConfigProvider
                            locale={this.props.isRTL ? locale_ar : locale_en}
                          >
                            <DatePicker
                              defaultValue={moment(
                                this.state.paidDate,
                                "YYYY/MM/DD"
                              )}
                              onChange={this.onChangePaidDate}
                            />
                          </ConfigProvider>
                          <span className="newSpanM">
                            <img
                              alt=""
                              style={{ marginRight: "4px" }}
                              src={require("../../assets/images/icons8_edit_property.png")}
                            ></img>
                            {this.state.paidDate}
                          </span>
                        </div>
                      </div>
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                      <label
                        htmlFor="defaultFormRegisterNameEx2"
                        className="grey-text formLabel"
                      >
                        {allStrings.paidDate}
                      </label>
                    </MDBCol>
                  </MDBRow>

                  <Radio.Group
                    onChange={this.onChange}
                    value={this.state.paymentSystem}
                    style={{ width: "100%" }}
                  >
                    <MDBRow style={{ marginBottom: "6px", marginTop: "6px" }}>
                      <MDBCol md="9" sm="9">
                        <input
                          name="cashPrice"
                          onChange={this.changeHandler}
                          min={0}
                          type="number"
                          id="defaultFormRegisterNameEx3"
                          className="form-control"
                          placeholder={allStrings.cashPrice}
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                          value={this.state.cashPrice}
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <Radio value="cash">
                          <label
                            htmlFor="defaultFormRegisterNameEx3"
                            className="grey-text formLabel"
                          >
                            {allStrings.cashPrice}
                          </label>
                        </Radio>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow style={{ marginBottom: "6px", marginTop: "6px" }}>
                      {this.state.paymentSystem !== "cash" ? (
                        <MDBCol md="9" sm="9">
                          <MDBContainer>
                            <MDBRow>
                              {this.state.packages.length > 0 ? (
                                this.state.packages.map((val, index) => (
                                  <MDBCol
                                    lg="6"
                                    md="12"
                                    style={{ paddingLeft: "0px" }}
                                  >
                                    <button
                                      className="hiddenButton"
                                      type="button"
                                      onClick={this.selectedPackage}
                                      value={index}
                                    >
                                      {" "}
                                    </button>
                                    <div
                                      className="package"
                                      id={val.id}
                                      // eslint-disable-next-line eqeqeq
                                      style={{
                                        background:
                                          this.state.selectedPackage == index
                                            ? "#C3D7F2"
                                            : "#c3d7f28f",
                                      }}
                                    >
                                      <MDBContainer>
                                        <MDBRow>
                                          <MDBCol
                                            md="3"
                                            sm="3"
                                            xs="3"
                                            className="noPadding"
                                            style={{ textAlign: "right" }}
                                          >
                                            <span className="packageLabel">
                                              %
                                            </span>
                                          </MDBCol>
                                          <MDBCol md="6" sm="6" xs="6">
                                            <input
                                              name="ratio2"
                                              onChange={this.changeHandler}
                                              type="text"
                                              id="defaultFormRegisterNameEx1"
                                              required
                                              className="form-control packageInput"
                                              style={{
                                                direction: this.props.isRTL
                                                  ? "rtl"
                                                  : "ltr",
                                              }}
                                              disabled
                                              defaultValue={val.ratio}
                                            />
                                          </MDBCol>
                                          <MDBCol
                                            md="3"
                                            sm="3"
                                            xs="3"
                                            className="noPadding"
                                            style={{ textAlign: "left" }}
                                          >
                                            <span className="packageLabel">
                                              {allStrings.ratio}
                                            </span>
                                          </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                          <MDBCol
                                            md="3"
                                            sm="3"
                                            xs="3"
                                            className="noPadding"
                                            style={{ textAlign: "right" }}
                                          >
                                            <span className="packageLabel">
                                              {allStrings.pound}
                                            </span>
                                          </MDBCol>
                                          <MDBCol md="6" sm="6" xs="6">
                                            <input
                                              name="firstPaid2"
                                              onChange={this.changeHandler}
                                              type="text"
                                              id="defaultFormRegisterNameEx1"
                                              required
                                              className="form-control packageInput"
                                              style={{
                                                direction: this.props.isRTL
                                                  ? "rtl"
                                                  : "ltr",
                                              }}
                                              disabled
                                              defaultValue={val.firstPaid}
                                            />
                                          </MDBCol>
                                          <MDBCol
                                            md="3"
                                            sm="3"
                                            xs="3"
                                            className="noPadding"
                                            style={{ textAlign: "left" }}
                                          >
                                            <span className="packageLabel">
                                              {allStrings.firstPaid}
                                            </span>
                                          </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                          <MDBCol
                                            md="3"
                                            sm="3"
                                            xs="3"
                                            className="noPadding"
                                            style={{ textAlign: "right" }}
                                          >
                                            <span className="packageLabel">
                                              {allStrings.months}
                                            </span>
                                          </MDBCol>
                                          <MDBCol md="6" sm="6" xs="6">
                                            <input
                                              name="months2"
                                              onChange={this.changeHandler}
                                              type="text"
                                              id="defaultFormRegisterNameEx1"
                                              required
                                              className="form-control packageInput"
                                              style={{
                                                direction: this.props.isRTL
                                                  ? "rtl"
                                                  : "ltr",
                                              }}
                                              disabled
                                              defaultValue={val.months}
                                            />
                                          </MDBCol>
                                          <MDBCol
                                            md="3"
                                            sm="3"
                                            xs="3"
                                            className="noPadding"
                                            style={{ textAlign: "left" }}
                                          >
                                            <span className="packageLabel">
                                              {allStrings.theMonths}
                                            </span>
                                          </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                          <MDBCol
                                            md="3"
                                            sm="3"
                                            xs="3"
                                            className="noPadding"
                                            style={{ textAlign: "right" }}
                                          >
                                            <span className="packageLabel">
                                              {allStrings.pound}
                                            </span>
                                          </MDBCol>
                                          <MDBCol
                                            md="6"
                                            sm="6"
                                            xs="6"
                                            style={{ textAlign: "center" }}
                                          >
                                            <input
                                              name="installment2"
                                              onChange={this.changeHandler}
                                              type="text"
                                              id="defaultFormRegisterNameEx1"
                                              required
                                              className="form-control installmentInput"
                                              disabled
                                              defaultValue={val.costPerMonth}
                                            />
                                          </MDBCol>
                                          <MDBCol
                                            md="3"
                                            sm="3"
                                            xs="3"
                                            className="noPadding"
                                            style={{ textAlign: "left" }}
                                          >
                                            <span className="packageLabel">
                                              {allStrings.theInstallment}
                                            </span>
                                          </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                          <MDBCol
                                            md="3"
                                            sm="3"
                                            xs="3"
                                            className="noPadding"
                                            style={{ textAlign: "right" }}
                                          >
                                            <span className="packageLabel">
                                              {allStrings.pound}
                                            </span>
                                          </MDBCol>
                                          <MDBCol
                                            md="6"
                                            sm="6"
                                            xs="6"
                                            style={{ textAlign: "center" }}
                                          >
                                            <input
                                              type="text"
                                              className="form-control installmentInput"
                                              disabled
                                              defaultValue={
                                                val.installmentPrice
                                              }
                                            />
                                          </MDBCol>
                                          <MDBCol
                                            md="3"
                                            sm="3"
                                            xs="3"
                                            className="noPadding"
                                            style={{ textAlign: "left" }}
                                          >
                                            <span className="packageLabel">
                                              {allStrings.totalCost}
                                            </span>
                                          </MDBCol>
                                        </MDBRow>
                                      </MDBContainer>
                                    </div>
                                  </MDBCol>
                                ))
                              ) : (
                                <div className="noData">
                                  {allStrings.noData}
                                </div>
                              )}
                            </MDBRow>
                          </MDBContainer>
                        </MDBCol>
                      ) : (
                        <MDBCol md="9" sm="9"></MDBCol>
                      )}
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <Radio
                          value="installment"
                          disabled={
                            this.state.packages.length === 0 ? true : false
                          }
                        >
                          <label
                            htmlFor="defaultFormRegisterNameEx13"
                            className="grey-text formLabel"
                          >
                            {allStrings.installmentPackages}
                          </label>
                        </Radio>
                      </MDBCol>
                    </MDBRow>
                  </Radio.Group>
                  <br></br>
                  <p style={{ textAlign: "right", fontSize: "18px" }}>
                    {allStrings.totalCost} :{" "}
                  </p>
                  <MDBRow>
                    {this.state.paymentSystem === "cash" ? (
                      <p style={{ textAlign: "center", fontSize: "18px" }}>
                        {this.state.cashPrice}
                      </p>
                    ) : (
                      <MDBCol lg="6" md="12" style={{ paddingLeft: "0px" }}>
                        <button className="hiddenButton" type="button">
                          {" "}
                        </button>
                        <div
                          className="package"
                          style={{ background: "#c3d7f28f" }}
                        >
                          <MDBContainer>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">%</span>
                              </MDBCol>
                              <MDBCol md="6" sm="6" xs="6">
                                <input
                                  name="ratio2"
                                  type="text"
                                  id="defaultFormReerNameEx1"
                                  required
                                  className="form-control packageInput"
                                  style={{
                                    direction: this.props.isRTL ? "rtl" : "ltr",
                                  }}
                                  disabled
                                  defaultValue={
                                    this.state.totalSelectedPackage != null
                                      ? this.state.totalSelectedPackage.ratio
                                      : ""
                                  }
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.ratio}
                                </span>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.pound}
                                </span>
                              </MDBCol>
                              <MDBCol md="6" sm="6" xs="6">
                                <input
                                  name="firstPaid2"
                                  type="text"
                                  id="defaultFormsterNameEx1"
                                  required
                                  className="form-control packageInput"
                                  style={{
                                    direction: this.props.isRTL ? "rtl" : "ltr",
                                  }}
                                  disabled
                                  defaultValue={
                                    this.state.totalSelectedPackage != null
                                      ? this.state.totalSelectedPackage
                                          .firstPaid
                                      : ""
                                  }
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.firstPaid}
                                </span>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.months}
                                </span>
                              </MDBCol>
                              <MDBCol md="6" sm="6" xs="6">
                                <input
                                  name="months2"
                                  type="text"
                                  id="defaultFormReNameEx1"
                                  required
                                  className="form-control packageInput"
                                  style={{
                                    direction: this.props.isRTL ? "rtl" : "ltr",
                                  }}
                                  disabled
                                  defaultValue={
                                    this.state.totalSelectedPackage != null
                                      ? this.state.totalSelectedPackage.months
                                      : ""
                                  }
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.theMonths}
                                </span>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.pound}
                                </span>
                              </MDBCol>
                              <MDBCol
                                md="6"
                                sm="6"
                                xs="6"
                                style={{ textAlign: "center" }}
                              >
                                <input
                                  name="installment2"
                                  type="text"
                                  id="defaultFormRegisNameEx1"
                                  required
                                  className="form-control installmentInput"
                                  disabled
                                  defaultValue={
                                    this.state.totalSelectedPackage != null
                                      ? this.state.totalSelectedPackage
                                          .costPerMonth
                                      : ""
                                  }
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.theInstallment}
                                </span>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.pound}
                                </span>
                              </MDBCol>
                              <MDBCol
                                md="6"
                                sm="6"
                                xs="6"
                                style={{ textAlign: "center" }}
                              >
                                <input
                                  type="text"
                                  className="form-control installmentInput"
                                  disabled
                                  defaultValue={
                                    this.state.totalSelectedPackage != null
                                      ? this.state.totalSelectedPackage
                                          .installmentPrice
                                      : ""
                                  }
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.totalCost}
                                </span>
                              </MDBCol>
                            </MDBRow>
                          </MDBContainer>
                        </div>
                      </MDBCol>
                    )}
                  </MDBRow>
                  <MDBRow>
                    <MDBBtn
                      type="submit"
                      style={{
                        margin: "4rem 0",
                        width: "170px",
                        padding: "10px",
                      }}
                      className="btn z-depth-1a "
                      color="primary"
                    >
                      {allStrings.done}
                    </MDBBtn>
                  </MDBRow>
                </MDBContainer>
              </form>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBModalBody>
    </MDBModal>
  );
  modal2 = () => (
    <MDBModal isOpen={this.state.modal2} toggle={this.toggle2} size="lg">
      <MDBModalHeader toggle={this.toggle2}>
        {allStrings.completeData}
      </MDBModalHeader>
      <MDBModalBody>
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12">
              <form
                style={{ marginLeft: "15%" }}
                className="needs-validation"
                onSubmit={this.payCashHandelSubmit}
                noValidate
              >
                <MDBContainer>
                  <MDBRow style={{ marginBottom: "6px", marginTop: "6px" }}>
                    <MDBCol md="9" sm="9">
                      <input
                        name="cashMoney"
                        onChange={this.changeHandlerPayCash}
                        type="number"
                        min={0}
                        id="defaultFormRegisNameEx12"
                        className="form-control"
                        placeholder={allStrings.cost}
                        style={{ direction: this.props.isRTL ? "rtl" : "ltr" }}
                        required
                      />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                      <label
                        htmlFor="defaultFormRegisNameEx12"
                        className="grey-text formLabel"
                      >
                        {allStrings.cost}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBBtn
                      type="submit"
                      style={{
                        margin: "3rem 1rem ",
                        width: "170px",
                        padding: "11px 20px",
                        height: "35px",
                      }}
                      className="btn z-depth-1a "
                      color="primary"
                    >
                      {allStrings.payCash}
                    </MDBBtn>
                  </MDBRow>
                </MDBContainer>
              </form>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBModalBody>
    </MDBModal>
  );
  render() {
    return (
      <div className="ClientProducts">
        {this.modal()}
        {this.modal2()}

        <MDBContainer>
          <MDBRow>
            <MDBCol md="4" sm="4" xs="4">
              <div
                className="top"
                style={{ width: "100%", textAlign: "center" }}
                onClick={() => this.props.history.goBack()}
              >
                <MDBIcon icon="times" className="closeIcon" />
              </div>
            </MDBCol>
            <MDBCol md="4" sm="4" xs="4">
              <label
                htmlFor="defaultFormRegisterNameE"
                style={{
                  fontWeight: "800",
                  color: "#43425D",
                  fontSize: "20px",
                  margin: "1rem",
                  textAlign: "center",
                  display: "block",
                }}
              >
                {allStrings.recievedProductsForClient}
              </label>
              <span
                style={{
                  fontWeight: "800",
                  color: "#43425D",
                  fontSize: "20px",
                  margin: "1rem",
                  textAlign: "center",
                  display: "block",
                }}
              >
                {this.state.client.fullname}
              </span>
            </MDBCol>
            <MDBCol md="4" sm="4" xs="4">
              <div className="addButton" onClick={this.toggle}>
                <MDBIcon icon="plus" />
              </div>
              <span
                onClick={this.toggle}
                style={{
                  float: "right",
                  color: "#43425D",
                  fontWeight: "600",
                  padding: "11px 2px",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              >
                {allStrings.DliveryNewProduct}
              </span>
              <br></br>
              <div style={{ display: "inline-block", width: "100%" }}>
                <div
                  className="addButton"
                  onClick={() =>
                    this.props.history.push("/multiSale", {
                      client: this.state.client,
                    })
                  }
                >
                  <MDBIcon icon="plus" />
                </div>
                <span
                  onClick={() =>
                    this.props.history.push("/multiSale", {
                      client: this.state.client,
                    })
                  }
                  style={{
                    float: "right",
                    color: "#43425D",
                    fontWeight: "600",
                    padding: "11px 2px",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                >
                  {allStrings.DliveryMultiProducts}
                </span>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="4" sm="4" xs="4"></MDBCol>
            <MDBCol md="4" sm="4" xs="4"></MDBCol>
            <MDBCol md="4" sm="4" xs="4"></MDBCol>
          </MDBRow>
          <MDBRow>
            <div className="productsContainer">
              {this.state.loading ? (
                <div>
                  <LoadCard />
                  <LoadCard />
                  <LoadCard />
                  <LoadCard />
                  <LoadCard />
                </div>
              ) : this.state.orders.length > 0 ? (
                this.state.orders.map((val) => (
                  <div className="myProduct">
                    <MDBRow>
                      <MDBCol md="3" sm="3">
                        <p
                          style={{
                            color: "red",
                            fontWeight: "600",
                            fontSize: "18px",
                            margin: "2rem",
                            textAlign: "center",
                          }}
                        ></p>
                      </MDBCol>
                      <MDBCol md="4" sm="4">
                        <p
                          className="productTopInfo"
                          style={{ fontSize: "14px", marginTop: "1rem" }}
                        >
                          {val.paymentSystem === "installment" &&
                          val.processType != "MULTI" ? (
                            <span>
                              <span
                                style={{
                                  flexDirection: "row",
                                  display: "inline-flex",
                                  justifyContent: "center",
                                  marginLeft: "2px",
                                  marginRight: "2px",
                                }}
                              >
                                {this.props.isRTL
                                  ? val.product.name_ar
                                  : val.product.name_en}
                              </span>
                              {" - " + val.count + " "}
                            </span>
                          ) : this.props.isRTL ? (
                            val.product.name_ar
                          ) : (
                            val.product.name_en
                          )}
                        </p>
                        {val.processType === "SINGLE" ? (
                          <p
                            className="productTopInfo"
                            style={{ fontSize: "18px", color: "#4B86FF" }}
                          >
                            {val.paymentSystem === "installment"
                              ? allStrings.installment
                              : allStrings.cash}
                          </p>
                        ) : (
                          <p
                            className="productTopInfo"
                            style={{ fontSize: "18px", color: "#4B86FF" }}
                          >
                            {val.paymentSystem === "installment"
                              ? allStrings.multiInstallment
                              : allStrings.multiCash}
                          </p>
                        )}
                        <p
                          className="productTopInfo"
                          style={{ fontSize: "12px" }}
                        >
                          {val.startDate ? val.startDate.substring(0, 10) : ""}
                        </p>
                      </MDBCol>
                      <MDBCol md="5" sm="5">
                        <div className="productTopImg">
                          <img
                            alt=""
                            style={{
                              width: "80%",
                              height: "120px",
                              borderRadius: "10px",
                              boxShadow: "4px 3px 9px #aeaeae",
                            }}
                            src={
                              val.product.mainImg
                                ? val.product.mainImg
                                : require("../../assets/images/profileicon.jpg")
                            }
                          ></img>
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <br></br>
                    {/*multi products */}
                    {val.multiProducts.length > 0 &&
                      val.processType == "MULTI" && (
                        <div>
                          <MDBContainer>
                            <MDBRow>
                              <MDBCol
                                md="5"
                                sm="5"
                                style={{
                                  paddingRight: "0px",
                                  paddingLeft: "5px",
                                }}
                              >
                                <div
                                  className="productInfoLeft"
                                  style={{
                                    background: "#43425D",
                                    boxShadow: "none",
                                  }}
                                >
                                  <p
                                    className="infoData"
                                    style={{ color: "#fff" }}
                                  >
                                    السعر
                                  </p>
                                </div>
                              </MDBCol>
                              <MDBCol
                                md="2"
                                sm="2"
                                style={{
                                  paddingRight: "0px",
                                  paddingLeft: "0px",
                                }}
                              >
                                <div
                                  className="productInfoCenter"
                                  style={{ background: "#dadada" }}
                                >
                                  <p
                                    className="infoData"
                                    style={{ color: "#000" }}
                                  >
                                    الكميه
                                  </p>
                                </div>
                              </MDBCol>
                              <MDBCol
                                md="5"
                                sm="5"
                                style={{
                                  paddingLeft: "0px",
                                  paddingRight: "5px",
                                }}
                              >
                                <div
                                  className="productInfoRight"
                                  style={{
                                    background: "#43425D",
                                    boxShadow: "none",
                                  }}
                                >
                                  <p className="infoTitle">المنتج</p>
                                </div>
                              </MDBCol>
                            </MDBRow>
                            {val.multiProducts.map((v, i) => (
                              <MDBRow>
                                <MDBCol
                                  md="5"
                                  sm="5"
                                  style={{
                                    paddingRight: "0px",
                                    paddingLeft: "5px",
                                  }}
                                >
                                  <div
                                    className="productInfoLeft"
                                    style={{
                                      background: "#43425D",
                                      boxShadow: "none",
                                    }}
                                  >
                                    <p
                                      className="infoData"
                                      style={{ color: "#fff" }}
                                    >
                                      {v.cost}
                                    </p>
                                  </div>
                                </MDBCol>
                                <MDBCol
                                  md="2"
                                  sm="2"
                                  style={{
                                    paddingRight: "0px",
                                    paddingLeft: "0px",
                                  }}
                                >
                                  <div
                                    className="productInfoCenter"
                                    style={{ background: "#dadada" }}
                                  >
                                    <p
                                      className="infoData"
                                      style={{ color: "#000" }}
                                    >
                                      {v.count}
                                    </p>
                                  </div>
                                </MDBCol>
                                <MDBCol
                                  md="5"
                                  sm="5"
                                  style={{
                                    paddingLeft: "0px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  <div
                                    className="productInfoRight"
                                    style={{
                                      background: "#43425D",
                                      boxShadow: "none",
                                    }}
                                  >
                                    <p className="infoTitle">
                                      {v.product
                                        ? this.props.isRTL
                                          ? v.product.name_ar
                                          : v.product.name_en
                                        : ""}
                                    </p>
                                  </div>
                                </MDBCol>
                              </MDBRow>
                            ))}
                          </MDBContainer>
                        </div>
                      )}
                    <div>
                      <MDBContainer>
                        <MDBRow>
                          <MDBCol md="6" sm="6" style={{ paddingRight: "0px" }}>
                            <div
                              className="productInfoLeft"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                let visibleSalesMenOrders = [];
                                let showMuliSalesMen =
                                  this.state.showMuliSalesMen;
                                console.log(
                                  "visibleSalesMenOrders2",
                                  this.state.showMuliSalesMen
                                );
                                console.log(
                                  "visibleSalesMenOrders",
                                  this.state.visibleSalesMenOrders
                                );
                                if (
                                  this.state.visibleSalesMenOrders.find(
                                    (e) => e == val.id
                                  ) &&
                                  showMuliSalesMen == true
                                ) {
                                  showMuliSalesMen = false;
                                } else {
                                  showMuliSalesMen = true;
                                  //visibleSalesMenOrders = [...new Set(visibleSalesMenOrders)]
                                }
                                visibleSalesMenOrders.push(val.id);
                                this.setState({
                                  showMuliSalesMen: showMuliSalesMen,
                                  visibleSalesMenOrders: visibleSalesMenOrders,
                                });
                              }}
                            >
                              <p className="infoData">
                                <span
                                  class="material-icons"
                                  style={{ color: "darkblue" }}
                                >
                                  {this.state.showMuliSalesMen
                                    ? this.state.visibleSalesMenOrders.find(
                                        (e) => e == val.id
                                      )
                                      ? "visibility_off"
                                      : "visibility"
                                    : "visibility"}
                                </span>
                                <span
                                  style={{
                                    margin: "5px",
                                    position: "relative",
                                    top: "-6px",
                                    fontSize: "16px",
                                  }}
                                >
                                  {allStrings.multiple}
                                </span>
                              </p>
                            </div>
                          </MDBCol>
                          <MDBCol md="6" sm="6" style={{ paddingLeft: "0px" }}>
                            <div className="productInfoRight">
                              <p className="infoTitle">
                                {allStrings.buySalesMen}
                              </p>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBContainer>
                    </div>
                    {/** multi buy salesMan */}
                    {this.state.showMuliSalesMen &&
                      this.state.visibleSalesMenOrders.find(
                        (e) => e == val.id
                      ) && (
                        <div className="topMulti">
                          <MDBContainer>
                            <MDBRow>
                              <MDBCol
                                md="6"
                                sm="6"
                                style={{
                                  paddingRight: "0px",
                                  paddingLeft: "0px",
                                }}
                              >
                                <div className="productInfoLeftTop">
                                  <p
                                    className="infoData"
                                    style={{
                                      textAlign: "center",
                                      color: "#fff",
                                    }}
                                  >
                                    {allStrings.theRatio}
                                  </p>
                                </div>
                              </MDBCol>
                              <MDBCol
                                md="6"
                                sm="6"
                                style={{
                                  paddingLeft: "0px",
                                  paddingRight: "0px",
                                }}
                              >
                                <div className="productInfoRightTop">
                                  <p
                                    className="infoTitle"
                                    style={{ textAlign: "center" }}
                                  >
                                    {allStrings.name}
                                  </p>
                                </div>
                              </MDBCol>
                            </MDBRow>
                            {val.multiBuySalesMen.map((v, i) => (
                              <MDBRow>
                                <MDBCol
                                  md="6"
                                  sm="6"
                                  style={{ paddingRight: "0px" }}
                                >
                                  <div className="productInfoLeft">
                                    <p
                                      className="infoData"
                                      style={{
                                        textAlign: "center",
                                        color: "brown",
                                      }}
                                    >
                                      {v.commission}
                                    </p>
                                  </div>
                                </MDBCol>
                                <MDBCol
                                  md="6"
                                  sm="6"
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div
                                    className="productInfoRight"
                                    style={{ background: "brown" }}
                                  >
                                    <p
                                      className="infoTitle"
                                      style={{ textAlign: "center" }}
                                    >
                                      {v.buySalesMan
                                        ? v.buySalesMan.fullname
                                        : ""}
                                    </p>
                                  </div>
                                </MDBCol>
                              </MDBRow>
                            ))}
                          </MDBContainer>
                        </div>
                      )}
                    <div>
                      <MDBContainer>
                        <MDBRow>
                          <MDBCol md="6" sm="6" style={{ paddingRight: "0px" }}>
                            <div className="productInfoLeft">
                              <p className="infoData">
                                {val.salesMan ? val.salesMan.fullname : ""}
                              </p>
                            </div>
                          </MDBCol>
                          <MDBCol md="6" sm="6" style={{ paddingLeft: "0px" }}>
                            <div className="productInfoRight">
                              <p className="infoTitle">
                                {allStrings.collectSalesMan}
                              </p>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBContainer>
                    </div>
                    {/*for installments */}
                    {val.paymentSystem === "installment" && (
                      <div>
                        <div>
                          <MDBContainer>
                            <MDBRow>
                              <MDBCol
                                md="6"
                                sm="6"
                                style={{ paddingRight: "0px" }}
                              >
                                <div className="productInfoLeft">
                                  <p className="infoData">{val.firstPaid}</p>
                                </div>
                              </MDBCol>
                              <MDBCol
                                md="6"
                                sm="6"
                                style={{ paddingLeft: "0px" }}
                              >
                                <div className="productInfoRight">
                                  <p className="infoTitle">
                                    {allStrings.firstPay}
                                  </p>
                                </div>
                              </MDBCol>
                            </MDBRow>
                          </MDBContainer>
                        </div>
                        <div>
                          <MDBContainer>
                            <MDBRow>
                              <MDBCol
                                md="6"
                                sm="6"
                                style={{ paddingRight: "0px" }}
                              >
                                <div className="productInfoLeft">
                                  <p className="infoData">{val.costPerMonth}</p>
                                </div>
                              </MDBCol>
                              <MDBCol
                                md="6"
                                sm="6"
                                style={{ paddingLeft: "0px" }}
                              >
                                <div className="productInfoRight">
                                  <p className="infoTitle">
                                    {allStrings.premuimCost}
                                  </p>
                                </div>
                              </MDBCol>
                            </MDBRow>
                          </MDBContainer>
                        </div>
                        <div>
                          <MDBContainer>
                            <MDBRow>
                              <MDBCol
                                md="6"
                                sm="6"
                                style={{ paddingRight: "0px" }}
                              >
                                <div className="productInfoLeft">
                                  {/* <p className="infoData">{val.monthCount}</p> */}
                                  <p className="infoData">{val.premuimsDesc}</p>
                                </div>
                              </MDBCol>
                              <MDBCol
                                md="6"
                                sm="6"
                                style={{ paddingLeft: "0px" }}
                              >
                                <div className="productInfoRight">
                                  <p className="infoTitle">
                                    {allStrings.premuimsCount}
                                  </p>
                                </div>
                              </MDBCol>
                            </MDBRow>
                          </MDBContainer>
                        </div>
                        <div>
                          <MDBContainer>
                            <MDBRow>
                              <MDBCol
                                md="6"
                                sm="6"
                                style={{ paddingRight: "0px" }}
                              >
                                <div className="productInfoLeft">
                                  <p className="infoData">
                                    {val.delayPremiums}
                                  </p>
                                </div>
                              </MDBCol>
                              <MDBCol
                                md="6"
                                sm="6"
                                style={{ paddingLeft: "0px" }}
                              >
                                <div className="productInfoRight">
                                  <p className="infoTitle">
                                    {allStrings.delayPremuims}
                                  </p>
                                </div>
                              </MDBCol>
                            </MDBRow>
                          </MDBContainer>
                        </div>
                        <div>
                          <MDBContainer>
                            <MDBRow>
                              <MDBCol
                                md="6"
                                sm="6"
                                style={{ paddingRight: "0px" }}
                              >
                                <div className="productInfoLeft">
                                  <p className="infoData">
                                    {val.paidMonth ? val.paidMonth : 0}
                                  </p>
                                </div>
                              </MDBCol>
                              <MDBCol
                                md="6"
                                sm="6"
                                style={{ paddingLeft: "0px" }}
                              >
                                <div className="productInfoRight">
                                  <p className="infoTitle">
                                    {allStrings.payPremuims}
                                  </p>
                                </div>
                              </MDBCol>
                            </MDBRow>
                          </MDBContainer>
                        </div>
                        <div>
                          <MDBContainer>
                            <MDBRow>
                              <MDBCol
                                md="6"
                                sm="6"
                                style={{ paddingRight: "0px" }}
                              >
                                <div className="productInfoLeft">
                                  <p className="infoData">
                                    {val.monthRemaining
                                      ? val.monthRemaining
                                      : val.monthCount}
                                  </p>
                                </div>
                              </MDBCol>
                              <MDBCol
                                md="6"
                                sm="6"
                                style={{ paddingLeft: "0px" }}
                              >
                                <div className="productInfoRight">
                                  <p className="infoTitle">
                                    {allStrings.remainPremuims}
                                  </p>
                                </div>
                              </MDBCol>
                            </MDBRow>
                          </MDBContainer>
                        </div>
                        <div>
                          <MDBContainer>
                            <MDBRow>
                              <MDBCol
                                md="6"
                                sm="6"
                                style={{ paddingRight: "0px" }}
                              >
                                <div className="productInfoLeft">
                                  <p className="infoData">
                                    {val.costPaid ? val.costPaid : 0}
                                  </p>
                                </div>
                              </MDBCol>
                              <MDBCol
                                md="6"
                                sm="6"
                                style={{ paddingLeft: "0px" }}
                              >
                                <div className="productInfoRight">
                                  <p className="infoTitle">
                                    {allStrings.payCost}
                                  </p>
                                </div>
                              </MDBCol>
                            </MDBRow>
                          </MDBContainer>
                        </div>
                        <div>
                          <MDBContainer>
                            <MDBRow>
                              <MDBCol
                                md="6"
                                sm="6"
                                style={{ paddingRight: "0px" }}
                              >
                                <div className="productInfoLeft">
                                  <p className="infoData">
                                    {val.costRemaining
                                      ? val.costRemaining
                                      : val.total}
                                  </p>
                                </div>
                              </MDBCol>
                              <MDBCol
                                md="6"
                                sm="6"
                                style={{ paddingLeft: "0px" }}
                              >
                                <div className="productInfoRight">
                                  <p className="infoTitle">
                                    {allStrings.remainCost}
                                  </p>
                                </div>
                              </MDBCol>
                            </MDBRow>
                          </MDBContainer>
                        </div>
                      </div>
                    )}
                    {/* count */}
                    {val.processType == "MULTI" && (
                      <div>
                        <MDBContainer>
                          <MDBRow>
                            <MDBCol
                              md="6"
                              sm="6"
                              style={{ paddingRight: "0px" }}
                            >
                              <div className="productInfoLeft">
                                <p className="infoData">
                                  {val.multiProducts
                                    ? val.multiProducts.length
                                    : ""}
                                </p>
                              </div>
                            </MDBCol>
                            <MDBCol
                              md="6"
                              sm="6"
                              style={{ paddingLeft: "0px" }}
                            >
                              <div
                                className="productInfoRight"
                                style={{ background: "rgb(13, 53, 127)" }}
                              >
                                <p className="infoTitle">
                                  {allStrings.theNumber}
                                </p>
                              </div>
                            </MDBCol>
                          </MDBRow>
                        </MDBContainer>
                      </div>
                    )}
                    {/* total */}
                    <div>
                      <MDBContainer>
                        <MDBRow>
                          <MDBCol md="6" sm="6" style={{ paddingRight: "0px" }}>
                            <div className="productInfoLeft">
                              <p className="infoData">
                                {val.total ? val.total : ""}
                              </p>
                            </div>
                          </MDBCol>
                          <MDBCol md="6" sm="6" style={{ paddingLeft: "0px" }}>
                            <div
                              className="productInfoRight"
                              style={{ background: "rgb(13, 53, 127)" }}
                            >
                              <p className="infoTitle">{allStrings.total}</p>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBContainer>
                    </div>
                    {/* buttons section */}
                    {/* update premiums */}
                    {val.paymentSystem == "installment" && (
                      <div>
                        <MDBBtn
                          onClick={() =>
                            this.props.history.push("/editPremuims", {
                              data: val,
                            })
                          }
                          style={{
                            margin: "5%",
                            marginBottom: "0px",
                            padding: "11px 20px",
                            height: "35px",
                            width: "90%",
                            fontSize: "12px",
                            fontWeight: "600",
                            background: "green",
                            color: "#fff",
                          }}
                          className="btn z-depth-1a "
                          color="green"
                        >
                          {allStrings.updatePremuims}
                        </MDBBtn>
                      </div>
                    )}
                    {/*for multiple */}
                    {val.processType == "MULTI" &&
                      val.status != "RETRIEVED" && (
                        <div>
                          <MDBBtn
                            onClick={() =>
                              this.props.history.push("/multiSale", {
                                order: val,
                                client: this.state.client,
                                requestType: "put",
                              })
                            }
                            style={{
                              margin: "5%",
                              marginBottom: "0px",
                              padding: "11px 20px",
                              height: "35px",
                              width: "90%",
                              fontSize: "12px",
                              fontWeight: "600",
                              background: "green",
                              color: "#fff",
                            }}
                            className="btn z-depth-1a "
                            color="green"
                          >
                            {allStrings.updateOrder}
                          </MDBBtn>
                        </div>
                      )}
                    {/* print  cash receipt*/}
                    {val.paymentSystem == "cash" && (
                      <div>
                        {this.state.toPrintId !== val.id && (
                          <MDBBtn
                            onClick={() => {
                              this.getPremuims(val.id, false);
                              this.setState({
                                premium: [],
                                toPrint: false,
                                toPrintId: null,
                                printGuarantor: false,
                                printType: val.processType == "MULTI" ? 2 : 1,
                              });
                            }}
                            style={{
                              margin: "1rem",
                              marginBottom: "0px",
                              padding: "11px 20px",
                              height: "35px",
                              width: "89%",
                              fontSize: "12px",
                              fontWeight: "600",
                              background: "green",
                              color: "#fff",
                            }}
                            className="btn z-depth-1a "
                            color="green"
                          >
                            {val.processType == "MULTI"
                              ? allStrings.printReceipts
                              : allStrings.printReceipt}
                          </MDBBtn>
                        )}
                        {this.state.toPrintId === val.id &&
                          this.state.printGuarantor == false && (
                            <div>
                              <ReactToPrint
                                documentTitle={
                                  "تقرير مبيعات العميل" +
                                  val.client.fullname +
                                  " بتاريخ " +
                                  moment().format("YYYY/MM/DD")
                                }
                                trigger={() => {
                                  return (
                                    <div>
                                      <MDBBtn
                                        style={{
                                          margin: "1rem",
                                          marginBottom: "0px",
                                          padding: "11px 20px",
                                          height: "35px",
                                          width: "89%",
                                          fontSize: "12px",
                                          fontWeight: "600",
                                          background: "#4F73AC",
                                          color: "#fff",
                                        }}
                                        className="btn z-depth-1a "
                                        color="#4F73AC"
                                        disabled={
                                          val.processType == "MULTI"
                                            ? false
                                            : this.state.premium.length < 0
                                            ? true
                                            : false
                                        }
                                      >
                                        {allStrings.readyToPrint}
                                      </MDBBtn>
                                    </div>
                                  );
                                }}
                                content={() => this.componentRef}
                              />
                              <div style={{ display: "none" }}>
                                <ComponentToPrint
                                  data={
                                    val.processType == "MULTI"
                                      ? val
                                      : this.state.premium
                                  }
                                  printType={val.processType == "MULTI" ? 2 : 1}
                                  advertisementUrl={this.state.advertisementUrl}
                                  ref={(el) => (this.componentRef = el)}
                                />
                              </div>
                            </div>
                          )}
                        {this.state.toPrintId === val.id &&
                          this.state.printGuarantor == true && (
                            <MDBBtn
                              onClick={() => {
                                this.getPremuims(val.id, false);
                                this.setState({
                                  toPrint: false,
                                  toPrintId: null,
                                  printGuarantor: false,
                                  printType: val.processType == "MULTI" ? 2 : 1,
                                });
                              }}
                              style={{
                                margin: "1rem",
                                marginBottom: "0px",
                                padding: "11px 20px",
                                height: "35px",
                                width: "89%",
                                fontSize: "12px",
                                fontWeight: "600",
                                background: "green",
                                color: "#fff",
                              }}
                              className="btn z-depth-1a "
                              color="green"
                            >
                              {val.processType == "MULTI"
                                ? allStrings.printReceipts
                                : allStrings.printReceipt}
                            </MDBBtn>
                          )}
                      </div>
                    )}
                    {/*print  guarantor info */}
                    {val.hasGuarantor == true && (
                      <div>
                        {this.state.toPrintId !== val.id && (
                          <div>
                            <MDBBtn
                              onClick={() => {
                                this.getGuarantor(val.id);
                                this.setState({
                                  guarantor: null,
                                  toPrint: false,
                                  toPrintId: null,
                                  printType: 3,
                                  printGuarantor: true,
                                });
                              }}
                              style={{
                                margin: "1rem",
                                marginBottom: "0px",
                                padding: "11px 20px",
                                height: "35px",
                                width: "89%",
                                fontSize: "12px",
                                fontWeight: "600",
                                background: "green",
                                color: "#fff",
                              }}
                              className="btn z-depth-1a "
                              color="green"
                            >
                              {allStrings.printGuarantor}
                            </MDBBtn>
                          </div>
                        )}
                        {this.state.toPrintId === val.id &&
                          this.state.printGuarantor == true && (
                            <div>
                              <ReactToPrint
                                documentTitle={
                                  "طباعه بيانات الضامن العميل " +
                                  val.client.fullname
                                }
                                trigger={() => {
                                  return (
                                    <div>
                                      <MDBBtn
                                        style={{
                                          margin: "1rem",
                                          marginBottom: "0px",
                                          padding: "11px 20px",
                                          height: "35px",
                                          width: "89%",
                                          fontSize: "12px",
                                          fontWeight: "600",
                                          background: "#4F73AC",
                                          color: "#fff",
                                        }}
                                        className="btn z-depth-1a "
                                        color="#4F73AC"
                                        disabled={
                                          this.state.guarantor ? false : true
                                        }
                                      >
                                        {allStrings.readyToPrint}
                                      </MDBBtn>
                                    </div>
                                  );
                                }}
                                content={() => this.componentRef}
                              />
                              <div style={{ display: "none" }}>
                                <ComponentToPrint
                                  data={this.state.guarantor}
                                  advertisementUrl={this.state.advertisementUrl}
                                  order={val}
                                  printType={3}
                                  ref={(el) => (this.componentRef = el)}
                                />
                              </div>
                            </div>
                          )}
                        {this.state.toPrintId === val.id &&
                          this.state.printGuarantor == false && (
                            <div>
                              <MDBBtn
                                onClick={() => {
                                  this.getGuarantor(val.id);
                                  this.setState({
                                    toPrint: false,
                                    toPrintId: null,
                                    printType: 3,
                                    printGuarantor: true,
                                  });
                                }}
                                style={{
                                  margin: "1rem",
                                  marginBottom: "0px",
                                  padding: "11px 20px",
                                  height: "35px",
                                  width: "89%",
                                  fontSize: "12px",
                                  fontWeight: "600",
                                  background: "green",
                                  color: "#fff",
                                }}
                                className="btn z-depth-1a "
                                color="green"
                              >
                                {allStrings.printGuarantor}
                              </MDBBtn>
                            </div>
                          )}
                      </div>
                    )}
                    {/*print  installments receipt */}
                    {val.paymentSystem == "installment" && (
                      <div>
                        {this.state.toPrintId !== val.id &&
                          val.processType != "MULTI" && (
                            <MDBBtn
                              onClick={() => {
                                this.getPremuims(val.id, false);
                                this.setState({
                                  premium: [],
                                  toPrint: false,
                                  toPrintId: null,
                                  printGuarantor: false,
                                  printType: val.processType == "MULTI" ? 4 : 1,
                                });
                              }}
                              style={{
                                margin: "1rem",
                                marginBottom: "0px",
                                padding: "11px 20px",
                                height: "35px",
                                width: "89%",
                                fontSize: "12px",
                                fontWeight: "600",
                                background: "green",
                                color: "#fff",
                              }}
                              className="btn z-depth-1a "
                              color="green"
                            >
                              {allStrings.printReceipts}
                            </MDBBtn>
                          )}
                        {this.state.toPrintId !== val.id &&
                          val.processType == "MULTI" &&
                          this.state.curOID != val.id && (
                            <MDBBtn
                              id="btnPdf"
                              onClick={() => {
                                this.state.url = null;
                                this.getPremuims(val.id, true);
                                this.setState({
                                  url: null,
                                  curOID: val.id,
                                  premium: [],
                                  toPrint: false,
                                  toPrintId: null,
                                  printGuarantor: false,
                                  printType: val.processType == "MULTI" ? 4 : 1,
                                });
                              }}
                              style={{
                                margin: "1rem",
                                marginBottom: "0px",
                                padding: "11px 20px",
                                height: "35px",
                                width: "89%",
                                fontSize: "12px",
                                fontWeight: "600",
                                background: "green",
                                color: "#fff",
                              }}
                              className="btn z-depth-1a "
                              color="green"
                            >
                              {allStrings.printReceipts}
                            </MDBBtn>
                          )}
                        {this.state.url &&
                          val.processType == "MULTI" &&
                          this.state.curOID == val.id && (
                            <a
                              onClick={() => {
                                this.returnNormal().then(() => {
                                  window.location.reload();
                                  this.setState({
                                    url: null,
                                  });
                                });
                              }}
                              style={{
                                margin: "1rem",
                                marginBottom: "0px",
                                padding: "11px 20px",
                                height: "35px",
                                width: "89%",
                                fontSize: "12px",
                                fontWeight: "600",
                                background: "green",
                                color: "#fff",
                              }}
                              className="btn z-depth-1a "
                              color="green"
                            >
                              عرض الملف
                            </a>
                          )}
                        {val.processType == "MULTI" &&
                          this.state.curOID == val.id &&
                          this.state.url == null && (
                            <a
                              style={{
                                margin: "1rem",
                                marginBottom: "0px",
                                padding: "11px 20px",
                                height: "35px",
                                width: "89%",
                                fontSize: "12px",
                                fontWeight: "600",
                                background: "green",
                                color: "#fff",
                                display: "block",
                              }}
                              className="btn z-depth-1a "
                              color="green"
                            >
                              {" "}
                              جار تجهيز الملف
                            </a>
                          )}
                        {this.state.toPrintId === val.id &&
                          this.state.printGuarantor == false &&
                          val.processType != "MULTI" && (
                            <div>
                              <ReactToPrint
                                documentTitle={
                                  " جميع ايصالات العميل " + val.client.fullname
                                }
                                trigger={() => {
                                  return (
                                    <div>
                                      <MDBBtn
                                        style={{
                                          margin: "1rem",
                                          marginBottom: "0px",
                                          padding: "11px 20px",
                                          height: "35px",
                                          width: "89%",
                                          fontSize: "12px",
                                          fontWeight: "600",
                                          background: "#4F73AC",
                                          color: "#fff",
                                        }}
                                        className="btn z-depth-1a "
                                        color="#4F73AC"
                                        disabled={
                                          this.state.premium.length < 0
                                            ? true
                                            : false
                                        }
                                      >
                                        {allStrings.readyToPrint}
                                      </MDBBtn>
                                    </div>
                                  );
                                }}
                                content={() => this.componentRef}
                              />
                              <div style={{ display: "none" }}>
                                <ComponentToPrint
                                  data={this.state.premium}
                                  advertisementUrl={this.state.advertisementUrl}
                                  printType={val.processType == "MULTI" ? 4 : 1}
                                  ref={(el) => (this.componentRef = el)}
                                />
                              </div>
                            </div>
                          )}
                        {this.state.toPrintId === val.id &&
                          this.state.printGuarantor == true && (
                            <MDBBtn
                              onClick={() => {
                                this.getPremuims(val.id, false);
                                this.setState({
                                  toPrint: false,
                                  toPrintId: null,
                                  printGuarantor: false,
                                  printType: val.processType == "MULTI" ? 4 : 1,
                                });
                              }}
                              style={{
                                margin: "1rem",
                                marginBottom: "0px",
                                padding: "11px 20px",
                                height: "35px",
                                width: "89%",
                                fontSize: "12px",
                                fontWeight: "600",
                                background: "green",
                                color: "#fff",
                              }}
                              className="btn z-depth-1a "
                              color="green"
                            >
                              {allStrings.printReceipts}
                            </MDBBtn>
                          )}
                      </div>
                    )}

                    {val.status !== "RETRIEVED" && (
                      <div>
                        {/** pay cash */}
                        {val.paymentSystem === "installment" &&
                        val.endPremiums !== true ? (
                          <MDBBtn
                            onClick={() => {
                              this.setState({ selectedOrder: val.id });
                              this.toggle2();
                            }}
                            style={{
                              margin: "5%",
                              marginBottom: "0px",
                              padding: "11px 20px",
                              height: "35px",
                              width: "90%",
                              fontSize: "12px",
                              fontWeight: "600",
                              background: "green",
                              color: "#fff",
                            }}
                            className="btn z-depth-1a "
                            color="green"
                          >
                            {allStrings.payCash}
                          </MDBBtn>
                        ) : (
                          <div
                            style={{ textAlign: "center", padding: "0px 16px" }}
                          >
                            {val.cashPaid && (
                              <div>
                                <p
                                  style={{
                                    marginBottom: "0",
                                    color: "#43425D",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    padding: "10px",
                                  }}
                                >
                                  {allStrings.remainHasPaid}
                                </p>
                                <p
                                  style={{
                                    marginBottom: "0",
                                    color: "#43425D",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    padding: "10px",
                                  }}
                                >
                                  <span>{allStrings.cashCost}</span>
                                  {" " + val.costPerMonth}{" "}
                                  <span>{" " + allStrings.pound} </span>{" "}
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    {/** retrieve */}
                    {val.status !== "RETRIEVED" ? (
                      <MDBBtn
                        onClick={() => {
                          this.Retrieve(val.id);
                        }}
                        style={{
                          margin: "1rem auto",
                          marginBottom: "0px",
                          padding: "11px 20px",
                          height: "35px",
                          width: "90%",
                          fontSize: "12px",
                          fontWeight: "600",
                          background: "green",
                          color: "#fff",
                        }}
                        className="btn z-depth-1a "
                        color="green"
                      >
                        {allStrings.Retrieve}
                      </MDBBtn>
                    ) : (
                      <p
                        style={{
                          marginBottom: "0",
                          color: "rgba(168, 8, 107, 0.767)",
                          fontSize: "16px",
                          fontWeight: "600",
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        {allStrings.RetrieveDone}
                      </p>
                    )}
                  </div>
                ))
              ) : (
                <div className="noData">{allStrings.noData}</div>
              )}
            </div>
          </MDBRow>
          <MDBRow>
            {this.state.orders.length > 0 && (
              <div
                style={{
                  textAlign: "center",
                  margin: "auto",
                  marginTop: "3rem",
                }}
              >
                <Pagination
                  onChange={(event, page) => {
                    console.log("page   ", page);
                    if (page !== this.state.page) {
                      this.getOrders(page);
                      this.setState({ page: page });
                    }
                  }}
                  defaultPage={1}
                  page={this.state.page}
                  count={this.state.pages}
                  style={{ color: `blue !important` }}
                />
              </div>
            )}
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = (state) => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  branch: state.company.branch,
  company: state.company.company,
});

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
};

export default withRouter(
  connect(mapToStateProps, mapDispatchToProps)(ClientProducts)
);
