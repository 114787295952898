import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
    en: {
        usersTable: 'USERS',
        firstName: 'First name',
        lastName: 'Last name',
        email: 'Email',
        phone: 'Phone',
        block: 'Block',
        action: 'Action',
        yes: 'yes',
        no: 'no',
        areYouSureDelete: 'Are you sure to delete ?',
        addNewRecored: 'ADD NEW RECORD',
        first: 'FIRST',
        last: 'LAST',
        next: 'NEXT',
        prev: 'PREV',
        login: "Login",
        signin: 'SIGN IN',
        password: 'Password',
        userDataIncorrect: 'User data in correct',
        thisUserIsnotAdmin: 'This user is not admin',
        active: 'Active',
        type: 'Type',
        signUpFrom: 'Signup from',
        address: 'Address',
        updateProfile: 'Update Profile',
        area: 'Area',
        activeButton: 'ACTIVE',
        disActiveButton: 'DIS-ACTIVE',
        blockButton: 'BLOCK',
        unblockButton: 'UN-BLOCK',
        remove: 'REMOVE',
        productsTable: 'PRODUCTS',
        name: 'Name',
        company: 'Company',
        price: 'Price',
        quantity: 'Quantity',
        hasOffer: 'Has offer',
        offerPrice: 'Offer price',
        offerRatio: 'Offer ratio',
        top: 'Top',
        category: 'Category',
        sallCount: 'sallCount',
        userProfile: 'User Info',
        addOffer: 'ADD OFFER',
        removeOffer: 'REMOVE OFFER',
        disActive: 'Dis-Active',
        normal: 'NORMAL',
        categoriesTable: 'CATEGORIES',
        arabicName: 'Arabic name',
        englishName: 'English name',
        categoryInfo: 'Category Info',
        updateCategory: 'Update Category',
        cities: 'Cities',
        citiesTable: 'CITIES',
        delivaryCost: 'Delivary cost',
        cityInfo: 'City Info',
        updateCity: 'Update city',
        areaInfo: 'Area Info',
        updateArea: 'Update area',
        username: 'UserName',
        forgetPassword: 'Forget Password?',
        sendCode: 'Send Code',
        enter: 'Enter',
        code: 'Code',
        newPassword: 'New Password',
        newTime: 'Repeate Password',
        enterCorrectPhone: 'UnCorrect Phone',
        enterCorrectEmail: 'UnCorrect Email',
        correctCode: 'UnCorrect Code',
        passwordMustEqualConfirmPassword: 'Password Not Equal Confirm Password',
        done: 'Done',
        addCategory: 'Add Category',
        areYouSure: "Are You Sure?",
        ok: 'Ok',
        cancel: 'Cancel',
        noData: 'No Data',
        completeData: 'Complete Require Data',
        arabicCategoryName: 'Arabic Category Name',
        englishCategoryName: 'English Category Name',
        categoryImg: 'Category Img',
        chooseFile: 'Choose File ',
        dropHere: 'Drop File Here',
        add: 'Add',
        edit: 'Edit',
        addProduct: 'Add Product',
        idFilter: ' Filter (ID)',
        nameFilter: ' Filter (Name)',
        cardFilter: 'Filter (Card)',
        salesManFilter: 'Filter (salesMan)',
        salesMan: 'SalesMan',
        all: 'All',
        print: 'Print',
        mainImg: 'Main Image',
        arabicProductName: 'Arabic Product Name',
        englishProductName: 'English Product Name',
        writeArabicName: 'Write Arabic Name',
        writeEnglishName: 'Write English Name',
        EnterQuantity: 'Enter Quantity',
        purchasePrice: 'Purchase Price',
        EnterPrice: 'Enter Price',
        commission: 'Commission',
        imgsGroup: 'Imgs Group',
        cashPrice: 'Cash Price',
        Properties: 'Properties',
        installmentPackages: 'Installment Packages',
        firstPaid: 'First Paid',
        months: 'Months',
        installment: 'Installment',
        theInstallment: 'Installment',
        pound: 'Pound',
        available: 'View On App',
        onlyInstallment: 'Only Installment',
        ratio: 'Ratio',
        property: 'Property',
        description: 'Description',
        theMonths: 'Months',
        addAds: 'Add Ads',
        day: 'Day ',
        show: 'Visable',
        notShow: 'Not Visable',
        product: 'Product',
        imgOrVideo: 'img / video',
        enterName: 'Enter Name',
        enterDuration: 'Enter Duration on Days',
        chooseCategory: 'Choose Category (Optional)',
        enterPhone: 'Enter Phone ',
        enterLink: 'Enter Link (Optional)',
        link: 'Link',
        chooseCompanys: 'Choose Companies (Optional)',
        chooseProducts: 'Choose Products (Optional)',
        optional: 'Optional',
        startDate: 'Start Date',
        companies: 'Companies',
        categories: 'Categories',
        products: 'Products',
        chooseDate: 'Choose Date',
        visable: 'Visable',
        addClient: 'Add Client',
        chooseCity: 'Choose City',
        city: 'City',
        note: 'Note',
        anotherPhone: 'Another Phone (Optional)',
        EnterPhone: 'Enter Phone (Optional)',
        EnterAnotherPhone: 'Enter Another Phone (Optional)',
        enterNote: 'Enter Notes',
        enterAddress: 'Enter Address',
        enterCardNum: 'Enter Card Num',
        cardNum: 'Card Num',
        profileImg: 'Profile img',
        receipts: 'Reciepts Imgs',
        cardImg: 'Card Imgs',
        client: 'Client',
        guarantor: 'Guarantor',
        enterPassword: 'Enter Password',
        delayPremuim: 'Delay Premuim',
        payCash: 'Pay Cash',
        collectPremuims: 'Collect Premuims',
        printReceitp: 'Print Receitp',
        sendNotif: 'Send Notif',
        showLocation: 'Show Location',
        sendMessage: 'Send Message',
        recievedProducts: 'recievedProducts',
        recievedProductsForClient: 'recieved Products For Client',
        DliveryNewProduct: 'Dlivery New Product',
        buySalesMan: 'Buy SalesMan',
        collectSalesMan: 'Collect SalesMan',
        firstPay: 'First Pay',
        premuimCost: 'Premuim Cost',
        premuimsCount: 'Premuims Count',
        delayPremuims: 'Delay Premuims',
        payPremuims: 'Paid Premuims',
        remainPremuims: 'Remain Premuims',
        payCost: 'Paid Cost',
        remainCost: 'Remain Cost',
        cash: 'Cash',
        chooseProduct: 'Choose Product',
        count: 'Count',
        adsViewsCount: 'Ads Views Count',
        allSoldProducts: 'All Sold Products',
        TotalSalesOfAllCompanies: 'Total Sales Of All Companies',
        companiesInApp: 'companies In App',
        allUsersCount: 'All Users Count',
        newUsers: 'New Users',
        topSalesMan: 'Top SalesMan',
        topCompany: 'Top Company',
        topProduct: 'Top Sold Product',
        topProducts: 'Top Sold Products',
        ads: 'Ads',
        showAll: 'Show All',
        dalyViews: 'Daly Views',
        duration: 'Duration ',
        allViews: 'All Views',
        overView: 'Over View',
        statistic: 'Statistic',
        title: 'Title',
        theAdd: 'The Add',
        addSalesMan: 'Add SalesMan',
        lastSeen: 'Last seen',
        collectedClients: 'Collected Clients',
        Receivables: 'Receivables',
        TransferCollections: 'TransferCollections',
        receiveMoney: 'Receive Money',
        changeSalesMan: 'changeSalesMan',
        receivedProducts: 'Received Products',
        daily: 'Total Daily',
        allPremiums: 'All Collect',
        additionCollect: 'Addition Collect',
        finishedPremiums: 'Finished Premiums',
        pendingPremiums: 'Pending Premiums',
        Dues: 'Dues',
        recieveReceivables: 'Recieve Receivables',
        money: 'Money',
        date: 'Date',
        to: 'To',
        from: 'From',
        addSupplier: 'Add Supplier',
        addTransaction: 'Add Transaction',
        theNaming: 'Naming',
        receive: 'Receive',
        give: 'Give',
        cost: 'Cost',
        addCost: 'Add Cost',
        chooseSalesMan: 'Choose SalesMan',
        chooseReceiptType: 'Choose Receipt Type',
        onlyLastMonth: 'Last Month',
        paid: 'Paid',
        monthNum: 'Month Num',
        firstPaids: 'First Paids',
        cashAccounts: 'Cash Accounts',
        moneyCapital: 'Money Capital',
        moneyCapitalRation: 'Money Capital Ration',
        appRatio: 'App Ratio',
        netProfit: 'Net Profit',
        remainCollectibles: 'Remain Collectibles',
        doneCollectibles: 'Finish Collectibles',
        TransactionHistory: 'Transaction History',
        expenses: 'Expenses',
        saleMenDues: 'SaleMen Dues',
        saleMenPaid: 'SaleMen Paid',
        stock: 'Stock',
        productsWithSalesMan: 'Products With SalesMen',
        theCollectable: 'The Collectable',
        procces: 'Procces',
        addPartner: 'Add Partner',
        recievedMoney: 'Recieved Money',
        partner: 'Partner',
        printContract: 'Print Contract',
        partnerShipCapital: 'PartnerShip Capital',
        outstandingBalance: 'Outstanding Balance',
        withdrawableBalance: 'Withdrawable ',
        totalGain: 'Total Gain From Begin',
        home: 'Home',
        partners: 'Partners',
        clients: 'Clients',
        salesMen: 'SalesMen',
        suppliers: 'Suppliers',
        collectibles: 'Collectibles',
        calculations: 'Calculations',
        orders: 'Orders',
        reports: 'Reports',
        settings: 'Settings',
        problemsAndSuggestions: 'Problems & Sug..',
        Receipts: 'Receipts',
        searchAbout: ' Search about user - company - item',
        qast: 'Qast',
        branchName: 'Branch Name',
        addBranch: 'Add Branch',
        lang: 'Language',
        chooseLang: 'Choose Lang',
        addCompany: 'Add Company',
        productOrder: 'Order Product',
        order: 'Order',
        onWay: 'On Way',
        deliverd: 'Deliverd',
        newOrder: 'New Order',
        content: 'Content',
        contactInfo: 'Contact Info',
        logo: 'Logo',
        Daily: 'Daily',
        monthly: 'Monthly',
        yearly: 'Yearly',
        chooseDurationType: 'Duration Type',
        thePrice: 'Price',
        notExist: 'Not Exist',
        permissions: 'Permissions',
        sendMessages: 'Send Messages',
        addBranchs: 'Add Branchs',
        appVisible: 'App Visible',
        sendNotifs: 'Send Notifs',
        appProducts: 'App Products',
        messageNum: 'messageNum',
        receiptsType: 'Receipts Type',
        collectSelectedPremuim: 'Collect Selected Premuim',
        delaySelectedPremuim: 'Delay Selected Premuim',
        collectAllMonthPremuim: 'Collect All Month Premuim',
        message: 'Message',
        send: 'Send',
        location: 'Location',
        newSalesMan: ' New SalesMan',
        changeDetails: 'All collectibles will be transfer to the new salesMan',
        changeSomeDetails: "Client's All collectibles will be transfer to the new salesMan",
        Notice: ' Notice : ',
        collectiblesCost: 'Collectibles Cost',
        delayOption: 'Delay Option',
        oldSalesMan: 'old Sales Man',
        newMonth: 'New Month',
        nextMonth: 'Next Month',
        paidDate: "Paid Date",
        collectibleSalesMan: 'Collectible SalesMan',
        showD: 'Show',
        orderType: 'Order Type',
        refuse: 'Refuse',
        accept: 'Accept',
        orderInfo: 'Order Info',
        sendSms: 'Send Sms',
        chooseBranch: 'Choose Branch',
        chooseCompany: 'Choose Company',
        messagesHistory: 'Messages History',
        typeMsg: 'Type Your Message',
        search: 'Search',
        cashAndInstallment: 'Cash &Installment',
        addCity: 'Add City',
        cityName_ar: 'Arabic Name',
        cityName_en: 'English Name',
        addUser: 'Add User',
        usersAndPermission: 'Users And Permission',
        user: 'User',
        salesNumber: 'Sales Number',
        balance: 'Balance',
        debt: 'Debt',
        additionCollectaibles: 'Addition Collectaibles',
        buyType: 'Buy Type',
        status: 'Status',
        activeNow: 'Active now',
        offline: 'Offline',
        accountSettings: 'Account Setting',
        logout: 'Logout',
        branchCount: 'branch Count',
        viewsCount: 'views count',
        adsCount: 'ads count',
        ordersCount: 'orders count',
        clientsCount: 'clients count',
        companiesCount: 'companies count',
        totalCapital: 'total capital',
        partnersCount: 'partners count',
        salesMenCount: 'salesMen count',
        recievesCount: 'recieves count',
        productsCount: 'products count',
        totalProducts: 'total cost',
        suppliersCount: 'suppliers count',
        usersCount: 'users count',
        citiesCount: 'cities count',
        number: 'Number',
        totalCost: 'Total Cost',
        type1: 'Type1',
        type2: 'Type2',
        type3: 'Type3',
        job: 'Job',
        enterJob: 'Enter Job',
        jobLocation: 'Job Location',
        enterJobLocation: 'Enter Job Location',
        jobArea: 'Job Area',
        enterJobArea: 'Enter Job Area',
        jobLevel: 'Job Level',
        enterJobLevel: 'Enter Job Level',
        buyDate: "Buy Date",
        remain: 'Remain',
        clientCode: 'Client Code',
        branch: 'Branch',
        areYouSureClient: "if you delete company you will delete every thing related to this client",
        areYouSureCompany: "if you delete company you will delete every thing related to this company",
        areYouSureStock: "if buy type not selected this mean the product will be not available",
        duplicatedCardNum: "Duplicated CardNum",
        duplicatedPhone: "Duplicated Phone",
        owner: 'Owner',
        purchaseDate: 'Purchase Date',
        salesManDebt: 'SalesMan Debt',
        giveMoney: 'Give Money',
        total: 'Total',
        complete: 'Complete',
        companyOwner: 'Company Owner',
        enterCompanyOwner: 'Enter Company Owner',
        deptPayment: 'Dept Payment',
        contractType: 'Contract Type',
        contractCost: 'Contract Cost',
        earnInMonth: 'Earn In Month',
        earnInDay: 'Earn In Day',
        earnInYear: 'Earn In Year',
        companiesDebt: 'Companies Debt',
        remainHasPaid: 'Remain Cost Has Been Paid Cash',
        prepareToPrint: 'Prepare Reciept To Print',
        paidDate2: 'Paid Date',
        adsIncome: 'Ads Income',
        companiesIncom: 'Companies Income',
        dailyFirstPaid: 'Daily First Paid',
        salesManCommission: 'SalesMan Commission',
        TotalSalesOfCompany: 'Total Sales Of Company',
        cashCost: 'Cash Cost',
        deleteSalesManError: 'SalesMan have Premuims Unable to delete him  ',
        clientsLastMonth: 'Last Month For',
        Retrieve: 'Retrieve',
        RetrieveDone: 'Retrieve Done',
        productNotAvailable: 'Product Not Available',
        error: 'Error',
        payToSalesMan: 'Pay To SalesMan',
        contactDetails: 'Contact Details',
        companySpecialization: 'Company Specialization',
        readyToPrint: 'Ready To Print',
        printType: 'Print Type',
        specialDuration: ' Special Duration',
        samplePrint: 'Sample Print',
        generalCode: 'General Code : ',
        advertisementUrl: 'reciept advertisement Url',
        multiSale: 'Multiple Products',
        paymentSystem: 'Payment System',
        chooseProducts2: 'Choose Products',
        installmentPackage: 'Installment Package',
        clientName: 'Client Name',
        updatePremuims: 'Update Premims',
        newTotal: 'New Total',
        currentTotal: 'Current Total',
        notPaid: 'Not Paid',
        delay: 'Delay',
        buySalesMen: ' buy SalesMen',
        DliveryMultiProducts: 'Dlivery Multi Products',
        multiple: 'Multiple',
        theRatio: 'The Ratio',
        multiInstallment: 'Multi Installment',
        multiCash: 'Multi Cash',
        updateOrder: 'Update Order',
        theNumber: 'the Number',
        printReceipts: 'Print All Receipts',
        printGuarantor: 'Print Guarantor',
        printReceipt: 'Print Receipt',
        stockCount: 'stock Count',
        installmentPrice: 'installment Price'
    },
    ar: {
        installmentPrice: 'سعر القسط',
        stockCount: 'الكميه بالمخزن',
        printReceipt: 'طباعه الايصال',
        printGuarantor: 'طباعه بيانات الضامن',
        printReceipts: 'طباعه جميع ايصالات',
        theNumber: 'العدد',
        updateOrder: 'تعديل عمليه البيع',
        multiInstallment: 'قسط متعدد',
        multiCash: 'كاش متعدد',
        theRatio: 'النسبه',
        multiple: 'متعدد',
        DliveryMultiProducts: 'تسليم منتجات متعدده',
        buySalesMen: 'مناديب البيع',
        delay: 'مؤجل',
        notPaid: 'غير مدفوع',
        currentTotal: 'الاجمالى الحالى',
        newTotal: 'الاجمالى الجديد',
        updatePremuims: 'تعديل الاقساط',
        clientName: 'اسم العميل',
        installmentPackage: 'باقه التقسيط',
        chooseProducts2: 'اختر المنتجات',
        paymentSystem: 'نـوع البيع',
        multiSale: 'تسليم منتج متعدد',
        advertisementUrl: 'رابط اعلان الايصال',
        generalCode: 'الكود العام : ',
        samplePrint: 'طباعه مبسطه',
        specialDuration: 'فتره معينه',
        printType: 'نوع الطباعه',
        readyToPrint: 'جاهز للطباعه',
        companySpecialization: 'تخصص الشركه',
        contactDetails: 'بيانات التواصل',
        payToSalesMan: 'دفع للمندوب',
        productNotAvailable: 'المنتج غير متوفر',
        error: 'خطأ',
        RetrieveDone: 'تم ارجاع المنتج',
        Retrieve: 'ارجاع المنتج',
        clientsLastMonth: 'اخر شهر لعدد عملاء يساوى',
        deleteSalesManError: 'المندوب لديه تحصيلات لايمكن مسحه',
        cashCost: 'مبلغ الكاش',
        TotalSalesOfCompany: 'اجمالى مبيعات الشركه',
        salesManCommission: 'نسبه المندوب',
        dailyFirstPaid: 'المقدمات اليوم',
        companiesIncom: 'الدخل من الشركات',
        adsIncome: 'دخل الاعلانات',
        paidDate2: 'موعد الدفع',
        prepareToPrint: 'اعداد الايصال للصباعه',
        remainHasPaid: 'تم دفع باقى المبلغ كاش',
        earnInMonth: 'التعاقد الشهرى',
        earnInDay: 'التعاقد اليومى',
        earnInYear: 'التعاقد السانوى',
        companiesDebt: 'ديون الشركات',
        contractCost: 'قيمه التعاقد',
        contractType: 'نوع التعاقد',
        day: 'يوم',
        deptPayment: 'معاد التسديد ',
        enterCompanyOwner: 'ادخل اسم صاحب الشركه',
        companyOwner: 'صاحب الشركه',
        complete: 'المكتمل',
        total: 'القيمه الاجماليه',
        giveMoney: 'تسليم مبلغ',
        salesManDebt: 'مديونيه المندوب',
        purchaseDate: 'تاريخ الشراء',
        owner: 'المالك',
        duplicatedPhone: "رقم الهاتف مكرر",
        duplicatedCardNum: 'رقم البطاقه مكرر',
        areYouSureStock: "اذا لم يتم اختيار وسيله البيع هذا يعنى ان يلغى عرض المنتج",
        areYouSureClient: "اذا قمت بمسح العميل سوف يتم مسح طلبات العميل بالكامل وكل مايخص العميل",
        areYouSureCompany: 'اذا قمت بمسح الشركه سيتم مسح كل مايخص الشركه من عملاء وطلبات وغيرها',
        branch: "الفرع",
        clientCode: 'كود العميل',
        remain: 'المتبقى',
        buyDate: 'تاريخ الشراء',
        job: 'الوظيفه',
        enterJob: 'ادخل الوظيفه',
        jobLocation: 'مكان الوظيفه',
        enterJobLocation: 'ادخل مكان الوظيفه',
        jobArea: 'منطقه الوظيفه',
        enterJobArea: 'ادخل منطقه الوظيفه',
        jobLevel: 'الدور',
        enterJobLevel: 'ادخل الدور',
        type1: 'النوع الاول',
        type2: 'النوع الثانى',
        type3: 'النوع الثالث',
        totalCost: 'المبلغ الكلى',
        number: 'الترتيب',
        citiesCount: 'عدد المحافظات',
        usersCount: 'عدد المستخدمين',
        suppliersCount: 'عدد الموردين',
        productsCount: 'عدد المنتجات',
        totalProducts: 'القيمه الكليه',
        recievesCount: ' المنتجات المستلمه',
        salesMenCount: 'عدد المناديب',
        clientsCount: 'عدد العملاء',
        companiesCount: 'عدد الشركات',
        partnersCount: 'عدد الشركاء',
        totalCapital: 'اجمالى رأس المال',
        viewsCount: 'عدد المشاهدات',
        adsCount: 'عدد الاعلانات',
        branchCount: 'عدد الفروع',
        logout: 'تسجيل الخروج',
        accountSettings: 'اعدادات الحساب',
        activeNow: 'نشط الان',
        offline: 'غير نشط',
        status: 'الحاله',
        buyType: 'نظام البيع',
        balance: 'الرصيد',
        debt: 'الديون',
        additionCollectaibles: 'التحصيلات الاضافيه',
        salesNumber: 'عدد المبيعات',
        user: 'المستخدم',
        usersAndPermission: 'المستخدمين والصلاحيات',
        addUser: 'اضافه مستخدم',
        addCity: 'اضافه محافظه',
        cityName_ar: 'الاسم بالعربيه',
        cityName_en: 'الاسم بالانجليزيه',
        cashAndInstallment: 'كاش & قسط',
        search: 'بحث',
        typeMsg: 'اكتب رسالتك',
        messagesHistory: 'سجل الرسائل',
        chooseCompany: 'اختر الشركه',
        chooseBranch: 'اختر الفرع',
        sendSms: 'ارسال رساله على الموبايل',
        orderInfo: 'تفاصيل الطلب',
        refuse: 'رفض',
        accept: 'قبول',
        orderType: 'نوع الطلب',
        showD: 'عرض',
        collectibleSalesMan: 'مندوب التحصيل',
        paidDate: 'موعد اول قسط',
        newMonth: 'شهر جديد',
        nextMonth: 'الشهر قادم',
        changeSomeDetails: 'سيتم تحويل اقساط العميل المحدد الى مندوب تحصيل اخر',
        oldSalesMan: 'المندوب الحالى',
        delayOption: 'خيار الترحيل',
        collectiblesCost: 'المبلغ المحصل',
        Notice: ' ملحوظه : ',
        changeDetails: 'سيتم تحويل تحصيلات وعملاء المندوب الحالى الى المندوب الجديد',
        newSalesMan: 'المندوب الجديد',
        location: 'الموقع',
        send: 'ارسال',
        message: 'الرساله',
        collectAllMonthPremuim: 'تحصيل كل الاقساط لهذا الشهر',
        delaySelectedPremuim: 'ترحيل القسط المحدد',
        collectSelectedPremuim: 'تحصيل القسط المحدد',
        receiptsType: 'شكل الايصالات',
        messageNum: 'عدد الرسائل',
        appProducts: 'المنتجات بالتطبيق',
        sendNotifs: 'ارسال اشعارات',
        appVisible: 'الظهور بالتطبيق',
        addBranchs: 'اضافه فروع',
        sendMessages: 'ارسال رسائل',
        permissions: 'الصلاحيات',
        notExist: 'لايوجد',
        thePrice: 'المبلغ',
        Daily: 'يوميا',
        monthly: 'شهريا',
        yearly: 'سنويا',
        chooseDurationType: 'نوع المده',
        logo: 'لوجو',
        contactInfo: 'بيانات التواصل',
        content: 'المحتوى',
        deliverd: 'مكتمله',
        onWay: 'قيد التوصيل',
        newOrder: 'طلبات جديده',
        productOrder: 'طلب منتج',
        order: 'طلب',
        addCompany: 'اضافه شركه',
        chooseLang: 'اختر اللغه',
        lang: 'اللغه',
        addBranch: 'اضافه فرع',
        branchName: 'اسم الفرع',
        qast: 'قسط',
        searchAbout: ' البحث عن مستخدم - شركه - صنف',
        Receipts: 'الايصالات',
        home: 'الصفحه الرئيسيه',
        partners: 'الشركاء',
        clients: 'العملاء',
        salesMen: 'المندوبين',
        suppliers: 'الموردين',
        collectibles: 'التحصيل',
        calculations: 'الحسابات',
        orders: 'الطلبات',
        branches: 'الفروع',
        reports: 'التقارير',
        settings: 'الاعدادات',
        problemsAndSuggestions: 'الشكاوى والمقترحات',
        totalGain: 'اجمالى الارباح من بدايه الشراكه',
        withdrawableBalance: 'القابل للسحب',
        outstandingBalance: 'الرصيد المعلق',
        partnerShipCapital: 'اجمالى مبلغ الشراكه',
        printContract: 'طباعه عقد',
        partner: 'شريك',
        recievedMoney: 'المبلغ المستلم',
        addPartner: 'اضافه شريك',
        procces: 'العمليه',
        theCollectable: 'المحصل',
        productsWithSalesMan: 'السلع مع المناديب',
        stock: 'المخزن',
        saleMenPaid: 'مدفوعات المناديب',
        saleMenDues: 'استحقاقات المناديب',
        expenses: 'مصاريف اداريه',
        TransactionHistory: 'تاريخ المعاملات',
        doneCollectibles: 'التحصيلات المكتمله',
        remainCollectibles: 'التحصيلات المتبقيه',
        netProfit: 'صافى الارباح',
        appRatio: 'نسبه التطبيق',
        moneyCapitalRation: 'النسبه من رأس المال',
        moneyCapital: 'اجمالى رأس المال',
        cashAccounts: 'حسابات الكاش',
        firstPaids: 'المقدمات',
        monthNum: 'عددالشهور',
        paid: 'المدفوع',
        onlyLastMonth: 'اخر شهر فقط',
        chooseSalesMan: 'اختر المندوب',
        chooseReceiptType: 'اختر شكل الايصال',
        addCost: 'اضافه مبلغ',
        cost: 'المبلغ',
        give: 'تسليم',
        receive: 'استلام',
        theNaming: 'المسمى',
        addTransaction: 'اضافه معامله ماليه',
        addSupplier: 'اضافه مورد',
        from: 'مـن',
        to: 'الـى',
        date: 'التاريخ',
        money: 'المبلغ',
        recieveReceivables: 'المبالغ المستلمه',
        Dues: 'المستحقات',
        pendingPremiums: 'التحصيلات المتبقيه',
        finishedPremiums: 'التحصيلات المكتمله',
        additionCollect: 'التحصيل الاضافى',
        allPremiums: 'التحصيل الكلى',
        daily: 'اجمالى اليوم',
        receivedProducts: 'المنتجات المستلمه',
        changeSalesMan: 'تغيير المندوب',
        receiveMoney: 'استلام مبلغ',
        TransferCollections: 'نقل التحصيلات',
        Receivables: 'المستحقات',
        collectedClients: 'عملاء التحصيل',
        lastSeen: 'اخر تواجد',
        addSalesMan: 'اضافه مندوب',
        theAdd: 'الاعلان',
        title: 'العنوان',
        ordersCount: 'عدد المبيعات',
        statistic: 'احصائيات',
        overView: 'نظره عامه',
        dalyViews: 'المشاهدات اليوميه',
        duration: 'المده ',
        allViews: 'المشاهدات الكليه',
        showAll: 'عرض الكل',
        ads: 'الاعلانات',
        topProducts: 'المنتجات الاكثر مبيعا',
        topSalesMan: 'المندوب الاكثر مبيعات',
        topCompany: ' الشركه الاكثر مبيعا',
        topProduct: 'المنتج الاكثر مبيعا',
        newUsers: 'المستخدمين الجدد',
        allUsersCount: 'عدد المستخدمين الكلى',
        companiesInApp: 'عدد الشركات فالتطبيق',
        TotalSalesOfAllCompanies: 'اجمالى المبيعات لجميع الشركات',
        allSoldProducts: 'عدد المنتجات المباعه',
        adsViewsCount: 'اجمالى مشاهدات الاعلانات',
        product: 'المنتج',
        chooseProduct: 'اختر المنتج',
        count: 'الكميه',
        cash: 'كاش',
        payPremuims: 'الاقساط المدفوعه',
        premuimCost: 'قيمه القسط',
        premuimsCount: 'عددد الاقساط',
        delayPremuims: 'الاقساط المرحله',
        remainPremuims: 'الاقساط المتبقيه',
        payCost: 'المبلغ المدفوع',
        firstPay: 'المقدم',
        remainCost: 'المبلغ المتبقى',
        buySalesMan: 'مندوب البيع',
        collectSalesMan: 'مندوب التحصيل',
        DliveryNewProduct: 'تسليم سلعه جديده',
        recievedProducts: 'المنتجات المستلمه',
        recievedProductsForClient: 'المنتجات المستلمه للعميل',
        showLocation: 'اظهار الموقع',
        sendMessage: 'رساله بالتطبيق',
        printReceitp: 'طباعه ايصال',
        sendNotif: 'ارسال اشعار',
        delayPremuim: 'ترحيل قسط',
        payCash: 'دفع كاش',
        collectPremuims: 'تحصيل اقساط',
        enterPassword: 'ادخل الرقم السرى',
        guarantor: 'الضامن',
        client: 'العميل',
        receipts: 'ايصالات الامانه',
        cardImg: 'صور البطاقه',
        chooseCity: 'اختر المحافظه',
        city: 'المحافظه',
        note: 'الملاحظات',
        anotherPhone: 'رقم موبايل اخر (اختيارى)',
        EnterPhone: 'ادخل رقم الموبايل',
        EnterAnotherPhone: 'ادخل رقم موبايل اخر (اختيارى)',
        enterNote: 'ادخل الملاحظات',
        enterAddress: 'ادخل العنوان',
        enterCardNum: 'ادخل رقم البطاقه',
        cardNum: 'رقم البطاقه',
        profileImg: 'صوره البروفايل',
        addClient: 'اضافه عميل',
        enterPhone: 'ادخل رقم الهاتف ',
        enterLink: ' ادخل الرابط (اختيارى) ',
        link: 'الرابط',
        visable: 'عرض',
        chooseDate: 'اختر التاريخ',
        products: 'المنتجات',
        categories: 'الاقسام',
        enterName: 'ادخل الاسم',
        enterDuration: ' ادخل المده بالايام',
        chooseCategory: '(اختيارى) اختر القسم',
        chooseCompanys: '(اختيارى) اختر الشركات',
        chooseProducts: '(اختيارى) اختر المنتجات',
        optional: 'اختيارى',
        startDate: 'تاريخ البدايه',
        companies: 'الشركات',
        imgOrVideo: 'صوره/فيديو',
        show: 'معروض',
        notShow: 'غير معروض',
        addAds: 'اضافه اعلان',
        theMonths: 'الشهور',
        Properties: 'خصائص المنتج',
        installmentPackages: 'باقات التقسيط',
        firstPaid: 'المقدم',
        months: 'الشهور',
        installment: 'قسط',
        theInstallment: 'القسط',
        pound: 'جنيه',
        available: 'يظهر بالتطبيق',
        onlyInstallment: 'تقسيط فقط',
        ratio: 'نسبه',
        property: 'ميزه',
        description: 'وصف',
        properties: '',
        cashPrice: 'سعر الكاش',
        imgsGroup: 'مجموعه صور',
        writeArabicName: 'ادخل الاسم بالعربيه',
        writeEnglishName: 'ادخل الاسم بالانجليزيه',
        EnterQuantity: 'ادخل الكميه',
        purchasePrice: 'سعر الشراء',
        EnterPrice: 'ادخل المبلغ',
        commission: 'مكافئه البيع',
        mainImg: 'الصوره الرئيسيه',
        arabicProductName: 'اسم المنتج بالعربيه',
        englishProductName: 'اسم المنتج بالانجليزيه',
        print: 'طباعه',
        all: 'الكل',
        idFilter: ' ( فلتر ( الكود ',
        nameFilter: '( فلتر ( الاسم',
        cardFilter: '( فلتر ( البطاقه',
        salesMan: 'المندوب',
        salesManFilter: 'فلتر (المندوب )',
        addProduct: 'اضافه منتج',
        edit: 'تعديل',
        add: 'اضافه',
        dropHere: 'قم بالسحب واسقط هنا',
        arabicCategoryName: 'اسم القسم باللغه العربيه',
        englishCategoryName: 'اسم القسم باللغه الانجليزيه',
        categoryImg: 'صوره القسم',
        chooseFile: 'اختر الملف',
        completeData: 'املأ البيانات المطلوبه',
        noData: 'لا يوجد بيانات',
        areYouSure: "هل انت متأكد؟",
        ok: 'موافق',
        cancel: 'غير موافق',
        addCategory: 'اضافه قسم',
        enterCorrectPhone: 'رقم هاتف غير صحيح',
        enterCorrectEmail: 'بريد الكترونى غير صحيح',
        correctCode: 'رمز خاطئ',
        passwordMustEqualConfirmPassword: 'يجب ان يكون الرقم السرى يشابه الرقم المؤكد',
        done: 'تم',
        newPassword: 'الباسورد الجديد',
        newTime: 'مره اخرى',
        code: 'الرمز',
        enter: 'ادخال',
        sendCode: 'ارسال الرمز',
        forgetPassword: 'نسيت كلمه السر؟',
        username: "اسم المستخدم",
        areaInfo: 'معلومات المنطقة',
        updateArea: 'تعديل المنطقة',
        updateCity: 'تعديل المدينة',
        cityInfo: 'معلومات المدينة',
        citiesTable: 'المدن',
        delivaryCost: 'سعر التوصيل',
        cities: 'محافظات',
        categoryInfo: 'معلومات القسم',
        updateCategory: 'تعديل القسم',
        categoriesTable: 'الاقسام',
        arabicName: 'الاسم بالعربية',
        englishName: 'الاسم بالاجنابية',
        normal: 'طبيعى',
        disActive: 'ايقاف التنشيط',
        addOffer: 'اضافة عرض',
        removeOffer: 'حذف العرض',
        userProfile: 'بيانات المستخدم',
        sallCount: 'الخصم',
        offerPrice: 'السعر بعد الخصم',
        offerRatio: 'نسبة الخصم',
        top: 'توب',
        category: 'القسم',
        name: 'الاسم',
        company: 'الشركة',
        price: 'السعر',
        quantity: 'الكمية',
        hasOffer: 'لدية عرض ',
        productsTable: 'المنتجات',
        remove: 'حذف',
        activeButton: 'تنشيط',
        disActiveButton: 'ايقاف التنشيط',
        blockButton: 'الحظر',
        unblockButton: 'فك الحظر',
        area: 'النطقة',
        updateProfile: 'تعديل الملف الشخصى',
        address: 'العنوان',
        signUpFrom: 'التسجيل من خلال',
        active: 'نشط',
        type: 'النوع',
        thisUserIsnotAdmin: 'هذا المستخدم ليس مسئولا',
        userDataIncorrect: 'بيانات المستخدم غير صحيحة',
        usersTable: 'المستخدمين',
        firstName: 'الاسم الاول',
        lastName: 'باقى الاسم',
        email: 'البريد الالكترونى',
        phone: 'رقم الموبايل',
        block: 'الحظر',
        action: 'الاجرائات',
        yes: 'نعم',
        no: 'لا',
        areYouSureDelete: 'هل انت متاكد من الحذف ؟',
        addNewRecored: 'اضافة عنصر جديد',
        first: 'البداية',
        last: 'الاخير',
        next: 'التالى',
        prev: 'السابق',
        login: "الدخول",
        signin: 'تسجيل الدخول',
        password: 'كلمة المرور',
    },

});
