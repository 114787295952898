/* eslint-disable eqeqeq */
import React from "react";
import "./stockForm.css";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { setUser } from "../../redux/actions/AuthActions";
import { hasValue } from "../../validations/validations";
import { message, TreeSelect, Checkbox } from "antd";
import { NavLink } from "react-router-dom";
import { ChangeLanguage } from "../../redux/actions/LanguageAction";
import { ChangeCompany, ChangeBranch } from "../../redux/actions/CompanyAction";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

const { TreeNode } = TreeSelect;
class Login extends React.Component {
  state = {
    name_ar: this.props.location.state
      ? this.props.location.state.data.name_ar
      : "",
    name_en: this.props.location.state
      ? this.props.location.state.data.name_en
      : "",
    description: this.props.location.state
      ? this.props.location.state.data.description
      : "",
    cashPrice: this.props.location.state
      ? this.props.location.state.data.cashPrice
      : 0,
    purchasePrice: this.props.location.state
      ? this.props.location.state.data.purchasePrice
      : 0,
    quantity: this.props.location.state
      ? this.props.location.state.data.quantity
      : 0,
    category: this.props.location.state
      ? this.props.location.state.data.category.id
      : [],
    status: this.props.location.state
      ? this.props.location.state.data.status
      : "NEW",
    buyType: this.props.location.state
      ? this.props.location.state.data.buyType
      : "BOTH",
    installment: this.props.location.state
      ? this.props.location.state.data.buyType !== "CASH"
        ? true
        : false
      : true,
    cash: this.props.location.state
      ? this.props.location.state.data.buyType !== "PREMIUM"
        ? true
        : false
      : true,
    visible: this.props.location.state
      ? this.props.location.state.data.visible
        ? this.props.location.state.data.visible
        : false
      : false,
    commission: this.props.location.state
      ? this.props.location.state.data.commission
      : 0,
    package: [],
    moneyGiven: 0,
    ratio1: this.props.location.state
      ? this.props.location.state.data.package.length > 0
        ? this.props.location.state.data.package[0].ratio
        : 0
      : 0,
    months1: this.props.location.state
      ? this.props.location.state.data.package.length > 0
        ? this.props.location.state.data.package[0].months
        : 1
      : 1,
    firstPaid1: this.props.location.state
      ? this.props.location.state.data.package.length > 0
        ? this.props.location.state.data.package[0].firstPaid
        : 0
      : 0,
    installmentPrice1: this.props.location.state
      ? this.props.location.state.data.package.length > 0
        ? this.props.location.state.data.package[0].installmentPrice
        : 0
      : 0,
    costPerMonth1: this.props.location.state
      ? this.props.location.state.data.package.length > 0
        ? this.props.location.state.data.package[0].costPerMonth
        : 0
      : 0,

    ratio2: this.props.location.state
      ? this.props.location.state.data.package.length > 1
        ? this.props.location.state.data.package[1].ratio
        : 0
      : 0,
    months2: this.props.location.state
      ? this.props.location.state.data.package.length > 1
        ? this.props.location.state.data.package[1].months
        : 1
      : 1,
    firstPaid2: this.props.location.state
      ? this.props.location.state.data.package.length > 1
        ? this.props.location.state.data.package[1].firstPaid
        : 0
      : 0,
    installmentPrice2: this.props.location.state
      ? this.props.location.state.data.package.length > 1
        ? this.props.location.state.data.package[1].installmentPrice
        : 0
      : 0,
    costPerMonth2: this.props.location.state
      ? this.props.location.state.data.package.length > 1
        ? this.props.location.state.data.package[1].costPerMonth
        : 0
      : 0,

    ratio3: this.props.location.state
      ? this.props.location.state.data.package.length > 2
        ? this.props.location.state.data.package[2].ratio
        : 0
      : 0,
    months3: this.props.location.state
      ? this.props.location.state.data.package.length > 2
        ? this.props.location.state.data.package[2].months
        : 1
      : 1,
    firstPaid3: this.props.location.state
      ? this.props.location.state.data.package.length > 2
        ? this.props.location.state.data.package[2].firstPaid
        : 0
      : 0,
    installmentPrice3: this.props.location.state
      ? this.props.location.state.data.package.length > 2
        ? this.props.location.state.data.package[2].installmentPrice
        : 0
      : 0,
    costPerMonth3: this.props.location.state
      ? this.props.location.state.data.package.length > 2
        ? this.props.location.state.data.package[2].costPerMonth
        : 0
      : 0,

    ratio4: this.props.location.state
      ? this.props.location.state.data.package.length > 3
        ? this.props.location.state.data.package[3].ratio
        : 0
      : 0,
    months4: this.props.location.state
      ? this.props.location.state.data.package.length > 3
        ? this.props.location.state.data.package[3].months
        : 1
      : 1,
    firstPaid4: this.props.location.state
      ? this.props.location.state.data.package.length > 3
        ? this.props.location.state.data.package[3].firstPaid
        : 0
      : 0,
    installmentPrice4: this.props.location.state
      ? this.props.location.state.data.package.length > 3
        ? this.props.location.state.data.package[3].installmentPrice
        : 0
      : 0,
    costPerMonth4: this.props.location.state
      ? this.props.location.state.data.package.length > 3
        ? this.props.location.state.data.package[3].costPerMonth
        : 0
      : 0,

    ratio5: this.props.location.state
      ? this.props.location.state.data.package.length > 4
        ? this.props.location.state.data.package[4].ratio
        : 0
      : 0,
    months5: this.props.location.state
      ? this.props.location.state.data.package.length > 4
        ? this.props.location.state.data.package[4].months
        : 1
      : 1,
    firstPaid5: this.props.location.state
      ? this.props.location.state.data.package.length > 4
        ? this.props.location.state.data.package[4].firstPaid
        : 0
      : 0,
    installmentPrice5: this.props.location.state
      ? this.props.location.state.data.package.length > 4
        ? this.props.location.state.data.package[4].installmentPrice
        : 0
      : 0,
    costPerMonth5: this.props.location.state
      ? this.props.location.state.data.package.length > 4
        ? this.props.location.state.data.package[4].costPerMonth
        : 0
      : 0,

    ratio6: this.props.location.state
      ? this.props.location.state.data.package.length > 5
        ? this.props.location.state.data.package[5].ratio
        : 0
      : 0,
    months6: this.props.location.state
      ? this.props.location.state.data.package.length > 5
        ? this.props.location.state.data.package[5].months
        : 1
      : 1,
    firstPaid6: this.props.location.state
      ? this.props.location.state.data.package.length > 5
        ? this.props.location.state.data.package[5].firstPaid
        : 0
      : 0,
    installmentPrice6: this.props.location.state
      ? this.props.location.state.data.package.length > 5
        ? this.props.location.state.data.package[5].installmentPrice
        : 0
      : 0,
    costPerMonth6: this.props.location.state
      ? this.props.location.state.data.package.length > 5
        ? this.props.location.state.data.package[5].costPerMonth
        : 0
      : 0,

    properties: [],
    attr1: this.props.location.state
      ? this.props.location.state.data.properties.length > 0
        ? this.props.location.state.data.properties[0].attr
        : ""
      : "",
    value1: this.props.location.state
      ? this.props.location.state.data.properties.length > 0
        ? this.props.location.state.data.properties[0].value
        : ""
      : "",

    attr2: this.props.location.state
      ? this.props.location.state.data.properties.length > 1
        ? this.props.location.state.data.properties[1].attr
        : ""
      : "",
    value2: this.props.location.state
      ? this.props.location.state.data.properties.length > 1
        ? this.props.location.state.data.properties[1].value
        : ""
      : "",

    attr3: this.props.location.state
      ? this.props.location.state.data.properties.length > 2
        ? this.props.location.state.data.properties[2].attr
        : ""
      : "",
    value3: this.props.location.state
      ? this.props.location.state.data.properties.length > 2
        ? this.props.location.state.data.properties[2].value
        : ""
      : "",

    attr4: this.props.location.state
      ? this.props.location.state.data.properties.length > 3
        ? this.props.location.state.data.properties[3].attr
        : ""
      : "",
    value4: this.props.location.state
      ? this.props.location.state.data.properties.length > 3
        ? this.props.location.state.data.properties[3].value
        : ""
      : "",

    attr5: this.props.location.state
      ? this.props.location.state.data.properties.length > 4
        ? this.props.location.state.data.properties[4].attr
        : ""
      : "",
    value5: this.props.location.state
      ? this.props.location.state.data.properties.length > 4
        ? this.props.location.state.data.properties[4].value
        : ""
      : "",

    attr6: this.props.location.state
      ? this.props.location.state.data.properties.length > 5
        ? this.props.location.state.data.properties[5].attr
        : ""
      : "",
    value6: this.props.location.state
      ? this.props.location.state.data.properties.length > 5
        ? this.props.location.state.data.properties[5].value
        : ""
      : "",

    attr7: this.props.location.state
      ? this.props.location.state.data.properties.length > 6
        ? this.props.location.state.data.properties[6].attr
        : ""
      : "",
    value7: this.props.location.state
      ? this.props.location.state.data.properties.length > 6
        ? this.props.location.state.data.properties[6].value
        : ""
      : "",

    attr8: this.props.location.state
      ? this.props.location.state.data.properties.length > 7
        ? this.props.location.state.data.properties[7].attr
        : ""
      : "",
    value8: this.props.location.state
      ? this.props.location.state.data.properties.length > 7
        ? this.props.location.state.data.properties[7].value
        : ""
      : "",

    requestType: this.props.location.state ? "put" : "post",

    product: this.props.location.state ? this.props.location.state.data : [],
    img: this.props.location.state
      ? this.props.location.state.data.mainImg
      : null,
    imgs: this.props.location.state
      ? this.props.location.state.data.img.length > 0
        ? this.props.location.state.data.img
        : null
      : null,
    imgType: this.props.location.state ? "url" : "data",
    imgsType: this.props.location.state ? "url" : "data",
    imgsViews: this.props.location.state
      ? this.props.location.state.data.img
      : [],
    categories: [],
    disabledPackage: this.props.location.state ? false : true,
    installementSystem: true,
    packageNum: this.props.location.state
      ? this.props.location.state.data.package
        ? this.props.location.state.data.package.length
        : 1
      : 1,
    packagePlus: this.props.location.state
      ? this.props.location.state.data.package
        ? this.props.location.state.data.package.length
        : 1
      : 1,
    photoIndex: 0,
    modal2: false,
    modalType: "map",
    isOpen1: false,
    isOpen2: false,
  };

  constructor(props) {
    super(props);
    console.log("isr   ", this.props.history);
    if (
      this.props.currentUser.user.type === "COMPANY" &&
      this.props.currentUser.user.buyType !== "CASH"
    ) {
      this.setState({ installementSystem: true });
    }
    if (
      this.props.currentUser.user.type === "COMPANY" &&
      this.props.currentUser.user.buyType === "CASH"
    ) {
      this.setState({ installementSystem: false });
    }
    if (
      this.props.currentUser.user.type === "ADMIN" ||
      this.props.currentUser.user.type === "SUB-ADMIN"
    ) {
      this.setState({ installementSystem: true });
    }
    if (this.props.isRTL) {
      allStrings.setLanguage("ar");
    } else {
      allStrings.setLanguage("en");
    }
    window.scrollTo(0, 0);
  }

  getCategories = () => {
    axios
      .get(`${BASE_END_POINT}categories/get/Normal`)
      .then((response) => {
        console.log(response.data);
        this.setState({ categories: response.data.data });
      })
      .catch((error) => {
        console.log("ALL Categories ERROR");
        console.log(error.response);
      });
  };
  componentDidMount() {
    this.getCategories();
    if (
      this.props.currentUser.user.type === "COMPANY" &&
      this.props.currentUser.user.buyType !== "CASH"
    ) {
      this.setState({ installementSystem: true });
    }
    if (
      this.props.currentUser.user.type === "COMPANY" &&
      this.props.currentUser.user.buyType === "CASH"
    ) {
      this.setState({ installementSystem: false });
    }
    if (
      this.props.currentUser.user.type === "ADMIN" ||
      this.props.currentUser.user.type === "SUB-ADMIN"
    ) {
      this.setState({ installementSystem: true });
    }
    //this.props.ChangeLanguage(false)
  }
  toggle2 = () => {
    this.setState({
      modal2: !this.state.modal2,
    });
    if (this.state.modal === true) {
      this.setState({
        modal: false,
      });
    }
  };
  add = () => {
    console.log("add");
    const {
      name_ar,
      description,
      category,
      imgs,
      commission,
      cashPrice,
      purchasePrice,
      quantity,
      img,
      months1,
      firstPaid1,
      installmentPrice1,
      costPerMonth1,
      ratio1,
      months2,
      firstPaid2,
      installmentPrice2,
      costPerMonth2,
      ratio2,
      months3,
      firstPaid3,
      installmentPrice3,
      costPerMonth3,
      ratio3,
      months4,
      firstPaid4,
      installmentPrice4,
      costPerMonth4,
      ratio4,
      months5,
      firstPaid5,
      installmentPrice5,
      costPerMonth5,
      ratio5,
      months6,
      firstPaid6,
      installmentPrice6,
      costPerMonth6,
      ratio6,
      attr1,
      value1,
      attr2,
      value2,
      attr3,
      value3,
      attr4,
      value4,
      attr5,
      value5,
      attr6,
      value6,
      attr7,
      value7,
      attr8,
      value8,
      visible,
      cash,
      installment,
    } = this.state;
    if (
      hasValue(name_ar) /*&&hasValue(name_en)*/ &&
      commission !== 0 &&
      cashPrice !== 0 &&
      purchasePrice !== 0 &&
      quantity !== 0 &&
      hasValue(description)
    ) {
      let l = message.loading("Wait..", 2.5);
      var data = new FormData();
      let packages = [];
      let properties = [];
      let buyType;
      data.append("name_ar", name_ar);
      data.append("name_en", name_ar);
      //data.append('name_en',name_en)
      data.append("category", category);
      data.append("commission", commission);
      data.append("cashPrice", cashPrice);
      data.append("purchasePrice", purchasePrice);
      data.append("quantity", quantity);
      data.append("description", description);
      data.append("status", "NEW");
      data.append("visible", visible);
      if (
        this.props.currentUser.user.type === "ADMIN" ||
        this.props.currentUser.user.type === "SUB-ADMIN"
      ) {
        if (this.props.company === false) {
          data.append("company", this.props.currentUser.user.company[0].id);
        } else {
          data.append("company", this.props.company);
        }
      } else {
        data.append("company", this.props.currentUser.user.id);
      }
      if (installment === true && cash === false) {
        data.append("buyType", "PREMIUM");
        buyType = "PREMIUM";
      }
      if (installment === false && cash === true) {
        data.append("buyType", "CASH");
        buyType = "CASH";
      }
      if (installment === true && cash === true) {
        data.append("buyType", "BOTH");
        buyType = "BOTH";
      }
      if (img != null) {
        data.append("mainImg", img);
      }
      if (imgs != null) {
        for (var i = 0; i <= imgs.length - 1; i++) {
          data.append(`img`, imgs[i]);
        }
      }
      if (buyType !== "CASH") {
        if (
          ratio1 !== 0 &&
          months1 !== 0 &&
          firstPaid1 !== 0 &&
          costPerMonth1 !== 0 &&
          installmentPrice1 !== 0
        ) {
          packages.push({
            name_ar: " باقه " + months1,
            name_en: months1 + " Package",
            months: months1,
            ratio: ratio1,
            firstPaid: firstPaid1,
            installmentPrice: installmentPrice1,
            costPerMonth: costPerMonth1,
          });
        }
        if (
          ratio2 !== 0 &&
          months2 !== 0 &&
          firstPaid2 !== 0 &&
          costPerMonth2 !== 0 &&
          installmentPrice2 !== 0
        ) {
          packages.push({
            name_ar: " باقه " + months2,
            name_en: months2 + " Package",
            months: months2,
            ratio: ratio2,
            firstPaid: firstPaid2,
            installmentPrice: installmentPrice2,
            costPerMonth: costPerMonth2,
          });
        }
        if (
          ratio3 !== 0 &&
          months3 !== 0 &&
          firstPaid3 !== 0 &&
          costPerMonth3 !== 0 &&
          installmentPrice3 !== 0
        ) {
          packages.push({
            name_ar: " باقه " + months3,
            name_en: months3 + " Package",
            months: months3,
            ratio: ratio3,
            firstPaid: firstPaid3,
            installmentPrice: installmentPrice3,
            costPerMonth: costPerMonth3,
          });
        }
        if (
          ratio4 !== 0 &&
          months4 !== 0 &&
          firstPaid4 !== 0 &&
          costPerMonth4 !== 0 &&
          installmentPrice4 !== 0
        ) {
          packages.push({
            name_ar: " باقه " + months4,
            name_en: months4 + " Package",
            months: months4,
            ratio: ratio4,
            firstPaid: firstPaid4,
            installmentPrice: installmentPrice4,
            costPerMonth: costPerMonth4,
          });
        }
        if (
          ratio5 !== 0 &&
          months5 !== 0 &&
          firstPaid5 !== 0 &&
          costPerMonth5 !== 0 &&
          installmentPrice5 !== 0
        ) {
          packages.push({
            name_ar: " باقه " + months5,
            name_en: months5 + " Package",
            months: months5,
            ratio: ratio5,
            firstPaid: firstPaid5,
            installmentPrice: installmentPrice5,
            costPerMonth: costPerMonth5,
          });
        }
        if (
          ratio6 !== 0 &&
          months6 !== 0 &&
          firstPaid6 !== 0 &&
          costPerMonth6 !== 0 &&
          installmentPrice6 !== 0
        ) {
          packages.push({
            name_ar: " باقه " + months1,
            name_en: months6 + " Package",
            months: months6,
            ratio: ratio6,
            firstPaid: firstPaid6,
            installmentPrice: installmentPrice6,
            costPerMonth: costPerMonth6,
          });
        }
      }
      console.log("packages", packages);

      console.log(Array.from(data));
      if (packages.length > 0) {
        data.append("package", JSON.stringify(packages));
      }
      console.log(Array.from(data));
      if (attr1 !== "" && value1 !== "") {
        properties.push({
          attr: attr1,
          value: value1,
        });
      }
      if (attr2 !== "" && value2 !== "") {
        properties.push({
          attr: attr2,
          value: value2,
        });
      }
      if (attr3 !== "" && value3 !== "") {
        properties.push({
          attr: attr3,
          value: value3,
        });
      }
      if (attr4 !== "" && value4 !== "") {
        properties.push({
          attr: attr4,
          value: value4,
        });
      }
      if (attr5 !== "" && value5 !== "") {
        properties.push({
          attr: attr5,
          value: value5,
        });
      }
      if (attr6 !== "" && value6 !== "") {
        properties.push({
          attr: attr6,
          value: value6,
        });
      }
      if (attr7 !== "" && value7 !== "") {
        properties.push({
          attr: attr7,
          value: value7,
        });
      }
      if (attr8 !== "" && value8 !== "") {
        properties.push({
          attr: attr8,
          value: value8,
        });
      }
      if (properties.length > 0) {
        data.append("properties", JSON.stringify(properties));
      }
      console.log(properties);

      //////

      console.log(Array.from(data));

      axios
        .post(`${BASE_END_POINT}products`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        })
        .then((response) => {
          l.then(() => message.success(allStrings.done, 2.5));
          this.props.history.push("/stock");
        })
        .catch((error) => {
          console.log(error.response);
          l.then(() => message.error("Error", 2.5));
        });
    }
  };
  update = () => {
    console.log("update");
    const {
      name_ar,
      description,
      category,
      imgs,
      commission,
      cashPrice,
      purchasePrice,
      quantity,
      img,
      months1,
      firstPaid1,
      installmentPrice1,
      costPerMonth1,
      ratio1,
      months2,
      firstPaid2,
      installmentPrice2,
      costPerMonth2,
      ratio2,
      months3,
      firstPaid3,
      installmentPrice3,
      costPerMonth3,
      ratio3,
      months4,
      firstPaid4,
      installmentPrice4,
      costPerMonth4,
      ratio4,
      months5,
      firstPaid5,
      installmentPrice5,
      costPerMonth5,
      ratio5,
      months6,
      firstPaid6,
      installmentPrice6,
      costPerMonth6,
      ratio6,
      attr1,
      value1,
      attr2,
      value2,
      attr3,
      value3,
      attr4,
      value4,
      attr5,
      value5,
      attr6,
      value6,
      attr7,
      value7,
      attr8,
      value8,
      visible,
      cash,
      installment,
    } = this.state;
    if (
      hasValue(name_ar) /*&&hasValue(name_en)*/ &&
      commission != 0 &&
      cashPrice !== 0 &&
      purchasePrice !== 0 &&
      quantity !== 0 &&
      hasValue(description)
    ) {
      let l = message.loading("Wait..", 2.5);
      var data = new FormData();
      let packages = [];
      let properties = [];
      let buyType;
      data.append("name_ar", name_ar);
      data.append("name_en", name_ar);
      //data.append('name_en',name_en)
      data.append("category", category);
      data.append("commission", commission);
      data.append("cashPrice", cashPrice);
      data.append("purchasePrice", purchasePrice);
      data.append("quantity", quantity);
      data.append("description", description);
      data.append("visible", visible);
      data.append("status", "NEW");
      if (
        this.props.currentUser.user.type == "ADMIN" ||
        this.props.currentUser.user.type == "SUB-ADMIN"
      ) {
        if (this.props.company == false) {
          data.append("company", this.props.currentUser.user.company[0].id);
        } else {
          data.append("company", this.props.company);
        }
      } else {
        data.append("company", this.props.currentUser.user.id);
      }
      if (installment === true && cash === false) {
        data.append("buyType", "PREMIUM");
        buyType = "PREMIUM";
      }
      if (installment === false && cash === true) {
        data.append("buyType", "CASH");
        buyType = "CASH";
      }
      if (installment === true && cash === true) {
        data.append("buyType", "BOTH");
        buyType = "BOTH";
      }

      if (img != null) {
        if (this.state.imgType === "data") {
          data.append("mainImg", img);
        }
      }
      if (imgs != null) {
        //if(this.state.imgsType === 'data'){
        for (var i = 0; i <= imgs.length - 1; i++) {
          data.append(`img`, imgs[i]);
        }
        //}
      }
      if (buyType !== "CASH") {
        if (
          ratio1 !== 0 &&
          months1 !== 0 &&
          firstPaid1 !== 0 &&
          costPerMonth1 !== 0 &&
          installmentPrice1 !== 0
        ) {
          packages.push({
            name_ar: " باقه " + months1,
            name_en: months1 + " Package",
            months: months1,
            ratio: ratio1,
            firstPaid: firstPaid1,
            installmentPrice: installmentPrice1,
            costPerMonth: costPerMonth1,
          });
        }
        if (
          ratio2 !== 0 &&
          months2 !== 0 &&
          firstPaid2 !== 0 &&
          costPerMonth2 !== 0 &&
          installmentPrice2 !== 0
        ) {
          packages.push({
            name_ar: " باقه " + months2,
            name_en: months2 + " Package",
            months: months2,
            ratio: ratio2,
            firstPaid: firstPaid2,
            installmentPrice: installmentPrice2,
            costPerMonth: costPerMonth2,
          });
        }
        if (
          ratio3 !== 0 &&
          months3 !== 0 &&
          firstPaid3 !== 0 &&
          costPerMonth3 !== 0 &&
          installmentPrice3 !== 0
        ) {
          packages.push({
            name_ar: " باقه " + months3,
            name_en: months3 + " Package",
            months: months3,
            ratio: ratio3,
            firstPaid: firstPaid3,
            installmentPrice: installmentPrice3,
            costPerMonth: costPerMonth3,
          });
        }
        if (
          ratio4 !== 0 &&
          months4 !== 0 &&
          firstPaid4 !== 0 &&
          costPerMonth4 !== 0 &&
          installmentPrice4 !== 0
        ) {
          packages.push({
            name_ar: " باقه " + months4,
            name_en: months4 + " Package",
            months: months4,
            ratio: ratio4,
            firstPaid: firstPaid4,
            installmentPrice: installmentPrice4,
            costPerMonth: costPerMonth4,
          });
        }
        if (
          ratio5 !== 0 &&
          months5 !== 0 &&
          firstPaid5 !== 0 &&
          costPerMonth5 !== 0 &&
          installmentPrice5 !== 0
        ) {
          packages.push({
            name_ar: " باقه " + months5,
            name_en: months5 + " Package",
            months: months5,
            ratio: ratio5,
            firstPaid: firstPaid5,
            installmentPrice: installmentPrice5,
            costPerMonth: costPerMonth5,
          });
        }
        if (
          ratio6 !== 0 &&
          months6 !== 0 &&
          firstPaid6 !== 0 &&
          costPerMonth6 !== 0 &&
          installmentPrice6 !== 0
        ) {
          packages.push({
            name_ar: " باقه " + months1,
            name_en: months6 + " Package",
            months: months6,
            ratio: ratio6,
            firstPaid: firstPaid6,
            installmentPrice: installmentPrice6,
            costPerMonth: costPerMonth6,
          });
        }
      }
      console.log(
        "installmentPrice",
        installmentPrice1,
        installmentPrice2,
        installmentPrice3,
        installmentPrice4,
        installmentPrice5,
        installmentPrice6
      );
      console.log("packages", packages);

      console.log(Array.from(data));
      if (packages.length > 0) {
        data.append("package", JSON.stringify(packages));
      }
      console.log(Array.from(data));
      if (attr1 !== "" && value1 !== "") {
        properties.push({
          attr: attr1,
          value: value1,
        });
      }
      if (attr2 !== "" && value2 !== "") {
        properties.push({
          attr: attr2,
          value: value2,
        });
      }
      if (attr3 !== "" && value3 !== "") {
        properties.push({
          attr: attr3,
          value: value3,
        });
      }
      if (attr4 !== "" && value4 !== "") {
        properties.push({
          attr: attr4,
          value: value4,
        });
      }
      if (attr5 !== "" && value5 !== "") {
        properties.push({
          attr: attr5,
          value: value5,
        });
      }
      if (attr6 !== "" && value6 !== "") {
        properties.push({
          attr: attr6,
          value: value6,
        });
      }
      if (attr7 !== "" && value7 !== "") {
        properties.push({
          attr: attr7,
          value: value7,
        });
      }
      if (attr8 !== "" && value8 !== "") {
        properties.push({
          attr: attr8,
          value: value8,
        });
      }
      if (properties.length > 0) {
        data.append("properties", JSON.stringify(properties));
      }
      console.log(properties);

      //////

      console.log(Array.from(data));

      axios
        .put(`${BASE_END_POINT}products/${this.state.product.id}`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        })
        .then((response) => {
          l.then(() => message.success(allStrings.done, 2.5));
          this.props.history.push("/stock");
        })
        .catch((error) => {
          console.log(error.response);
          l.then(() => message.error("Error", 2.5));
        });
    }
  };

  updateSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.update();
  };

  addSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.add();
  };
  OKBUTTON = (e) => {
    if (this.state.requestType) {
      this.add();
    } else {
      this.update();
    }
  };
  openAddToStockHis = () => {
    this.setState({ modalType: "pay", modalReqType: "pay" });
    this.toggle2();
  };
  openMinToSalesMan = () => {
    this.setState({ modalType: "min", modalReqType: "min" });
    this.toggle2();
  };
  changeHandler = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  changeInstallmentPrice = (event) => {
    console.log(event.target.id);
    let id = event.target.id.substring(16);
    console.log(id);

    let purchasePrice = this.state.purchasePrice;
    if (this.state.purchasePrice === "") {
      purchasePrice = 0;
    }
    console.log(purchasePrice);
    let addtionPrice = (purchasePrice * event.target.value) / 100;
    console.log(addtionPrice);
    let price = parseInt(purchasePrice) + addtionPrice;
    console.log(price);
    if (id == "1") {
      let costPerMonth = (price - this.state.firstPaid1) / this.state.months1;
      costPerMonth = Math.ceil(costPerMonth);
      console.log(costPerMonth);
      let rem = costPerMonth % 5;
      if (rem < 3) {
        costPerMonth = costPerMonth - rem;
      } else {
        let add = 5 - rem;
        costPerMonth = costPerMonth + add;
      }
      this.setState({
        costPerMonth1: Math.ceil(costPerMonth),
        installmentPrice1: Math.ceil(price),
        [event.target.name]: event.target.value,
      });
    }
    if (id == "2") {
      let costPerMonth = (price - this.state.firstPaid2) / this.state.months2;
      costPerMonth = Math.ceil(costPerMonth);
      console.log(costPerMonth);
      let rem = costPerMonth % 5;
      if (rem < 3) {
        costPerMonth = costPerMonth - rem;
      } else {
        let add = 5 - rem;
        costPerMonth = costPerMonth + add;
      }
      this.setState({
        costPerMonth2: Math.ceil(costPerMonth),
        installmentPrice2: Math.ceil(price),
        [event.target.name]: event.target.value,
      });
    }
    if (id == "3") {
      let costPerMonth = (price - this.state.firstPaid3) / this.state.months3;
      costPerMonth = Math.ceil(costPerMonth);
      console.log(costPerMonth);
      let rem = costPerMonth % 5;
      if (rem < 3) {
        costPerMonth = costPerMonth - rem;
      } else {
        let add = 5 - rem;
        costPerMonth = costPerMonth + add;
      }
      this.setState({
        costPerMonth3: Math.ceil(costPerMonth),
        installmentPrice3: Math.ceil(price),
        [event.target.name]: event.target.value,
      });
    }
    if (id == "4") {
      let costPerMonth = (price - this.state.firstPaid4) / this.state.months4;
      costPerMonth = Math.ceil(costPerMonth);
      console.log(costPerMonth);
      let rem = costPerMonth % 5;
      if (rem < 3) {
        costPerMonth = costPerMonth - rem;
      } else {
        let add = 5 - rem;
        costPerMonth = costPerMonth + add;
      }
      this.setState({
        costPerMonth4: Math.ceil(costPerMonth),
        installmentPrice4: Math.ceil(price),
        [event.target.name]: event.target.value,
      });
    }
    if (id == "5") {
      let costPerMonth = (price - this.state.firstPaid5) / this.state.months5;
      costPerMonth = Math.ceil(costPerMonth);
      console.log(costPerMonth);
      let rem = costPerMonth % 5;
      if (rem < 3) {
        costPerMonth = costPerMonth - rem;
      } else {
        let add = 5 - rem;
        costPerMonth = costPerMonth + add;
      }
      this.setState({
        costPerMonth5: Math.ceil(costPerMonth),
        installmentPrice5: Math.ceil(price),
        [event.target.name]: event.target.value,
      });
    }
    if (id == "6") {
      let costPerMonth = (price - this.state.firstPaid6) / this.state.months6;
      costPerMonth = Math.ceil(costPerMonth);
      console.log(costPerMonth);
      let rem = costPerMonth % 5;
      if (rem < 3) {
        costPerMonth = costPerMonth - rem;
      } else {
        let add = 5 - rem;
        costPerMonth = costPerMonth + add;
      }
      this.setState({
        costPerMonth6: Math.ceil(costPerMonth),
        installmentPrice6: Math.ceil(price),
        [event.target.name]: event.target.value,
      });
    }
  };
  removePackage = (id) => {
    if (id == 1) {
      this.setState({
        costPerMonth1: 0,
        months1: 1,
        firstPaid1: 0,
        installmentPrice1: 0,
        ratio1: 0,
        packageNum: this.state.packageNum - 1,
        packagePlus: this.state.packagePlus - 1,
      });
    }
    if (id == 2) {
      this.setState({
        costPerMonth2: 0,
        months2: 1,
        firstPaid2: 0,
        installmentPrice2: 0,
        ratio2: 0,
        packageNum: this.state.packageNum - 1,
        packagePlus: this.state.packagePlus - 1,
      });
    }
    if (id == 3) {
      this.setState({
        costPerMonth1: 0,
        months3: 1,
        firstPaid3: 0,
        installmentPrice3: 0,
        ratio3: 0,
        packageNum: this.state.packageNum - 1,
        packagePlus: this.state.packagePlus - 1,
      });
    }
    if (id == 4) {
      this.setState({
        costPerMonth4: 0,
        months4: 1,
        firstPaid4: 0,
        installmentPrice4: 0,
        ratio4: 0,
        packageNum: this.state.packageNum - 1,
        packagePlus: this.state.packagePlus - 1,
      });
    }
    if (id == 5) {
      this.setState({
        costPerMonth5: 0,
        months5: 1,
        firstPaid5: 0,
        installmentPrice5: 0,
        ratio5: 0,
        packageNum: this.state.packageNum - 1,
        packagePlus: this.state.packagePlus - 1,
      });
    }
    if (id == 6) {
      this.setState({
        costPerMonth6: 0,
        months6: 1,
        firstPaid6: 0,
        installmentPrice6: 0,
        ratio6: 0,
        packageNum: this.state.packageNum - 1,
        packagePlus: this.state.packagePlus - 1,
      });
    }
  };
  changePurchase = (event) => {
    let purchasePrice = event.target.value;
    let ratio1 = this.state.ratio1;
    let addtionPrice1 = (purchasePrice * ratio1) / 100;
    console.log(addtionPrice1);
    let price1 = parseInt(purchasePrice) + addtionPrice1;
    console.log(price1);
    let ratio2 = this.state.ratio2;
    let addtionPrice2 = (purchasePrice * ratio2) / 100;
    let price2 = parseInt(purchasePrice) + addtionPrice2;

    let ratio3 = this.state.ratio3;
    let addtionPrice3 = (purchasePrice * ratio3) / 100;
    let price3 = parseInt(purchasePrice) + addtionPrice3;

    let ratio4 = this.state.ratio4;
    let addtionPrice4 = (purchasePrice * ratio4) / 100;
    let price4 = parseInt(purchasePrice) + addtionPrice4;

    let ratio5 = this.state.ratio5;
    let addtionPrice5 = (purchasePrice * ratio5) / 100;
    let price5 = parseInt(purchasePrice) + addtionPrice5;

    let ratio6 = this.state.ratio6;
    let addtionPrice6 = (purchasePrice * ratio6) / 100;
    let price6 = parseInt(purchasePrice) + addtionPrice6;

    let costPerMonth1 = (price1 - this.state.firstPaid1) / this.state.months1;
    //
    costPerMonth1 = Math.ceil(costPerMonth1);
    console.log(costPerMonth1);
    let rem1 = costPerMonth1 % 5;
    if (rem1 < 3) {
      costPerMonth1 = costPerMonth1 - rem1;
    } else {
      let add1 = 5 - rem1;
      costPerMonth1 = costPerMonth1 + add1;
    }
    let costPerMonth2 = (price2 - this.state.firstPaid2) / this.state.months2;
    //
    costPerMonth2 = Math.ceil(costPerMonth2);
    console.log(costPerMonth2);
    let rem2 = costPerMonth2 % 5;
    if (rem2 < 3) {
      costPerMonth2 = costPerMonth2 - rem2;
    } else {
      let add2 = 5 - rem2;
      costPerMonth2 = costPerMonth2 + add2;
    }
    let costPerMonth3 = (price3 - this.state.firstPaid3) / this.state.months3;
    //
    costPerMonth3 = Math.ceil(costPerMonth3);
    console.log(costPerMonth3);
    let rem3 = costPerMonth3 % 5;
    if (rem3 < 3) {
      costPerMonth3 = costPerMonth3 - rem3;
    } else {
      let add3 = 5 - rem3;
      costPerMonth3 = costPerMonth3 + add3;
    }
    let costPerMonth4 = (price4 - this.state.firstPaid4) / this.state.months4;
    //
    costPerMonth4 = Math.ceil(costPerMonth4);
    console.log(costPerMonth4);
    let rem4 = costPerMonth4 % 5;
    if (rem4 < 3) {
      costPerMonth4 = costPerMonth4 - rem4;
    } else {
      let add4 = 5 - rem4;
      costPerMonth4 = costPerMonth4 + add4;
    }
    let costPerMonth5 = (price5 - this.state.firstPaid5) / this.state.months5;
    //
    costPerMonth5 = Math.ceil(costPerMonth5);
    console.log(costPerMonth5);
    let rem5 = costPerMonth5 % 5;
    if (rem5 < 3) {
      costPerMonth5 = costPerMonth5 - rem5;
    } else {
      let add5 = 5 - rem5;
      costPerMonth5 = costPerMonth5 + add5;
    }
    let costPerMonth6 = (price6 - this.state.firstPaid6) / this.state.months6;
    //
    costPerMonth6 = Math.ceil(costPerMonth6);
    console.log(costPerMonth6);
    let rem6 = costPerMonth6 % 5;
    if (rem6 < 3) {
      costPerMonth6 = costPerMonth6 - rem6;
    } else {
      let add6 = 5 - rem6;
      costPerMonth6 = costPerMonth6 + add6;
    }
    this.setState({
      disabledPackage: false,
      costPerMonth1: Math.ceil(costPerMonth1),
      costPerMonth2: Math.ceil(costPerMonth2),
      costPerMonth3: Math.ceil(costPerMonth3),
      costPerMonth4: Math.ceil(costPerMonth4),
      costPerMonth5: Math.ceil(costPerMonth5),
      costPerMonth6: Math.ceil(costPerMonth6),
      installmentPrice1: Math.ceil(price1),
      installmentPrice2: Math.ceil(price2),
      installmentPrice3: Math.ceil(price3),
      installmentPrice4: Math.ceil(price4),
      installmentPrice5: Math.ceil(price5),
      installmentPrice6: Math.ceil(price6),
      [event.target.name]: event.target.value,
    });
  };
  changeMonth = (event) => {
    console.log(event.target.id);
    let id = event.target.id.substring(5);

    if (id == "1") {
      console.log(this.state.installmentPrice1);
      console.log(this.state.firstPaid1);
      console.log(this.state.months1);
      let costPerMonth =
        (this.state.installmentPrice1 - this.state.firstPaid1) /
        event.target.value;
      costPerMonth = Math.ceil(costPerMonth);
      console.log(costPerMonth);
      let rem = costPerMonth % 5;
      if (rem < 3) {
        costPerMonth = costPerMonth - rem;
      } else {
        let add = 5 - rem;
        costPerMonth = costPerMonth + add;
      }
      this.setState({
        costPerMonth1: Math.ceil(costPerMonth),
        [event.target.name]: event.target.value,
      });
    }
    if (id == "2") {
      let costPerMonth =
        (this.state.installmentPrice2 - this.state.firstPaid2) /
        event.target.value;
      costPerMonth = Math.ceil(costPerMonth);
      console.log(costPerMonth);
      let rem = costPerMonth % 5;
      if (rem < 3) {
        costPerMonth = costPerMonth - rem;
      } else {
        let add = 5 - rem;
        costPerMonth = costPerMonth + add;
      }
      this.setState({
        costPerMonth2: Math.ceil(costPerMonth),
        [event.target.name]: event.target.value,
      });
    }
    if (id == "3") {
      let costPerMonth =
        (this.state.installmentPrice3 - this.state.firstPaid3) /
        event.target.value;
      costPerMonth = Math.ceil(costPerMonth);
      console.log(costPerMonth);
      let rem = costPerMonth % 5;
      if (rem < 3) {
        costPerMonth = costPerMonth - rem;
      } else {
        let add = 5 - rem;
        costPerMonth = costPerMonth + add;
      }
      this.setState({
        costPerMonth3: Math.ceil(costPerMonth),
        [event.target.name]: event.target.value,
      });
    }
    if (id == "4") {
      let costPerMonth =
        (this.state.installmentPrice4 - this.state.firstPaid4) /
        event.target.value;
      costPerMonth = Math.ceil(costPerMonth);
      console.log(costPerMonth);
      let rem = costPerMonth % 5;
      if (rem < 3) {
        costPerMonth = costPerMonth - rem;
      } else {
        let add = 5 - rem;
        costPerMonth = costPerMonth + add;
      }
      this.setState({
        costPerMonth4: Math.ceil(costPerMonth),
        [event.target.name]: event.target.value,
      });
    }
    if (id == "5") {
      let costPerMonth =
        (this.state.installmentPrice5 - this.state.firstPaid5) /
        event.target.value;
      costPerMonth = Math.ceil(costPerMonth);
      console.log(costPerMonth);
      let rem = costPerMonth % 5;
      if (rem < 3) {
        costPerMonth = costPerMonth - rem;
      } else {
        let add = 5 - rem;
        costPerMonth = costPerMonth + add;
      }
      this.setState({
        costPerMonth5: Math.ceil(costPerMonth),
        [event.target.name]: event.target.value,
      });
    }
    if (id == "6") {
      let costPerMonth =
        (this.state.installmentPrice6 - this.state.firstPaid6) /
        event.target.value;
      costPerMonth = Math.ceil(costPerMonth);
      console.log(costPerMonth);
      let rem = costPerMonth % 5;
      if (rem < 3) {
        costPerMonth = costPerMonth - rem;
      } else {
        let add = 5 - rem;
        costPerMonth = costPerMonth + add;
      }
      this.setState({
        costPerMonth6: Math.ceil(costPerMonth),
        [event.target.name]: event.target.value,
      });
    }
  };
  changeFirstPaid = (event) => {
    console.log(event.target.id);
    let id = event.target.id.substring(9);

    if (id == "1") {
      let costPerMonth =
        (this.state.installmentPrice1 - event.target.value) /
        this.state.months1;
      costPerMonth = Math.ceil(costPerMonth);
      console.log(costPerMonth);
      let rem = costPerMonth % 5;
      if (rem < 3) {
        costPerMonth = costPerMonth - rem;
      } else {
        let add = 5 - rem;
        costPerMonth = costPerMonth + add;
      }
      this.setState({
        costPerMonth1: costPerMonth,
        [event.target.name]: event.target.value,
      });
    }
    if (id == "2") {
      let costPerMonth =
        (this.state.installmentPrice2 - event.target.value) /
        this.state.months2;
      costPerMonth = Math.ceil(costPerMonth);
      console.log(costPerMonth);
      let rem = costPerMonth % 5;
      if (rem < 3) {
        costPerMonth = costPerMonth - rem;
      } else {
        let add = 5 - rem;
        costPerMonth = costPerMonth + add;
      }
      this.setState({
        costPerMonth2: costPerMonth,
        [event.target.name]: event.target.value,
      });
    }
    if (id == "3") {
      let costPerMonth =
        (this.state.installmentPrice3 - event.target.value) /
        this.state.months3;
      costPerMonth = Math.ceil(costPerMonth);
      console.log(costPerMonth);
      let rem = costPerMonth % 5;
      if (rem < 3) {
        costPerMonth = costPerMonth - rem;
      } else {
        let add = 5 - rem;
        costPerMonth = costPerMonth + add;
      }
      this.setState({
        costPerMonth3: costPerMonth,
        [event.target.name]: event.target.value,
      });
    }
    if (id == "4") {
      let costPerMonth =
        (this.state.installmentPrice4 - event.target.value) /
        this.state.months4;
      costPerMonth = Math.ceil(costPerMonth);
      console.log(costPerMonth);
      let rem = costPerMonth % 5;
      if (rem < 3) {
        costPerMonth = costPerMonth - rem;
      } else {
        let add = 5 - rem;
        costPerMonth = costPerMonth + add;
      }
      this.setState({
        costPerMonth4: costPerMonth,
        [event.target.name]: event.target.value,
      });
    }
    if (id == "5") {
      let costPerMonth =
        (this.state.installmentPrice5 - event.target.value) /
        this.state.months5;
      costPerMonth = Math.ceil(costPerMonth);
      console.log(costPerMonth);
      let rem = costPerMonth % 5;
      if (rem < 3) {
        costPerMonth = costPerMonth - rem;
      } else {
        let add = 5 - rem;
        costPerMonth = costPerMonth + add;
      }
      this.setState({
        costPerMonth5: costPerMonth,
        [event.target.name]: event.target.value,
      });
    }
    if (id == "6") {
      let costPerMonth =
        (this.state.installmentPrice6 - event.target.value) /
        this.state.months6;
      costPerMonth = Math.ceil(costPerMonth);
      console.log(costPerMonth);
      let rem = costPerMonth % 5;
      if (rem < 3) {
        costPerMonth = costPerMonth - rem;
      } else {
        let add = 5 - rem;
        costPerMonth = costPerMonth + add;
      }
      this.setState({
        costPerMonth6: costPerMonth,
        [event.target.name]: event.target.value,
      });
    }
  };
  fileHandlerImgs = (event) => {
    let imgs = [];
    for (var i = 0; i <= event.target.files.length - 1; i++) {
      imgs.push(URL.createObjectURL(event.target.files[i]));
    }
    console.log(imgs);
    this.setState({
      imgs: event.target.files,
      imgsViews: imgs,
      ImgsType: "data",
    });
  };

  removeImgs = (url) => {
    let arr = this.state.imgsViews;
    let arr2 = Array.from(this.state.imgs);
    console.log(arr2);
    var index = arr.indexOf(url);
    if (index !== -1) arr.splice(index, 1);
    if (index !== -1) arr2.splice(index, 1);
    console.log(arr2);
    this.setState({
      imgsViews: arr,
      imgs: arr2,
    });
    if (arr2.length == 0) {
      this.setState({
        imgs: null,
      });
    }
  };

  fileHandlerImg = (event) => {
    this.setState({
      img: event.target.files[0],
      imgType: "data",
    });
  };

  removeImg = () => {
    this.setState({
      img: null,
    });
  };
  MinToStockHis = () => {
    const { moneyGiven } = this.state;
    if (moneyGiven !== 0) {
      let l = message.loading("Wait..", 2.5);
      let data = {
        count: moneyGiven,
        singlePrice: this.state.product.purchasePrice,
        type: "MINUS",
        company: this.state.product.company.id,
      };
      console.log(data);
      axios
        .post(
          `${BASE_END_POINT}stockHistory/${this.state.product.id}`,
          JSON.stringify(data),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.currentUser.token}`,
            },
          }
        )
        .then((response) => {
          l.then(() => message.success(allStrings.done, 2.5));
          console.log(response.data.data);
          this.toggle2();
          this.setState({
            quantity: parseInt(this.state.quantity) - parseInt(moneyGiven),
          });
          let { quantity } = this.state;
          console.log(quantity);
          this.update();
        })
        .catch((error) => {
          console.log(error.response);
          l.then(() => message.error("Error", 2.5));
        });
    }
  };

  paySubmitHandler2 = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.MinToStockHis();
  };
  AddToStockHis = () => {
    const { moneyGiven } = this.state;
    if (moneyGiven !== 0) {
      let l = message.loading("Wait..", 2.5);
      let data = {
        count: moneyGiven,
        singlePrice: this.state.product.purchasePrice,
        type: "ADD",
        company: this.state.product.company.id,
      };
      console.log(data);
      axios
        .post(
          `${BASE_END_POINT}stockHistory/${this.state.product.id}`,
          JSON.stringify(data),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.currentUser.token}`,
            },
          }
        )
        .then((response) => {
          l.then(() => message.success(allStrings.done, 2.5));
          console.log(response.data.data);
          this.toggle2();
          this.setState({
            quantity: parseInt(this.state.quantity) + parseInt(moneyGiven),
          });
          let { quantity } = this.state;
          console.log(quantity);
          this.update();
        })
        .catch((error) => {
          console.log(error.response);
          l.then(() => message.error("Error", 2.5));
        });
    }
  };

  paySubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.AddToStockHis();
  };

  modal2 = () => (
    <MDBModal isOpen={this.state.modal2} toggle={this.toggle2} size="lg">
      <MDBModalHeader toggle={this.toggle2}>
        {this.state.modalType === "map"
          ? allStrings.location
          : allStrings.completeData}
      </MDBModalHeader>
      <MDBModalBody>
        <MDBContainer>
          <MDBRow>
            {this.state.modalType === "pay" && (
              <MDBCol md="12">
                <form
                  style={{ marginLeft: "15%" }}
                  className="needs-validation"
                  onSubmit={this.paySubmitHandler}
                  noValidate
                >
                  <MDBContainer>
                    <MDBRow>
                      <MDBCol md="9">
                        <input
                          name="moneyGiven"
                          onChange={this.changeHandler}
                          type="number"
                          min={0}
                          id="defaultFormRegisterNamx1"
                          required
                          placeholder={allStrings.quantity}
                          className="form-control"
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                        />
                      </MDBCol>
                      <MDBCol md="3">
                        <label
                          htmlFor="defaultFmRegisterNameEx1"
                          className="grey-text formLabel"
                        >
                          {allStrings.quantity}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="9">
                        <MDBBtn
                          type="submit"
                          style={{
                            width: "100%",
                            padding: "11px 20px",
                            height: "35px",
                          }}
                          className="btn z-depth-1a "
                          color="success"
                        >
                          {allStrings.done}
                        </MDBBtn>
                      </MDBCol>
                      <MDBCol md="3">
                        <label
                          htmlFor="defaultFmRegisterNameEx1"
                          className="grey-text formLabel"
                        ></label>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </form>
              </MDBCol>
            )}
            {this.state.modalType === "min" && (
              <MDBCol md="12">
                <form
                  style={{ marginLeft: "15%" }}
                  className="needs-validation"
                  onSubmit={this.paySubmitHandler2}
                  noValidate
                >
                  <MDBContainer>
                    <MDBRow>
                      <MDBCol md="9">
                        <input
                          name="moneyGiven"
                          onChange={this.changeHandler}
                          type="number"
                          min={0}
                          id="defaultFormRegisterNamx1"
                          required
                          placeholder={allStrings.quantity}
                          className="form-control"
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                        />
                      </MDBCol>
                      <MDBCol md="3">
                        <label
                          htmlFor="defaultFmRegisterNameEx1"
                          className="grey-text formLabel"
                        >
                          {allStrings.quantity}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="9">
                        <MDBBtn
                          type="submit"
                          style={{
                            width: "100%",
                            padding: "11px 20px",
                            height: "35px",
                          }}
                          className="btn z-depth-1a "
                          color="danger"
                        >
                          {allStrings.done}
                        </MDBBtn>
                      </MDBCol>
                      <MDBCol md="3">
                        <label
                          htmlFor="defaultFmRegisterNameEx1"
                          className="grey-text formLabel"
                        ></label>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </form>
              </MDBCol>
            )}
          </MDBRow>
        </MDBContainer>
      </MDBModalBody>
    </MDBModal>
  );
  render() {
    let { img, imgs, photoIndex, isOpen1, isOpen2 } = this.state;
    img = [img];
    console.log(this.state.category);
    return (
      <div className="stockForm">
        {this.modal2()}
        <MDBContainer>
          <MDBRow>
            <div className="top" style={{ width: "100%", textAlign: "center" }}>
              <NavLink to="/stock">
                <MDBIcon icon="times" className="closeIcon" />
              </NavLink>
              <span className="stockTitle">{allStrings.completeData}</span>
            </div>
          </MDBRow>
          <form
            className="needs-validation"
            onSubmit={
              this.state.requestType === "post"
                ? this.addSubmitHandler
                : this.updateSubmitHandler
            }
            noValidate
          >
            <MDBRow>
              <MDBCol xl="6" lg="6" md="6" sm="12">
                <MDBRow>
                  <MDBCol md="12">
                    <label
                      htmlFor="defaultFormRegisterNameEx20"
                      className="formLabel"
                      style={{
                        float: "right",
                        color: "#43425D",
                      }}
                    >
                      {allStrings.description}
                    </label>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="12" sm="12">
                    <textarea
                      name="description"
                      onChange={this.changeHandler}
                      type="text"
                      id="defaultFormRegisterNameEx1"
                      required
                      placeholder={allStrings.description}
                      className="form-control"
                      style={{ direction: this.props.isRTL ? "rtl" : "ltr" }}
                      defaultValue={this.state.product.name_ar}
                    />
                  </MDBCol>
                </MDBRow>
                <br></br>
                {this.state.installementSystem == true && (
                  <MDBRow>
                    <MDBCol md="12">
                      <label
                        htmlFor="defaultFormRegisterNameEx2"
                        style={{
                          float: "right",
                          fontWeight: "800",
                          color: "#43425D",
                          fontSize: "18px",
                          marginBottom: "1rem",
                        }}
                      >
                        {allStrings.installmentPackages}
                      </label>
                    </MDBCol>
                  </MDBRow>
                )}
                {this.state.installementSystem == true && (
                  <MDBRow>
                    {this.state.packageNum > 0 && (
                      <MDBCol lg="6" md="12">
                        <img
                          alt=""
                          className="removePackage"
                          onClick={() => this.removePackage(1)}
                          src={require("../../assets/images/delete_bin.svg")}
                        />
                        <div className="package">
                          <MDBContainer>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">%</span>
                              </MDBCol>
                              <MDBCol md="6" sm="6" xs="6">
                                <input
                                  name="ratio1"
                                  onChange={this.changeInstallmentPrice}
                                  type="number"
                                  min={0}
                                  id="installmentPrice1"
                                  className="form-control packageInput"
                                  style={{
                                    direction: this.props.isRTL ? "rtl" : "ltr",
                                  }}
                                  disabled={
                                    this.state.disabledPackage ? true : false
                                  }
                                  defaultValue={
                                    this.state.ratio1 == 0
                                      ? ""
                                      : this.state.ratio1
                                  }
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.ratio}
                                </span>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.pound}
                                </span>
                              </MDBCol>
                              <MDBCol md="6" sm="6" xs="6">
                                <input
                                  name="firstPaid1"
                                  onChange={this.changeFirstPaid}
                                  id="firstPaid1"
                                  type="number"
                                  min={0}
                                  className="form-control packageInput"
                                  defaultValue={
                                    this.state.firstPaid1 == 0
                                      ? ""
                                      : this.state.firstPaid1
                                  }
                                  style={{
                                    direction: this.props.isRTL ? "rtl" : "ltr",
                                  }}
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.firstPaid}
                                </span>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.months}
                                </span>
                              </MDBCol>
                              <MDBCol md="6" sm="6" xs="6">
                                <input
                                  name="months1"
                                  onChange={this.changeMonth}
                                  id="month1"
                                  type="number"
                                  min={0}
                                  className="form-control packageInput"
                                  defaultValue={
                                    this.state.months1 == 1
                                      ? ""
                                      : this.state.months1
                                  }
                                  style={{
                                    direction: this.props.isRTL ? "rtl" : "ltr",
                                  }}
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.theMonths}
                                </span>
                              </MDBCol>
                            </MDBRow>
                            <hr style={{ margin: "8px 0px 0px 0px" }}></hr>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.pound}
                                </span>
                              </MDBCol>
                              <MDBCol
                                md="6"
                                sm="6"
                                xs="6"
                                style={{ textAlign: "center" }}
                              >
                                <input
                                  type="text"
                                  className="form-control installmentInput"
                                  disabled
                                  value={
                                    this.state.costPerMonth1 == 0
                                      ? ""
                                      : this.state.costPerMonth1
                                  }
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.installment}
                                </span>
                              </MDBCol>
                            </MDBRow>

                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.pound}
                                </span>
                              </MDBCol>
                              <MDBCol
                                md="6"
                                sm="6"
                                xs="6"
                                style={{ textAlign: "center" }}
                              >
                                <input
                                  type="text"
                                  className="form-control installmentInput"
                                  disabled
                                  value={
                                    this.state.costPerMonth1 == 0
                                      ? ""
                                      : parseInt(
                                          this.state.costPerMonth1 *
                                            this.state.months1
                                        ) + parseInt(this.state.firstPaid1)
                                  }
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.totalCost}
                                </span>
                              </MDBCol>
                            </MDBRow>
                          </MDBContainer>
                        </div>
                      </MDBCol>
                    )}
                    {this.state.packagePlus == 0 && (
                      <MDBCol lg="6" md="12" style={{ textAlign: "center" }}>
                        <span
                          className="addPackage"
                          onClick={() =>
                            this.setState({
                              packageNum: this.state.packageNum + 1,
                              packagePlus: this.state.packagePlus + 1,
                            })
                          }
                        >
                          {allStrings.add}
                        </span>
                        <span
                          onClick={() =>
                            this.setState({
                              packageNum: this.state.packageNum + 1,
                              packagePlus: this.state.packagePlus + 1,
                            })
                          }
                          style={{
                            color: "#31313F",
                            fontSize: "50px",
                            marginTop: "30px",
                            cursor: "pointer",
                          }}
                          class="material-icons"
                        >
                          add_circle
                        </span>
                      </MDBCol>
                    )}
                    {this.state.packageNum > 1 && (
                      <MDBCol lg="6" md="12">
                        <img
                          alt=""
                          className="removePackage"
                          onClick={() => this.removePackage(2)}
                          src={require("../../assets/images/delete_bin.svg")}
                        />
                        <div className="package">
                          <MDBContainer>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">%</span>
                              </MDBCol>
                              <MDBCol md="6" sm="6" xs="6">
                                <input
                                  name="ratio2"
                                  onChange={this.changeInstallmentPrice}
                                  type="number"
                                  min={0}
                                  id="installmentPrice2"
                                  className="form-control packageInput"
                                  style={{
                                    direction: this.props.isRTL ? "rtl" : "ltr",
                                  }}
                                  disabled={
                                    this.state.disabledPackage ? true : false
                                  }
                                  defaultValue={
                                    this.state.ratio2 == 0
                                      ? ""
                                      : this.state.ratio2
                                  }
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.ratio}
                                </span>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.pound}
                                </span>
                              </MDBCol>
                              <MDBCol md="6" sm="6" xs="6">
                                <input
                                  name="firstPaid2"
                                  onChange={this.changeFirstPaid}
                                  id="firstPaid2"
                                  type="number"
                                  min={0}
                                  className="form-control packageInput"
                                  defaultValue={
                                    this.state.firstPaid2 == 0
                                      ? ""
                                      : this.state.firstPaid2
                                  }
                                  style={{
                                    direction: this.props.isRTL ? "rtl" : "ltr",
                                  }}
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.firstPaid}
                                </span>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.months}
                                </span>
                              </MDBCol>
                              <MDBCol md="6" sm="6" xs="6">
                                <input
                                  name="months2"
                                  onChange={this.changeMonth}
                                  id="month2"
                                  type="number"
                                  min={0}
                                  className="form-control packageInput"
                                  defaultValue={
                                    this.state.months2 == 1
                                      ? ""
                                      : this.state.months2
                                  }
                                  style={{
                                    direction: this.props.isRTL ? "rtl" : "ltr",
                                  }}
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.theMonths}
                                </span>
                              </MDBCol>
                            </MDBRow>
                            <hr style={{ margin: "8px 0px 0px 0px" }}></hr>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.pound}
                                </span>
                              </MDBCol>
                              <MDBCol
                                md="6"
                                sm="6"
                                xs="6"
                                style={{ textAlign: "center" }}
                              >
                                <input
                                  type="text"
                                  className="form-control installmentInput"
                                  disabled
                                  value={
                                    this.state.costPerMonth2 == 0
                                      ? ""
                                      : this.state.costPerMonth2
                                  }
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.installment}
                                </span>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.pound}
                                </span>
                              </MDBCol>
                              <MDBCol
                                md="6"
                                sm="6"
                                xs="6"
                                style={{ textAlign: "center" }}
                              >
                                <input
                                  type="text"
                                  className="form-control installmentInput"
                                  disabled
                                  value={
                                    this.state.costPerMonth2 == 0
                                      ? ""
                                      : parseInt(
                                          this.state.costPerMonth2 *
                                            this.state.months2
                                        ) + parseInt(this.state.firstPaid2)
                                  }
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.totalCost}
                                </span>
                              </MDBCol>
                            </MDBRow>
                          </MDBContainer>
                        </div>
                      </MDBCol>
                    )}
                    {this.state.packagePlus == 1 && (
                      <MDBCol lg="6" md="12" style={{ textAlign: "center" }}>
                        <span
                          className="addPackage"
                          onClick={() =>
                            this.setState({
                              packageNum: this.state.packageNum + 1,
                              packagePlus: this.state.packagePlus + 1,
                            })
                          }
                        >
                          {allStrings.add}
                        </span>
                        <span
                          onClick={() =>
                            this.setState({
                              packageNum: this.state.packageNum + 1,
                              packagePlus: this.state.packagePlus + 1,
                            })
                          }
                          style={{
                            color: "#31313F",
                            fontSize: "50px",
                            marginTop: "30px",
                            cursor: "pointer",
                          }}
                          class="material-icons"
                        >
                          add_circle
                        </span>
                      </MDBCol>
                    )}
                  </MDBRow>
                )}
                {this.state.installementSystem == true && (
                  <MDBRow>
                    {this.state.packageNum > 2 && (
                      <MDBCol lg="6" md="12">
                        <img
                          alt=""
                          className="removePackage"
                          onClick={() => this.removePackage(3)}
                          src={require("../../assets/images/delete_bin.svg")}
                        />
                        <div className="package">
                          <MDBContainer>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">%</span>
                              </MDBCol>
                              <MDBCol md="6" sm="6" xs="6">
                                <input
                                  name="ratio3"
                                  onChange={this.changeInstallmentPrice}
                                  type="number"
                                  min={0}
                                  id="installmentPrice3"
                                  className="form-control packageInput"
                                  style={{
                                    direction: this.props.isRTL ? "rtl" : "ltr",
                                  }}
                                  disabled={
                                    this.state.disabledPackage ? true : false
                                  }
                                  defaultValue={
                                    this.state.ratio3 == 0
                                      ? ""
                                      : this.state.ratio3
                                  }
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.ratio}
                                </span>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.pound}
                                </span>
                              </MDBCol>
                              <MDBCol md="6" sm="6" xs="6">
                                <input
                                  name="firstPaid3"
                                  onChange={this.changeFirstPaid}
                                  id="firstPaid3"
                                  type="number"
                                  min={0}
                                  className="form-control packageInput"
                                  defaultValue={
                                    this.state.firstPaid3 == 0
                                      ? ""
                                      : this.state.firstPaid3
                                  }
                                  style={{
                                    direction: this.props.isRTL ? "rtl" : "ltr",
                                  }}
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.firstPaid}
                                </span>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.months}
                                </span>
                              </MDBCol>
                              <MDBCol md="6" sm="6" xs="6">
                                <input
                                  name="months3"
                                  onChange={this.changeMonth}
                                  id="month3"
                                  type="number"
                                  min={0}
                                  className="form-control packageInput"
                                  defaultValue={
                                    this.state.months3 == 1
                                      ? ""
                                      : this.state.months3
                                  }
                                  style={{
                                    direction: this.props.isRTL ? "rtl" : "ltr",
                                  }}
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.theMonths}
                                </span>
                              </MDBCol>
                            </MDBRow>
                            <hr style={{ margin: "8px 0px 0px 0px" }}></hr>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.pound}
                                </span>
                              </MDBCol>
                              <MDBCol
                                md="6"
                                sm="6"
                                xs="6"
                                style={{ textAlign: "center" }}
                              >
                                <input
                                  type="text"
                                  className="form-control installmentInput"
                                  disabled
                                  value={
                                    this.state.costPerMonth3 == 0
                                      ? ""
                                      : this.state.costPerMonth3
                                  }
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.installment}
                                </span>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.pound}
                                </span>
                              </MDBCol>
                              <MDBCol
                                md="6"
                                sm="6"
                                xs="6"
                                style={{ textAlign: "center" }}
                              >
                                <input
                                  type="text"
                                  className="form-control installmentInput"
                                  disabled
                                  value={
                                    this.state.costPerMonth3 == 0
                                      ? ""
                                      : parseInt(
                                          this.state.costPerMonth3 *
                                            this.state.months3
                                        ) + parseInt(this.state.firstPaid3)
                                  }
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.totalCost}
                                </span>
                              </MDBCol>
                            </MDBRow>
                          </MDBContainer>
                        </div>
                      </MDBCol>
                    )}
                    {this.state.packagePlus == 2 && (
                      <MDBCol lg="6" md="12" style={{ textAlign: "center" }}>
                        <span
                          className="addPackage"
                          onClick={() =>
                            this.setState({
                              packageNum: this.state.packageNum + 1,
                              packagePlus: this.state.packagePlus + 1,
                            })
                          }
                        >
                          {allStrings.add}
                        </span>
                        <span
                          onClick={() =>
                            this.setState({
                              packageNum: this.state.packageNum + 1,
                              packagePlus: this.state.packagePlus + 1,
                            })
                          }
                          style={{
                            color: "#31313F",
                            fontSize: "50px",
                            marginTop: "30px",
                            cursor: "pointer",
                          }}
                          class="material-icons"
                        >
                          add_circle
                        </span>
                      </MDBCol>
                    )}
                    {this.state.packageNum > 3 && (
                      <MDBCol lg="6" md="12">
                        <img
                          alt=""
                          className="removePackage"
                          onClick={() => this.removePackage(4)}
                          src={require("../../assets/images/delete_bin.svg")}
                        />
                        <div className="package">
                          <MDBContainer>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">%</span>
                              </MDBCol>
                              <MDBCol md="6" sm="6" xs="6">
                                <input
                                  name="ratio4"
                                  onChange={this.changeInstallmentPrice}
                                  type="number"
                                  min={0}
                                  id="installmentPrice4"
                                  className="form-control packageInput"
                                  style={{
                                    direction: this.props.isRTL ? "rtl" : "ltr",
                                  }}
                                  disabled={
                                    this.state.disabledPackage ? true : false
                                  }
                                  defaultValue={
                                    this.state.ratio4 == 0
                                      ? ""
                                      : this.state.ratio4
                                  }
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.ratio}
                                </span>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.pound}
                                </span>
                              </MDBCol>
                              <MDBCol md="6" sm="6" xs="6">
                                <input
                                  name="firstPaid4"
                                  onChange={this.changeFirstPaid}
                                  id="firstPaid4"
                                  type="number"
                                  min={0}
                                  className="form-control packageInput"
                                  defaultValue={
                                    this.state.firstPaid4 == 0
                                      ? ""
                                      : this.state.firstPaid4
                                  }
                                  style={{
                                    direction: this.props.isRTL ? "rtl" : "ltr",
                                  }}
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.firstPaid}
                                </span>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.months}
                                </span>
                              </MDBCol>
                              <MDBCol md="6" sm="6" xs="6">
                                <input
                                  name="months4"
                                  onChange={this.changeMonth}
                                  id="month4"
                                  type="number"
                                  min={0}
                                  className="form-control packageInput"
                                  defaultValue={
                                    this.state.months4 == 1
                                      ? ""
                                      : this.state.months4
                                  }
                                  style={{
                                    direction: this.props.isRTL ? "rtl" : "ltr",
                                  }}
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.theMonths}
                                </span>
                              </MDBCol>
                            </MDBRow>
                            <hr style={{ margin: "8px 0px 0px 0px" }}></hr>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.pound}
                                </span>
                              </MDBCol>
                              <MDBCol
                                md="6"
                                sm="6"
                                xs="6"
                                style={{ textAlign: "center" }}
                              >
                                <input
                                  type="text"
                                  className="form-control installmentInput"
                                  disabled
                                  value={
                                    this.state.costPerMonth4 == 0
                                      ? ""
                                      : this.state.costPerMonth4
                                  }
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.installment}
                                </span>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.pound}
                                </span>
                              </MDBCol>
                              <MDBCol
                                md="6"
                                sm="6"
                                xs="6"
                                style={{ textAlign: "center" }}
                              >
                                <input
                                  type="text"
                                  className="form-control installmentInput"
                                  disabled
                                  value={
                                    this.state.costPerMonth3 == 0
                                      ? ""
                                      : parseInt(
                                          this.state.costPerMonth4 *
                                            this.state.months4
                                        ) + parseInt(this.state.firstPaid4)
                                  }
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.totalCost}
                                </span>
                              </MDBCol>
                            </MDBRow>
                          </MDBContainer>
                        </div>
                      </MDBCol>
                    )}
                    {this.state.packagePlus == 3 && (
                      <MDBCol lg="6" md="12" style={{ textAlign: "center" }}>
                        <span
                          className="addPackage"
                          onClick={() =>
                            this.setState({
                              packageNum: this.state.packageNum + 1,
                              packagePlus: this.state.packagePlus + 1,
                            })
                          }
                        >
                          {allStrings.add}
                        </span>
                        <span
                          onClick={() =>
                            this.setState({
                              packageNum: this.state.packageNum + 1,
                              packagePlus: this.state.packagePlus + 1,
                            })
                          }
                          style={{
                            color: "#31313F",
                            fontSize: "50px",
                            marginTop: "30px",
                            cursor: "pointer",
                          }}
                          class="material-icons"
                        >
                          add_circle
                        </span>
                      </MDBCol>
                    )}
                  </MDBRow>
                )}
                {this.state.installementSystem == true && (
                  <MDBRow>
                    {this.state.packageNum > 4 && (
                      <MDBCol lg="6" md="12">
                        <img
                          alt=""
                          className="removePackage"
                          onClick={() => this.removePackage(5)}
                          src={require("../../assets/images/delete_bin.svg")}
                        />
                        <div className="package">
                          <MDBContainer>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">%</span>
                              </MDBCol>
                              <MDBCol md="6" sm="6" xs="6">
                                <input
                                  name="ratio5"
                                  onChange={this.changeInstallmentPrice}
                                  type="number"
                                  min={0}
                                  id="installmentPrice5"
                                  className="form-control packageInput"
                                  style={{
                                    direction: this.props.isRTL ? "rtl" : "ltr",
                                  }}
                                  disabled={
                                    this.state.disabledPackage ? true : false
                                  }
                                  defaultValue={
                                    this.state.ratio5 == 0
                                      ? ""
                                      : this.state.ratio5
                                  }
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.ratio}
                                </span>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.pound}
                                </span>
                              </MDBCol>
                              <MDBCol md="6" sm="6" xs="6">
                                <input
                                  name="firstPaid5"
                                  onChange={this.changeFirstPaid}
                                  id="firstPaid5"
                                  type="number"
                                  min={0}
                                  className="form-control packageInput"
                                  defaultValue={
                                    this.state.firstPaid5 == 0
                                      ? ""
                                      : this.state.firstPaid5
                                  }
                                  style={{
                                    direction: this.props.isRTL ? "rtl" : "ltr",
                                  }}
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.firstPaid}
                                </span>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.months}
                                </span>
                              </MDBCol>
                              <MDBCol md="6" sm="6" xs="6">
                                <input
                                  name="months5"
                                  onChange={this.changeMonth}
                                  id="month5"
                                  type="number"
                                  min={0}
                                  className="form-control packageInput"
                                  defaultValue={
                                    this.state.months5 == 1
                                      ? ""
                                      : this.state.months5
                                  }
                                  style={{
                                    direction: this.props.isRTL ? "rtl" : "ltr",
                                  }}
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.theMonths}
                                </span>
                              </MDBCol>
                            </MDBRow>
                            <hr style={{ margin: "8px 0px 0px 0px" }}></hr>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.pound}
                                </span>
                              </MDBCol>
                              <MDBCol
                                md="6"
                                sm="6"
                                xs="6"
                                style={{ textAlign: "center" }}
                              >
                                <input
                                  type="text"
                                  className="form-control installmentInput"
                                  disabled
                                  value={
                                    this.state.costPerMonth5 == 0
                                      ? ""
                                      : this.state.costPerMonth5
                                  }
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.installment}
                                </span>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.pound}
                                </span>
                              </MDBCol>
                              <MDBCol
                                md="6"
                                sm="6"
                                xs="6"
                                style={{ textAlign: "center" }}
                              >
                                <input
                                  type="text"
                                  className="form-control installmentInput"
                                  disabled
                                  value={
                                    this.state.costPerMonth5 == 0
                                      ? ""
                                      : parseInt(
                                          this.state.costPerMonth5 *
                                            this.state.months5
                                        ) + parseInt(this.state.firstPaid5)
                                  }
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.totalCost}
                                </span>
                              </MDBCol>
                            </MDBRow>
                          </MDBContainer>
                        </div>
                      </MDBCol>
                    )}
                    {this.state.packagePlus == 4 && (
                      <MDBCol lg="6" md="12" style={{ textAlign: "center" }}>
                        <span
                          className="addPackage"
                          onClick={() =>
                            this.setState({
                              packageNum: this.state.packageNum + 1,
                              packagePlus: this.state.packagePlus + 1,
                            })
                          }
                        >
                          {allStrings.add}
                        </span>
                        <span
                          onClick={() =>
                            this.setState({
                              packageNum: this.state.packageNum + 1,
                              packagePlus: this.state.packagePlus + 1,
                            })
                          }
                          style={{
                            color: "#31313F",
                            fontSize: "50px",
                            marginTop: "30px",
                            cursor: "pointer",
                          }}
                          class="material-icons"
                        >
                          add_circle
                        </span>
                      </MDBCol>
                    )}
                    {this.state.packageNum > 5 && (
                      <MDBCol lg="6" md="12">
                        <img
                          alt=""
                          className="removePackage"
                          onClick={() => this.removePackage(6)}
                          src={require("../../assets/images/delete_bin.svg")}
                        />
                        <div className="package">
                          <MDBContainer>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">%</span>
                              </MDBCol>
                              <MDBCol md="6" sm="6" xs="6">
                                <input
                                  name="ratio6"
                                  onChange={this.changeInstallmentPrice}
                                  type="number"
                                  min={0}
                                  id="installmentPrice6"
                                  className="form-control packageInput"
                                  style={{
                                    direction: this.props.isRTL ? "rtl" : "ltr",
                                  }}
                                  disabled={
                                    this.state.disabledPackage ? true : false
                                  }
                                  defaultValue={
                                    this.state.ratio6 == 0
                                      ? ""
                                      : this.state.ratio6
                                  }
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.ratio}
                                </span>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.pound}
                                </span>
                              </MDBCol>
                              <MDBCol md="6" sm="6" xs="6">
                                <input
                                  name="firstPaid6"
                                  onChange={this.changeFirstPaid}
                                  id="firstPaid6"
                                  type="number"
                                  min={0}
                                  className="form-control packageInput"
                                  defaultValue={
                                    this.state.firstPaid6 == 0
                                      ? ""
                                      : this.state.firstPaid6
                                  }
                                  style={{
                                    direction: this.props.isRTL ? "rtl" : "ltr",
                                  }}
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.firstPaid}
                                </span>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.months}
                                </span>
                              </MDBCol>
                              <MDBCol md="6" sm="6" xs="6">
                                <input
                                  name="months6"
                                  onChange={this.changeMonth}
                                  id="month6"
                                  type="number"
                                  min={0}
                                  className="form-control packageInput"
                                  defaultValue={
                                    this.state.months6 == 1
                                      ? ""
                                      : this.state.months6
                                  }
                                  style={{
                                    direction: this.props.isRTL ? "rtl" : "ltr",
                                  }}
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.theMonths}
                                </span>
                              </MDBCol>
                            </MDBRow>
                            <hr style={{ margin: "8px 0px 0px 0px" }}></hr>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.pound}
                                </span>
                              </MDBCol>
                              <MDBCol
                                md="6"
                                sm="6"
                                xs="6"
                                style={{ textAlign: "center" }}
                              >
                                <input
                                  type="text"
                                  className="form-control installmentInput"
                                  disabled
                                  value={
                                    this.state.costPerMonth6 == 0
                                      ? ""
                                      : this.state.costPerMonth6
                                  }
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.installment}
                                </span>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "right" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.pound}
                                </span>
                              </MDBCol>
                              <MDBCol
                                md="6"
                                sm="6"
                                xs="6"
                                style={{ textAlign: "center" }}
                              >
                                <input
                                  type="text"
                                  className="form-control installmentInput"
                                  disabled
                                  value={
                                    this.state.costPerMonth6 == 0
                                      ? ""
                                      : parseInt(
                                          this.state.costPerMonth6 *
                                            this.state.months6
                                        ) + parseInt(this.state.firstPaid6)
                                  }
                                />
                              </MDBCol>
                              <MDBCol
                                md="3"
                                sm="3"
                                xs="3"
                                className="noPadding"
                                style={{ textAlign: "left" }}
                              >
                                <span className="packageLabel">
                                  {allStrings.totalCost}
                                </span>
                              </MDBCol>
                            </MDBRow>
                          </MDBContainer>
                        </div>
                      </MDBCol>
                    )}
                    {this.state.packagePlus == 5 && (
                      <MDBCol lg="6" md="12" style={{ textAlign: "center" }}>
                        <span
                          className="addPackage"
                          onClick={() =>
                            this.setState({
                              packageNum: this.state.packageNum + 1,
                              packagePlus: this.state.packagePlus + 1,
                            })
                          }
                        >
                          {allStrings.add}
                        </span>
                        <span
                          onClick={() =>
                            this.setState({
                              packageNum: this.state.packageNum + 1,
                              packagePlus: this.state.packagePlus + 1,
                            })
                          }
                          style={{
                            color: "#31313F",
                            fontSize: "50px",
                            marginTop: "30px",
                            cursor: "pointer",
                          }}
                          class="material-icons"
                        >
                          add_circle
                        </span>
                      </MDBCol>
                    )}
                  </MDBRow>
                )}
                <br></br>
                <br></br>
                <MDBRow>
                  {this.state.installementSystem == true && (
                    <MDBCol md="4" sm="4" xs="4">
                      <Checkbox
                        style={{ float: "right", marginTop: "5px" }}
                        defaultChecked={
                          this.state.installment
                            ? this.state.installment
                            : false
                        }
                        onChange={(e) => {
                          console.log(e.target.checked);
                          this.setState({ installment: e.target.checked });
                        }}
                      >
                        <span className="grey-text formLabel">
                          {allStrings.installment}
                        </span>
                      </Checkbox>
                    </MDBCol>
                  )}
                  {this.state.installementSystem == true && (
                    <MDBCol md="4" sm="4" xs="4">
                      <Checkbox
                        style={{ float: "right", marginTop: "5px" }}
                        defaultChecked={
                          this.state.cash ? this.state.cash : false
                        }
                        onChange={(e) => {
                          console.log(e.target.checked);
                          this.setState({ cash: e.target.checked });
                        }}
                      >
                        <span className="grey-text formLabel">
                          {allStrings.cash}
                        </span>
                      </Checkbox>
                    </MDBCol>
                  )}
                  <MDBCol md="4" sm="4" xs="4">
                    <Checkbox
                      style={{ float: "right", marginTop: "5px" }}
                      defaultChecked={
                        this.state.visible ? this.state.visible : false
                      }
                      onChange={(e) => {
                        console.log(e.target.checked);
                        this.setState({ visible: e.target.checked });
                      }}
                    >
                      <span className="grey-text formLabel">
                        {allStrings.show}
                      </span>
                    </Checkbox>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  {/*} <Popconfirm
                    title={allStrings.areYouSureStock}
                    onConfirm={this.OKBUTTON}
                    onCancel={this.fCANCELBUTTON}
                    okText={allStrings.ok}
                    cancelText={allStrings.cancel}
                  >*/}
                  <MDBBtn
                    type="submit"
                    style={{
                      margin: "1rem",
                      width: "150px",
                      padding: "9px",
                      height: "35px",
                    }}
                    className="btn z-depth-1a "
                    color="primary"
                  >
                    {this.state.requestType == "post"
                      ? allStrings.add
                      : allStrings.edit}
                  </MDBBtn>
                  {/*</Popconfirm>*/}
                </MDBRow>
              </MDBCol>
              <MDBCol xl="6" lg="6" md="6" sm="12">
                <div style={{ borderLeft: "2px solid" }}>
                  <MDBContainer>
                    <MDBRow>
                      <MDBCol md="9" sm="9">
                        {this.state.img == null && (
                          <div className="smallUploadContainer">
                            <img
                              alt=""
                              name="img"
                              className="smallUploderImg"
                              src={require("../../assets/images/Path 343.png")}
                            ></img>
                            <input
                              onChange={this.fileHandlerImg}
                              type="file"
                              id="defaultFormRegisterNameEx3"
                              required
                              className="smallUploader"
                            />
                            <MDBBtn
                              color="primary"
                              size="sm"
                              className="smallUploaderBtn"
                            >
                              {allStrings.chooseFile}
                            </MDBBtn>
                            <p
                              style={{
                                fontSize: "14px",
                                marginTop: "3px 15px",
                                float: "right",
                                paddingTop: "4px",
                              }}
                              className="grey-text"
                            >
                              {allStrings.dropHere}
                            </p>
                          </div>
                        )}
                        <div className="previewRaduis">
                          {this.state.img != null && (
                            <MDBRow>
                              <MDBCol md="3">
                                <div
                                  style={{
                                    paddingTop: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.setState({ isOpen1: true })
                                  }
                                >
                                  <span
                                    style={{
                                      fontSize: "17px",
                                      color: "#478DFF",
                                      fontWeight: "600",
                                      margin: "5px",
                                    }}
                                  >
                                    {allStrings.showD}
                                  </span>
                                  <img
                                    alt=""
                                    className="showImge"
                                    width="20px"
                                    src={require("../../assets/images/icons8_external_link_480px_2.png")}
                                  ></img>
                                </div>
                              </MDBCol>
                              <MDBCol md="9" style={{ paddingRight: "30px" }}>
                                {this.state.img != null && (
                                  <div
                                    style={{
                                      display: "inline-block",
                                      float: "right",
                                    }}
                                  >
                                    <img
                                      alt=""
                                      width="220px"
                                      src={
                                        this.state.imgType == "data"
                                          ? URL.createObjectURL(this.state.img)
                                          : this.state.img
                                      }
                                    />
                                    <MDBIcon
                                      icon="times"
                                      className="mr-2 removeImg"
                                      onClick={() => this.removeImg()}
                                    ></MDBIcon>
                                  </div>
                                )}
                              </MDBCol>
                            </MDBRow>
                          )}
                          {isOpen1 && (
                            <Lightbox
                              mainSrc={img[photoIndex]}
                              nextSrc={img[(photoIndex + 1) % img.length]}
                              prevSrc={
                                img[(photoIndex + img.length - 1) % img.length]
                              }
                              onCloseRequest={() =>
                                this.setState({ isOpen1: false, photoIndex: 0 })
                              }
                              onMovePrevRequest={() =>
                                this.setState({
                                  photoIndex:
                                    (photoIndex + img.length - 1) % img.length,
                                })
                              }
                              onMoveNextRequest={() =>
                                this.setState({
                                  photoIndex: (photoIndex + 1) % img.length,
                                })
                              }
                            />
                          )}
                        </div>
                      </MDBCol>
                      <MDBCol md="3" sm="3">
                        <label
                          htmlFor="defaultFormRegisterNameEx3"
                          className="grey-text formLabel"
                        >
                          {allStrings.mainImg}
                        </label>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="9" sm="9">
                        <TreeSelect
                          value={this.state.category}
                          showSearch={false}
                          style={{
                            width: "100%",
                            textAlign: this.props.isRTL ? "right" : "left",
                          }}
                          treeCheckable={false}
                          treeNodeFilterProp="title"
                          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                          placeholder={allStrings.category}
                          onChange={(value) => {
                            console.log(value);
                            this.setState({ category: value });
                          }}
                        >
                          {this.state.categories.map((val) => (
                            <TreeNode
                              value={val.id}
                              title={
                                this.props.isRTL
                                  ? val.categoryName_ar
                                  : val.categoryName_en
                              }
                              key={val.id}
                            />
                          ))}
                        </TreeSelect>
                      </MDBCol>
                      <MDBCol md="3" sm="3">
                        <label
                          htmlFor="defaultFormRegisterNameEx1"
                          className="grey-text formLabel"
                        >
                          {allStrings.category}
                        </label>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="9" sm="9">
                        <input
                          name="name_ar"
                          onChange={this.changeHandler}
                          type="text"
                          id="defaultFormRegisterNameEx10"
                          required
                          placeholder={allStrings.writeArabicName}
                          className="form-control"
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                          defaultValue={this.state.product.name_ar}
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3">
                        <label
                          htmlFor="defaultFormRegisterNameEx1"
                          className="grey-text formLabel"
                        >
                          {allStrings.arabicProductName}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    {/*}
                  <MDBRow>
                    <MDBCol md="9" sm="9">
                      <input name="name_en" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx2" required
                        placeholder={allStrings.writeEnglishName} defaultValue={this.state.name_en} className="form-control" style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.product.name_en} />
                    </MDBCol>
                    <MDBCol md="3" sm="3">
                      <label  htmlFor="defaultFormRegisterNameEx2" className="grey-text formLabel">
                      {allStrings.englishProductName}
                      </label>
                    </MDBCol>
                  </MDBRow>
                      */}
                    <MDBRow>
                      <MDBCol md="9" sm="9">
                        {this.state.imgs == null && (
                          <div className="smallUploadContainer">
                            <img
                              alt=""
                              name="img"
                              className="smallUploderImg"
                              src={require("../../assets/images/Path 343.png")}
                            ></img>
                            <input
                              multiple
                              onChange={this.fileHandlerImgs}
                              type="file"
                              id="defaultFormRegisterNameEx003"
                              className="smallUploader"
                            />
                            <MDBBtn
                              color="primary"
                              size="sm"
                              className="smallUploaderBtn"
                            >
                              {allStrings.chooseFile}
                            </MDBBtn>
                            <p
                              style={{
                                fontSize: "14px",
                                marginTop: "3px 15px",
                                float: "right",
                                paddingTop: "4px",
                              }}
                              className="grey-text"
                            >
                              {allStrings.dropHere}
                            </p>
                          </div>
                        )}

                        {this.state.imgs != null && (
                          <div className="previewRaduis">
                            {this.state.imgs != null && (
                              <MDBRow>
                                <MDBCol md="3">
                                  <div
                                    style={{
                                      paddingTop: "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      this.setState({ isOpen2: true })
                                    }
                                  >
                                    <span
                                      style={{
                                        fontSize: "17px",
                                        color: "#478DFF",
                                        fontWeight: "600",
                                        margin: "5px",
                                      }}
                                    >
                                      {allStrings.showD}
                                    </span>
                                    <img
                                      alt=""
                                      className="showImge"
                                      width="20px"
                                      src={require("../../assets/images/icons8_external_link_480px_2.png")}
                                    ></img>
                                  </div>
                                </MDBCol>
                                <MDBCol md="9" style={{ paddingRight: "30px" }}>
                                  {this.state.imgsViews.map((val) => (
                                    <div
                                      style={{
                                        display: "inline-block",
                                        float: "right",
                                        marginBottom: "1rem",
                                      }}
                                    >
                                      <img
                                        alt=""
                                        src={
                                          this.state.imgsType == "data"
                                            ? val
                                            : val
                                        }
                                      />
                                      <MDBIcon
                                        icon="times"
                                        className="mr-2 removeImg"
                                        onClick={() => this.removeImgs(val)}
                                      ></MDBIcon>
                                    </div>
                                  ))}
                                </MDBCol>
                              </MDBRow>
                            )}
                            {isOpen2 && (
                              <Lightbox
                                mainSrc={imgs[photoIndex]}
                                nextSrc={imgs[(photoIndex + 1) % imgs.length]}
                                prevSrc={
                                  imgs[
                                    (photoIndex + imgs.length - 1) % imgs.length
                                  ]
                                }
                                onCloseRequest={() =>
                                  this.setState({
                                    isOpen2: false,
                                    photoIndex: 0,
                                  })
                                }
                                onMovePrevRequest={() =>
                                  this.setState({
                                    photoIndex:
                                      (photoIndex + imgs.length - 1) %
                                      imgs.length,
                                  })
                                }
                                onMoveNextRequest={() =>
                                  this.setState({
                                    photoIndex: (photoIndex + 1) % imgs.length,
                                  })
                                }
                              />
                            )}
                          </div>
                        )}
                      </MDBCol>
                      <MDBCol md="3" sm="3">
                        <label
                          htmlFor="defaultFormRegisterNameEx3"
                          className="grey-text formLabel"
                        >
                          {allStrings.imgsGroup}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol
                        md="2"
                        sm="2"
                        style={{ cursor: "pointer" }}
                        onClick={this.openMinToSalesMan}
                      >
                        <span style={{ color: "#f21414" }}>خصم</span>
                        <img
                          src={require("../../assets/images/minu.png")}
                          style={{ width: "20px" }}
                        />
                      </MDBCol>
                      <MDBCol
                        md="2"
                        sm="2"
                        style={{ cursor: "pointer" }}
                        onClick={this.openAddToStockHis}
                      >
                        <span style={{ color: "#329832" }}>اضافة</span>
                        <img
                          src={require("../../assets/images/plus.png")}
                          style={{ width: "20px" }}
                        />
                      </MDBCol>
                      <MDBCol md="5" sm="5">
                        <input
                          name="quantity"
                          disabled
                          onChange={this.changeHandler}
                          type="number"
                          min={0}
                          id="defaultFormRegisterNameEx25"
                          required
                          placeholder={allStrings.EnterQuantity}
                          className="form-control"
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                          defaultValue={this.state.quantity}
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3">
                        <label
                          htmlFor="defaultFormRegisterNameEx25"
                          className="grey-text formLabel"
                        >
                          {allStrings.quantity}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="9" sm="9">
                        <input
                          name="purchasePrice"
                          onChange={this.changePurchase}
                          type="number"
                          min={0}
                          id="defaultFormRegisterNameEx27"
                          required
                          placeholder={allStrings.EnterPrice}
                          className="form-control"
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                          defaultValue={this.state.product.purchasePrice}
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3">
                        <label
                          htmlFor="defaultFormRegisterNameEx27"
                          className="grey-text formLabel"
                        >
                          {allStrings.purchasePrice}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="9" sm="9">
                        <input
                          name="cashPrice"
                          onChange={this.changeHandler}
                          type="number"
                          min={0}
                          id="defaultFormRegisterNameEx28"
                          required
                          placeholder={allStrings.EnterPrice}
                          className="form-control"
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                          defaultValue={this.state.product.cashPrice}
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3">
                        <label
                          htmlFor="defaultFormRegisterNameEx28"
                          className="grey-text formLabel"
                        >
                          {allStrings.cashPrice}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="9" sm="9">
                        <input
                          name="commission"
                          onChange={this.changeHandler}
                          type="number"
                          min={0}
                          id="defaultFormRegisterNameEx29"
                          required
                          placeholder={allStrings.EnterPrice}
                          className="form-control"
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                          defaultValue={this.state.product.commission}
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3">
                        <label
                          htmlFor="defaultFormRegisterNameEx29"
                          className="grey-text formLabel"
                        >
                          {allStrings.commission}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <br></br>
                    <MDBRow>
                      <MDBCol md="9" sm="9">
                        <MDBContainer>
                          <MDBRow>
                            <MDBCol md="8" className="noPadding">
                              <input
                                name="attr1"
                                onChange={this.changeHandler}
                                type="text"
                                id="dfaultFormisterNameEx2"
                                placeholder={allStrings.description}
                                defaultValue={this.state.attr1}
                                className="form-control"
                                style={{
                                  direction: this.props.isRTL ? "rtl" : "ltr",
                                  height: "28px",
                                }}
                              />
                            </MDBCol>
                            <MDBCol
                              md="4"
                              className="noPadding"
                              style={{ paddingLeft: "5px" }}
                            >
                              <input
                                name="value1"
                                defaultValue={this.state.value1}
                                onChange={this.changeHandler}
                                type="text"
                                id="defaultFormRegisterNameEx2"
                                placeholder={allStrings.property}
                                className="form-control"
                                style={{
                                  direction: this.props.isRTL ? "rtl" : "ltr",
                                  height: "28px",
                                }}
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol md="8" className="noPadding">
                              <input
                                name="attr2"
                                onChange={this.changeHandler}
                                type="text"
                                id="defaultFormRegisterNameEx3"
                                placeholder={allStrings.description}
                                defaultValue={this.state.attr2}
                                className="form-control"
                                style={{
                                  direction: this.props.isRTL ? "rtl" : "ltr",
                                  height: "28px",
                                }}
                              />
                            </MDBCol>
                            <MDBCol
                              md="4"
                              className="noPadding"
                              style={{ paddingLeft: "5px" }}
                            >
                              <input
                                name="value2"
                                onChange={this.changeHandler}
                                type="text"
                                id="defaultFormReterNameEx3"
                                placeholder={allStrings.property}
                                defaultValue={this.state.attr2}
                                className="form-control"
                                style={{
                                  direction: this.props.isRTL ? "rtl" : "ltr",
                                  height: "28px",
                                }}
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol md="8" className="noPadding">
                              <input
                                name="attr3"
                                onChange={this.changeHandler}
                                type="text"
                                id="defaultFormRegisterNameE2"
                                placeholder={allStrings.description}
                                defaultValue={this.state.attr3}
                                className="form-control"
                                style={{
                                  direction: this.props.isRTL ? "rtl" : "ltr",
                                  height: "28px",
                                }}
                              />
                            </MDBCol>
                            <MDBCol
                              md="4"
                              className="noPadding"
                              style={{ paddingLeft: "5px" }}
                            >
                              <input
                                name="value3"
                                onChange={this.changeHandler}
                                type="text"
                                id="defaultFormRegameE2"
                                placeholder={allStrings.property}
                                defaultValue={this.state.attr3}
                                className="form-control"
                                style={{
                                  direction: this.props.isRTL ? "rtl" : "ltr",
                                  height: "28px",
                                }}
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol md="8" className="noPadding">
                              <input
                                name="attr4"
                                onChange={this.changeHandler}
                                type="text"
                                id="defaultFormRegisterNmeEx2"
                                placeholder={allStrings.description}
                                defaultValue={this.state.attr4}
                                className="form-control"
                                style={{
                                  direction: this.props.isRTL ? "rtl" : "ltr",
                                  height: "28px",
                                }}
                              />
                            </MDBCol>
                            <MDBCol
                              md="4"
                              className="noPadding"
                              style={{ paddingLeft: "5px" }}
                            >
                              <input
                                name="value4"
                                onChange={this.changeHandler}
                                type="text"
                                id="defaultFormRegisx2"
                                placeholder={allStrings.property}
                                defaultValue={this.state.attr4}
                                className="form-control"
                                style={{
                                  direction: this.props.isRTL ? "rtl" : "ltr",
                                  height: "28px",
                                }}
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol md="8" className="noPadding">
                              <input
                                name="attr5"
                                onChange={this.changeHandler}
                                type="text"
                                id="defaultFormRegiserNameEx2"
                                placeholder={allStrings.description}
                                defaultValue={this.state.attr5}
                                className="form-control"
                                style={{
                                  direction: this.props.isRTL ? "rtl" : "ltr",
                                  height: "28px",
                                }}
                              />
                            </MDBCol>
                            <MDBCol
                              md="4"
                              className="noPadding"
                              style={{ paddingLeft: "5px" }}
                            >
                              <input
                                name="value5"
                                onChange={this.changeHandler}
                                type="text"
                                id="defaultFormRiserNameEx2"
                                placeholder={allStrings.property}
                                defaultValue={this.state.attr5}
                                className="form-control"
                                style={{
                                  direction: this.props.isRTL ? "rtl" : "ltr",
                                  height: "28px",
                                }}
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol md="8" className="noPadding">
                              <input
                                name="attr6"
                                onChange={this.changeHandler}
                                type="text"
                                id="defaultFormRegiterNameEx2"
                                placeholder={allStrings.description}
                                defaultValue={this.state.attr6}
                                className="form-control"
                                style={{
                                  direction: this.props.isRTL ? "rtl" : "ltr",
                                  height: "28px",
                                }}
                              />
                            </MDBCol>
                            <MDBCol
                              md="4"
                              className="noPadding"
                              style={{ paddingLeft: "5px" }}
                            >
                              <input
                                name="value6"
                                onChange={this.changeHandler}
                                type="text"
                                id="defaultFoRegiterNameEx2"
                                placeholder={allStrings.property}
                                defaultValue={this.state.attr6}
                                className="form-control"
                                style={{
                                  direction: this.props.isRTL ? "rtl" : "ltr",
                                  height: "28px",
                                }}
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol md="8" className="noPadding">
                              <input
                                name="attr7"
                                onChange={this.changeHandler}
                                type="text"
                                id="defaultFormRegierNameEx2"
                                placeholder={allStrings.description}
                                defaultValue={this.state.attr7}
                                className="form-control"
                                style={{
                                  direction: this.props.isRTL ? "rtl" : "ltr",
                                  height: "28px",
                                }}
                              />
                            </MDBCol>
                            <MDBCol
                              md="4"
                              className="noPadding"
                              style={{ paddingLeft: "5px" }}
                            >
                              <input
                                name="value7"
                                onChange={this.changeHandler}
                                type="text"
                                id="defaultrmRegierNameEx2"
                                placeholder={allStrings.property}
                                defaultValue={this.state.value7}
                                className="form-control"
                                style={{
                                  direction: this.props.isRTL ? "rtl" : "ltr",
                                  height: "28px",
                                }}
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol md="8" className="noPadding">
                              <input
                                name="attr8"
                                onChange={this.changeHandler}
                                type="text"
                                id="defauFormRegisterNameEx2"
                                placeholder={allStrings.description}
                                defaultValue={this.state.attr8}
                                className="form-control"
                                style={{
                                  direction: this.props.isRTL ? "rtl" : "ltr",
                                  height: "28px",
                                }}
                              />
                            </MDBCol>
                            <MDBCol
                              md="4"
                              className="noPadding"
                              style={{ paddingLeft: "5px" }}
                            >
                              <input
                                name="value8"
                                onChange={this.changeHandler}
                                type="text"
                                id="defauFormRegisterameEx2"
                                placeholder={allStrings.property}
                                defaultValue={this.state.value8}
                                className="form-control"
                                style={{
                                  direction: this.props.isRTL ? "rtl" : "ltr",
                                  height: "28px",
                                }}
                              />
                            </MDBCol>
                          </MDBRow>
                        </MDBContainer>
                      </MDBCol>
                      <MDBCol md="3" sm="3">
                        <label
                          htmlFor="defaultFormRegisteameEx2"
                          className="grey-text formLabel"
                        >
                          {allStrings.Properties}
                        </label>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </div>
              </MDBCol>
            </MDBRow>
          </form>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = (state) => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  branch: state.company.branch,
  company: state.company.company,
});

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
};

export default withRouter(connect(mapToStateProps, mapDispatchToProps)(Login));
