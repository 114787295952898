/* eslint-disable eqeqeq */
import React from "react";
import "./multi sale.css";
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon } from "mdbreact";
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { setUser } from "../../redux/actions/AuthActions";
//import {hasValue} from '../../validations/validations'
import { message, TreeSelect, DatePicker, ConfigProvider } from "antd";
import { ChangeLanguage } from "../../redux/actions/LanguageAction";
import { ChangeCompany, ChangeBranch } from "../../redux/actions/CompanyAction";
import moment from "moment";
import locale_ar from "antd/lib/locale/ar_EG";
import locale_en from "antd/lib/locale/en_GB";
import "moment/locale/ar";
const { TreeNode } = TreeSelect;
const { SHOW_PARENT } = TreeSelect;

let paidDate, purchaseDate;
class multiSale extends React.Component {
  state = {
    paidDate: this.props.location.state
      ? this.props.location.state.order
        ? this.props.location.state.order.startDate
          ? moment(this.props.location.state.order.startDate).format(
              "YYYY/MM/DD"
            )
          : moment().format("YYYY/MM/DD")
        : moment().format("YYYY/MM/DD")
      : moment().format("YYYY/MM/DD"),
    purchaseDate: this.props.location.state
      ? this.props.location.state.order
        ? this.props.location.state.order.startDate
          ? moment(this.props.location.state.order.startDate).format(
              "YYYY/MM/DD"
            )
          : moment().format("YYYY/MM/DD")
        : moment().format("YYYY/MM/DD")
      : moment().format("YYYY/MM/DD"),
    client: this.props.location.state ? this.props.location.state.client : [],
    order: this.props.location.state ? this.props.location.state.order : [],
    salesMan: this.props.location.state
      ? this.props.location.state.order
        ? this.props.location.state.order.salesMan.id
        : null
      : null,
    buySalesMan: [],
    buySalesManIds: [],
    paymentSystem: this.props.location.state
      ? this.props.location.state.order
        ? this.props.location.state.order.paymentSystem
        : "cash"
      : "cash",
    total: this.props.location.state
      ? this.props.location.state.order
        ? this.props.location.state.order.total
        : 0
      : 0,
    firstPaid: 0,
    months: 0,
    costPerMonth: 0,
    multiProducts: [{ product: null, count: 1, price: 0, cost: 0 }],
    requestType: this.props.location.state.requestType
      ? this.props.location.state.requestType
      : "post",
    loading: true,
    refresh: false,
    page: 1,
    pages: 1,
    salesMen: [],
    products: [],
    installmentPrice: 0,
  };

  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    console.log("isr   ", this.props.location);
    this.getProducts();
    this.getSalesMen();
    if (this.props.isRTL) {
      allStrings.setLanguage("ar");
    } else {
      allStrings.setLanguage("en");
    }
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.state.order) {
      if (this.state.order.paymentSystem == "installment") {
        this.setState({
          firstPaid: this.props.location.state.order.firstPaid,
          months: this.props.location.state.order.monthCount,
          costPerMonth: this.props.location.state.order.costPerMonth,
        });
      }
      let buySalesManIds = [];
      this.state.order.multiBuySalesMen.forEach((element) => {
        buySalesManIds.push(element.buySalesMan.id);
      });
      console.log("buySalesMen", buySalesManIds);
      this.setState({ buySalesMan: buySalesManIds });
      //multi products
      let multiProducts = [];
      this.state.order.multiProducts.forEach((element) => {
        multiProducts.push({
          product: element.product.id,
          price: element.price ? element.price : 0,
          count: element.count,
          cost: element.cost,
        });
      });
      console.log("multiProducts", multiProducts);
      this.setState({ multiProducts: multiProducts });
    }

    console.log(moment().format("YYYY/MM/DD"));
    this.setState({ paidDate: moment().format("YYYY/MM/DD") });
    if (this.props.isRTL) {
      this.setState({ paidDate: moment().format("YYYY/MM/DD") });
    } else {
      this.setState({ paidDate: moment().locale("en").format("YYYY/MM/DD") });
    }
    paidDate = moment().locale("en").format("YYYY-MM-DD");
    //purchaseDate
    this.setState({ purchaseDate: moment().format("YYYY/MM/DD") });
    if (this.props.isRTL) {
      this.setState({ purchaseDate: moment().format("YYYY/MM/DD") });
    } else {
      this.setState({
        purchaseDate: moment().locale("en").format("YYYY/MM/DD"),
      });
    }
    purchaseDate = moment().locale("en").format("YYYY-MM-DD");
  }
  addOrder = () => {
    console.log("fire");
    const {
      multiProducts,
      buySalesMan,
      salesMan,
      total,
      paymentSystem,
      purchaseDate,
      paidDate,
      firstPaid,
      months,
      costPerMonth,
    } = this.state;

    if (multiProducts.length > 0 && buySalesMan != null) {
      let l = message.loading("Wait..", 2.5);
      var data = new FormData();
      data.append("destination", JSON.stringify([1.3, 1.5]));
      let allProducts = [
        ...new Set(multiProducts.filter((e) => e.product != null)),
      ];

      data.append("multiProducts", JSON.stringify(allProducts));
      data.append("total", total);
      data.append("paymentSystem", paymentSystem);
      data.append("status", "ACCEPTED");
      data.append(
        "paidDate",
        moment(paidDate).locale("en").format("YYYY-MM-DD")
      );
      if (paymentSystem == "installment") {
        data.append("salesMan", salesMan);
        data.append("firstPaid", firstPaid);
        data.append("monthCount", months);
        data.append("costPerMonth", costPerMonth);
      } else {
        data.append("salesMan", buySalesMan[0]);
      }
      data.append("buySalesMan", buySalesMan[0]);
      data.append(
        "multiBuySalesMan",
        JSON.stringify([...new Set(buySalesMan)])
      );
      if (
        this.props.currentUser.user.type === "ADMIN" ||
        this.props.currentUser.user.type === "SUB-ADMIN"
      ) {
        if (this.props.company === false) {
          data.append("company", this.props.currentUser.user.company[0].id);
        } else {
          data.append("company", this.props.company);
        }
      } else {
        data.append("company", this.props.currentUser.user.id);
      }
      data.append(
        "startDate",
        moment(purchaseDate).locale("en").format("YYYY-MM-DD")
      );
      console.log(Array.from(data));
      axios
        .post(
          `${BASE_END_POINT}orders/${this.state.client.user.id}/multiSale`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${this.props.currentUser.token}`,
            },
          }
        )
        .then((response) => {
          l.then(() => message.success(allStrings.done, 2.5));
          this.props.history.goBack();
        })
        .catch((error) => {
          console.log(error.response);
          if (
            error.response.data.errors === "the product count is not available"
          ) {
            l.then(() => message.error(allStrings.productNotAvailable, 2.5));
          } else {
            l.then(() => message.error(allStrings.error, 2.5));
          }
        });
    }
  };
  updateOrder = () => {
    const {
      multiProducts,
      buySalesMan,
      salesMan,
      total,
      paymentSystem,
      purchaseDate,
      paidDate,
      firstPaid,
      months,
      costPerMonth,
    } = this.state;
    if (multiProducts.length > 0) {
      let l = message.loading("Wait..", 2.5);
      var data = new FormData();
      data.append("destination", JSON.stringify([1.3, 1.5]));
      let allProducts = [
        ...new Set(multiProducts.filter((e) => e.product != null)),
      ];

      data.append("multiProducts", JSON.stringify(allProducts));
      data.append("total", total);
      data.append("paymentSystem", paymentSystem);
      data.append("status", "ACCEPTED");
      data.append(
        "paidDate",
        moment(paidDate).locale("en").format("YYYY-MM-DD")
      );
      if (paymentSystem == "installment") {
        data.append("salesMan", salesMan);
        data.append("firstPaid", firstPaid);
        data.append("monthCount", months);
        data.append("costPerMonth", costPerMonth);
      } else {
        data.append("salesMan", buySalesMan[0]);
      }
      data.append("buySalesMan", buySalesMan[0]);
      data.append(
        "multiBuySalesMan",
        JSON.stringify([...new Set(buySalesMan)])
      );
      if (
        this.props.currentUser.user.type === "ADMIN" ||
        this.props.currentUser.user.type === "SUB-ADMIN"
      ) {
        if (this.props.company === false) {
          data.append("company", this.props.currentUser.user.company[0].id);
        } else {
          data.append("company", this.props.company);
        }
      } else {
        data.append("company", this.props.currentUser.user.id);
      }
      data.append(
        "startDate",
        moment(purchaseDate).locale("en").format("YYYY-MM-DD")
      );
      console.log(Array.from(data));
      axios
        .post(
          `${BASE_END_POINT}orders/${this.state.order.id}/updateOrder/${this.state.client.id}/client`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${this.props.currentUser.token}`,
            },
          }
        )
        .then((response) => {
          l.then(() => message.success(allStrings.done, 2.5));
          this.props.history.goBack();
        })
        .catch((error) => {
          console.log(error.response);
          if (
            error.response.data.errors === "the product count is not available"
          ) {
            l.then(() => message.error(allStrings.productNotAvailable, 2.5));
          } else {
            l.then(() => message.error(allStrings.error, 2.5));
          }
        });
    }
  };
  getSalesMen = () => {
    let url = ``;
    if (
      this.props.currentUser.user.type === "ADMIN" ||
      this.props.currentUser.user.type === "SUB-ADMIN"
    ) {
      if (this.props.company === false) {
        url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN`;
      } else {
        url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN&company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN&company=${this.props.currentUser.user.id}`;
    }
    axios
      .get(url)
      .then((response) => {
        console.log(response.data.users);
        this.setState({ salesMen: response.data.users });
      })
      .catch((error) => {
        console.log("ALL salesMen ERROR");
        console.log(error.response);
      });
  };
  getProducts = () => {
    let url = ``;
    if (
      this.props.currentUser.user.type === "ADMIN" ||
      this.props.currentUser.user.type === "SUB-ADMIN"
    ) {
      if (this.props.company === false) {
        url = `${BASE_END_POINT}products/withoutPagenation/get`;
      } else {
        url = `${BASE_END_POINT}products/withoutPagenation/get?company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}products/withoutPagenation/get?company=${this.props.currentUser.user.id}`;
    }
    axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        this.setState({ products: response.data.data });
      })
      .catch((error) => {
        console.log("ALL products ERROR");
        console.log(error);
      });
  };
  getProductById = (id, count) => {
    axios
      .get(`${BASE_END_POINT}products/${id}`)
      .then((response) => {
        let price = response.data.data.cashPrice;
        let cashPrice = response.data.data.cashPrice;
        console.log("cash", response.data.data.cashPrice * count);
        let installmentPrices = response.data.data.package.map((v) => {
          return v.costPerMonth * v.months + v.firstPaid;
        });
        let installmentPrice = Math.max(...installmentPrices);
        if (this.state.paymentSystem != "cash") {
          price = Math.max(...installmentPrices);
        }
        console.log("price", price);
        console.log("product info", response.data);
        let arr = this.state.multiProducts;
        var index = arr.findIndex((e) => e.product == id);
        console.log("multiProducts", arr);
        console.log("index", index);
        let multiProducts = [...this.state.multiProducts];
        //{product:null,count:1,price:0 , cost:0}
        if (index != -1) {
          multiProducts[index].price = price;
          multiProducts[index].count = count;
          multiProducts[index].cashPrice = cashPrice; //
          multiProducts[index].installmentPrice = installmentPrice; //
          multiProducts[index].cost = price * count;
          multiProducts[index].stockCount = response.data.data.quantity;
          this.setState({ multiProducts });
        }
        let total = 0;
        multiProducts.forEach((element) => {
          total += element.cost;
        });
        this.setState({ total });
      })
      .catch((error) => {
        console.log("ALL product ERROR");
        console.log(error);
      });
  };
  changePaymentStatus(paymentSystem) {
    let multiProducts = [...this.state.multiProducts];
    let total = 0;
    let newMultiProducts = [];
    multiProducts.forEach((element) => {
      newMultiProducts.push({
        product: element.product,
        cashPrice: element.cashPrice,
        count: element.count,
        installmentPrice: element.installmentPrice,
        stockCount: element.stockCount,
        price:
          paymentSystem == "cash"
            ? element.cashPrice
            : element.installmentPrice,
        cost:
          paymentSystem == "cash"
            ? element.cashPrice * element.count
            : element.installmentPrice * element.count,
      });
      total +=
        paymentSystem == "cash"
          ? element.cashPrice * element.count
          : element.installmentPrice * element.count;
    });
    console.log("old multi", multiProducts);
    console.log("new multi", newMultiProducts);
    this.setState({ total: total, multiProducts: newMultiProducts });
  }
  changeMonth = (event) => {
    console.log(this.state.total);
    console.log(this.state.firstPaid);
    console.log(this.state.months);
    let costPerMonth =
      (this.state.total - this.state.firstPaid) / event.target.value;
    costPerMonth = Math.ceil(costPerMonth);
    console.log(costPerMonth);
    let rem = costPerMonth % 5;
    if (rem < 3) {
      costPerMonth = costPerMonth - rem;
    } else {
      let add = 5 - rem;
      costPerMonth = costPerMonth + add;
    }
    this.setState({
      costPerMonth: Math.ceil(costPerMonth),
      [event.target.name]: event.target.value,
    });
  };
  changeFirstPaid = (event) => {
    console.log("costPerMonth", this.state.total);
    let costPerMonth =
      (this.state.total - event.target.value) / this.state.months;
    costPerMonth = Math.ceil(costPerMonth);
    console.log("costPerMonth", costPerMonth);
    let rem = costPerMonth % 5;
    if (rem < 3) {
      costPerMonth = costPerMonth - rem;
    } else {
      let add = 5 - rem;
      costPerMonth = costPerMonth + add;
    }
    this.setState({
      costPerMonth: costPerMonth,
      [event.target.name]: event.target.value,
    });
  };
  changeTotal = (total) => {
    console.log("firstPaid", this.state.firstPaid, "months", this.state.months);
    let costPerMonth = (total - this.state.firstPaid) / this.state.months;
    costPerMonth = Math.ceil(costPerMonth);
    console.log("costPerMonth", costPerMonth);
    let rem = costPerMonth % 5;
    if (rem < 3) {
      costPerMonth = costPerMonth - rem;
    } else {
      let add = 5 - rem;
      costPerMonth = costPerMonth + add;
    }
    if (total == 0) {
      this.setState({
        costPerMonth: 0,
        firstPaid: 0,
        months: 0,
      });
    } else {
      this.setState({
        costPerMonth: Math.ceil(costPerMonth),
      });
    }
  };
  onChangePaidDate = (date, dateString) => {
    if (this.props.isRTL) {
      this.setState({ paidDate: moment(dateString).format("YYYY/MM/DD") });
    } else {
      this.setState({
        paidDate: moment(dateString).locale("en").format("YYYY/MM/DD"),
      });
    }
    paidDate = moment(dateString).locale("en").format("YYYY-MM-DD");
    console.log(paidDate);
  };
  onChangePurchaseDate = (date, dateString) => {
    if (this.props.isRTL) {
      this.setState({ purchaseDate: moment(dateString).format("YYYY/MM/DD") });
    } else {
      this.setState({
        purchaseDate: moment(dateString).locale("en").format("YYYY/MM/DD"),
      });
    }
    purchaseDate = moment(dateString).locale("en").format("YYYY-MM-DD");
    console.log(purchaseDate);
  };

  changeHandler = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  addSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.addOrder();
  };
  updateSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.updateOrder();
  };

  //theOptions inputs
  theProducts() {
    return this.state.multiProducts.map((v, i) => (
      <MDBRow>
        {this.state.requestType == "post" ? (
          <MDBCol md="1" style={{ paddingLeft: 0 }}>
            {i > 0 && (
              <div
                className="minusInput"
                onClick={() => this.removeProdcutInput(i)}
              >
                <MDBIcon
                  icon="times"
                  className="mr-2"
                  style={{ fontSize: "23px" }}
                />
              </div>
            )}
          </MDBCol>
        ) : (
          <MDBCol md="1" style={{ paddingLeft: 0 }}>
            <div
              className="minusInput"
              onClick={() => this.removeProdcutInput(i)}
            >
              <MDBIcon
                icon="times"
                className="mr-2"
                style={{ fontSize: "23px" }}
              />
            </div>
          </MDBCol>
        )}
        <MDBCol md="2" sm="2" style={{ paddingLeft: 0, paddingRight: "5px" }}>
          <label
            htmlFor="defaultFormRegisterNameEx2"
            className="grey-text formLabel"
            style={{ float: "right" }}
          >
            {allStrings.total}
          </label>
          <input
            name="cost"
            onChange={(event) => {
              let multiProducts = [...this.state.multiProducts];
              multiProducts[i].cost = event.target.value;
              console.log("multiProducts", multiProducts);
              let total = 0;
              multiProducts.forEach((element) => {
                total += parseInt(element.cost);
              });
              this.setState({ total });
              this.changeTotal(total);
              this.setState({ multiProducts });
            }}
            min={1}
            type="number"
            id="defaultFormRegisterNameEx2"
            className="form-control"
            value={v.cost}
            placeholder={allStrings.total}
            style={{
              direction: this.props.isRTL ? "rtl" : "ltr",
              border: ".5px solid #aaa",
            }}
          />
        </MDBCol>
        <MDBCol md="2" sm="2" style={{ paddingLeft: 0, paddingRight: "5px" }}>
          <label
            htmlFor="defaultFormRegisterNameEx2"
            className="grey-text formLabel"
            style={{ float: "right" }}
          >
            {this.state.paymentSystem == "cash"
              ? allStrings.cashPrice
              : allStrings.installmentPrice}
          </label>
          <input
            name="price"
            onChange={(event) => {
              let multiProducts = [...this.state.multiProducts];
              multiProducts[i].price = event.target.value;
              if (this.state.paymentSystem == "cash") {
                multiProducts[i].cashPrice = event.target.value;
              } else {
                multiProducts[i].installmentPrice = event.target.value;
              }
              multiProducts[i].cost = event.target.value * v.count;
              let total = 0;
              multiProducts.forEach((element) => {
                total += parseInt(element.cost);
              });
              this.setState({ total });
              console.log("total", total);
              this.changeTotal(total);
              console.log("multiProducts", multiProducts);
              this.setState({ multiProducts });
            }}
            min={1}
            type="number"
            id="defaultFormRegisterNameEx2"
            className="form-control"
            value={
              this.state.requestType == "post"
                ? this.state.paymentSystem == "cash"
                  ? v.cashPrice
                  : v.installmentPrice
                : v.price
            }
            placeholder={allStrings.price}
            style={{
              direction: this.props.isRTL ? "rtl" : "ltr",
              padding: ".3rem .2rem",
              border: ".5px solid #aaa",
            }}
          />
        </MDBCol>
        <MDBCol md="2" sm="2" style={{ paddingLeft: 0, paddingRight: "5px" }}>
          <label
            htmlFor="defaultFormRegisterNameEx2"
            className="grey-text formLabel"
            style={{ float: "right" }}
          >
            {allStrings.count}
          </label>
          <input
            name="count"
            onChange={(event) => {
              let multiProducts = [...this.state.multiProducts];
              multiProducts[i].count = event.target.value;
              multiProducts[i].cost =
                event.target.value * multiProducts[i].price;
              let total = 0;
              multiProducts.forEach((element) => {
                total += parseInt(element.cost);
              });
              this.setState({ total });
              this.changeTotal(total);
              console.log("multiProducts", multiProducts);
              this.setState({ multiProducts });
            }}
            min={1}
            type="number"
            id="defaultFormRegisterNameEx2"
            className="form-control"
            value={v.count}
            placeholder={allStrings.count}
            style={{
              direction: this.props.isRTL ? "rtl" : "ltr",
              padding: ".3rem .2rem",
              border: ".5px solid #aaa",
            }}
          />
        </MDBCol>
        <MDBCol md="2" sm="2" style={{ paddingLeft: 0, paddingRight: "5px" }}>
          <label
            htmlFor="defaultFormRegisterNameEx2"
            className="grey-text formLabel"
            style={{ float: "right" }}
          >
            {allStrings.stockCount}
          </label>
          <input
            name="stockCount"
            disabled
            min={1}
            type="number"
            id="defaultFormRegisterNameEx2"
            className="form-control"
            value={
              this.state.products.find((e) => e.id == v.product)
                ? this.state.products.find((e) => e.id == v.product).quantity
                : 0
            }
            placeholder={allStrings.stockCount}
            style={{
              direction: this.props.isRTL ? "rtl" : "ltr",
              padding: ".3rem .2rem",
              border: ".5px solid #aaa",
            }}
          />
        </MDBCol>
        <MDBCol md="3" sm="3" style={{ paddingLeft: 0 }}>
          <label
            htmlFor="defaultFormRegisterNameEx2"
            className="grey-text formLabel"
            style={{ float: "right" }}
          >
            {allStrings.product}
          </label>
          <TreeSelect
            showSearch={true}
            value={v.product != null ? v.product : allStrings.chooseProduct}
            style={{
              width: "100%",
              textAlign: this.props.isRTL ? "right" : "left",
            }}
            treeCheckable={false}
            treeNodeFilterProp="title"
            dropdownStyle={{ maxHeight: 400, overflow: "auto", left: 1000 }}
            placeholder={allStrings.chooseProduct}
            onChange={(value) => {
              console.log(value);
              let multiProducts = [...this.state.multiProducts];
              multiProducts[i].product = value;
              this.setState({ multiProducts });
              this.getProductById(value, v.count);
            }}
          >
            {/*<TreeNode style={{background:"antiquewhite",color:'#fff !important'}} value="newClient" title={allStrings.newClient} key={allStrings.newClient} />*/}
            {this.state.products.map((val) => (
              <TreeNode
                value={val.id}
                title={this.props.isRTL ? val.name_ar : val.name_en}
                key={val.id}
              />
            ))}
          </TreeSelect>
        </MDBCol>
        {i === this.state.multiProducts.length - 1 &&
          this.state.multiProducts.length && (
            <MDBCol lg="12" md="12" style={{ textAlign: "right" }}>
              <span
                className="addProduct"
                onClick={() =>
                  this.setState((prevState) => ({
                    multiProducts: [
                      ...prevState.multiProducts,
                      { product: null, count: 1, price: 0, cost: 0 },
                    ],
                  }))
                }
              >
                {allStrings.addProduct}
              </span>
              <span
                onClick={() =>
                  this.setState((prevState) => ({
                    multiProducts: [
                      ...prevState.multiProducts,
                      { product: null, count: 1, price: 0, cost: 0 },
                    ],
                  }))
                }
                style={{
                  color: "#31313F",
                  fontSize: "35px",
                  marginTop: "30px",
                  cursor: "pointer",
                }}
                class="material-icons"
              >
                add_circle
              </span>
            </MDBCol>
          )}
      </MDBRow>
    ));
  }

  removeProdcutInput = (i) => {
    let multiProducts = [...this.state.multiProducts];
    console.log(i);
    multiProducts.splice(i, 1);
    console.log("multiProducts", multiProducts);
    this.setState({ multiProducts });
    let total = 0;
    multiProducts.forEach((element) => {
      total += parseInt(element.cost);
    });
    console.log("total", total);
    this.setState({ total });
    this.changeTotal(total);
  };

  render() {
    console.log("requestType", this.state.requestType);
    return (
      <div className="multiSale">
        <MDBContainer>
          <MDBRow>
            <div
              className="top"
              style={{
                width: "100%",
                textAlign:
                  this.state.requestType === "post" ? "center" : "right",
              }}
            >
              <span onClick={() => this.props.history.goBack()}>
                <MDBIcon icon="times" className="closeIcon" />
              </span>
            </div>
          </MDBRow>
          <MDBRow>
            <MDBCol md="4"></MDBCol>
            <MDBCol md="4" style={{ textAlign: "center" }}>
              <label
                htmlFor="defaultFormRegisterNameEx2"
                style={{
                  //float:'right',
                  fontWeight: "800",
                  color: "#43425D",
                  fontSize: "18px",
                  marginBottom: "2rem",
                  //marginRight:'1rem'
                }}
              >
                {allStrings.multiSale}
              </label>
            </MDBCol>
            <MDBCol md="4"></MDBCol>
          </MDBRow>
          <form
            className="needs-validation"
            onSubmit={
              this.state.requestType === "post"
                ? this.addSubmitHandler
                : this.updateSubmitHandler
            }
            noValidate
            style={{ marginLeft: "2rem" }}
          >
            <MDBRow>
              <MDBContainer>
                {/* Check First */}
                <MDBRow>
                  <MDBCol md="5" style={{ float: "left" }}></MDBCol>
                  <MDBCol
                    md="7"
                    style={{ float: "right", paddingRight: "40px" }}
                  >
                    <MDBRow style={{ marginBottom: "6px", marginTop: "6px" }}>
                      <MDBCol md="9" sm="9">
                        <TreeSelect
                          style={{
                            width: "100%",
                            textAlign: this.props.isRTL ? "right" : "left",
                          }}
                          treeCheckable={false}
                          defaultValue={this.state.paymentSystem}
                          disabled={
                            this.state.requestType == "post" ? false : true
                          }
                          treeNodeFilterProp="title"
                          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                          placeholder={allStrings.paymentSystem}
                          onChange={(value) => {
                            console.log(value);
                            this.setState({ paymentSystem: value });
                            this.changePaymentStatus(value);
                          }}
                        >
                          <TreeNode
                            value="cash"
                            title={allStrings.cash}
                            key="cash"
                          />
                          <TreeNode
                            value="installment"
                            title={allStrings.installment}
                            key="installment"
                          />
                        </TreeSelect>
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx1"
                          className="grey-text formLabel"
                        >
                          {allStrings.paymentSystem}
                        </label>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="5" style={{ minHeight: "460px" }}>
                    {/* start left */}

                    <MDBRow
                      style={{ marginBottom: "6px", marginTop: "6px" }}
                      className="theClient"
                    >
                      <MDBCol md="9" sm="9">
                        <input
                          name="client"
                          disabled
                          type="text"
                          id="defaultFormRegisterName"
                          className="form-control"
                          style={{
                            border: ".5px solid #aaa",
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                          defaultValue={
                            this.state.client
                              ? this.state.client.fullname
                              : allStrings.clientName
                          }
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterName"
                          className="grey-text formLabel"
                        >
                          {allStrings.clientName}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow style={{ marginBottom: "6px", marginTop: "6px" }}>
                      <MDBCol md="9" sm="9">
                        <TreeSelect
                          showSearch={true}
                          style={{
                            width: "100%",
                            textAlign: this.props.isRTL ? "right" : "left",
                            height: "100%",
                            minHeight: "35px",
                            marginBottom: "1rem",
                          }}
                          treeCheckable={true}
                          listHeight={300}
                          treeNodeFilterProp="title"
                          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                          showCheckedStrategy={SHOW_PARENT}
                          value={this.state.buySalesMan}
                          placeholder={allStrings.chooseSalesMan}
                          onChange={(value) => {
                            console.log(value);
                            this.setState({ buySalesMan: value });
                          }}
                        >
                          {/*<TreeNode style={{background:"antiquewhite",color:'#fff !important'}} value="newClient" title={allStrings.newClient} key={allStrings.newClient} />*/}
                          {this.state.salesMen.map((val) => (
                            <TreeNode
                              value={val.id}
                              title={val.fullname}
                              key={val.id}
                            />
                          ))}
                        </TreeSelect>
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx1"
                          className="grey-text formLabel"
                        >
                          {allStrings.buySalesMen}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    {this.state.paymentSystem != "cash" && (
                      <MDBRow style={{ marginBottom: "6px", marginTop: "6px" }}>
                        <MDBCol md="9" sm="9">
                          <TreeSelect
                            showSearch={true}
                            defaultValue={this.state.salesMan}
                            style={{
                              width: "100%",
                              textAlign: this.props.isRTL ? "right" : "left",
                            }}
                            treeCheckable={false}
                            treeNodeFilterProp="title"
                            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                            placeholder={allStrings.chooseSalesMan}
                            onChange={(value) => {
                              console.log(value);
                              this.setState({ salesMan: value });
                            }}
                          >
                            {this.state.salesMen.map((val) => (
                              <TreeNode
                                value={val.id}
                                title={val.fullname}
                                key={val.id}
                              />
                            ))}
                          </TreeSelect>
                        </MDBCol>
                        <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                          <label
                            htmlFor="defaultFormRegisterNameEx1"
                            className="grey-text formLabel"
                          >
                            {allStrings.collectSalesMan}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    )}
                    <MDBRow style={{ marginBottom: "6px", marginTop: "6px" }}>
                      <MDBCol md="9" sm="9">
                        <div
                          style={{ marginBottom: "10px", textAlign: "right" }}
                        >
                          <div
                            style={{
                              display: "inline-block",
                              textAlign: "center",
                              marginLeft: this.props.isRTL ? "0" : "-93px",
                            }}
                          >
                            <ConfigProvider
                              locale={this.props.isRTL ? locale_ar : locale_en}
                            >
                              <DatePicker
                                defaultValue={moment(
                                  this.state.purchaseDate,
                                  "YYYY/MM/DD"
                                )}
                                onChange={this.onChangePurchaseDate}
                              />
                            </ConfigProvider>
                            <span className="newSpanM">
                              <img
                                alt=""
                                style={{ marginRight: "4px" }}
                                src={require("../../assets/images/icons8_edit_property.png")}
                              ></img>
                              {this.state.purchaseDate}
                            </span>
                          </div>
                        </div>
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNam2"
                          className="grey-text formLabel"
                        >
                          {allStrings.purchaseDate}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    {this.state.paymentSystem != "cash" && (
                      <MDBRow style={{ marginBottom: "6px", marginTop: "6px" }}>
                        <MDBCol md="9" sm="9">
                          <div
                            style={{ marginBottom: "10px", textAlign: "right" }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                textAlign: "center",
                                marginLeft: this.props.isRTL ? "0" : "-93px",
                              }}
                            >
                              <ConfigProvider
                                locale={
                                  this.props.isRTL ? locale_ar : locale_en
                                }
                              >
                                <DatePicker
                                  defaultValue={moment(
                                    this.state.paidDate,
                                    "YYYY/MM/DD"
                                  )}
                                  onChange={this.onChangePaidDate}
                                />
                              </ConfigProvider>
                              <span className="newSpanM">
                                <img
                                  alt=""
                                  style={{ marginRight: "4px" }}
                                  src={require("../../assets/images/icons8_edit_property.png")}
                                ></img>
                                {this.state.paidDate}
                              </span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                          <label
                            htmlFor="defaultFormRegisterNameEx2"
                            className="grey-text formLabel"
                          >
                            {allStrings.paidDate}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    )}
                    <MDBRow
                      style={{ marginBottom: "6px", marginTop: "6px" }}
                      className="theTotal"
                    >
                      <MDBCol md="9" sm="9">
                        <input
                          name="total"
                          onChange={this.changeHandler}
                          min={1}
                          type="number"
                          id="defaultFormRegisterNameEx2"
                          className="form-control"
                          disabled
                          value={this.state.total}
                          placeholder={allStrings.total}
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                            border: ".5px solid #aaa",
                          }}
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx2"
                          className="grey-text formLabel"
                        >
                          {allStrings.total}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    {this.state.paymentSystem == "installment" && (
                      <MDBRow
                        style={{ marginTop: "6px", marginBottom: "5rem" }}
                      >
                        <MDBCol md="3" sm="3"></MDBCol>
                        <MDBCol md="6" sm="6">
                          <div className="package">
                            <MDBContainer>
                              <MDBRow>
                                <MDBCol
                                  md="3"
                                  sm="3"
                                  xs="3"
                                  className="noPadding"
                                  style={{ textAlign: "right" }}
                                >
                                  <span className="packageLabel">
                                    {allStrings.pound}
                                  </span>
                                </MDBCol>
                                <MDBCol md="6" sm="6" xs="6">
                                  <input
                                    name="firstPaid"
                                    onChange={this.changeFirstPaid}
                                    id="firstPaid"
                                    type="number"
                                    min={0}
                                    className="form-control packageInput"
                                    defaultValue={
                                      this.state.firstPaid == 0
                                        ? ""
                                        : this.state.firstPaid
                                    }
                                    style={{
                                      direction: this.props.isRTL
                                        ? "rtl"
                                        : "ltr",
                                    }}
                                  />
                                </MDBCol>
                                <MDBCol
                                  md="3"
                                  sm="3"
                                  xs="3"
                                  className="noPadding"
                                  style={{ textAlign: "left" }}
                                >
                                  <span className="packageLabel">
                                    {allStrings.firstPaid}
                                  </span>
                                </MDBCol>
                              </MDBRow>
                              <MDBRow>
                                <MDBCol
                                  md="3"
                                  sm="3"
                                  xs="3"
                                  className="noPadding"
                                  style={{ textAlign: "right" }}
                                >
                                  <span className="packageLabel">
                                    {allStrings.months}
                                  </span>
                                </MDBCol>
                                <MDBCol md="6" sm="6" xs="6">
                                  <input
                                    name="months"
                                    onChange={this.changeMonth}
                                    id="month1"
                                    type="number"
                                    min={0}
                                    className="form-control packageInput"
                                    defaultValue={
                                      this.state.months == 0
                                        ? ""
                                        : this.state.months
                                    }
                                    style={{
                                      direction: this.props.isRTL
                                        ? "rtl"
                                        : "ltr",
                                    }}
                                  />
                                </MDBCol>
                                <MDBCol
                                  md="3"
                                  sm="3"
                                  xs="3"
                                  className="noPadding"
                                  style={{ textAlign: "left" }}
                                >
                                  <span className="packageLabel">
                                    {allStrings.theMonths}
                                  </span>
                                </MDBCol>
                              </MDBRow>
                              <hr style={{ margin: "8px 0px 0px 0px" }}></hr>
                              <MDBRow>
                                <MDBCol
                                  md="3"
                                  sm="3"
                                  xs="3"
                                  className="noPadding"
                                  style={{ textAlign: "right" }}
                                >
                                  <span className="packageLabel">
                                    {allStrings.pound}
                                  </span>
                                </MDBCol>
                                <MDBCol
                                  md="6"
                                  sm="6"
                                  xs="6"
                                  style={{ textAlign: "center" }}
                                >
                                  <input
                                    type="text"
                                    className="form-control installmentInput"
                                    style={{ border: "none" }}
                                    disabled
                                    value={
                                      this.state.months == 0
                                        ? ""
                                        : this.state.costPerMonth
                                        ? this.state.costPerMonth == 0
                                          ? ""
                                          : this.state.costPerMonth
                                        : ""
                                    }
                                  />
                                </MDBCol>
                                <MDBCol
                                  md="3"
                                  sm="3"
                                  xs="3"
                                  className="noPadding"
                                  style={{ textAlign: "left" }}
                                >
                                  <span className="packageLabel">
                                    {allStrings.installment}
                                  </span>
                                </MDBCol>
                              </MDBRow>
                            </MDBContainer>
                          </div>
                        </MDBCol>
                        <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                          <label
                            htmlFor="defaultFormRegisterName"
                            className="grey-text formLabel"
                          >
                            {allStrings.installmentPackage}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    )}
                    <MDBRow>
                      <MDBBtn
                        type="submit"
                        style={{
                          margin: "1rem",
                          width: "150px",
                          padding: "9px",
                          height: "35px",
                          position: "absolute",
                          bottom: "0",
                        }}
                        className="btn z-depth-1a "
                        color="primary"
                        disabled={
                          this.state.multiProducts.length > 0 ? false : true
                        }
                      >
                        {this.state.requestType == "post"
                          ? allStrings.add
                          : allStrings.edit}
                      </MDBBtn>
                    </MDBRow>
                  </MDBCol>
                  <MDBCol
                    md="7"
                    style={{
                      borderLeft: "1px solid",
                      minHeight: "600px",
                      paddingRight: "40px",
                    }}
                  >
                    <MDBRow>
                      <MDBCol md="12">
                        <label
                          htmlFor="defaultFormRegisterNameEx2"
                          style={{
                            float: "right",
                            fontWeight: "500",
                            color: "blue",
                            fontSize: "20px",
                            marginBottom: "1rem",
                            marginRight: "1rem",
                          }}
                        >
                          {allStrings.chooseProducts2}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    {/* start right */}
                    {this.theProducts()}
                    {this.state.multiProducts.length == 0 && (
                      <MDBCol lg="12" md="12" style={{ textAlign: "right" }}>
                        <span
                          className="addProduct"
                          onClick={() =>
                            this.setState((prevState) => ({
                              multiProducts: [
                                ...prevState.multiProducts,
                                { product: null, count: 1, price: 0, cost: 0 },
                              ],
                            }))
                          }
                        >
                          {allStrings.addProduct}
                        </span>
                        <span
                          onClick={() =>
                            this.setState((prevState) => ({
                              multiProducts: [
                                ...prevState.multiProducts,
                                { product: null, count: 1, price: 0, cost: 0 },
                              ],
                            }))
                          }
                          style={{
                            color: "#31313F",
                            fontSize: "35px",
                            marginTop: "30px",
                            cursor: "pointer",
                          }}
                          class="material-icons"
                        >
                          add_circle
                        </span>
                      </MDBCol>
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBRow>
          </form>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = (state) => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  branch: state.company.branch,
  company: state.company.company,
});

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
};

export default withRouter(
  connect(mapToStateProps, mapDispatchToProps)(multiSale)
);
