import React from "react";
import "./receivedProducts.css";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { setUser } from "../../redux/actions/AuthActions";
import { hasValue } from "../../validations/validations";
import { message, TreeSelect } from "antd";
import { ChangeLanguage } from "../../redux/actions/LanguageAction";
import LoadCard from "../../component/load card/load card";
import Pagination from "@material-ui/lab/Pagination";
import { ChangeCompany, ChangeBranch } from "../../redux/actions/CompanyAction";
import ReactToPrint from "react-to-print";
import moment from "moment";
import locale_ar from "antd/lib/locale/ar_EG";
import locale_en from "antd/lib/locale/en_GB";
import "moment/locale/ar";
//  enum:['RETRIEVE','RECEIVE','SALE'],

const { TreeNode } = TreeSelect;
//PRINT

//CAT PRINT
//سجل الاصناف المستلمة
class ComponentToPrint extends React.Component {
  render() {
    console.log(this.props);
    return (
      <MDBContainer style={{ direction: "rtl" }}>
        <br></br>
        <br></br>
        <MDBRow style={{ textAlign: "center" }}>
          <MDBCol size="4" style={{ textAlign: "center" }}>
            <img
              alt=""
              style={{ width: "70px", height: "70px", borderRadius: "50%" }}
              src={require("../../assets/images/icons8_pdf.png")}
            ></img>
          </MDBCol>
          <MDBCol size="4">
            <p style={{ fontWeight: "500", fontSize: "24px" }}>
              سجل الاصناف المستلمة
            </p>
          </MDBCol>
          <MDBCol size="4"></MDBCol>
        </MDBRow>
        <br></br>

        <MDBRow style={{ textAlign: "right" }}>
          <MDBCol size="4">
            <p style={{ fontWeight: "500" }}>
              <span> تاريخ الطباعه / </span>
              <span style={{ color: "red", textDecoration: "underline" }}>
                {moment().format("YYYY/MM/DD")}{" "}
              </span>
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="12" sm="12">
            <div className="tabelDev">
              <div class="row">
                <table class="table table-bordered table-sm">
                  <thead>
                    <tr class="thead-light">
                      <th>نوع العملية</th>
                      <th>كود المنتج</th>
                      <th>اسم المنتج</th>
                      <th>الكميه</th>
                      <th>التاريخ</th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.props.data.map((product) => (
                      <tr>
                        <td style={{ padding: "8px" }}>
                          {product.type
                            ? product.type === "RETRIEVE"
                              ? "إرجاع الى المخزن"
                              : product.type === "RECEIVE"
                              ? "تسليم من المخزن"
                              : "بيع للعميل"
                            : ""}
                        </td>
                        <td style={{ padding: "8px" }}>
                          {product.product ? product.product.id : 0}
                        </td>
                        <td style={{ padding: "8px" }}>
                          {product.product ? product.product.name_ar : ""}
                        </td>
                        <td style={{ padding: "8px" }}>
                          {product.count ? product.count : 0}
                        </td>
                        <td style={{ padding: "8px" }}>
                          {product.createdAt
                            ? moment.utc(product.createdAt).format("YYYY/MM/DD")
                            : ""}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

//SALLLLLE
class ComponentToPrintSale extends React.Component {
  render() {
    console.log(this.props);
    return (
      <MDBContainer style={{ direction: "rtl" }}>
        <br></br>
        <br></br>
        <MDBRow style={{ textAlign: "center" }}>
          <MDBCol size="4" style={{ textAlign: "center" }}>
            <img
              alt=""
              style={{ width: "70px", height: "70px", borderRadius: "50%" }}
              src={require("../../assets/images/icons8_pdf.png")}
            ></img>
          </MDBCol>
          <MDBCol size="4">
            <p style={{ fontWeight: "500", fontSize: "24px" }}>سجل البيع</p>
          </MDBCol>
          <MDBCol size="4"></MDBCol>
        </MDBRow>
        <br></br>

        <br></br>
        <MDBRow style={{ textAlign: "right" }}>
          <MDBCol size="4">
            <p style={{ fontWeight: "500" }}>
              <span>اجمالي مقدمات</span>
              <span style={{ color: "red" }}>{this.props.allFirstPaid}</span>
            </p>
          </MDBCol>
          <MDBCol size="4">
            <p style={{ fontWeight: "500" }}>
              <span>اجمالي المتبقي</span>
              <span style={{ color: "red" }}>{this.props.costRemaining}</span>
            </p>
          </MDBCol>
          <MDBCol size="4">
            <p style={{ fontWeight: "500" }}>
              <span style={{ float: "right" }}>الكمية المباعة</span>
              <span style={{ color: "red" }}>{this.props.productsCount}</span>
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow style={{ textAlign: "right" }}>
          <MDBCol size="4">
            <p style={{ fontWeight: "500" }}>
              <span> تاريخ الطباعه / </span>
              <span style={{ color: "red", textDecoration: "underline" }}>
                {moment().format("YYYY/MM/DD")}{" "}
              </span>
            </p>
          </MDBCol>
          <MDBCol size="4"></MDBCol>
          <MDBCol size="4">
            <p style={{ fontWeight: "500" }}>
              <span>اجمالي العمولات</span>
              <span style={{ color: "red" }}>{this.props.totalCommission}</span>
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="12" sm="12">
            <div className="tabelDev">
              <div class="row">
                <table class="table table-bordered table-sm">
                  <thead>
                    <tr class="thead-light">
                      <th>تاريخ العملية</th>
                      <th>كود العميل</th>
                      <th>اسم العميل</th>
                      <th>مكان العمل</th>
                      <th>رفم التليفون</th>
                      <th>نوع المنتج</th>
                      <th>سعر البيع</th>
                      <th>المقدم</th>
                      <th>المتبقي</th>
                      <th>عدد الاقساط</th>
                      <th>مندوب البيع</th>
                      <th>عمولة المبيعات</th>
                      <th>تاريخ الشراء</th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.props.data.map((product) => (
                      <tr>
                        <td style={{ padding: "8px" }}>
                          {moment.utc(product.createdAt).format("YYYY/MM/DD")}
                        </td>
                        <td style={{ padding: "8px" }}>
                          {product.client ? product.client.id : 0}
                        </td>
                        <td style={{ padding: "8px" }}>
                          {product.client ? product.client.fullname : ""}
                        </td>
                        <td style={{ padding: "8px" }}>
                          {product.client ? product.client.job : ""}
                        </td>
                        <td style={{ padding: "8px" }}>
                          {product.client ? product.client.phone : 0}
                        </td>
                        <td style={{ padding: "8px" }}>
                          {product.processType == "MULTI"
                            ? product.multiProducts.map((pro) => (
                                <span>
                                  {pro.count} {pro.product.name_ar},
                                </span>
                              ))
                            : product.multiProducts.map((pro) => (
                                <span>
                                  {pro.count} {pro.product.name_ar}
                                </span>
                              ))}
                        </td>
                        <td style={{ padding: "8px" }}>
                          {product.total ? product.total : ""}
                        </td>
                        <td style={{ padding: "8px" }}>
                          {product.firstPaid ? product.firstPaid : ""}
                        </td>
                        <td style={{ padding: "8px" }}>
                          {product.costRemaining ? product.costRemaining : ""}
                        </td>
                        <td style={{ padding: "8px" }}>
                          {product.premuimsDesc ? product.premuimsDesc : 0}
                        </td>
                        <td style={{ padding: "8px" }}>
                          {product.multiBuySalesMen.map((pro) => (
                            <span>{pro.buySalesMan.fullname},</span>
                          ))}
                        </td>
                        <td style={{ padding: "8px" }}>
                          {product.multiBuySalesMen.reduce(
                            (acc, curr) => acc + curr.commission,
                            0
                          )}
                        </td>

                        <td style={{ padding: "8px" }}>
                          {product.startDate
                            ? moment.utc(product.startDate).format("YYYY/MM/DD")
                            : ""}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}
//END PRINT
class ReceivedProducts extends React.Component {
  state = {
    count: "",
    product: [],
    products: [],
    loading: true,
    refresh: false,
    page: 1,
    pages: 1,
    modal: false,
    receiveProducts: [],
    salesMan: this.props.location.state ? this.props.location.state.data : [],
    productsHistory: [],
    resp: "",
    printSale: [],
  };

  constructor(props) {
    super(props);
    console.log("isr   ", this.props.history);
    if (this.props.isRTL) {
      allStrings.setLanguage("ar");
    } else {
      allStrings.setLanguage("en");
    }
    window.scrollTo(0, 0);
  }
  getProducts = () => {
    let url = ``;
    if (
      this.props.currentUser.user.type === "ADMIN" ||
      this.props.currentUser.user.type === "SUB-ADMIN"
    ) {
      if (this.props.company === false) {
        url = `${BASE_END_POINT}products/withoutPagenation/get`;
      } else {
        url = `${BASE_END_POINT}products/withoutPagenation/get?company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}products/withoutPagenation/get?company=${this.props.currentUser.user.id}`;
    }
    axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("PROD", response.data);
        this.setState({ products: response.data.data });
      })
      .catch((error) => {
        console.log("ALL products ERROR");
        console.log(error);
      });
  };

  getReceiveProducts = (page, refresh) => {
    this.setState({ loading: true });
    axios
      .get(
        `${BASE_END_POINT}productsReceived?salesMan=${this.state.salesMan.id}&page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        }
      )
      .then((response) => {
        console.log("ReceiveProducts   ", response.data);
        this.setState({
          loading: false,
          refresh: false,
          company: response.data.data[0].company.id,
          receiveProducts: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
        this.getReceiveProductsHistory();
        this.getORINTSale();
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading: false });
      });
  };
  getReceiveProductsHistory = (page, refresh) => {
    this.setState({ loading: true });
    axios
      .get(
        `${BASE_END_POINT}productReceivedHistory?salesMan=${this.state.salesMan.id}&company=${this.state.company}`,
        {
          headers: {
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        }
      )
      .then((response) => {
        console.log("ReceiveProducts HISTORY  ", response.data);
        this.setState({
          loading: false,
          refresh: false,
          productsHistory: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading: false });
      });
  };
  getORINTSale = (page, refresh) => {
    this.setState({ loading: true });
    axios
      .get(
        `${BASE_END_POINT}orders/${this.state.salesMan.id}/getSalesManSales?company=${this.state.company}`,
        {
          headers: {
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        }
      )
      .then((response) => {
        console.log("PRINT SALES  ", response.data);
        this.setState({
          loading: false,
          refresh: false,
          printSale: response.data.data,
          resp: response.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading: false });
      });
  };
  componentDidMount() {
    this.getReceiveProducts(1, false);
    this.getProducts();

    //this.props.ChangeLanguage(false)
  }
  add = () => {
    const { product, count } = this.state;
    console.log(count);
    console.log(product);
    if (product !== [] && hasValue(count)) {
      let l = message.loading("Wait..", 2.5);
      var data = {
        product: product,
        count: count,
        salesMan: this.state.salesMan.id,
      };
      if (
        this.props.currentUser.user.type === "ADMIN" ||
        this.props.currentUser.user.type === "SUB-ADMIN"
      ) {
        if (this.props.company === false) {
          data.company = this.props.currentUser.user.company[0].id;
        } else {
          data.company = this.props.company;
        }
      } else {
        data.company = this.props.currentUser.user.id;
      }

      axios
        .post(`${BASE_END_POINT}productsReceived`, JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        })
        .then((response) => {
          l.then(() => message.success(allStrings.done, 2.5));
          this.setState({ loading: true, modal: false });
          this.getReceiveProducts();
        })
        .catch((error) => {
          console.log(error.response);
          l.then(() => message.error("Error", 2.5));
        });
    }
  };
  editCount = (type, id, proID) => {
    console.log(id);
    let l = message.loading("Wait..", 2.5);
    var data = {
      processType: type,
      company:
        this.props.currentUser.user.type == "ADMIN"
          ? this.props.company
            ? this.props.company
            : this.props.currentUser.user.company[0].id
          : this.props.currentUser.user.id,
      count: 1,
      salesMan: this.state.salesMan.id,
      product: proID,
    };
    axios
      .put(
        `${BASE_END_POINT}productsReceived/${id}/editProductCount`,
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        }
      )
      .then((response) => {
        l.then(() => message.success(allStrings.done, 2.5));
        this.getReceiveProducts(this.state.page);
      })
      .catch((error) => {
        console.log(error.response);
        l.then(() => message.error("Error", 2.5));
      });
  };

  submitHandler = (event) => {
    event.preventDefault();
    console.log(event.target);
    event.target.className += " was-validated";
    this.add();
  };

  changeHandler = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  modal = () => (
    <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
      <MDBModalHeader toggle={this.toggle}>
        {allStrings.completeData}
      </MDBModalHeader>
      <MDBModalBody>
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12">
              <form
                style={{ marginLeft: "4rem" }}
                className="needs-validation"
                onSubmit={this.submitHandler}
                noValidate
              >
                <MDBContainer>
                  <MDBRow style={{ marginBottom: "6px", marginTop: "6px" }}>
                    <MDBCol md="9" sm="9">
                      <TreeSelect
                        value={this.state.product}
                        showSearch={true}
                        style={{
                          width: "100%",
                          textAlign: this.props.isRTL ? "right" : "left",
                        }}
                        treeCheckable={false}
                        treeNodeFilterProp="title"
                        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                        placeholder={allStrings.chooseProduct}
                        onChange={(value) => {
                          console.log("VAAL", value);
                          this.setState({ product: value });
                        }}
                      >
                        {/*<TreeNode style={{background:"antiquewhite",color:'#fff !important'}} value="newReceived" title={allStrings.newReceived} key={allStrings.newReceived} />*/}
                        {this.state.products.map((val) => (
                          <TreeNode
                            value={val.id}
                            title={this.props.isRTL ? val.name_ar : val.name_en}
                            key={val.id}
                          />
                        ))}
                      </TreeSelect>
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                      <label
                        htmlFor="defaultFormRegisterNameEx1"
                        className="grey-text formLabel"
                      >
                        {allStrings.chooseProduct}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{ marginBottom: "6px", marginTop: "6px" }}>
                    <MDBCol md="9" sm="9">
                      <input
                        name="quantity"
                        min={1}
                        type="number"
                        disabled="true"
                        id="defaultFormRegisterQ"
                        className="form-control"
                        value={
                          this.state.products.find(
                            (val) => val.id == this.state.product
                          )?.quantity || "الكمية بالمخزن"
                        }
                        placeholder={
                          this.state.products.find(
                            (val) => val.id == this.state.product
                          )?.quantity || "الكمية بالمخزن"
                        }
                        style={{ direction: this.props.isRTL ? "rtl" : "ltr" }}
                      />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                      <label
                        htmlFor="defaultFormRegisterQ"
                        className="grey-text formLabel"
                      >
                        الكمية بالمخزن
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{ marginBottom: "6px", marginTop: "6px" }}>
                    <MDBCol md="9" sm="9">
                      <input
                        name="count"
                        onChange={this.changeHandler}
                        min={1}
                        type="number"
                        id="defaultFormRegisterNameEx2"
                        className="form-control"
                        placeholder={allStrings.count}
                        style={{ direction: this.props.isRTL ? "rtl" : "ltr" }}
                      />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                      <label
                        htmlFor="defaultFormRegisterNameEx2"
                        className="grey-text formLabel"
                      >
                        {allStrings.count}
                      </label>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBBtn
                      type="submit"
                      style={{
                        margin: "4rem 0",
                        width: "170px",
                        padding: "10px",
                      }}
                      className="btn z-depth-1a "
                      color="primary"
                    >
                      {allStrings.add}
                    </MDBBtn>
                  </MDBRow>
                </MDBContainer>
              </form>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBModalBody>
    </MDBModal>
  );

  render() {
    return (
      <div className="ReceivedProducts">
        {this.modal()}
        <MDBRow>
          <MDBCol md="4" sm="4" xs="4">
            <div
              className="top"
              style={{ width: "100%", textAlign: "center" }}
              onClick={() => this.props.history.goBack()}
            >
              <MDBIcon icon="times" className="closeIcon" />
            </div>
          </MDBCol>
        </MDBRow>
        <MDBContainer>
          <MDBRow>
            <MDBCol md="4" sm="4" xs="4">
              <ReactToPrint
                documentTitle={
                  "سجل الاصناف المستلمة" + moment().format("YYYY/MM/DD")
                }
                trigger={() => {
                  return (
                    <div
                      style={{
                        margin: "0rem 1rem",
                      }}
                    >
                      {
                        <div>
                          <img
                            alt=""
                            style={{ cursor: "pointer" }}
                            src={require("../../assets/images/icons10_pdf.png")}
                          ></img>
                          <span
                            style={{
                              color: "#228385",
                              fontWeight: "600",
                              padding: "10px",
                              fontSize: "20px",
                              position: "absolute",
                              cursor: "pointer",
                            }}
                          >
                            طباعه سجل الاصناف المستلمة
                          </span>
                        </div>
                      }
                    </div>
                  );
                }}
                content={() => this.componentRef}
              />
              <div style={{ display: "none" }}>
                <ComponentToPrint
                  data={this.state.productsHistory}
                  ref={(el) => (this.componentRef = el)}
                />
              </div>
            </MDBCol>
            <MDBCol md="4" sm="4" xs="4">
              <label
                htmlFor="defaultFormRegisterNameE"
                style={{
                  fontWeight: "800",
                  color: "#43425D",
                  fontSize: "20px",
                  margin: "1rem",
                  textAlign: "center",
                  display: "block",
                }}
              >
                {allStrings.receivedProducts} للمندوب
              </label>
            </MDBCol>
            <MDBCol md="4" sm="4" xs="4">
              <div>
                <div className="addButton" onClick={this.toggle}>
                  <MDBIcon icon="plus" />
                </div>
                <span
                  onClick={this.toggle}
                  style={{
                    float: "right",
                    color: "#43425D",
                    fontWeight: "600",
                    padding: "11px 2px",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                >
                  {allStrings.DliveryNewProduct}
                </span>
              </div>
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol md="4" sm="4" xs="4">
              <ReactToPrint
                documentTitle={"سجل البيع" + moment().format("YYYY/MM/DD")}
                trigger={() => {
                  return (
                    <div
                      style={{
                        margin: "0rem 1rem",
                      }}
                    >
                      {
                        <div>
                          <img
                            alt=""
                            style={{ cursor: "pointer" }}
                            src={require("../../assets/images/icons11_pdf.png")}
                          ></img>
                          <span
                            style={{
                              color: "#1051b3",
                              fontWeight: "600",
                              padding: "10px",
                              fontSize: "20px",
                              position: "absolute",
                              cursor: "pointer",
                            }}
                          >
                            طباعه سجل البيع
                          </span>
                        </div>
                      }
                    </div>
                  );
                }}
                content={() => this.componentRef2}
              />
              <div style={{ display: "none" }}>
                <ComponentToPrintSale
                  data={this.state.printSale}
                  allFirstPaid={this.state.resp.allFirstPaid}
                  costRemaining={this.state.resp.costRemaining}
                  productsCount={this.state.resp.productsCount}
                  totalCommission={this.state.resp.totalCommission}
                  ref={(el) => (this.componentRef2 = el)}
                />
              </div>
            </MDBCol>
            <MDBCol md="4" sm="4" xs="4">
              {this.state.loading ? (
                <div>
                  <LoadCard />
                  <LoadCard />
                </div>
              ) : (
                <p
                  style={{
                    fontWeight: "800",
                    color: "#43425D",
                    fontSize: "15px",
                    margin: "1rem",
                    textAlign: "center",
                    display: "block",
                  }}
                >
                  {this.state.receiveProducts[0].salesMan.fullname}
                </p>
              )}
            </MDBCol>
            <MDBCol md="4" sm="4" xs="4">
              <div
                style={{
                  textAlign: "right",
                  direction: "rtl",
                  margin: "0rem 1rem",
                }}
              >
                {
                  <div onClick={this.toggle}>
                    <img
                      alt=""
                      style={{ cursor: "pointer" }}
                      src={require("../../assets/images/icons8_pdf.png")}
                    ></img>
                    <span
                      style={{
                        color: "#FF3B40",
                        fontWeight: "600",
                        padding: "10px",
                        fontSize: "20px",
                        position: "absolute",
                        cursor: "pointer",
                      }}
                    >
                      طباعه سجل المشتريات
                    </span>
                  </div>
                }
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            {this.state.loading ? (
              <div>
                <LoadCard />
                <LoadCard />
              </div>
            ) : this.state.receiveProducts.length > 0 ? (
              this.state.receiveProducts.map((val) => (
                <MDBCol xl="3" lg="3" md="3" sm="12">
                  <div className="categoryCard">
                    <MDBContainer>
                      <MDBRow className="rowblue" style={{ padding: "10px" }}>
                        <MDBCol xl="12" lg="12" md="12">
                          <div className="centerImage">
                            <span
                              style={{
                                color: "#fff",
                                fontWeight: 400,
                                textAlign: "center",
                              }}
                            >
                              {val.product.id}
                            </span>
                            <span
                              style={{
                                color: "#fff",
                                fontWeight: 400,
                                textAlign: "center",
                              }}
                            >
                              :
                            </span>
                            <span
                              style={{
                                color: "#fff",
                                fontWeight: 400,
                                textAlign: "center",
                              }}
                            >
                              الكود
                            </span>
                          </div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol xl="12" lg="12" className="noPadding">
                          <div
                            className="centerImage"
                            style={{
                              height: "200px",
                              overflow: "hidden",

                              backgroundImage:
                                "url(" + val.product.mainImg + ")",
                              backgroundSize: "cover",
                            }}
                          ></div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow style={{ background: "#d2d2d2" }}>
                        <MDBCol xl="12" lg="12" className="noPadding">
                          <div className="centerImage">
                            <p
                              style={{
                                textAlign: "center",
                                color: "black",
                                marginTop: "14px",
                                fontWeight: "600",
                              }}
                            >
                              {this.props.isRTL
                                ? val.product.name_ar
                                : val.product.name_en}
                            </p>
                          </div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow
                        className="rowblue text-center"
                        style={{ padding: "10px" }}
                      >
                        <MDBCol
                          xl="4"
                          lg="4"
                          md="4"
                          style={{ textAlign: "center" }}
                        >
                          <div
                            onClick={() =>
                              this.editCount("min", val.id, val.product.id)
                            }
                            className="spanRec spanRec1"
                          >
                            <span style={{ lineHeight: "20px" }}>-</span>
                          </div>
                        </MDBCol>
                        <MDBCol
                          xl="4"
                          lg="4"
                          md="4"
                          style={{ textAlign: "center" }}
                        >
                          {val.delivered ? (
                            <p
                              style={{
                                textAlign: "center",
                                color: "#fff",
                                fontSize: "20px",
                              }}
                            >
                              {allStrings.deliverd}
                            </p>
                          ) : (
                            <div
                              className="centerDiv"
                              style={{ color: "#fff" }}
                            >
                              <span className="centerSpan">{val.remain}</span>
                            </div>
                          )}
                        </MDBCol>
                        <MDBCol
                          xl="4"
                          lg="4"
                          md="4"
                          style={{ textAlign: "center" }}
                        >
                          <div
                            onClick={() =>
                              this.editCount("add", val.id, val.product.id)
                            }
                            className="spanRec spanRec2"
                          >
                            <span style={{ lineHeight: "20px" }}>+</span>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                  </div>
                </MDBCol>
              ))
            ) : (
              <div className="noData">{allStrings.noData}</div>
            )}
          </MDBRow>
          <MDBRow>
            {this.state.receiveProducts.length > 0 && (
              <div
                style={{
                  textAlign: "center",
                  margin: "auto",
                  marginTop: "3rem",
                }}
              >
                <Pagination
                  onChange={(event, page) => {
                    console.log("page Right Now  ", this.state.page, page);
                    if (page !== this.state.page) {
                      this.getReceiveProducts(page);
                      this.setState({ page: page });
                    }
                  }}
                  defaultPage={1}
                  page={this.state.page}
                  count={this.state.pages}
                  style={{ color: `blue !important` }}
                />
              </div>
            )}
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = (state) => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  branch: state.company.branch,
  company: state.company.company,
});

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
};
export default withRouter(
  connect(mapToStateProps, mapDispatchToProps)(ReceivedProducts)
);
