import React from "react";
import "./updateSalesMan.css";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { BASE_END_POINT, SOCKET_BASE_END } from "../../urls";
import axios from "axios";
import { setUser } from "../../redux/actions/AuthActions";
import { hasValue } from "../../validations/validations";
import { message, DatePicker, TreeSelect, ConfigProvider } from "antd";
import { NavLink } from "react-router-dom";
import { ChangeLanguage } from "../../redux/actions/LanguageAction";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import io from "socket.io-client";
import { ChangeCompany, ChangeBranch } from "../../redux/actions/CompanyAction";
import locale_ar from "antd/lib/locale/ar_EG";
import locale_en from "antd/lib/locale/en_GB";
import "moment/locale/ar";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import ReactToPrint from "react-to-print";
const { TreeNode } = TreeSelect;
let fromDate, toDate, printFromDate, printToDate;
class ComponentToPrint extends React.Component {
  render() {
    console.log("ewe", this.props);
    return (
      <MDBContainer>
        <br></br>
        <br></br>
        <MDBRow style={{ textAlign: "center" }}>
          <MDBCol size="4" style={{ textAlign: "center" }}>
            <img
              alt=""
              style={{ width: "70px", height: "70px", borderRadius: "50%" }}
              src={require("../../assets/images/icons8_pdf.png")}
            ></img>
          </MDBCol>
          <MDBCol size="4">
            <p style={{ fontWeight: "500", fontSize: "24px" }}>
              <span> كشف حساب المندوب </span>
            </p>
          </MDBCol>
          <MDBCol size="4"></MDBCol>
        </MDBRow>
        <br></br>
        <MDBRow style={{ textAlign: "right" }}>
          <MDBCol size="3">
            {this.props.reportType === "DURATION" && (
              <p style={{ fontWeight: "500" }}>
                <span> من / </span>
                <span style={{ color: "red", textDecoration: "underline" }}>
                  {this.props.from}
                </span>
                <span> الى </span>
                <span style={{ color: "red", textDecoration: "underline" }}>
                  {this.props.to}
                </span>
              </p>
            )}
          </MDBCol>
          <MDBCol size="3">
            <p style={{ fontWeight: "500" }}>
              <span> تاريخ الطباعه / </span>
              <span style={{ color: "red", textDecoration: "underline" }}>
                {moment().format("YYYY/MM/DD")}
              </span>
            </p>
          </MDBCol>
          <MDBCol size="3">
            <p style={{ fontWeight: "500" }}>
              <span style={{ float: "right" }}> / اسم المندوب </span>
              <span style={{ color: "red", textDecoration: "underline" }}>
                {this.props.salesMan.fullname
                  ? this.props.salesMan.fullname
                  : ""}
              </span>
            </p>
          </MDBCol>
          <MDBCol size="3">
            <p style={{ fontWeight: "500" }}>
              <span style={{ float: "right" }}> / اسم الشركه </span>
              <span style={{ color: "red", textDecoration: "underline" }}>
                {this.props.theCompany.fullname
                  ? this.props.theCompany.fullname
                  : ""}
              </span>
            </p>
          </MDBCol>
        </MDBRow>
        <br></br>
        <br></br>
        <MDBRow>
          <MDBCol md="12" sm="12">
            <div className="tabelDev">
              <div class="row">
                <table class="table table-bordered table-sm">
                  <thead>
                    <tr class="thead-light">
                      <th>التحصيل الاضافى المتبقى </th>
                      <th>التحصيل الاضافى المكتمل </th>
                      <th>التحصيل الاضافى الكلى </th>
                      <th>اجمالى العمولات </th>
                      <th> التحصيلات المتبقيه </th>
                      <th> التحصيلات المكتمله </th>
                      <th>التحصيل الكلى </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <th scope="row">{this.props.additionCollectRemain}</th>
                      <th scope="row">{this.props.additionCollectComplete}</th>
                      <th scope="row">{this.props.additionCollectAll}</th>
                      <th scope="row">{this.props.totalCommission}</th>
                      <th scope="row">{this.props.pendingPremiums}</th>
                      <th scope="row">{this.props.finishedPremiums}</th>
                      <th scope="row">{this.props.allCollections}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <MDBRow>
          <MDBCol md="12" sm="12">
            <p
              style={{ color: "#000", fontWeight: "600", textAlign: "center" }}
            >
              تفاصيل منتجات البيع
            </p>
            <div className="tabelDev">
              <div class="row">
                <table class="table table-bordered table-sm">
                  <thead>
                    <tr class="thead-light">
                      <th>نوع البيع</th>
                      <th>العموله </th>
                      <th>عدد المبيعات </th>
                      <th> منتجات البيع </th>
                      <th>رقم </th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.props.data.map((val) => (
                      <tr>
                        <th scope="row">
                          {val.buyType.length > 1
                            ? allStrings.cashAndInstallment
                            : val.buyType[0] === "cash"
                            ? allStrings.cash
                            : allStrings.installment}
                        </th>
                        <th scope="row">{val.commision ? val.commision : 0}</th>
                        <th scope="row">{val.saleCount ? val.saleCount : 0}</th>
                        <td style={{ padding: "8px" }}>
                          {val.name_ar
                            ? this.props.isRTL
                              ? val.name_en
                              : val.name_ar
                            : ""}
                        </td>
                        <th scope="row">{val.product ? val.product : ""}</th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}
class updateSalesManForm extends React.Component {
  state = {
    fullname: this.props.location.state
      ? this.props.location.state.data.fullname
      : "",
    password: this.props.location.state
      ? this.props.location.state.data.password
      : "",
    phone: this.props.location.state
      ? this.props.location.state.data.phone
      : "",
    anotherPhone: this.props.location.state
      ? this.props.location.state.data.anotherPhone
      : "",
    address: this.props.location.state
      ? this.props.location.state.data.address
      : "",
    notes: this.props.location.state
      ? this.props.location.state.data.notes
      : "",
    cardNum: this.props.location.state
      ? this.props.location.state.data.cardNum
      : "",

    profileImg: this.props.location.state
      ? this.props.location.state.data.img !== ""
        ? this.props.location.state.data.img
        : null
      : null,
    cardImg: this.props.location.state
      ? this.props.location.state.data.cardImg
        ? this.props.location.state.data.cardImg.length > 0
          ? this.props.location.state.data.cardImg
          : null
        : null
      : null,
    cardImgViews: this.props.location.state
      ? this.props.location.state.data.cardImg
      : [],
    blocked: this.props.location.state
      ? this.props.location.state.blocked
        ? this.props.location.state.blocked
        : false
      : false,
    SalesMan: this.props.location.state ? this.props.location.state.data : [],
    requestType: "put",
    profileimgType: this.props.location.state ? "url" : "data",
    cardImgType: this.props.location.state ? "url" : "data",
    commission: this.props.location.state
      ? this.props.location.state.data.commission
      : 0,
    loading: true,
    loading2: true,
    salesManClients: [],
    salesManClientsCount: 0,
    recievedProducts: [],
    recievedProductsCount: 0,
    count: [],
    refresh: false,
    page: 1,
    pages: 1,
    filterType: "",
    total: "",
    from: this.props.isRTL
      ? moment().startOf("month").format("YYYY/MM/DD")
      : moment().startOf("month").locale("en").format("YYYY/MM/DD"),
    to: this.props.isRTL
      ? moment().endOf("month").format("YYYY/MM/DD")
      : moment().endOf("month").locale("en").format("YYYY/MM/DD"),
    printFrom: this.props.isRTL
      ? moment().startOf("month").format("YYYY/MM/DD")
      : moment().startOf("month").locale("en").format("YYYY/MM/DD"),
    printTo: this.props.isRTL
      ? moment().endOf("month").format("YYYY/MM/DD")
      : moment().endOf("month").locale("en").format("YYYY/MM/DD"),

    tableData: [],
    tableType: "type1",
    num: "",
    modal2: false,
    modalType: "map",
    modalReqType: "map",
    message: "",
    cost: 0,
    moneyGiven: 0,
    newSalesMan: null,
    additionCollectCost: 0,
    additionalCollectionCount: [],
    photoIndex: 0,
    isOpen1: false,
    isOpen2: false,
    firstPaidFilter: false,
    collectNum: null,
    recieveFromAddition: 0,
    modal3: false,
    modal4: false,
    daily: false,
    reportType: "ALL",
    orders: [],
    enablePrint: false,
    additionCollectAll: 0,
    additionCollectRemain: 0,
    additionCollectComplete: 0,
    totalCommission: 0,
    finishedPremiums: 0,
    pendingPremiums: 0,
    allCollections: 0,
    theCompany: this.props.location.state
      ? this.props.location.state.data.company
        ? this.props.location.state.data.company[0]
        : []
      : [],
  };

  constructor(props) {
    super(props);
    console.log("isr   ", this.props.history);
    console.log("Props", this.props);
    this.socket = io(SOCKET_BASE_END, {
      query: `id=${this.props.currentUser.user.id}`,
    });
    window.scrollTo(0, 0);
    this.getStatistics();
    this.getRecieveProducts();
    this.getSalesManClients();
    this.getSalesMan();
    this.getSalesMen();
    this.getDuration();
    this.getCounts();
    this.getAdditionCollectCounts();
    if (this.props.isRTL) {
      allStrings.setLanguage("ar");
      moment.locale("ar");
    } else {
      allStrings.setLanguage("en");
      moment.locale("en");
    }
  }
  componentDidMount() {}

  getDuration = () => {
    //first day
    let date = new Date();
    let newDate = date.toISOString().slice(0, 8);
    let firstDay = newDate + "01";
    //get days in month
    function daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    }

    let DaysInCurrentMonth = daysInMonth(
      date.getMonth() + 1,
      date.getFullYear()
    ); //number of day in month
    //last day
    let lastDay = newDate + DaysInCurrentMonth;
    console.log(firstDay);
    console.log(lastDay);
    if (this.props.isRTL) {
      this.setState({
        from: moment(firstDay).format("YYYY/MM/DD"),
        to: moment(lastDay).format("YYYY/MM/DD"),
      });
      this.setState({
        printFromDate: moment(firstDay).format("YYYY/MM/DD"),
        to: moment(lastDay).format("YYYY/MM/DD"),
      });
    } else {
      this.setState({
        from: moment(firstDay).locale("en").format("YYYY/MM/DD"),
        printToDate: moment(lastDay).locale("en").format("YYYY/MM/DD"),
      });
      this.setState({
        printFromDate: moment(firstDay).locale("en").format("YYYY/MM/DD"),
        printToDate: moment(lastDay).locale("en").format("YYYY/MM/DD"),
      });
    }

    fromDate = firstDay;
    toDate = lastDay;
    printFromDate = firstDay;
    printToDate = lastDay;
  };
  getSalesMen = () => {
    let url = ``;
    if (
      this.props.currentUser.user.type === "ADMIN" ||
      this.props.currentUser.user.type === "SUB-ADMIN"
    ) {
      if (this.props.company === false) {
        url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN`;
      } else {
        url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN&company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN&company=${this.props.currentUser.user.id}`;
    }
    axios
      .get(url)
      .then((response) => {
        console.log("DGGG", response.data.users);
        this.setState({
          salesMen: response.data.users,
        });
      })
      .catch((error) => {
        console.log("ALL salesMen ERROR");
        console.log(error.response);
      });
  };
  getSalesMan = () => {
    let url = ``;
    if (
      this.props.currentUser.user.type === "ADMIN" ||
      this.props.currentUser.user.type === "SUB-ADMIN"
    ) {
      if (this.props.company === false) {
        url = `${BASE_END_POINT}${this.state.SalesMan.id}/findById?type=Sales-Man`;
      } else {
        url = `${BASE_END_POINT}${this.state.SalesMan.id}/findById?type=Sales-Man&company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}${this.state.SalesMan.id}/findById?type=Sales-Man&company=${this.props.currentUser.user.id}`;
    }
    axios
      .get(url)
      .then((response) => {
        console.log("DTRGGG", response.data.user);
        this.setState({
          SalesMan: response.data.user,
          fullname: response.data.user.fullname,
          password: response.data.user.password,
          address: response.data.user.address || "",
          notes: response.data.user.notes || "",
          cardImg:
            response.data.user.cardImg.length > 0
              ? response.data.user.cardImg
              : null,
          cardImgViews: response.data.user.cardImg || [],
          commission: response.data.user.commission,
          theCompany: response.data.user.company[0],
        });
      })
      .catch((error) => {
        console.log("SalesMan Error");
        console.log(error.response);
      });
  };
  deleteBlock = (id) => {
    let l = message.loading("Wait..", 2.5);
    axios
      .delete(`${BASE_END_POINT}block/${id}/removeAppBlock`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("Block DELETED", response);
        l.then(() => message.success(allStrings.done, 2));
        this.props.history.push("/SalesMan");
      })
      .catch((error) => {
        console.log("err", error);
        l.then(() => message.error("Error", 2.5));
      });
  };
  deleteBlockComp = (id) => {
    let l = message.loading("Wait..", 2.5);
    console.log(this.props.currentUser.token, this.props.company);
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.props.currentUser.token}`,
    };
    let data = {
      company:
        this.props.currentUser.user.type == "ADMIN"
          ? this.props.company
            ? this.props.company
            : this.props.currentUser.user.company[0].id
          : this.props.currentUser.user.id,
    };
    axios
      .delete(`${BASE_END_POINT}block/${id}/removeCompanyBlock`, {
        headers,
        data,
      })
      .then((response) => {
        console.log("Block DELETED");
        l.then(() => message.success(allStrings.done, 2));
        this.props.history.push("/SalesMan");
      })
      .catch((error) => {
        console.log("er", error);
        l.then(() => message.error("Error", 2.5));
      });
  };

  //ADD BLOCK
  addBlock = (id) => {
    let l = message.loading("Wait..", 2.5);
    axios
      .post(
        `${BASE_END_POINT}block/${id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        }
      )
      .then((response) => {
        console.log("Block DELETED", response);
        l.then(() => message.success(allStrings.done, 2));
        this.props.history.push("/SalesMan");
      })
      .catch((error) => {
        console.log("err", error);
        l.then(() => message.error("Error", 2.5));
      });
  };
  addBlockComp = (id) => {
    let l = message.loading("Wait..", 2.5);
    console.log(this.props.currentUser.token, this.props.company);
    axios
      .post(
        `${BASE_END_POINT}block/${id}`,
        {
          company:
            this.props.currentUser.user.type == "ADMIN"
              ? this.props.company
                ? this.props.company
                : this.props.currentUser.user.company[0].id
              : this.props.currentUser.user.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        }
      )
      .then((response) => {
        console.log("Block DELETED");
        l.then(() => message.success(allStrings.done, 2));
        this.props.history.push("/SalesMan");
      })
      .catch((error) => {
        console.log("er", error);
        l.then(() => message.error("Error", 2.5));
      });
  };

  sendNotif = () => {
    const msg = this.state.message;
    console.log(msg);
    if (hasValue(msg)) {
      let l = message.loading("Wait..", 2.5);
      let data = {
        description: msg,
        users: this.state.SalesMan.id,
      };
      axios
        .post(`${BASE_END_POINT}sendnotif`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        })
        .then((response) => {
          l.then(() => message.success(allStrings.done, 2.5));
          this.toggle2();
        })
        .catch((error) => {
          console.log(error.response);
          l.then(() => message.error("Error", 2.5));
        });
    }
  };
  sendMessage = () => {
    const msg = this.state.message;
    console.log(msg);
    if (hasValue(msg)) {
      let l = message.loading("Wait..", 2.5);
      const data = {
        text: msg,
        user: {
          _id: this.props.currentUser.user.id,
        },
      };
      this.socket.emit("newMessage", {
        toId: this.state.SalesMan.id,
        data: data,
      });
      l.then(() => message.success(allStrings.done, 2.5));
      this.toggle2();
    }
  };
  changeSalesMan = () => {
    const { newSalesMan } = this.state;
    console.log(newSalesMan);
    if (newSalesMan != null) {
      let l = message.loading("Wait..", 2.5);

      axios
        .put(
          `${BASE_END_POINT}premiums/${this.state.SalesMan.id}/oldSalesMan/${newSalesMan}/newsalesMan/replaceAll`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.currentUser.token}`,
            },
          }
        )
        .then((response) => {
          l.then(() => message.success(allStrings.done, 2.5));
          this.toggle2();
        })
        .catch((error) => {
          console.log(error.response);
          l.then(() => message.error("Error", 2.5));
        });
    }
  };
  recieveMoney = () => {
    const { cost } = this.state;
    if (cost !== 0) {
      let l = message.loading("Wait..", 2.5);
      let data = {
        //"salesMan":this.state.SalesMan.id,
        money: cost,
      };
      console.log(data);
      axios
        .put(
          `${BASE_END_POINT}${this.state.SalesMan.id}/takeFromSalesMan`,
          JSON.stringify(data),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.currentUser.token}`,
            },
          }
        )
        .then((response) => {
          l.then(() => message.success(allStrings.done, 2.5));
          this.toggle2();
          this.getCounts();
        })
        .catch((error) => {
          console.log(error.response);
          l.then(() => message.error("Error", 2.5));
        });
    }
  };
  payToSalesMan = () => {
    const { moneyGiven } = this.state;
    if (moneyGiven !== 0) {
      let l = message.loading("Wait..", 2.5);
      let data = {
        //"salesMan":this.state.SalesMan.id,
        money: moneyGiven,
      };
      console.log(data);
      axios
        .put(
          `${BASE_END_POINT}${this.state.SalesMan.id}/payToSalesMan`,
          JSON.stringify(data),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.currentUser.token}`,
            },
          }
        )
        .then((response) => {
          l.then(() => message.success(allStrings.done, 2.5));
          this.toggle2();
          this.getCounts();
        })
        .catch((error) => {
          console.log(error.response);
          l.then(() => message.error("Error", 2.5));
        });
    }
  };
  //addition collected
  addAdditionCollected = () => {
    const { additionCollectCost } = this.state;
    if (additionCollectCost !== 0) {
      let l = message.loading("Wait..", 2.5);
      let data = {
        salesMan: this.state.SalesMan.id,
        cost: additionCollectCost,
      };
      if (
        this.props.currentUser.user.type === "ADMIN" ||
        this.props.currentUser.user.type === "SUB-ADMIN"
      ) {
        if (this.props.company === false) {
          data.company = this.props.currentUser.user.company[0].id;
        } else {
          data.company = this.props.company;
        }
      } else {
        data.company = this.props.currentUser.user.id;
      }
      console.log(data);
      axios
        .post(`${BASE_END_POINT}additionalCollection`, JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        })
        .then((response) => {
          l.then(() => message.success(allStrings.done, 2.5));
          this.getAddition(1, false);
          this.getAdditionCollectCounts();
        })
        .catch((error) => {
          console.log(error.response);
          l.then(() => message.error("Error", 2.5));
        });
    }
  };

  updateAddetionCollectedSubmitHandel = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.updateAddetionCollected();
  };

  updateAddetionCollected = () => {
    let { collectNum, recieveFromAddition } = this.state;
    if (recieveFromAddition !== 0) {
      let l = message.loading("Wait..", 2.5);
      let data = {
        num: recieveFromAddition,
      };
      console.log(data);
      axios
        .put(
          `${BASE_END_POINT}additionalCollection/${collectNum}/collect`,
          JSON.stringify(data),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.currentUser.token}`,
            },
          }
        )
        .then((response) => {
          l.then(() => message.success(allStrings.done, 2.5));
          this.getAddition(1, false);
          this.getAdditionCollectCounts();
          this.toggle3();
        })
        .catch((error) => {
          console.log(error.response);
          l.then(() => message.error("Error", 2.5));
        });
    }
  };
  getAdditionCollectCounts = () => {
    this.setState({ loading: true });
    axios
      .get(
        `${BASE_END_POINT}additionalCollection/getCount?salesMan=${this.state.SalesMan.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        }
      )
      .then((response) => {
        console.log("additionalCollection count   ", response.data);
        this.setState({
          loading: false,
          additionalCollectionCount: response.data,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        this.setState({ loading: false });
      });
  };

  getSalesManClients = () => {
    this.setState({ loading: true });
    let url = ``;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}clients/findAllForMobile?salesMan=${this.state.SalesMan.id}&limit=100`;
      } else {
        url = `${BASE_END_POINT}clients/findAllForMobile?salesMan=${this.state.SalesMan.id}&company=${this.props.company}&limit=100`;
      }
    } else {
      url = `${BASE_END_POINT}clients/findAllForMobile?salesMan=${this.state.SalesMan.id}&company=${this.props.currentUser.user.id}&limit=100`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("salesManClients   ", response.data);
        this.setState({
          loading: false,
          salesManClients: response.data.data,
          salesManClientsCount: response.data.data.length,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading: false });
      });
  };
  getRecieveProducts = () => {
    this.setState({ loading: true });
    axios
      .get(
        `${BASE_END_POINT}productsReceived?salesMan=${this.state.SalesMan.id}&limit=14`,
        {
          headers: {
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        }
      )
      .then((response) => {
        console.log("recievedProducts   ", response.data);
        this.setState({
          loading: false,
          recievedProducts: response.data.data,
          recievedProductsCount: response.data.totalCount,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        this.setState({ loading: false });
      });
  };
  getCounts = () => {
    this.setState({ loading: true });
    axios
      .get(
        `${BASE_END_POINT}${this.state.SalesMan.id}/salesManAccounting?start=${fromDate}&end=${toDate}`,
        {
          headers: {
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        }
      )
      .then((response) => {
        console.log("get count   ", response.data);
        this.setState({ loading: false, count: response.data });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        this.setState({ loading: false });
      });
  };

  updateSalesMan = () => {
    const {
      fullname,
      password,
      phone,
      anotherPhone,
      profileImg,
      cardNum,
      address,
      notes,
      cardImg,
      commission,
    } = this.state;

    if (hasValue(fullname) && hasValue(phone) && hasValue(cardNum)) {
      let l = message.loading("Wait..", 2.5);
      var data = new FormData();
      data.append("fullname", fullname);
      data.append("commission", commission);
      if (cardImg != null) {
        //if(cardImgType === 'data'){
        for (var i = 0; i <= cardImg.length - 1; i++) {
          data.append("cardImg", cardImg[i]);
        }
        //}
      }

      if (password !== "" && password !== undefined) {
        data.append("password", password);
      }
      if (cardNum !== "" && cardNum !== undefined) {
        data.append("cardNum", cardNum);
      }

      data.append("type", "SALES-MAN");
      if (phone !== "" && phone !== undefined) {
        data.append("phone", phone);
      }
      // if(this.state.profileImgType === 'data'){
      if (profileImg != null) {
        data.append("img", profileImg);
      }
      //}

      if (anotherPhone !== "" && anotherPhone !== undefined) {
        data.append("anotherPhone", anotherPhone);
      }
      if (notes !== "" && notes !== undefined) {
        data.append("notes", notes);
      }
      if (address !== "" && address !== undefined) {
        data.append("address", address);
      }
      //////

      console.log(Array.from(data));

      axios
        .put(
          `${BASE_END_POINT}user/${this.state.SalesMan.id}/updateInfo`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${this.props.currentUser.token}`,
            },
          }
        )
        .then((response) => {
          l.then(() => message.success(allStrings.done, 2.5));
          this.props.history.push("/SalesMan");
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.data.errors[0].msg === "cardNum duplicated") {
            l.then(() => message.error(allStrings.duplicatedCardNum, 2.5));
          }
          if (error.response.data.errors[0].msg === "phone duplicated") {
            l.then(() => message.error(allStrings.duplicatedPhone, 2.5));
          }
        });
    }
  };
  getAllCollect = (page, refresh) => {
    this.setState({ loading2: true, page: 1 });
    axios
      .get(
        `${BASE_END_POINT}premiums?salesMan=${this.state.SalesMan.id}&type=INSTALLMENT&page=${page}&start=${fromDate}&end=${toDate}`,
        {
          headers: {
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        }
      )
      .then((response) => {
        console.log("allCollect   ", response.data);
        this.setState({
          loading2: false,
          refresh: false,
          daily: false,
          tableData: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading2: false });
      });
  };
  getDaily = (page, refresh) => {
    this.setState({ loading2: true, page: 1 });
    axios
      .get(
        `${BASE_END_POINT}premiums?salesMan=${this.state.SalesMan.id}&type=INSTALLMENT&daily=true&page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        }
      )
      .then((response) => {
        console.log("daily   ", response.data);
        this.setState({
          loading2: false,
          refresh: false,
          daily: true,
          tableData: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading2: false });
      });
  };
  getDailyFirstPaid = (page, refresh) => {
    this.setState({ loading2: true, page: 1 });
    axios
      .get(
        `${BASE_END_POINT}orders?paymentSystem=installment&buySalesMan=${this.state.SalesMan.id}&daily=true&page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        }
      )
      .then((response) => {
        console.log("daily firstPaid   ", response.data);
        this.setState({
          loading2: false,
          daily: true,
          refresh: false,
          tableData: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading2: false });
      });
  };
  getFirstPaid = (page, refresh) => {
    this.setState({ loading2: true, page: 1 });
    axios
      .get(
        `${BASE_END_POINT}orders?paymentSystem=installment&buySalesMan=${this.state.SalesMan.id}&page=${page}&start=${fromDate}&end=${toDate}`,
        {
          headers: {
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        }
      )
      .then((response) => {
        console.log("first paid   ", response.data);
        this.setState({
          loading2: false,
          daily: false,
          refresh: false,
          tableData: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading2: false });
      });
  };
  getPendingCollect = (page, refresh) => {
    this.setState({ loading2: true, page: 1 });
    axios
      .get(
        `${BASE_END_POINT}premiums?type=INSTALLMENT&salesMan=${this.state.SalesMan.id}&status=PENDING&page=${page}&start=${fromDate}&end=${toDate}`,
        {
          headers: {
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        }
      )
      .then((response) => {
        console.log("pendingCollect   ", response.data);
        this.setState({
          loading2: false,
          refresh: false,
          daily: false,
          tableData: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading2: false });
      });
  };
  getFinishCollect = (page, refresh) => {
    this.setState({ loading2: true, page: 1 });
    axios
      .get(
        `${BASE_END_POINT}premiums?type=INSTALLMENT&salesMan=${this.state.SalesMan.id}&status=FINISHED&page=${page}&start=${fromDate}&end=${toDate}`,
        {
          headers: {
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        }
      )
      .then((response) => {
        console.log("finishCollect   ", response.data);
        this.setState({
          loading2: false,
          refresh: false,
          daily: false,
          tableData: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading2: false });
      });
  };
  getAddition = (page, refresh) => {
    this.setState({ loading2: true, page: 1 });
    axios
      .get(
        `${BASE_END_POINT}additionalCollection?salesMan=${this.state.SalesMan.id}&page=${page}&start=${fromDate}&end=${toDate}`,
        {
          headers: {
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        }
      )
      .then((response) => {
        console.log("addition Collect   ", response.data);
        this.setState({
          loading2: false,
          refresh: false,
          daily: false,
          tableData: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading2: false });
      });
  };
  getReceive = (page, refresh) => {
    this.setState({ loading2: true, page: 1 });
    axios
      .get(
        `${BASE_END_POINT}transactions?user=${this.state.SalesMan.id}&type=RECEIPT-OF-RECEIVABLES&page=${page}&start=${fromDate}&end=${toDate}`,
        {
          headers: {
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        }
      )
      .then((response) => {
        console.log("addition Collect   ", response.data);
        this.setState({
          loading2: false,
          refresh: false,
          daily: false,
          tableData: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading2: false });
      });
  };
  getDues = (page, refresh) => {
    console.log("klkll");
    this.setState({ loading2: true, page: 1 });
    axios
      .get(
        `${BASE_END_POINT}transactions?user=${this.state.SalesMan.id}&type=DUES&page=${page}&start=${fromDate}&end=${toDate}`,
        {
          headers: {
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        }
      )
      .then((response) => {
        console.log("addition Collect   ", response.data);
        this.setState({
          loading2: false,
          refresh: false,
          daily: false,
          tableData: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading2: false });
      });
  };
  getStatistics = () => {
    this.setState({ loading: true });
    let url = `${BASE_END_POINT}orders/${this.state.SalesMan.id}/salesManSales`;
    if (this.state.reportType === "DURATION") {
      url = `${BASE_END_POINT}orders/${this.state.SalesMan.id}/salesManSales?start=${printFromDate}&end=${printToDate}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("get count   ", response.data);
        this.setState({
          enablePrint: true,
          orders: response.data.data,
          additionCollectAll: response.data.additionCollectAll,
          additionCollectRemain: response.data.additionCollectRemain,
          additionCollectComplete: response.data.additionCollectComplete,
          totalCommission: response.data.totalCommission,
          finishedPremiums: response.data.finishedPremiums,
          pendingPremiums: response.data.pendingPremiums,
          allCollections: response.data.allCollections,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        this.setState({ loading: false });
      });
  };
  getStatisticsHandel = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.setState({ enablePrint: false });
    this.getStatistics();
  };

  fileHandlerProfileImg = (event) => {
    this.setState({
      profileImg: event.target.files[0],
      profileimgType: "data",
    });
  };
  removeProfileImg = () => {
    this.setState({
      profileImg: null,
    });
  };

  //card img
  fileHandlerCardImg = (event) => {
    let imgs = [];
    for (var i = 0; i <= event.target.files.length - 1; i++) {
      imgs.push(URL.createObjectURL(event.target.files[i]));
    }
    console.log(imgs);
    this.setState({
      cardImg: event.target.files,
      cardImgViews: imgs,
      cardImgType: "data",
    });
  };
  removeCardImg = (url) => {
    let arr = this.state.cardImgViews;
    let arr2 = Array.from(this.state.cardImg);
    console.log(arr2);
    var index = arr.indexOf(url);
    if (index !== -1) arr.splice(index, 1);
    if (index !== -1) arr2.splice(index, 1);
    console.log(arr2);
    this.setState({
      cardImgViews: arr,
      cardImg: arr2,
    });
    if (arr2.length === 0) {
      this.setState({
        cardImg: null,
      });
    }
  };

  changeHandler = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  changeHandlerAdditionCollected = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };

  updateSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.updateSalesMan();
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
    if (this.state.modal2 === true) {
      this.setState({
        modal2: false,
      });
    }
  };
  toggle3 = () => {
    this.setState({
      modal3: !this.state.modal3,
    });
    if (this.state.modal === true) {
      this.setState({
        modal: false,
      });
    }
    if (this.state.modal2 === true) {
      this.setState({
        modal2: false,
      });
    }
  };
  toggle2 = () => {
    this.setState({
      modal2: !this.state.modal2,
    });
    if (this.state.modal === true) {
      this.setState({
        modal: false,
      });
    }
  };
  toggle4 = () => {
    this.setState({
      modal4: !this.state.modal4,
    });
    if (this.state.modal === true) {
      this.setState({
        modal: false,
      });
    }
    if (this.state.modal2 === true) {
      this.setState({
        modal2: false,
      });
    }
    if (this.state.modal3 === true) {
      this.setState({
        modal3: false,
      });
    }
  };
  notifSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.sendNotif();
  };
  msgSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.sendMessage();
  };
  changeSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.changeSalesMan();
  };
  recieveSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.recieveMoney();
  };
  paySubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.payToSalesMan();
  };
  openMap = () => {
    this.setState({ modalType: "map" });
    this.toggle2();
  };
  openNotif = () => {
    this.setState({ modalType: "notif", modalReqType: "notif" });
    this.toggle2();
  };
  openMsg = () => {
    this.setState({ modalType: "notif", modalReqType: "msg" });
    this.toggle2();
  };
  openChange = () => {
    this.setState({ modalType: "change", modalReqType: "change" });
    this.toggle2();
  };
  openReceiveMoney = () => {
    this.setState({ modalType: "recieve", modalReqType: "recieve" });
    this.toggle2();
  };
  openPayToSalesMan = () => {
    this.setState({ modalType: "pay", modalReqType: "pay" });
    this.toggle2();
  };
  onChangePrintFrom = (date, dateString) => {
    console.log(dateString);
    if (this.props.isRTL) {
      this.setState({ printFrom: moment(dateString).format("YYYY/MM/DD") });
    } else {
      this.setState({
        printFrom: moment(dateString).locale("en").format("YYYY/MM/DD"),
      });
    }
    printFromDate = moment(dateString).locale("en").format("YYYY-MM-DD");
  };
  onChangePrintTo = (date, dateString) => {
    if (this.props.isRTL) {
      this.setState({ printTo: moment(dateString).format("YYYY/MM/DD") });
    } else {
      this.setState({
        printTo: moment(dateString).locale("en").format("YYYY/MM/DD"),
      });
    }
    printToDate = moment(dateString).locale("en").format("YYYY-MM-DD");
  };
  onChangeFrom = (date, dateString) => {
    console.log(dateString);
    if (this.props.isRTL) {
      this.setState({ from: moment(dateString).format("YYYY/MM/DD") });
    } else {
      this.setState({
        from: moment(dateString).locale("en").format("YYYY/MM/DD"),
      });
    }
    fromDate = moment(dateString).locale("en").format("YYYY-MM-DD");
  };
  onChangeTo = (date, dateString) => {
    if (this.props.isRTL) {
      this.setState({ to: moment(dateString).format("YYYY/MM/DD") });
    } else {
      this.setState({
        to: moment(dateString).locale("en").format("YYYY/MM/DD"),
      });
    }
    toDate = moment(dateString).locale("en").format("YYYY-MM-DD");
    if (this.state.filterType === "all") {
      this.getAllCollect(1, false);
      this.getCounts();
    }
    if (this.state.filterType === "day") {
      this.getDaily(1, false);
    }
    if (this.state.filterType === "addition") {
      this.getAddition(1, false);
    }
    if (this.state.filterType === "finish") {
      this.getFinishCollect(1, false);
      this.getCounts();
    }
    if (this.state.filterType === "pending") {
      this.getPendingCollect(1, false);
      this.getCounts();
    }
    if (this.state.filterType === "dues") {
      this.getDues(1, false);
    }
    if (this.state.filterType === "dailyFirstPaid") {
      this.getDailyFirstPaid(1, false);
    }
    if (this.state.filterType === "firstPaid") {
      this.getFirstPaid(1, false);
      this.getCounts();
    }
  };
  chooseAllCollect = (value) => {
    this.setState({
      daily: false,
      firstPaidFilter: false,
      filterType: "all",
      title: allStrings.allPremiums,
      tableType: "type1",
      modal: true,
      num: value,
    });
    this.getAllCollect(1, false);
  };
  chooseDaily = (value) => {
    this.setState({
      daily: true,
      firstPaidFilter: false,
      filterType: "day",
      title: allStrings.daily,
      modal: true,
      tableType: "type1",
      num: value,
    });
    this.getDaily(1, false);
  };
  chooseDailyFistPaid = (value) => {
    this.setState({
      daily: true,
      firstPaidFilter: true,
      filterType: "dayFirstPaid",
      title: allStrings.dailyFirstPaid,
      modal: true,
      tableType: "type2",
      num: value,
    });
    this.getDailyFirstPaid(1, false);
  };
  chooseFistPaid = (value) => {
    this.setState({
      daily: false,
      firstPaidFilter: true,
      filterType: "firstPaid",
      title: allStrings.firstPaids,
      modal: true,
      tableType: "type2",
      num: value,
    });
    this.getFirstPaid(1, false);
  };
  chooseAddtion = (value) => {
    this.setState({
      daily: false,
      firstPaidFilter: false,
      filterType: "addition",
      title: allStrings.additionCollect,
      modal: true,
      tableType: "type2",
      num: value,
    });
    this.getAddition(1, false);
  };
  chooseFinish = (value) => {
    this.setState({
      daily: false,
      firstPaidFilter: false,
      filterType: "finish",
      title: allStrings.finishedPremiums,
      modal: true,
      tableType: "type1",
      num: value,
    });
    this.getFinishCollect(1, false);
  };
  choosePending = (value) => {
    this.setState({
      daily: false,
      firstPaidFilter: false,
      filterType: "pending",
      title: allStrings.pendingPremiums,
      modal: true,
      tableType: "type1",
      num: value,
    });
    this.getPendingCollect(1, false);
  };
  chooseDues = (value) => {
    this.setState({
      daily: false,
      firstPaidFilter: false,
      filterType: "dues",
      title: allStrings.Dues,
      modal: true,
      tableType: "type2",
      num: value,
    });
    this.getDues(1, false);
  };
  chooseReceive = (value) => {
    this.setState({
      daily: false,
      firstPaidFilter: false,
      filterType: "receive",
      title: allStrings.recieveReceivables,
      modal: true,
      tableType: "type2",
      num: value,
    });
    this.getReceive(1, false);
  };
  modal = () => (
    <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
      <MDBModalHeader toggle={this.toggle}>{this.state.title}</MDBModalHeader>
      <MDBModalBody>
        <MDBContainer>
          <MDBRow>
            <MDBCol md="3" sm="3">
              <p style={{ fontSize: "60px", fontWeight: "700", color: "#aaa" }}>
                {this.state.filterType === "all"
                  ? this.state.count.allCollections
                  : this.state.filterType === "firstPaid"
                  ? this.state.count.firstPaidTotal
                  : //:this.state.filterType === "addition"?this.state.count.firstPaidTotal
                  this.state.filterType === "finish"
                  ? this.state.count.finishedPremiums
                  : this.state.filterType === "pending"
                  ? this.state.count.pendingPremiums
                  : ""}
              </p>
            </MDBCol>
            <MDBCol md="5" sm="5">
              {this.state.daily !== true && (
                <div style={{ paddingtop: "15px" }}>
                  <div style={{ marginBottom: "10px", textAlign: "center" }}>
                    <span
                      className="fromSpan"
                      style={{ float: this.props.isRTL ? "right" : "left" }}
                    >
                      {allStrings.from}
                    </span>
                    <div
                      style={{
                        display: "inline-block",
                        textAlign: "center",
                        marginLeft: this.props.isRTL ? "35px" : "-93px",
                      }}
                    >
                      <ConfigProvider
                        locale={this.props.isRTL ? locale_ar : locale_en}
                      >
                        <DatePicker
                          defaultValue={moment(this.state.from, "YYYY/MM/DD")}
                          onChange={this.onChangeFrom}
                        />
                      </ConfigProvider>
                      <span className="newSpan">
                        <img
                          alt=""
                          style={{ marginRight: "4px" }}
                          src={require("../../assets/images/icons8_edit_property.png")}
                        ></img>
                        {this.state.from}
                      </span>
                    </div>
                  </div>
                  <div style={{ marginBottom: "10px", textAlign: "center" }}>
                    <span
                      className="fromSpan"
                      style={{ float: this.props.isRTL ? "right" : "left" }}
                    >
                      {allStrings.to}
                    </span>
                    <div
                      style={{
                        display: "inline-block",
                        textAlign: "center",
                        marginLeft: this.props.isRTL ? "35px" : "-93px",
                      }}
                    >
                      <ConfigProvider
                        locale={this.props.isRTL ? locale_ar : locale_en}
                      >
                        <DatePicker
                          defaultValue={moment(this.state.to, "YYYY/MM/DD")}
                          onChange={this.onChangeTo}
                        />
                      </ConfigProvider>
                      <span className="newSpan">
                        <img
                          alt=""
                          style={{ marginRight: "4px" }}
                          src={require("../../assets/images/icons8_edit_property.png")}
                        ></img>
                        {this.state.to}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </MDBCol>
            {this.state.filterType === "addition" ? (
              <MDBCol md="4" sm="4">
                <MDBRow>
                  <MDBCol md="6" sm="6">
                    <p
                      style={{
                        color: "#43425D",
                        fontWeight: "600",
                        fontSize: "18px",
                        cursor: "pointer",
                      }}
                    >
                      {this.state.additionalCollectionCount.totalCost
                        ? this.state.additionalCollectionCount.totalCost
                        : ""}
                    </p>
                  </MDBCol>
                  <MDBCol md="6" sm="6">
                    <p
                      style={{
                        color: "#43425D",
                        fontWeight: "600",
                        fontSize: "18px",
                        textAlign: "right",
                      }}
                    >
                      {allStrings.total}
                    </p>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="6" sm="6">
                    <p
                      style={{
                        color: "green",
                        fontWeight: "600",
                        fontSize: "18px",
                        cursor: "pointer",
                      }}
                    >
                      {this.state.additionalCollectionCount.collectedCost
                        ? this.state.additionalCollectionCount.collectedCost
                        : ""}
                    </p>
                  </MDBCol>
                  <MDBCol md="6" sm="6">
                    <p
                      style={{
                        color: "green",
                        fontWeight: "600",
                        fontSize: "18px",
                        textAlign: "right",
                      }}
                    >
                      {allStrings.complete}
                    </p>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="6" sm="6">
                    <p
                      style={{
                        color: "red",
                        fontWeight: "600",
                        fontSize: "18px",
                        cursor: "pointer",
                      }}
                    >
                      {this.state.additionalCollectionCount.remainCost
                        ? this.state.additionalCollectionCount.remainCost
                        : 0}
                    </p>
                  </MDBCol>
                  <MDBCol md="6" sm="6">
                    <p
                      style={{
                        color: "red",
                        fontWeight: "600",
                        fontSize: "18px",
                        textAlign: "right",
                      }}
                    >
                      {allStrings.remain}
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            ) : (
              <MDBCol md="4" sm="4"></MDBCol>
            )}
          </MDBRow>
          {this.state.filterType === "addition" && (
            <MDBRow>
              <MDBCol xl="4" lg="4" md="4" sm="6">
                <div
                  className="smallAddButton"
                  onClick={() => this.addAdditionCollected()}
                >
                  <MDBIcon icon="plus" />
                </div>
                <span
                  onClick={() => this.addAdditionCollected()}
                  style={{
                    float: "right",
                    color: "#43425D",
                    fontWeight: "600",
                    padding: "20px 2px",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                >
                  {allStrings.giveMoney}
                </span>
              </MDBCol>
              <MDBCol xl="8" lg="8" md="8" sm="6">
                <MDBRow style={{ marginBottom: "25px", marginTop: "15px" }}>
                  <MDBCol md="9" sm="9">
                    <input
                      name="additionCollectCost"
                      onChange={this.changeHandlerAdditionCollected}
                      id="defaultFomRegisterNameEx16"
                      type="number"
                      min={1}
                      className="form-control"
                      placeholder={allStrings.EnterPrice}
                      style={{ direction: this.props.isRTL ? "rtl" : "ltr" }}
                    />
                  </MDBCol>
                  <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                    <label
                      htmlFor="defaultFomRegisterNameEx16"
                      className="grey-text formLabel"
                    >
                      {allStrings.cost}
                    </label>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          )}
          <MDBRow>
            <MDBCol md="12">
              <table class="table table-borderless">
                <thead class="thead-light">
                  {this.state.tableType === "type2" ? (
                    <tr>
                      {this.state.filterType === "addition" && (
                        <th scope="col">{allStrings.receive}</th>
                      )}
                      <th scope="col">{allStrings.date}</th>
                      {this.state.filterType === "addition" && (
                        <th scope="col">{allStrings.paid}</th>
                      )}
                      {this.state.filterType === "addition" && (
                        <th scope="col">{allStrings.remain}</th>
                      )}
                      {this.state.filterType === "dues" && (
                        <th scope="col">{allStrings.description}</th>
                      )}
                      <th scope="col">{allStrings.money}</th>
                    </tr>
                  ) : (
                    <tr>
                      <th scope="col">{allStrings.date}</th>
                      <th scope="col">{allStrings.product}</th>
                      <th scope="col">{allStrings.client}</th>
                      <th scope="col">{allStrings.money}</th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {this.state.loading2 ? (
                    <tr style={{ height: "200px" }}>
                      <th colspan="4" className="noData">
                        <Skeleton height={400} />
                      </th>
                    </tr>
                  ) : this.state.tableData.length > 0 ? (
                    this.state.tableData.map((val) =>
                      this.state.tableType === "type2" ? (
                        <tr>
                          {this.state.filterType === "addition" && (
                            <td>
                              {val.remain > 0 ? (
                                <div>
                                  <span
                                    onClick={() => {
                                      this.setState({ collectNum: val.id });
                                      this.toggle3();
                                    }}
                                    style={{
                                      color: "#43425D",
                                      fontWeight: "600",
                                      fontSize: "18px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {allStrings.receive}
                                  </span>
                                </div>
                              ) : (
                                <MDBIcon
                                  far
                                  icon="check-circle"
                                  style={{ color: "lawngreen" }}
                                />
                              )}
                            </td>
                          )}
                          <td>{val.createdAt.substring(0, 10)}</td>
                          {this.state.filterType === "addition" && (
                            <td>{val.cost - val.remain}</td>
                          )}
                          {this.state.filterType === "addition" && (
                            <td>{val.remain}</td>
                          )}
                          {this.state.filterType === "dues" && (
                            <td>
                              {this.props.isRTL
                                ? val.description_ar
                                : val.description_en}
                            </td>
                          )}
                          <th scope="row">
                            {this.state.firstPaidFilter
                              ? val.firstPaid
                              : val.cost}
                          </th>
                        </tr>
                      ) : (
                        <tr>
                          <td>{val.paidDate.substring(0, 10)}</td>
                          <td>
                            {this.props.isRTL
                              ? val.product.name_ar
                              : val.product.name_en}
                          </td>
                          <td>{val.client.fullname}</td>
                          <th scope="row">
                            {this.state.firstPaidFilter
                              ? val.firstPaid
                              : val.costPerMonth}
                          </th>
                        </tr>
                      )
                    )
                  ) : (
                    <tr style={{ height: "200px" }}>
                      <th colspan="4" className="noData">
                        <p style={{ marginTop: "4rem" }}>{allStrings.noData}</p>
                      </th>
                    </tr>
                  )}
                  <th colspan="4">
                    <MDBRow>
                      {this.state.tableData.length > 0 && (
                        <div
                          style={{
                            textAlign: "center",
                            margin: "auto",
                            marginTop: "3rem",
                          }}
                        >
                          <Pagination
                            onChange={(event, page) => {
                              console.log("page   ", page);
                              if (page !== this.state.page) {
                                if (this.state.filterType === "all") {
                                  this.getAllCollect(page, false);
                                }
                                if (
                                  this.state.filterType === "dailyFirstPaid"
                                ) {
                                  this.getDailyFirstPaid(page, false);
                                }
                                if (this.state.filterType === "firstPaid") {
                                  this.getFirstPaid(page, false);
                                }
                                if (this.state.filterType === "pending") {
                                  this.getPendingCollect(page, false);
                                }
                                if (this.state.filterType === "finish") {
                                  this.getFinishCollect(page, false);
                                }
                                if (this.state.filterType === "dues") {
                                  this.getDues(page, false);
                                }
                                if (this.state.filterType === "day") {
                                  this.getDaily(page, false);
                                }
                                if (this.state.filterType === "addition") {
                                  this.getAddition(page, false);
                                }
                                if (this.state.filterType === "receive") {
                                  this.getReceive(page, false);
                                }

                                this.setState({ page: page });
                              }
                            }}
                            defaultPage={1}
                            page={this.state.page}
                            count={this.state.pages}
                            style={{ color: `blue !important` }}
                          />
                        </div>
                      )}
                    </MDBRow>
                  </th>
                </tbody>
              </table>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBModalBody>
    </MDBModal>
  );
  modal2 = () => (
    <MDBModal isOpen={this.state.modal2} toggle={this.toggle2} size="lg">
      <MDBModalHeader toggle={this.toggle2}>
        {this.state.modalType === "map"
          ? allStrings.location
          : allStrings.completeData}
      </MDBModalHeader>
      <MDBModalBody>
        <MDBContainer>
          <MDBRow>
            {this.state.modalType === "notif" && (
              <MDBCol md="12">
                <form
                  style={{ marginLeft: "15%" }}
                  className="needs-validation"
                  onSubmit={
                    this.state.modalReqType === "notif"
                      ? this.notifSubmitHandler
                      : this.msgSubmitHandler
                  }
                  noValidate
                >
                  <MDBContainer>
                    <MDBRow>
                      <MDBCol md="9">
                        <textarea
                          name="message"
                          onChange={this.changeHandler}
                          type="text"
                          id="defaultFormRegisterNamx1"
                          required
                          placeholder={allStrings.message}
                          className="form-control"
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                        />
                      </MDBCol>
                      <MDBCol md="3">
                        <label
                          htmlFor="defaultFmRegisterNameEx1"
                          className="grey-text formLabel"
                        >
                          {allStrings.message}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBBtn
                        type="submit"
                        style={{
                          margin: "3rem 1rem ",
                          width: "170px",
                          padding: "11px 20px",
                          height: "35px",
                        }}
                        className="btn z-depth-1a "
                        color="primary"
                      >
                        {allStrings.send}
                      </MDBBtn>
                    </MDBRow>
                  </MDBContainer>
                </form>
              </MDBCol>
            )}
            {this.state.modalType === "recieve" && (
              <MDBCol md="12">
                <form
                  style={{ marginLeft: "15%" }}
                  className="needs-validation"
                  onSubmit={this.recieveSubmitHandler}
                  noValidate
                >
                  <MDBContainer>
                    <MDBRow>
                      <MDBCol md="9">
                        <input
                          name="cost"
                          onChange={this.changeHandler}
                          type="number"
                          min={0}
                          id="defaultFormRegisterNamx1"
                          required
                          placeholder={allStrings.cost}
                          className="form-control"
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                        />
                      </MDBCol>
                      <MDBCol md="3">
                        <label
                          htmlFor="defaultFmRegisterNameEx1"
                          className="grey-text formLabel"
                        >
                          {allStrings.cost}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBBtn
                        type="submit"
                        style={{
                          margin: "3rem 1rem ",
                          width: "170px",
                          padding: "11px 20px",
                          height: "35px",
                        }}
                        className="btn z-depth-1a "
                        color="primary"
                      >
                        {allStrings.done}
                      </MDBBtn>
                    </MDBRow>
                  </MDBContainer>
                </form>
              </MDBCol>
            )}
            {/*give money to salesMan*/}
            {this.state.modalType === "pay" && (
              <MDBCol md="12">
                <form
                  style={{ marginLeft: "15%" }}
                  className="needs-validation"
                  onSubmit={this.paySubmitHandler}
                  noValidate
                >
                  <MDBContainer>
                    <MDBRow>
                      <MDBCol md="9">
                        <input
                          name="moneyGiven"
                          onChange={this.changeHandler}
                          type="number"
                          min={0}
                          id="defaultFormRegisterNamx1"
                          required
                          placeholder={allStrings.cost}
                          className="form-control"
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                        />
                      </MDBCol>
                      <MDBCol md="3">
                        <label
                          htmlFor="defaultFmRegisterNameEx1"
                          className="grey-text formLabel"
                        >
                          {allStrings.cost}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBBtn
                        type="submit"
                        style={{
                          margin: "3rem 1rem ",
                          width: "170px",
                          padding: "11px 20px",
                          height: "35px",
                        }}
                        className="btn z-depth-1a "
                        color="primary"
                      >
                        {allStrings.done}
                      </MDBBtn>
                    </MDBRow>
                  </MDBContainer>
                </form>
              </MDBCol>
            )}
            {this.state.modalType === "change" && (
              <MDBCol md="12">
                <form
                  style={{ marginLeft: "15%" }}
                  className="needs-validation"
                  onSubmit={this.changeSubmitHandler}
                  noValidate
                >
                  <MDBContainer>
                    <p
                      style={{
                        textAlign: this.props.isRTL ? "right" : "left",
                        margin: "0rem 4rem 3rem 0rem",
                        color: "red",
                      }}
                    >
                      <span>{allStrings.Notice}</span>
                      {allStrings.changeDetails}
                    </p>
                    <MDBRow>
                      <MDBCol md="9">
                        <TreeSelect
                          showSearch={false}
                          style={{
                            width: "100%",
                            textAlign: this.props.isRTL ? "right" : "left",
                          }}
                          treeCheckable={false}
                          treeNodeFilterProp="title"
                          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                          placeholder={allStrings.chooseSalesMan}
                          onChange={(value) => {
                            console.log(value);
                            this.setState({ newSalesMan: value });
                          }}
                        >
                          {this.state.salesMen.map((val) => (
                            <TreeNode
                              value={val.id}
                              title={val.fullname}
                              key={val.id}
                            />
                          ))}
                        </TreeSelect>
                      </MDBCol>
                      <MDBCol md="3">
                        <label
                          htmlFor="defaultFmRegisterNameEx1"
                          className="grey-text formLabel"
                        >
                          {allStrings.newSalesMan}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBBtn
                        type="submit"
                        style={{
                          margin: "3rem 1rem ",
                          width: "170px",
                          padding: "11px 20px",
                          height: "35px",
                        }}
                        className="btn z-depth-1a "
                        color="primary"
                      >
                        {allStrings.done}
                      </MDBBtn>
                    </MDBRow>
                  </MDBContainer>
                </form>
              </MDBCol>
            )}
            {this.state.modalType === "map" && (
              <MDBCol md="12">
                <div style={{ width: "100%", height: "300px" }}></div>
              </MDBCol>
            )}
          </MDBRow>
        </MDBContainer>
      </MDBModalBody>
    </MDBModal>
  );
  modal3 = () => (
    <MDBModal isOpen={this.state.modal3} toggle={this.toggle3} size="lg">
      <MDBModalHeader toggle={this.toggle3}>
        {allStrings.completeData}
      </MDBModalHeader>
      <MDBModalBody>
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12">
              <form
                style={{ marginLeft: "15%" }}
                className="needs-validation"
                onSubmit={this.updateAddetionCollectedSubmitHandel}
                noValidate
              >
                <MDBContainer>
                  <MDBRow style={{ marginBottom: "6px", marginTop: "6px" }}>
                    <MDBCol md="9" sm="9">
                      <input
                        name="recieveFromAddition"
                        onChange={this.changeHandler}
                        type="number"
                        min={0}
                        id="defaultFormRegisNameEx12"
                        className="form-control"
                        placeholder={allStrings.cost}
                        style={{ direction: this.props.isRTL ? "rtl" : "ltr" }}
                        required
                      />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                      <label
                        htmlFor="defaultFormRegisNameEx12"
                        className="grey-text formLabel"
                      >
                        {allStrings.cost}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBBtn
                      type="submit"
                      style={{
                        margin: "3rem 1rem ",
                        width: "170px",
                        padding: "11px 20px",
                        height: "35px",
                      }}
                      className="btn z-depth-1a "
                      color="primary"
                    >
                      {allStrings.receive}
                    </MDBBtn>
                  </MDBRow>
                </MDBContainer>
              </form>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBModalBody>
    </MDBModal>
  );
  modal4 = () => (
    <MDBModal isOpen={this.state.modal4} toggle={this.toggle4} size="lg">
      <MDBModalHeader toggle={this.toggle4}>{allStrings.print}</MDBModalHeader>
      <MDBModalBody>
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12">
              <form
                style={{ marginLeft: "15%", marginRight: "15%" }}
                className="needs-validation"
                onSubmit={this.getStatisticsHandel}
                noValidate
              >
                <MDBContainer>
                  <MDBRow>
                    <div
                      style={{
                        margin: "3rem auto ",
                        color: "white",
                        background: "#4F73AC",
                        width: "40%",
                        padding: "11px 20px",
                        height: "35px",
                      }}
                      className="btn z-depth-1a "
                      color="primary"
                      onClick={() => {
                        this.setState({
                          reportType: "DURATION",
                          enablePrint: false,
                        });
                      }}
                    >
                      {allStrings.specialDuration}
                    </div>
                    <div
                      style={{
                        margin: "3rem auto ",
                        color: "white",
                        background: "#4F73AC",
                        width: "40%",
                        padding: "11px 20px",
                        height: "35px",
                      }}
                      className="btn z-depth-1a "
                      color="primary"
                      onClick={() => {
                        this.setState({
                          reportType: "ALL",
                          enablePrint: false,
                        });
                      }}
                    >
                      {allStrings.all}
                    </div>
                    {/*<TreeSelect 
                      style={{ width: '100%',float:'right',height:'40px'  }} 
                      placeholder={allStrings.type}
                      value = {this.state.reportType}
                      onChange={(value) => {
                        this.setState({reportType:value,enablePrint:false})
                      }} 
                    >
                    
                      <TreeNode value="DURATION" title={allStrings.duration}></TreeNode>
                      <TreeNode value="ALL"title={allStrings.all}></TreeNode>
                      
                    </TreeSelect>*/}
                  </MDBRow>
                  {this.state.reportType === "DURATION" && (
                    <MDBRow>
                      <MDBCol xl="1" lg="1" md="12" sm="12"></MDBCol>
                      <MDBCol xl="8" lg="8" md="12" sm="12">
                        <div style={{ paddingtop: "15px" }}>
                          <div
                            style={{
                              marginBottom: "10px",
                              textAlign: "center",
                            }}
                          >
                            <span
                              className="fromSpan"
                              style={{
                                float: this.props.isRTL ? "right" : "left",
                                marginRight: "8%",
                              }}
                            >
                              {allStrings.from}
                            </span>
                            <div
                              style={{
                                display: "inline-block",
                                textAlign: "center",
                                marginLeft: this.props.isRTL
                                  ? "55px"
                                  : "-255px",
                              }}
                            >
                              <ConfigProvider
                                locale={
                                  this.props.isRTL ? locale_ar : locale_en
                                }
                              >
                                <DatePicker
                                  defaultValue={moment(
                                    this.state.printFrom,
                                    "YYYY/MM/DD"
                                  )}
                                  onChange={this.onChangePrintFrom}
                                />
                              </ConfigProvider>
                              <span
                                className="newSpan"
                                style={{ left: "110px" }}
                              >
                                <img
                                  alt=""
                                  style={{ marginRight: "4px" }}
                                  src={require("../../assets/images/icons8_edit_property.png")}
                                ></img>
                                {this.state.printFrom}
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              marginBottom: "10px",
                              textAlign: "center",
                            }}
                          >
                            <span
                              className="fromSpan"
                              style={{
                                float: this.props.isRTL ? "right" : "left",
                                marginRight: "8%",
                              }}
                            >
                              {allStrings.to}
                            </span>
                            <div
                              style={{
                                display: "inline-block",
                                textAlign: "center",
                                marginLeft: this.props.isRTL
                                  ? "55px"
                                  : "-255px",
                              }}
                            >
                              <ConfigProvider
                                locale={
                                  this.props.isRTL ? locale_ar : locale_en
                                }
                              >
                                <DatePicker
                                  defaultValue={moment(
                                    this.state.printTo,
                                    "YYYY/MM/DD"
                                  )}
                                  onChange={this.onChangePrintTo}
                                />
                              </ConfigProvider>
                              <span
                                className="newSpan"
                                style={{ left: "110px" }}
                              >
                                <img
                                  alt=""
                                  style={{ marginRight: "4px" }}
                                  src={require("../../assets/images/icons8_edit_property.png")}
                                ></img>
                                {this.state.printTo}
                              </span>
                            </div>
                          </div>
                        </div>
                      </MDBCol>
                      <MDBCol xl="1" lg="1" md="12" sm="12"></MDBCol>
                    </MDBRow>
                  )}

                  <MDBRow>
                    {this.state.enablePrint === false ? (
                      <MDBBtn
                        type="submit"
                        style={{
                          margin: "3rem auto ",
                          width: "100%",
                          padding: "11px 20px",
                          height: "35px",
                        }}
                        className="btn z-depth-1a "
                        color="primary"
                      >
                        {allStrings.readyToPrint}
                      </MDBBtn>
                    ) : (
                      <MDBCol md="12" sm="12">
                        <ReactToPrint
                          documentTitle={
                            "كشف حساب المندوب بتاريخ " +
                            moment().format("YYYY/MM/DD")
                          }
                          trigger={() => {
                            return (
                              <div
                                style={{ textAlign: "center", padding: "3rem" }}
                              >
                                <img
                                  alt=""
                                  style={{ cursor: "pointer" }}
                                  src={require("../../assets/images/icons8_pdf.png")}
                                ></img>
                                <span
                                  style={{
                                    color: "#FF3B40",
                                    fontWeight: "600",
                                    fontSize: "20px",
                                    position: "absolute",
                                    padding: "10px 10px",
                                    cursor: "pointer",
                                  }}
                                >
                                  {allStrings.print}
                                </span>
                              </div>
                            );
                          }}
                          content={() => this.componentRef}
                        />
                        <div style={{ display: "none" }}>
                          <ComponentToPrint
                            data={this.state.orders}
                            theCompany={this.state.theCompany}
                            reportType={this.state.reportType}
                            additionCollectAll={this.state.additionCollectAll}
                            additionCollectRemain={
                              this.state.additionCollectRemain
                            }
                            additionCollectComplete={
                              this.state.additionCollectComplete
                            }
                            totalCommission={this.state.totalCommission}
                            finishedPremiums={this.state.finishedPremiums}
                            pendingPremiums={this.state.pendingPremiums}
                            allCollections={this.state.allCollections}
                            salesMan={this.state.SalesMan}
                            from={printFromDate}
                            to={printToDate}
                            ref={(el) => (this.componentRef = el)}
                          />
                        </div>
                      </MDBCol>
                    )}
                  </MDBRow>
                </MDBContainer>
              </form>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBModalBody>
    </MDBModal>
  );

  render() {
    console.log(this.state.title);
    console.log(this.state.filterType);
    let { cardImg, profileImg, photoIndex, isOpen1, isOpen2 } = this.state;
    profileImg = [profileImg];
    return (
      <div className="stockForm">
        {this.modal()}
        {this.modal2()}
        {this.modal3()}
        {this.modal4()}
        <MDBContainer>
          <MDBRow>
            <div className="top" style={{ width: "100%", textAlign: "right" }}>
              <NavLink to="/SalesMan">
                <MDBIcon icon="times" className="closeIcon" />
              </NavLink>
              <span
                className="newTitle"
                style={{ margin: "1rem 0rem 1rem .5rem" }}
              >
                {this.state.SalesMan.lastSeen
                  ? moment().calendar(this.state.SalesMan.lastSeen)
                  : moment().calendar()}
              </span>
              <span className="newTitle">{allStrings.lastSeen}</span>
            </div>
          </MDBRow>
          <form
            className="needs-validation"
            onSubmit={this.updateSubmitHandler}
            noValidate
            style={{ marginLeft: "2rem" }}
          >
            <MDBRow>
              <MDBContainer>
                <MDBRow>
                  <MDBCol lg="6" md="12">
                    <MDBRow>
                      <div style={{ width: "94%", marginTop: "2.5rem" }}>
                        <label
                          htmlFor="defaultFormRegisterNameE"
                          style={{
                            float: "right",
                            fontWeight: "800",
                            color: "#43425D",
                            fontSize: "18px",
                          }}
                        >
                          {allStrings.recievedProducts}
                        </label>
                        <label
                          htmlFor="defaultFormRegisterNameE"
                          style={{
                            float: "left",
                            fontWeight: "400",
                            color: "#43425D",
                            fontSize: "16px",
                          }}
                        >
                          {this.state.recievedProductsCount}
                        </label>
                      </div>
                      <MDBCol md="1">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.props.history.push("/receivedProducts", {
                              data: this.state.SalesMan,
                            })
                          }
                        >
                          <span
                            style={{
                              fontSize: "13px",
                              color: "#478DFF",
                              fontWeight: "600",
                              margin: "0px",
                            }}
                          >
                            {allStrings.showD}
                          </span>
                          <img
                            alt=""
                            width="15px"
                            src={require("../../assets/images/icons8_external_link_480px_2.png")}
                          ></img>
                        </div>
                      </MDBCol>
                      <MDBCol md="11" style={{ paddingRight: "15px" }}>
                        {this.state.loading ? (
                          <Skeleton height={40} />
                        ) : this.state.recievedProducts.length > 0 ? (
                          this.state.recievedProducts.map((val) => (
                            <div
                              style={{
                                display: "inline-block",
                                float: "right",
                                marginLeft: "2px",
                              }}
                            >
                              <img
                                alt=""
                                className="salesManProduct"
                                src={val.product.mainImg}
                              ></img>
                              <span className="productNum">{val.remain}</span>
                            </div>
                          ))
                        ) : (
                          <div
                            className="noData"
                            style={{ margin: "1rem auto" }}
                          >
                            {allStrings.noData}
                          </div>
                        )}
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <div style={{ width: "94%", marginTop: "1.5rem" }}>
                        <label
                          htmlFor="defaultFormRegisterNameE"
                          style={{
                            float: "right",
                            fontWeight: "800",
                            color: "#43425D",
                            fontSize: "18px",
                          }}
                        >
                          {allStrings.collectedClients}
                        </label>
                        <label
                          htmlFor="defaultFormRegisterNameE"
                          style={{
                            float: "left",
                            fontWeight: "400",
                            color: "#43425D",
                            fontSize: "16px",
                          }}
                        >
                          {this.state.salesManClientsCount}
                        </label>
                      </div>
                      <MDBCol md="1">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.props.history.push("/salesMenClients", {
                              data: this.state.SalesMan,
                            })
                          }
                        >
                          <span
                            style={{
                              fontSize: "13px",
                              color: "#478DFF",
                              fontWeight: "600",
                              margin: "0px",
                            }}
                          >
                            {allStrings.showD}
                          </span>
                          <img
                            alt=""
                            width="15px"
                            src={require("../../assets/images/icons8_external_link_480px_2.png")}
                          ></img>
                        </div>
                      </MDBCol>
                      <MDBCol md="11" style={{ paddingRight: "15px" }}>
                        {this.state.loading ? (
                          <Skeleton height={40} />
                        ) : this.state.salesManClients.length > 0 ? (
                          this.state.salesManClients.map((val) => (
                            <div
                              style={{
                                display: "inline-block",
                                marginLeft: "4px",
                                float: "right",
                              }}
                            >
                              <img
                                alt=""
                                className="salesManProduct"
                                src={
                                  val.img
                                    ? val.img
                                    : require("../../assets/images/profileicon.jpg")
                                }
                              ></img>
                            </div>
                          ))
                        ) : (
                          <div
                            className="noData"
                            style={{ margin: "1rem auto" }}
                          >
                            {allStrings.noData}
                          </div>
                        )}
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <div style={{ width: "94%", marginTop: "1.5rem" }}>
                        <label
                          htmlFor="defaultFormRegisterNameE"
                          style={{
                            float: "right",
                            fontWeight: "800",
                            color: "#43425D",
                            fontSize: "18px",
                          }}
                        >
                          {allStrings.Receivables}
                        </label>
                        <label
                          htmlFor="defaultFormRegisterNameE"
                          style={{
                            float: "left",
                            fontWeight: "400",
                            color: "#43425D",
                            fontSize: "16px",
                          }}
                        >
                          {this.state.count.Dues}
                        </label>
                      </div>
                      <MDBCol md="12" style={{ cursor: "pointer" }}>
                        <div className="receivable">
                          <div
                            className="receiveBox"
                            onClick={() =>
                              this.chooseDaily(this.state.count.totalDaily)
                            }
                          >
                            <p className="topReceiveBox">{allStrings.daily}</p>
                            <p className="bottomReceiveBox">
                              {this.state.loading ? (
                                <Skeleton height={20} />
                              ) : (
                                this.state.count.totalDaily
                              )}
                            </p>
                          </div>
                          <div
                            className="receiveBox"
                            onClick={() =>
                              this.chooseDailyFistPaid(
                                this.state.count.dailyFirstPaid
                              )
                            }
                          >
                            <p className="topReceiveBox">
                              {allStrings.dailyFirstPaid}
                            </p>
                            <p className="bottomReceiveBox">
                              {this.state.loading ? (
                                <Skeleton height={20} />
                              ) : (
                                this.state.count.dailyFirstPaid
                              )}
                            </p>
                          </div>

                          <div
                            className="receiveBox1"
                            style={{ backgroundColor: "#267a46" }}
                            onClick={() =>
                              this.chooseAllCollect(
                                this.state.count.allCollections
                              )
                            }
                          >
                            <p className="topReceiveBox">
                              {allStrings.finishedPremiums}
                            </p>
                            <p className="bottomReceiveBox">
                              {this.state.loading ? (
                                <Skeleton height={20} />
                              ) : (
                                this.state.count.finishedPremiums
                              )}
                            </p>
                          </div>
                          <div
                            className="receiveBox1"
                            style={{ backgroundColor: "#912828" }}
                            onClick={() =>
                              this.chooseAddtion(
                                this.state.count.additionCollect
                              )
                            }
                          >
                            <p className="topReceiveBox">
                              {allStrings.pendingPremiums}
                            </p>
                            <p className="bottomReceiveBox">
                              {this.state.loading ? (
                                <Skeleton height={20} />
                              ) : (
                                this.state.count.pendingPremiums
                              )}
                            </p>
                          </div>
                          <div
                            className="receiveBox1"
                            onClick={() =>
                              this.chooseFinish(
                                this.state.count.finishedPremiums
                              )
                            }
                          >
                            <p className="topReceiveBox">
                              {allStrings.allPremiums}
                            </p>
                            <p className="bottomReceiveBox">
                              {this.state.loading ? (
                                <Skeleton height={20} />
                              ) : (
                                this.state.count.allCollections
                              )}
                            </p>
                          </div>
                          <div
                            className="receiveBox"
                            style={{ backgroundColor: "#070d16" }}
                            onClick={() =>
                              this.choosePending(
                                this.state.count.pendingPremiums
                              )
                            }
                          >
                            <p className="topReceiveBox">
                              {allStrings.additionCollect}
                            </p>
                            <p className="bottomReceiveBox">
                              {this.state.loading ? (
                                <Skeleton height={20} />
                              ) : (
                                this.state.count.additionCollect
                              )}
                            </p>
                          </div>
                          <div
                            className="receiveBox"
                            onClick={() =>
                              this.chooseDues(this.state.count.Dues)
                            }
                          >
                            <p className="topReceiveBox">{allStrings.Dues}</p>
                            <p className="bottomReceiveBox">
                              {this.state.loading ? (
                                <Skeleton height={20} />
                              ) : (
                                this.state.count.Dues
                              )}
                            </p>
                          </div>
                          <div
                            className="receiveBox1"
                            onClick={() =>
                              this.chooseReceive(
                                this.state.count.recieveReceivables
                              )
                            }
                          >
                            <p className="topReceiveBox">
                              {allStrings.recieveReceivables}
                            </p>
                            <p className="bottomReceiveBox">
                              {this.state.loading ? (
                                <Skeleton height={20} />
                              ) : (
                                this.state.count.recieveReceivables
                              )}
                            </p>
                          </div>
                          <div
                            className="receiveBox1"
                            onClick={() =>
                              this.chooseFistPaid(
                                this.state.count.firstPaidTotal
                              )
                            }
                          >
                            <p className="topReceiveBox">
                              {allStrings.firstPaids}
                            </p>
                            <p className="bottomReceiveBox">
                              {this.state.loading ? (
                                <Skeleton height={20} />
                              ) : (
                                this.state.count.firstPaidTotal
                              )}
                            </p>
                          </div>
                          {/*<div className="receiveBox" >
                          <p className="topReceiveBox">{allStrings.collectiblesCost}</p>
                          <p className="bottomReceiveBox">{this.state.loading?<Skeleton height={20} />:this.state.count.debt}</p>
                        </div>*/}
                          <div className="receiveBox1">
                            <p className="topReceiveBox">
                              {allStrings.salesManDebt}
                            </p>
                            <p className="bottomReceiveBox">
                              {this.state.loading ? (
                                <Skeleton height={20} />
                              ) : (
                                this.state.count.totalDebt
                              )}
                            </p>
                          </div>
                          {/* <div className="receiveBox" onClick={() => this.props.history.push('/receivedProducts', { data: this.state.SalesMan })} >
                            <p className="topReceiveBox">{allStrings.recievedProducts}</p>
                            <p className="bottomReceiveBox">{this.state.loading ? <Skeleton height={20} /> : this.state.count.RecieveProducts}</p>
                          </div> */}
                        </div>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow
                      style={{
                        borderTop: "1px solid #000",
                        marginTop: "2rem",
                        paddingTop: "1rem",
                      }}
                    >
                      {!this.state.SalesMan.appBlocked && (
                        <MDBCol
                          lg="6"
                          md="12"
                          style={{ margin: "auto", textAlign: "center" }}
                        >
                          <img
                            style={{ margin: "auto" }}
                            src={require("../../assets/images/confirm.svg")}
                          />
                          <span
                            style={{
                              color: "#329832",
                              margin: "auto",
                              fontSize: "20px",
                              fontWeight: "600",
                              display: "block",
                              textAlign: "center",
                            }}
                          >
                            فعال في التطبيق
                          </span>
                        </MDBCol>
                      )}
                      {this.state.SalesMan.appBlocked && (
                        <MDBCol
                          lg="6"
                          md="12"
                          style={{ margin: "auto", textAlign: "center" }}
                        >
                          <img
                            src={require("../../assets/images/cancel.svg")}
                          />
                          <span
                            style={{
                              color: "red",
                              margin: "auto",
                              fontSize: "20px",
                              fontWeight: "600",
                              textAlign: "center",
                              display: "block",
                            }}
                          >
                            محظور في التطبيق
                          </span>
                        </MDBCol>
                      )}

                      {this.state.blocked && (
                        <MDBCol
                          lg="6"
                          md="12"
                          style={{ margin: "auto", textAlign: "center" }}
                        >
                          <img
                            src={require("../../assets/images/cancel.svg")}
                          />
                          <span
                            style={{
                              color: "red",
                              margin: "auto",
                              fontSize: "20px",
                              fontWeight: "600",
                              textAlign: "center",
                              display: "block",
                            }}
                          >
                            محظور من الشركة
                          </span>
                        </MDBCol>
                      )}
                      {!this.state.blocked && (
                        <MDBCol
                          lg="6"
                          md="12"
                          style={{ margin: "auto", textAlign: "center" }}
                        >
                          <img
                            src={require("../../assets/images/confirm.svg")}
                          />
                          <span
                            style={{
                              color: "#329832",
                              margin: "auto",
                              fontSize: "20px",
                              fontWeight: "600",
                              textAlign: "center",
                              display: "block",
                            }}
                          >
                            فعال في الشركة
                          </span>
                        </MDBCol>
                      )}
                    </MDBRow>

                    <MDBRow>
                      <MDBBtn
                        type="submit"
                        style={{
                          margin: "1rem",
                          width: "150px",
                          padding: "9px",
                          height: "35px",
                          marginBottom: "80px",
                          marginTop: "80px",
                        }}
                        className="btn z-depth-1a "
                        color="primary"
                      >
                        {allStrings.edit}
                      </MDBBtn>
                      <MDBCol
                        style={{
                          margin: "1rem",
                          width: "150px",
                          height: "35px",
                          marginBottom: "80px",
                          marginTop: "80px",
                          fontWeight: "600",
                          fontSize: "20px",
                          color: "#ff5722",
                          textAlign: "right",
                        }}
                      >
                        <img src={require("../../assets/images/pdf.png")} />

                        <span>طباعة حساب المندوب</span>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                  <MDBCol lg="6" md="12" style={{ borderLeft: "1px solid" }}>
                    <MDBRow>
                      <MDBCol md="12">
                        <label
                          htmlFor="defaultFormRegisterNameEx2"
                          style={{
                            float: "right",
                            fontWeight: "800",
                            color: "#43425D",
                            fontSize: "18px",
                            marginBottom: "2rem",
                            marginRight: "4rem",
                          }}
                        >
                          {allStrings.SalesMan}
                        </label>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="9" sm="9">
                        <input
                          name="fullname"
                          onChange={this.changeHandler}
                          type="text"
                          id="defaultFormRegisterNameEx5"
                          required
                          className="form-control hash-new"
                          placeholder={allStrings.enterName}
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                          defaultValue={this.state.SalesMan.fullname}
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx5"
                          className="grey-text formLabel"
                        >
                          {allStrings.name}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="9" sm="9">
                        <input
                          name="password"
                          onChange={this.changeHandler}
                          type="text"
                          id="defaultFormRegisterNameEx5"
                          className="form-control hash-new"
                          placeholder={allStrings.enterPassword}
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                          defaultValue={this.state.SalesMan.password}
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx5"
                          className="grey-text formLabel"
                        >
                          {allStrings.password}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="9" sm="9">
                        {this.state.profileImg === null && (
                          <div className="smallUploadContainer">
                            <img
                              alt=""
                              name="img"
                              className="smallUploderImg"
                              src={require("../../assets/images/Path 343.png")}
                            ></img>
                            <input
                              onChange={this.fileHandlerProfileImg}
                              type="file"
                              id="defaultFormRegisterNameEx6"
                              required
                              className="smallUploader"
                            />
                            <MDBBtn
                              color="primary"
                              size="sm"
                              className="smallUploaderBtn"
                            >
                              {allStrings.chooseFile}
                            </MDBBtn>
                            <p
                              style={{
                                fontSize: "14px",
                                marginTop: "3px 15px",
                                float: "right",
                                paddingTop: "4px",
                              }}
                              className="grey-text"
                            >
                              {allStrings.dropHere}
                            </p>
                          </div>
                        )}
                        <div className="previewRaduis">
                          {this.state.profileImg != null && (
                            <MDBRow>
                              <MDBCol md="3">
                                <div
                                  style={{
                                    paddingTop: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.setState({ isOpen2: true })
                                  }
                                >
                                  <span
                                    style={{
                                      fontSize: "17px",
                                      color: "#478DFF",
                                      fontWeight: "600",
                                      margin: "5px",
                                    }}
                                  >
                                    {allStrings.showD}
                                  </span>
                                  <img
                                    alt=""
                                    className="showImge"
                                    width="20px"
                                    src={require("../../assets/images/icons8_external_link_480px_2.png")}
                                  ></img>
                                </div>
                              </MDBCol>
                              <MDBCol md="9" style={{ paddingRight: "30px" }}>
                                {this.state.profileImg != null && (
                                  <div
                                    style={{
                                      display: "inline-block",
                                      float: "right",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    <img
                                      alt=""
                                      src={
                                        this.state.profileImgType === "data"
                                          ? URL.createObjectURL(
                                              this.state.profileImg
                                            )
                                          : this.state.profileImg
                                      }
                                    />
                                    <MDBIcon
                                      icon="times"
                                      className="mr-2 removeImg"
                                      onClick={() => this.removeProfileImg()}
                                    ></MDBIcon>
                                  </div>
                                )}
                              </MDBCol>
                            </MDBRow>
                          )}
                          {isOpen2 && (
                            <Lightbox
                              mainSrc={profileImg[photoIndex]}
                              nextSrc={
                                profileImg[(photoIndex + 1) % profileImg.length]
                              }
                              prevSrc={
                                profileImg[
                                  (photoIndex + profileImg.length - 1) %
                                    profileImg.length
                                ]
                              }
                              onCloseRequest={() =>
                                this.setState({ isOpen2: false, photoIndex: 0 })
                              }
                              onMovePrevRequest={() =>
                                this.setState({
                                  photoIndex:
                                    (photoIndex + profileImg.length - 1) %
                                    profileImg.length,
                                })
                              }
                              onMoveNextRequest={() =>
                                this.setState({
                                  photoIndex:
                                    (photoIndex + 1) % profileImg.length,
                                })
                              }
                            />
                          )}
                        </div>
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx7"
                          className="grey-text formLabel"
                        >
                          {allStrings.profileImg}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="9" sm="9">
                        {this.state.cardImg === null && (
                          <div className="smallUploadContainer">
                            <img
                              alt=""
                              name="img"
                              className="smallUploderImg"
                              src={require("../../assets/images/Path 343.png")}
                            ></img>
                            <input
                              multiple
                              onChange={this.fileHandlerCardImg}
                              type="file"
                              id="defaultFormRegisterNameE03"
                              className="smallUploader"
                            />
                            <MDBBtn
                              color="primary"
                              size="sm"
                              className="smallUploaderBtn"
                            >
                              {allStrings.chooseFile}
                            </MDBBtn>
                            <p
                              style={{
                                fontSize: "14px",
                                marginTop: "3px 15px",
                                float: "right",
                                paddingTop: "4px",
                              }}
                              className="grey-text"
                            >
                              {allStrings.dropHere}
                            </p>
                          </div>
                        )}

                        {this.state.cardImg != null && (
                          <div className="previewRaduis">
                            <MDBRow>
                              <MDBCol md="3">
                                <div
                                  style={{
                                    paddingTop: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.setState({ isOpen1: true })
                                  }
                                >
                                  <span
                                    style={{
                                      fontSize: "17px",
                                      color: "#478DFF",
                                      fontWeight: "600",
                                      margin: "5px",
                                    }}
                                  >
                                    {allStrings.showD}
                                  </span>
                                  <img
                                    alt=""
                                    className="showImge"
                                    width="20px"
                                    src={require("../../assets/images/icons8_external_link_480px_2.png")}
                                  ></img>
                                </div>
                              </MDBCol>
                              <MDBCol md="9" style={{ paddingRight: "30px" }}>
                                {this.state.cardImgViews.map((val) => (
                                  <div
                                    style={{
                                      display: "inline-block",
                                      float: "right",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    <img
                                      alt=""
                                      src={
                                        this.state.cardImgType === "data"
                                          ? val
                                          : val
                                      }
                                    />
                                    <MDBIcon
                                      icon="times"
                                      className="mr-2 removeImg"
                                      onClick={() => this.removeCardImg(val)}
                                    ></MDBIcon>
                                  </div>
                                ))}
                              </MDBCol>
                            </MDBRow>
                            {isOpen1 && (
                              <Lightbox
                                mainSrc={cardImg[photoIndex]}
                                nextSrc={
                                  cardImg[(photoIndex + 1) % cardImg.length]
                                }
                                prevSrc={
                                  cardImg[
                                    (photoIndex + cardImg.length - 1) %
                                      cardImg.length
                                  ]
                                }
                                onCloseRequest={() =>
                                  this.setState({
                                    isOpen1: false,
                                    photoIndex: 0,
                                  })
                                }
                                onMovePrevRequest={() =>
                                  this.setState({
                                    photoIndex:
                                      (photoIndex + cardImg.length - 1) %
                                      cardImg.length,
                                  })
                                }
                                onMoveNextRequest={() =>
                                  this.setState({
                                    photoIndex:
                                      (photoIndex + 1) % cardImg.length,
                                  })
                                }
                              />
                            )}
                          </div>
                        )}
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx003"
                          className="grey-text formLabel"
                        >
                          {allStrings.cardImg}
                        </label>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow style={{}}>
                      <MDBCol md="9" sm="9">
                        <input
                          name="cardNum"
                          onChange={this.changeHandler}
                          type="text"
                          id="defaultFormRegisterNameEx12"
                          className="form-control hash-new"
                          placeholder={allStrings.enterCardNum}
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                          defaultValue={this.state.SalesMan.cardNum}
                          required
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx12"
                          className="grey-text formLabel"
                        >
                          {allStrings.cardNum}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow style={{}}>
                      <MDBCol md="9" sm="9">
                        <input
                          name="commission"
                          onChange={this.changeHandler}
                          type="number"
                          min={0}
                          id="defaultFormRegisNameEx12"
                          className="form-control hash-new"
                          placeholder={allStrings.salesManCommission}
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                          required
                          defaultValue={this.state.SalesMan.commission}
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisNameEx12"
                          className="grey-text formLabel"
                        >
                          {allStrings.salesManCommission}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow style={{}}>
                      <MDBCol md="9" sm="9">
                        <input
                          name="address"
                          onChange={this.changeHandler}
                          type="text"
                          id="defaultFormRegisterNameEx13"
                          className="form-control hash-new"
                          placeholder={allStrings.enterAddress}
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                          defaultValue={this.state.SalesMan.address}
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx13"
                          className="grey-text formLabel"
                        >
                          {allStrings.address}
                        </label>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow style={{}}>
                      <MDBCol md="9" sm="9">
                        <input
                          name="phone"
                          onChange={this.changeHandler}
                          type="text"
                          id="defaultFormRegisterNameEx15"
                          className="form-control hash-new"
                          placeholder={allStrings.enterPhone}
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                          defaultValue={this.state.SalesMan.phone}
                          required
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx15"
                          className="grey-text formLabel"
                        >
                          {allStrings.phone}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow style={{}}>
                      <MDBCol md="9" sm="9">
                        <input
                          name="anotherPhone"
                          onChange={this.changeHandler}
                          type="text"
                          id="defaultFormRegisterNameEx16"
                          className="form-control hash-new"
                          placeholder={allStrings.EnterAnotherPhone}
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                          defaultValue={this.state.SalesMan.anotherPhone}
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx16"
                          className="grey-text formLabel"
                        >
                          {allStrings.anotherPhone}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow style={{}}>
                      <MDBCol md="9" sm="9">
                        <textarea
                          name="notes"
                          onChange={this.changeHandler}
                          type="text"
                          id="defaultFormRegisterNameEx17"
                          className="form-control hash-new"
                          placeholder={allStrings.enterNote}
                          style={{
                            height: "90px",
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                          defaultValue={this.state.SalesMan.notes}
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx17"
                          className="grey-text formLabel"
                        >
                          {allStrings.note}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol lg="4" md="12" style={{ padding: "6px" }}>
                        <MDBBtn
                          onClick={this.openChange}
                          className="btn z-depth-1a hash-btn"
                          color="#4F73AC"
                        >
                          {allStrings.changeSalesMan}
                        </MDBBtn>
                      </MDBCol>
                      <MDBCol lg="4" md="12" style={{ padding: "6px" }}>
                        <MDBBtn
                          onClick={this.openReceiveMoney}
                          className="btn z-depth-1a hash-btn"
                          color="#4F73AC"
                        >
                          {allStrings.receiveMoney}
                        </MDBBtn>
                      </MDBCol>

                      <MDBCol lg="4" md="12" style={{ padding: "6px" }}>
                        <MDBBtn
                          onClick={() =>
                            this.props.history.push("/collectibles", {
                              salesMan: this.state.SalesMan.id,
                              filterType: "salesMan",
                            })
                          }
                          className="btn z-depth-1a hash-btn"
                          color="#4F73AC"
                        >
                          {allStrings.TransferCollections}
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>

                    {this.props.currentUser.user.type == "ADMIN" && (
                      <MDBRow>
                        {!this.state.SalesMan.appBlocked && (
                          <MDBCol
                            xl="4"
                            lg="6"
                            md="6"
                            style={{ padding: "6px" }}
                          >
                            <MDBBtn
                              onClick={() => {
                                this.addBlock(this.state.SalesMan.id);
                              }}
                              className="btn z-depth-1a hash-btn hzr"
                              color="#4F73AC"
                            >
                              حظر من التطبيق
                            </MDBBtn>
                          </MDBCol>
                        )}
                        {this.state.SalesMan.appBlocked && (
                          <MDBCol
                            xl="4"
                            lg="6"
                            md="6"
                            style={{ padding: "6px" }}
                          >
                            <MDBBtn
                              onClick={() => {
                                this.deleteBlock(this.state.SalesMan.id);
                              }}
                              className="btn z-depth-1a hash-btn unblock"
                              color="#4F73AC"
                            >
                              ازالة حظر التطبيق
                            </MDBBtn>
                          </MDBCol>
                        )}
                        <MDBCol lg="4" md="12" style={{ padding: "6px" }}>
                          <MDBBtn
                            onClick={this.openPayToSalesMan}
                            className="btn z-depth-1a hash-btn"
                            color="#4F73AC"
                          >
                            {allStrings.payToSalesMan}
                          </MDBBtn>
                        </MDBCol>
                        {this.state.blocked && (
                          <MDBCol
                            xl="4"
                            lg="6"
                            md="6"
                            style={{ padding: "6px" }}
                          >
                            <MDBBtn
                              onClick={() => {
                                this.deleteBlockComp(this.state.SalesMan.id);
                              }}
                              className="btn z-depth-1a hash-btn unblock"
                              color="#4F73AC"
                            >
                              ازالة حظر الشركة
                            </MDBBtn>
                          </MDBCol>
                        )}
                        {!this.state.blocked && (
                          <MDBCol
                            xl="4"
                            lg="6"
                            md="6"
                            style={{ padding: "6px" }}
                          >
                            <MDBBtn
                              onClick={() => {
                                this.addBlockComp(this.state.SalesMan.id);
                              }}
                              style={{}}
                              className="btn z-depth-1a hash-btn hzr"
                              color="#4F73AC"
                            >
                              حظر من الشركة
                            </MDBBtn>
                          </MDBCol>
                        )}
                      </MDBRow>
                    )}
                    {this.props.currentUser.user.type != "ADMIN" && (
                      <MDBRow>
                        <MDBCol lg="6" md="12" style={{ padding: "6px" }}>
                          <MDBBtn
                            onClick={this.openPayToSalesMan}
                            className="btn z-depth-1a hash-btn"
                            color="#4F73AC"
                          >
                            {allStrings.payToSalesMan}
                          </MDBBtn>
                        </MDBCol>
                        <MDBCol lg="6" md="12" style={{ padding: "6px" }}>
                          <MDBBtn
                            className="btn z-depth-1a hash-btn unblock"
                            color="#4F73AC"
                          >
                            إزالة حظر الشركة
                          </MDBBtn>
                        </MDBCol>
                      </MDBRow>
                    )}
                    <MDBRow>
                      <MDBCol xl="4" lg="4" md="12" style={{ padding: "6px" }}>
                        <MDBBtn
                          onClick={this.openNotif}
                          className="btn z-depth-1a hash-btn"
                          color="#4F73AC"
                        >
                          {allStrings.sendNotif}
                        </MDBBtn>
                      </MDBCol>
                      <MDBCol xl="4" lg="4" md="12" style={{ padding: "6px" }}>
                        <MDBBtn
                          onClick={this.openMsg}
                          className="btn z-depth-1a hash-btn"
                          color="#4F73AC"
                        >
                          {allStrings.sendMessage}
                        </MDBBtn>
                      </MDBCol>
                      <MDBCol xl="4" lg="4" md="12" style={{ padding: "6px" }}>
                        <MDBBtn
                          onClick={this.openMap}
                          className="btn z-depth-1a hash-btn"
                          color="#4F73AC"
                        >
                          {allStrings.showLocation}
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBRow>
          </form>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = (state) => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  branch: state.company.branch,
  company: state.company.company,
});

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
};

export default withRouter(
  connect(mapToStateProps, mapDispatchToProps)(updateSalesManForm)
);
