
import {BRANCH,COMPANY} from "../actions/types"
const initState = {
    branch:false,
    company:false
}

const CompanyReducer = (state=initState,action) => {
    switch(action.type){
        
        case BRANCH:
            return {...state,branch:action.payload}
        case COMPANY:
            return {...state,company:action.payload}
        default:
            return state;
    }
}

export default CompanyReducer;