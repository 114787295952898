/* eslint-disable eqeqeq */
import React from "react";
import "./clientForm.css";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { BASE_END_POINT, SOCKET_BASE_END } from "../../urls";
import axios from "axios";
import { setUser } from "../../redux/actions/AuthActions";
import { hasValue } from "../../validations/validations";
import { message, TreeSelect, Checkbox } from "antd";
import { NavLink } from "react-router-dom";
import { ChangeLanguage } from "../../redux/actions/LanguageAction";
import moment from "moment";
import io from "socket.io-client";
import { ChangeCompany, ChangeBranch } from "../../redux/actions/CompanyAction";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

const { TreeNode } = TreeSelect;

class ClientForm extends React.Component {
  state = {
    modal2: false,
    fullname: this.props.location.state
      ? this.props.location.state.data.fullname
      : "",
    blocked: this.props.location.state
      ? this.props.location.state.blocked
        ? this.props.location.state.blocked
        : false
      : false,
    password: this.props.location.state
      ? this.props.location.state.data.password
      : null,
    phone: this.props.location.state
      ? this.props.location.state.data.phone
      : "",
    anotherPhone: this.props.location.state
      ? this.props.location.state.data.anotherPhone
      : "",
    address: this.props.location.state
      ? this.props.location.state.data.address
      : "",
    notes: this.props.location.state
      ? this.props.location.state.data.notes
      : "",
    cardNum: this.props.location.state
      ? this.props.location.state.data.cardNum
      : "",
    job: this.props.location.state ? this.props.location.state.data.job : "",
    jobLocation: this.props.location.state
      ? this.props.location.state.data.jobLocation
      : "",
    jobArea: this.props.location.state
      ? this.props.location.state.data.jobArea
      : "",
    jobLevel: this.props.location.state
      ? this.props.location.state.data.jobLevel
      : "",

    guarantorName: this.props.location.state
      ? this.props.location.state.data.guarantorName
      : "",
    guarantorCardNum: this.props.location.state
      ? this.props.location.state.data.guarantorCardNum
      : "",
    guarantorPhone: this.props.location.state
      ? this.props.location.state.data.guarantorPhone
      : "",
    guarantorPhone2: this.props.location.state
      ? this.props.location.state.data.guarantorPhone2
      : "",
    guarantorNote: this.props.location.state
      ? this.props.location.state.data.guarantorNote
      : "",

    city: this.props.location.state
      ? this.props.location.state.data.city.id
      : [],
    profileImg: this.props.location.state
      ? this.props.location.state.data.img != ""
        ? this.props.location.state.data.img
        : null
      : null,

    cardImg: this.props.location.state
      ? this.props.location.state.data.cardImg.length > 0
        ? this.props.location.state.data.cardImg
        : null
      : null,
    cardImgViews: this.props.location.state
      ? this.props.location.state.data.cardImg
      : [],

    profileimgType: this.props.location.state ? "url" : "data",
    cardImgType: this.props.location.state ? "url" : "data",
    recieptImgType: this.props.location.state ? "url" : "data",
    guarantorReceiptType: this.props.location.state ? "url" : "data",
    guarantorCardImgType: this.props.location.state ? "url" : "data",

    client: this.props.location.state ? this.props.location.state.data : [],
    requestType: this.props.location.state ? "put" : "post",
    cities: [],
    hasGuarantor: this.props.location.state
      ? this.props.location.state.data.guarantorCardNum
        ? true
        : false
      : false,
    loading: true,
    orders: [],
    refresh: false,
    page: 1,
    pages: 1,
    modal: false,
    modalType: "map",
    message: "",
    photoIndex: 0,
    isOpen1: false,
    isOpen2: false,
    isOpen3: false,
    isOpen4: false,
    isOpen5: false,
    companyClientId: "",
  };

  constructor(props) {
    super(props);

    console.log("isr   ", this.props.history);
    console.log("COPMIDD", this.state.companyClientId);
    this.socket = io(SOCKET_BASE_END, {
      query: `id=${this.props.currentUser.user.id}`,
    });
    this.getCities();
    if (this.state.requestType == "put") {
      this.getOrders(1, false);
    }

    if (this.props.isRTL) {
      allStrings.setLanguage("ar");
    } else {
      allStrings.setLanguage("en");
    }

    window.scrollTo(0, 0);
  }
  getOrders = (page, refresh) => {
    this.setState({ loading: true });
    axios
      .get(
        `${BASE_END_POINT}orders?page=${page}&limit=5&client=${
          this.state.client.user.id
        }&company=${
          this.props.currentUser.user.type == "ADMIN"
            ? this.props.company
              ? this.props.company
              : this.props.currentUser.user.company[0].id
            : this.props.currentUser.user.id
        }`,
        {
          headers: {
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        }
      )
      .then((response) => {
        console.log("orders   ", response.data);
        this.setState({
          loading: false,
          refresh: false,
          orders: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading: false });
      });
  };
  getCities = () => {
    axios
      .get(`${BASE_END_POINT}cities`)
      .then((response) => {
        console.log(response.data);
        this.setState({ cities: response.data.data });
      })
      .catch((error) => {
        console.log("ALL cities ERROR");
        console.log(error.response);
      });
  };
  componentDidMount() {
    this.getCities();
    this.getAllClones();
    if (this.state.requestType == "put") {
      let company;
      if (
        this.props.currentUser.user.type == "ADMIN" ||
        this.props.currentUser.user.type == "SUB-ADMIN"
      ) {
        if (this.props.company == false) {
          company = this.props.currentUser.user.company[0].id;
        } else {
          company = this.props.company;
        }
      } else {
        company = this.props.currentUser.user.id;
      }
      console.log(company);
      let arr = this.state.client.clientCompanyIds;
      var found = this.state.client.company.id;
      console.log("found", found);
      this.setState({ companyClientId: found.value });
    }
  }
  addClient = () => {
    const {
      fullname,
      phone,
      anotherPhone,
      profileImg,
      receipts,

      cardNum,
      address,
      notes,
      city,
      cardImg,

      job,
      jobArea,
      jobLevel,
      jobLocation,
    } = this.state;
    console.log(city);
    console.log(receipts);
    if (
      hasValue(fullname) &&
      hasValue(phone) &&
      hasValue(cardNum) &&
      city != []
    ) {
      let l = message.loading("Wait..", 2.5);
      var data = new FormData();
      data.append("fullname", fullname);
      if (cardImg != null) {
        for (let i = 0; i <= cardImg.length - 1; i++) {
          data.append("cardImg", cardImg[i]);
        }
      }
      data.append("cardNum", cardNum);
      data.append("city", city);
      data.append("type", "CLIENT");
      data.append("password", cardNum);
      data.append("phone", phone);
      if (
        this.props.currentUser.user.type == "ADMIN" ||
        this.props.currentUser.user.type == "SUB-ADMIN"
      ) {
        if (this.props.company == false) {
          data.append("company", this.props.currentUser.user.company[0].id);
        } else {
          data.append("company", this.props.company);
        }
      } else {
        data.append("company", this.props.currentUser.user.id);
      }
      if (receipts != null) {
        for (var j = 0; j <= receipts.length - 1; j++) {
          console.log(receipts[j]);
          data.append(`receipts`, receipts[j]);
        }
      }
      if (profileImg != null) {
        data.append("img", profileImg);
      }

      if (anotherPhone !== "" && anotherPhone != undefined) {
        data.append("anotherPhone", anotherPhone);
      }
      if (job !== "" && job != undefined) {
        data.append("job", job);
      }
      if (jobArea !== "" && jobArea != undefined) {
        data.append("jobArea", jobArea);
      }
      if (jobLocation !== "" && jobLocation != undefined) {
        data.append("jobLocation", jobLocation);
      }
      if (jobLevel !== "" && jobLevel != undefined) {
        data.append("jobLevel", jobLevel);
      }
      if (notes !== "" && notes != undefined) {
        data.append("notes", notes);
      }
      if (address !== "" && address != undefined) {
        data.append("address", address);
      }
      // //guarantor
      // if (guarantorCardNum !== "" && guarantorCardNum != undefined) {
      //   data.append("guarantorCardNum", guarantorCardNum);
      // }
      // if (guarantorName !== "" && guarantorName != undefined) {
      //   data.append("guarantorName", guarantorName);
      // }
      // if (guarantorPhone !== "" && guarantorPhone != undefined) {
      //   data.append("guarantorPhone", guarantorPhone);
      // }
      // if (guarantorPhone2 !== "" && guarantorPhone2 != undefined) {
      //   data.append("guarantorPhone2", guarantorPhone2);
      // }
      // if (guarantorNote !== "" && guarantorNote != undefined) {
      //   data.append("guarantorNote", guarantorNote);
      // }
      // if (guarantorCardImg != null) {
      //   for (var k = 0; k <= guarantorCardImg.length - 1; k++) {
      //     data.append(`guarantorCardImg`, guarantorCardImg[k]);
      //   }
      // }
      // if (guarantorReceipt != null) {
      //   for (var v = 0; v <= guarantorReceipt.length - 1; v++) {
      //     data.append(`guarantorReceipt`, guarantorReceipt[v]);
      //   }
      // }
      //////

      console.log(Array.from(data));

      axios
        .post(`${BASE_END_POINT}clients`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        })
        .then((response) => {
          console.log(response);
          l.then(() => message.success(allStrings.done, 2.5));
          this.setState({ client: response.data.data, requestType: "put" });
          this.props.history.push("/Clients");
        })
        .catch((error) => {
          console.log("ERROR", error);
          if (error.response.data.errors[0].msg == "cardNum duplicated") {
            l.then(() => message.error(allStrings.duplicatedCardNum, 2.5));
          }
          if (error.response.data.errors[0].msg == "phone duplicated") {
            l.then(() => message.error(allStrings.duplicatedPhone, 2.5));
          }
        });
    }
  };
  updateClient = () => {
    const {
      fullname,
      password,
      phone,
      anotherPhone,
      profileImg,
      receipts,

      cardNum,
      address,
      notes,
      city,
      cardImg,
      job,
      jobLevel,
      jobArea,
      jobLocation,
    } = this.state;
    console.log("CITYY", city);
    console.log(receipts);
    if (
      hasValue(fullname) &&
      hasValue(phone) &&
      hasValue(cardNum) &&
      city != []
    ) {
      let l = message.loading("Wait..", 2.5);
      var data = new FormData();
      data.append("fullname", fullname);
      data.append("cardNum", cardNum);
      data.append("city", city);
      data.append("type", "CLIENT");
      if (password != "" && password != undefined)
        data.append("password", password);
      data.append("phone", phone);
      if (
        this.props.currentUser.user.type == "ADMIN" ||
        this.props.currentUser.user.type == "SUB-ADMIN"
      ) {
        if (this.props.company == false) {
          data.append("company", this.props.currentUser.user.company[0].id);
        } else {
          data.append("company", this.props.company);
        }
      } else {
        data.append("company", this.props.currentUser.user.id);
      }

      if (job != "" && job != undefined) {
        data.append("job", job);
      }
      if (jobArea != "" && jobArea != undefined) {
        data.append("jobArea", jobArea);
      }
      if (jobLocation != "" && jobLocation != undefined) {
        data.append("jobLocation", jobLocation);
      }
      if (jobLevel != "" && jobLevel != undefined) {
        data.append("jobLevel", jobLevel);
      }
      if (profileImg != null) {
        //if(profileimgType == 'data'){
        data.append("img", profileImg);
        //}
      }
      if (cardImg != null) {
        // if(cardImgType == 'data'){

        for (let i = 0; i <= cardImg.length - 1; i++) {
          data.append("cardImg", cardImg[i]);
        }
        // }
      }

      if (anotherPhone != "" && anotherPhone != undefined) {
        data.append("anotherPhone", anotherPhone);
      }
      if (notes != "" && notes != undefined) {
        data.append("notes", notes);
      }
      if (address != "" && address != undefined) {
        data.append("address", address);
      }

      console.log(Array.from(data));

      axios
        .put(`${BASE_END_POINT}clients/${this.state.client.id}`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        })
        .then((response) => {
          l.then(() => message.success(allStrings.done, 2.5));
          this.props.history.push("/Clients");
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.data.errors[0].msg == "cardNum duplicated") {
            l.then(() => message.error(allStrings.duplicatedCardNum, 2.5));
          }
          if (error.response.data.errors[0].msg == "phone duplicated") {
            l.then(() => message.error(allStrings.duplicatedPhone, 2.5));
          }
        });
    }
  };
  getAllClones = () => {
    let url = ``;

    if (
      this.props.currentUser.user.type === "ADMIN" ||
      this.props.currentUser.user.type === "SUB-ADMIN"
    ) {
      url = `${BASE_END_POINT}clients?user=${this.state.client.user.id}`;
    }

    console.log("url", url);
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("Clients Clones  ", response.data);
        this.setState({
          loading: false,
          refresh: false,
          Clients: response.data.data,
        });
      })
      .catch((error) => {
        console.log("error   ", error);
        console.log("error2   ", error);
        this.setState({ loading: false });
      });
  };
  sendNotif = () => {
    const msg = this.state.message;
    console.log(msg);
    if (hasValue(msg)) {
      let l = message.loading("Wait..", 2.5);
      let data = {
        description: msg,
        users: this.state.client.id,
      };
      axios
        .post(`${BASE_END_POINT}sendnotif`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        })
        .then((response) => {
          l.then(() => message.success(allStrings.done, 2.5));
          this.toggle();
        })
        .catch((error) => {
          console.log(error.response);
          l.then(() => message.error("Error", 2.5));
        });
    }
  };
  sendMessage = () => {
    const msg = this.state.message;
    console.log(msg);
    if (hasValue(msg)) {
      let l = message.loading("Wait..", 2.5);
      const data = {
        text: msg,
        user: {
          _id: this.props.currentUser.user.id,
        },
      };
      this.socket.emit("newMessage", {
        toId: this.state.client.id,
        data: data,
      });
      l.then(() => message.success(allStrings.done, 2.5));
      this.toggle();
    }
  };

  fileHandlerProfileImg = (event) => {
    this.setState({
      profileImg: event.target.files[0],
      profileImgType: "data",
    });
  };
  removeProfileImg = () => {
    this.setState({
      profileImg: null,
    });
  };
  //card img
  fileHandlerCardImg = (event) => {
    let imgs = [];
    for (let i = 0; i <= event.target.files.length - 1; i++) {
      imgs.push(URL.createObjectURL(event.target.files[i]));
    }
    console.log(imgs);
    this.setState({
      cardImg: event.target.files,
      cardImgViews: imgs,
      cardImgType: "data",
    });
  };
  removeCardImg = (url) => {
    let arr = this.state.cardImgViews;
    let arr2 = Array.from(this.state.cardImg);
    console.log(arr2);
    let index = arr.indexOf(url);
    if (index !== -1) arr.splice(index, 1);
    if (index !== -1) arr2.splice(index, 1);
    console.log(arr2);
    this.setState({
      cardImgViews: arr,
      cardImg: arr2,
    });
    if (arr2.length == 0) {
      this.setState({
        cardImg: null,
      });
    }
  };
  deleteBlock = (id) => {
    let l = message.loading("Wait..", 2.5);
    axios
      .delete(`${BASE_END_POINT}block/${id}/removeAppBlock`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("Block DELETED", response);
        l.then(() => message.success(allStrings.done, 2));
        this.props.history.push("/Clients");
      })
      .catch((error) => {
        console.log("err", error);
        l.then(() => message.error("Error", 2.5));
      });
  };
  deleteBlockComp = (id) => {
    let l = message.loading("Wait..", 2.5);
    console.log(this.props.currentUser.token, this.props.company);
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.props.currentUser.token}`,
    };
    let data = {
      company:
        this.props.currentUser.user.type == "ADMIN"
          ? this.props.company
            ? this.props.company
            : this.props.currentUser.user.company[0].id
          : this.props.currentUser.user.id,
    };
    axios
      .delete(`${BASE_END_POINT}block/${id}/removeCompanyBlock`, {
        headers,
        data,
      })
      .then((response) => {
        console.log("Block DELETED");
        l.then(() => message.success(allStrings.done, 2));
        this.props.history.push("/Clients");
      })
      .catch((error) => {
        console.log("er", error);
        l.then(() => message.error("Error", 2.5));
      });
  };

  //ADD BLOCK
  addBlock = (id) => {
    let l = message.loading("Wait..", 2.5);
    axios
      .post(
        `${BASE_END_POINT}block/${id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        }
      )
      .then((response) => {
        console.log("Block DELETED", response);
        l.then(() => message.success(allStrings.done, 2));
        this.props.history.push("/Clients");
      })
      .catch((error) => {
        console.log("err", error);
        l.then(() => message.error("Error", 2.5));
      });
  };
  addBlockComp = (id) => {
    let l = message.loading("Wait..", 2.5);
    console.log(this.props.currentUser.token, this.props.company);
    axios
      .post(
        `${BASE_END_POINT}block/${id}`,
        {
          company:
            this.props.currentUser.user.type == "ADMIN"
              ? this.props.company
                ? this.props.company
                : this.props.currentUser.user.company[0].id
              : this.props.currentUser.user.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        }
      )
      .then((response) => {
        console.log("Block DELETED");
        l.then(() => message.success(allStrings.done, 2));
        this.props.history.push("/Clients");
      })
      .catch((error) => {
        console.log("er", error);
        l.then(() => message.error("Error", 2.5));
      });
  };

  //END BLOCK
  //reciept img
  fileHandlerRecieptImg = (event) => {
    let imgs = [];
    for (let i = 0; i <= event.target.files.length - 1; i++) {
      imgs.push(URL.createObjectURL(event.target.files[i]));
    }
    console.log(imgs);
    this.setState({
      receipts: event.target.files,
      receiptsViews: imgs,
      recieptImgType: "data",
    });
  };
  // removeRecieptImg = (url) => {
  //   let arr = this.state.receiptsViews;
  //   let arr2 = Array.from(this.state.receipts);
  //   console.log(arr2);
  //   let index = arr.indexOf(url);
  //   if (index !== -1) arr.splice(index, 1);
  //   if (index !== -1) arr2.splice(index, 1);
  //   console.log(arr2);
  //   this.setState({
  //     receiptsViews: arr,
  //     receipts: arr2,
  //   });
  //   if (arr2.length == 0) {
  //     this.setState({
  //       receipts: null,
  //     });
  //   }
  // };

  //guarantor  card img
  fileHandlerGuarantorCardImg = (event) => {
    let imgs = [];
    for (let i = 0; i <= event.target.files.length - 1; i++) {
      imgs.push(URL.createObjectURL(event.target.files[i]));
    }
    console.log(imgs);
    this.setState({
      guarantorCardImg: event.target.files,
      guarantorCardImgViews: imgs,
      recieptImgType: "data",
    });
  };
  removeGuarantorCardImg = (url) => {
    let arr = this.state.guarantorCardImgViews;
    let arr2 = Array.from(this.state.guarantorCardImg);
    console.log(arr2);
    let index = arr.indexOf(url);
    if (index !== -1) arr.splice(index, 1);
    if (index !== -1) arr2.splice(index, 1);
    console.log(arr2);
    this.setState({
      guarantorCardImgViews: arr,
      guarantorCardImg: arr2,
    });
    if (arr2.length == 0) {
      this.setState({
        guarantorCardImg: null,
      });
    }
  };
  //guarantor receipt
  fileHandlerGuarantorReceipt = (event) => {
    let imgs = [];
    for (let i = 0; i <= event.target.files.length - 1; i++) {
      imgs.push(URL.createObjectURL(event.target.files[i]));
    }
    console.log(imgs);
    this.setState({
      guarantorReceipt: event.target.files,
      guarantorReceiptViews: imgs,
      guarantorReceiptType: "data",
    });
  };
  removeGuarantorReceipt = () => {
    this.setState({
      guarantorReceipt: null,
    });
  };
  changeHandler = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  clientSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.addClient();
  };
  updateSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.updateClient();
  };
  notifSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.sendNotif();
  };
  msgSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.sendMessage();
  };

  onChangeDate = (date, dateString) => {
    console.log(date, dateString);
    this.setState({ start: dateString });
  };
  openChange = () => {
    console.log("CLONESss");
    this.setState({ modalType: "clones" });
    this.toggle2();
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  openMap = () => {
    this.setState({ modalType: "map" });
    this.toggle();
  };
  openClones = () => {
    this.setState({ modalType: "clone" });
    this.toggle();
  };
  openNotif = () => {
    this.setState({ modalType: "notif" });
    this.toggle();
  };
  openMsg = () => {
    this.setState({ modalType: "msg" });
    this.toggle();
  };
  modal = () => (
    <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
      <MDBModalHeader toggle={this.toggle}>
        {this.state.modalType == "map"
          ? allStrings.location
          : allStrings.completeData}
      </MDBModalHeader>
      <MDBModalBody>
        <MDBContainer>
          <MDBRow>
            {this.state.modalType != "map" && (
              <MDBCol md="12">
                <form
                  style={{ marginLeft: "15%" }}
                  className="needs-validation"
                  onSubmit={
                    this.state.modalType == "notif"
                      ? this.notifSubmitHandler
                      : this.msgSubmitHandler
                  }
                  noValidate
                >
                  <MDBContainer>
                    <MDBRow>
                      <MDBCol md="9">
                        <textarea
                          name="message"
                          onChange={this.changeHandler}
                          type="text"
                          id="defaultFormRegisterNamx1"
                          required
                          placeholder={allStrings.message}
                          className="form-control"
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                        />
                      </MDBCol>
                      <MDBCol md="3">
                        <label
                          htmlFor="defaultFmRegisterNameEx1"
                          className="grey-text formLabel"
                        >
                          {allStrings.message}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBBtn
                        type="submit"
                        style={{
                          margin: "3rem 1rem ",
                          width: "170px",
                          padding: "11px 20px",
                          height: "35px",
                        }}
                        className="btn z-depth-1a "
                        color="primary"
                      >
                        {allStrings.send}
                      </MDBBtn>
                    </MDBRow>
                  </MDBContainer>
                </form>
              </MDBCol>
            )}
            {this.state.modalType == "map" && (
              <MDBCol md="12">
                <div style={{ width: "100%", height: "300px" }}></div>
              </MDBCol>
            )}
          </MDBRow>
        </MDBContainer>
      </MDBModalBody>
    </MDBModal>
  );
  toggle2 = () => {
    this.setState({
      modal2: !this.state.modal2,
    });
    if (this.state.modal === true) {
      this.setState({
        modal: false,
      });
    }
  };
  modal2 = () => {
    let {
      cardImg,
      profileImg,
      guarantorCardImg,
      guarantorReceipt,
      receipts,
      photoIndex,
      isOpen1,
      isOpen2,
      isOpen3,
      isOpen4,
      isOpen5,
    } = this.state;
    return (
      <MDBModal isOpen={this.state.modal2} toggle={this.toggle2} size="lg">
        <MDBModalHeader toggle={this.toggle2}>
          {this.state.modalType === "map"
            ? allStrings.location
            : "بيانات العميل بالنسبة للشركات"}
        </MDBModalHeader>
        <MDBModalBody>
          <MDBContainer>
            {this.state.modalType === "clones" &&
              (this.state.Clients.length > 0
                ? this.state.Clients.map((client) => (
                    <MDBRow
                      style={{ border: "1px solid black", padding: "5px" }}
                    >
                      <MDBCol
                        md="12"
                        className="text-center"
                        style={{
                          color: "red",
                          fontSize: "18px",
                        }}
                      >
                        {client.company.fullname}
                      </MDBCol>

                      <MDBCol md="6">
                        <MDBRow>
                          <MDBCol md="9" sm="9">
                            {this.state.cardImg == null && (
                              <div className="smallUploadContainer">
                                <img
                                  alt=""
                                  name="img"
                                  className="smallUploderImg"
                                  src={require("../../assets/images/Path 343.png")}
                                ></img>
                                <input
                                  multiple
                                  onChange={this.fileHandlerCardImg}
                                  type="file"
                                  id="defaultFormRegisterNameE03"
                                  className="smallUploader"
                                />
                                <MDBBtn
                                  color="primary"
                                  size="sm"
                                  className="smallUploaderBtn"
                                >
                                  {allStrings.chooseFile}
                                </MDBBtn>
                                <p
                                  style={{
                                    fontSize: "14px",
                                    marginTop: "3px 15px",
                                    float: "right",
                                    paddingTop: "4px",
                                  }}
                                  className="grey-text"
                                >
                                  {allStrings.dropHere}
                                </p>
                              </div>
                            )}

                            {this.state.cardImg != null && (
                              <div className="previewRaduis">
                                <MDBRow>
                                  <MDBCol md="3">
                                    <div
                                      style={{
                                        paddingTop: "5px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.setState({ isOpen1: true })
                                      }
                                    >
                                      <span
                                        style={{
                                          fontSize: "17px",
                                          color: "#478DFF",
                                          fontWeight: "600",
                                          margin: "5px",
                                        }}
                                      >
                                        {allStrings.showD}
                                      </span>
                                      <img
                                        alt=""
                                        className="showImge"
                                        width="20px"
                                        src={require("../../assets/images/icons8_external_link_480px_2.png")}
                                      ></img>
                                    </div>
                                  </MDBCol>
                                  <MDBCol
                                    md="9"
                                    style={{ paddingRight: "30px" }}
                                  >
                                    {this.state.cardImgViews.map((val) => (
                                      <div
                                        style={{
                                          display: "inline-block",
                                          float: "right",
                                          marginBottom: "1rem",
                                        }}
                                      >
                                        <img
                                          alt=""
                                          src={
                                            this.state.cardImgType == "data"
                                              ? val
                                              : val
                                          }
                                        />
                                        <MDBIcon
                                          icon="times"
                                          className="mr-2 removeImg"
                                          onClick={() =>
                                            this.removeCardImg(val)
                                          }
                                        ></MDBIcon>
                                      </div>
                                    ))}
                                  </MDBCol>
                                </MDBRow>
                                {isOpen1 && (
                                  <Lightbox
                                    mainSrc={cardImg[photoIndex]}
                                    nextSrc={
                                      cardImg[(photoIndex + 1) % cardImg.length]
                                    }
                                    prevSrc={
                                      cardImg[
                                        (photoIndex + cardImg.length - 1) %
                                          cardImg.length
                                      ]
                                    }
                                    onCloseRequest={() =>
                                      this.setState({
                                        isOpen1: false,
                                        photoIndex: 0,
                                      })
                                    }
                                    onMovePrevRequest={() =>
                                      this.setState({
                                        photoIndex:
                                          (photoIndex + cardImg.length - 1) %
                                          cardImg.length,
                                      })
                                    }
                                    onMoveNextRequest={() =>
                                      this.setState({
                                        photoIndex:
                                          (photoIndex + 1) % cardImg.length,
                                      })
                                    }
                                  />
                                )}
                              </div>
                            )}
                          </MDBCol>
                          <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                            <label
                              htmlFor="defaultFormRegisterNameEx003"
                              className="grey-text formLabel"
                            >
                              {allStrings.cardImg}
                            </label>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol md="9" sm="9">
                            <input
                              name="jobArea"
                              onChange={this.changeHandler}
                              type="text"
                              id="defaultFormRegisterNameEx12"
                              className="form-control hash-new"
                              placeholder={allStrings.enterJobArea}
                              style={{
                                direction: this.props.isRTL ? "rtl" : "ltr",
                                marginTop: "2%",
                              }}
                              defaultValue={client.jobArea}
                            />
                          </MDBCol>
                          <MDBCol
                            md="3"
                            sm="3"
                            style={{ paddingLeft: "0px", marginTop: "1%" }}
                          >
                            <label
                              htmlFor="defaultFormRegisterNameEx12"
                              className="grey-text formLabel"
                            >
                              {allStrings.jobArea}
                            </label>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow style={{}}>
                          <MDBCol md="9" sm="9">
                            <input
                              name="jobLocation"
                              onChange={this.changeHandler}
                              type="text"
                              id="defaultFormRegisterNameEx12"
                              className="form-control hash-new"
                              placeholder={allStrings.enterJobLocation}
                              style={{
                                direction: this.props.isRTL ? "rtl" : "ltr",
                              }}
                              defaultValue={client.jobLocation}
                            />
                          </MDBCol>
                          <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                            <label
                              htmlFor="defaultFormRegisterNameEx12"
                              className="grey-text formLabel"
                            >
                              {allStrings.jobLocation}
                            </label>
                          </MDBCol>
                        </MDBRow>

                        <MDBRow style={{}}>
                          <MDBCol md="9" sm="9">
                            <input
                              name="job"
                              onChange={this.changeHandler}
                              type="text"
                              id="defaultFormRegisterNameEx12"
                              className="form-control hash-new"
                              placeholder={allStrings.enterJob}
                              style={{
                                direction: this.props.isRTL ? "rtl" : "ltr",
                              }}
                              defaultValue={client.job}
                            />
                          </MDBCol>
                          <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                            <label
                              htmlFor="defaultFormRegisterNameEx12"
                              className="grey-text formLabel"
                            >
                              {allStrings.job}
                            </label>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow style={{}}>
                          <MDBCol md="9" sm="9">
                            <input
                              name="jobLevel"
                              onChange={this.changeHandler}
                              type="text"
                              id="defaultFormRegisterNameEx12"
                              className="form-control hash-new"
                              placeholder={allStrings.enterJobLevel}
                              style={{
                                direction: this.props.isRTL ? "rtl" : "ltr",
                              }}
                              defaultValue={client.jobLevel}
                            />
                          </MDBCol>
                          <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                            <label
                              htmlFor="defaultFormRegisterNameEx12"
                              className="grey-text formLabel"
                            >
                              {allStrings.jobLevel}
                            </label>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol md="6">
                        <MDBRow>
                          <MDBCol md="9" sm="9">
                            <input
                              name="#"
                              type="text"
                              id="defaultFormRegisterEx5"
                              disabled
                              className="form-control hash-new"
                              style={{
                                direction: this.props.isRTL ? "rtl" : "ltr",
                              }}
                              defaultValue={client.idInCompany}
                            />
                          </MDBCol>
                          <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                            <label
                              htmlFor="defaultFormRegisterEx5"
                              className="grey-text formLabel"
                            >
                              {allStrings.clientCode}
                            </label>
                          </MDBCol>
                        </MDBRow>

                        <MDBRow>
                          <MDBCol md="9" sm="9">
                            <input
                              name="fullname"
                              onChange={this.changeHandler}
                              type="text"
                              id="defaultFormRegisterNameEx5"
                              required
                              className="form-control hash-new"
                              placeholder={allStrings.enterName}
                              style={{
                                direction: this.props.isRTL ? "rtl" : "ltr",
                              }}
                              defaultValue={client.fullname}
                            />
                          </MDBCol>
                          <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                            <label
                              htmlFor="defaultFormRegisterNameEx5"
                              className="grey-text formLabel"
                            >
                              {allStrings.name}
                            </label>
                          </MDBCol>
                        </MDBRow>

                        <MDBRow style={{}}>
                          <MDBCol md="9" sm="9">
                            <input
                              name="cardNum"
                              onChange={this.changeHandler}
                              type="text"
                              id="defaultFormRegisterNameEx12"
                              className="form-control hash-new"
                              placeholder={allStrings.enterCardNum}
                              style={{
                                direction: this.props.isRTL ? "rtl" : "ltr",
                              }}
                              defaultValue={client.cardNum}
                              required
                            />
                          </MDBCol>
                          <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                            <label
                              htmlFor="defaultFormRegisterNameEx12"
                              className="grey-text formLabel"
                            >
                              {allStrings.cardNum}
                            </label>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow style={{}}>
                          <MDBCol md="9" sm="9">
                            <input
                              name="address"
                              onChange={this.changeHandler}
                              type="text"
                              id="defaultFormRegisterNameEx13"
                              className="form-control hash-new"
                              placeholder={allStrings.enterAddress}
                              style={{
                                direction: this.props.isRTL ? "rtl" : "ltr",
                              }}
                              defaultValue={client.address}
                            />
                          </MDBCol>
                          <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                            <label
                              htmlFor="defaultFormRegisterNameEx13"
                              className="grey-text formLabel"
                            >
                              {allStrings.address}
                            </label>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow style={{}}>
                          <MDBCol md="9" sm="9">
                            <TreeSelect
                              value={this.state.city}
                              showSearch={false}
                              style={{
                                width: "100%",
                                textAlign: this.props.isRTL ? "right" : "left",
                              }}
                              treeCheckable={false}
                              treeNodeFilterProp="title"
                              dropdownStyle={{
                                maxHeight: 400,
                                overflow: "auto",
                              }}
                              placeholder={allStrings.chooseCity}
                              onChange={(value) => {
                                console.log(value);
                                this.setState({ city: value });
                              }}
                            >
                              {/*<TreeNode style={{background:"antiquewhite",color:'#fff !important'}} value="newClient" title={allStrings.newClient} key={allStrings.newClient} />*/}
                              {this.state.cities.map((val) => (
                                <TreeNode
                                  value={val.id}
                                  title={
                                    this.props.isRTL
                                      ? val.cityName_ar
                                      : val.cityName_en
                                  }
                                  key={val.id}
                                />
                              ))}
                            </TreeSelect>
                          </MDBCol>
                          <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                            <label
                              htmlFor="defaultFormRegisterNameEx14"
                              className="grey-text formLabel"
                            >
                              {allStrings.city}
                            </label>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow style={{}}>
                          <MDBCol md="9" sm="9">
                            <input
                              name="phone"
                              onChange={this.changeHandler}
                              type="text"
                              id="defaultFormRegisterNameEx15"
                              className="form-control hash-new"
                              placeholder={allStrings.enterPhone}
                              style={{
                                direction: this.props.isRTL ? "rtl" : "ltr",
                              }}
                              defaultValue={client.phone}
                              required
                            />
                          </MDBCol>
                          <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                            <label
                              htmlFor="defaultFormRegisterNameEx15"
                              className="grey-text formLabel"
                            >
                              {allStrings.phone}
                            </label>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow style={{}}>
                          <MDBCol md="9" sm="9">
                            <input
                              name="anotherPhone"
                              onChange={this.changeHandler}
                              type="text"
                              id="defaultFormRegisterNameEx16"
                              className="form-control hash-new"
                              placeholder={allStrings.EnterAnotherPhone}
                              style={{
                                direction: this.props.isRTL ? "rtl" : "ltr",
                              }}
                              defaultValue={client.anotherPhone}
                            />
                          </MDBCol>
                          <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                            <label
                              htmlFor="defaultFormRegisterNameEx16"
                              className="grey-text formLabel"
                            >
                              {allStrings.anotherPhone}
                            </label>
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                  ))
                : "")}
          </MDBContainer>
        </MDBModalBody>
      </MDBModal>
    );
  };
  render() {
    //client card img slider
    let {
      cardImg,
      profileImg,
      guarantorCardImg,
      guarantorReceipt,
      receipts,
      photoIndex,
      isOpen1,
      isOpen2,
      isOpen3,
      isOpen4,
      isOpen5,
    } = this.state;
    profileImg = [profileImg];
    console.log("img", profileImg);
    return (
      <div className="stockForm clientForm">
        <MDBContainer>
          {this.modal()}
          {this.modal2()}
          <MDBRow>
            <div
              className="top"
              style={{
                width: "100%",
                textAlign:
                  this.state.requestType === "post" ? "center" : "right",
              }}
            >
              <NavLink to="/clients">
                <MDBIcon icon="times" className="closeIcon" />
              </NavLink>
              {this.state.requestType === "post" ? (
                <span className="stockTitle">{allStrings.completeData}</span>
              ) : (
                <span>
                  <span
                    className="newTitle"
                    style={{ margin: "1rem 0rem 1rem .5rem" }}
                  >
                    {this.state.client.lastSeen
                      ? moment().calendar(this.state.client.lastSeen)
                      : moment().calendar()}
                  </span>
                  <span className="newTitle">{allStrings.lastSeen}</span>
                </span>
              )}
            </div>
          </MDBRow>
          <form
            className="needs-validation"
            onSubmit={
              this.state.requestType === "post"
                ? this.clientSubmitHandler
                : this.updateSubmitHandler
            }
            noValidate
            style={{ marginLeft: "2rem" }}
          >
            <MDBRow>
              <MDBContainer>
                <MDBRow className="mdRevers">
                  <MDBCol md="6">
                    {/* Notes */}
                    <MDBRow style={{ marginBottom: "6px" }}>
                      <MDBCol md="10" sm="10">
                        <textarea
                          name="notes"
                          onChange={this.changeHandler}
                          type="text"
                          id="defaultFormRegisterNameEx17"
                          className="form-control"
                          placeholder={allStrings.enterNote}
                          style={{
                            height: "150px",
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                          defaultValue={this.state.client.notes}
                        />
                      </MDBCol>
                      <MDBCol
                        md="2"
                        sm="2"
                        style={{
                          textAlign: this.props.isRTL ? "right" : "left",
                        }}
                      >
                        <label
                          htmlFor="defaultFormRegisterNameEx17"
                          className="grey-text formLabel"
                        >
                          {allStrings.note}
                        </label>
                      </MDBCol>
                    </MDBRow>

                    {/* Recived Products */}
                    {this.state.requestType == "put" && (
                      <MDBRow style={{ padding: "0px 15px" }}>
                        <MDBCol
                          md="12"
                          style={{
                            cursor: "pointer",
                            borderTop: "1px solid #000",
                            marginTop: "2rem",
                            paddingTop: "1rem",
                          }}
                        >
                          <label
                            htmlFor="defaultFormRegisterNameE"
                            style={{
                              float: "right",
                              fontWeight: "800",
                              color: "#43425D",
                              fontSize: "14px",
                            }}
                          >
                            {allStrings.recievedProducts}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    )}
                    {this.state.requestType == "put" && (
                      <MDBRow>
                        <MDBCol md="3">
                          <div
                            style={{ paddingTop: "5px", cursor: "pointer" }}
                            onClick={() =>
                              this.props.history.push("/clientOrders", {
                                data: this.state.client,
                              })
                            }
                          >
                            <span
                              style={{
                                fontSize: "17px",
                                color: "#478DFF",
                                fontWeight: "600",
                                margin: "5px",
                              }}
                            >
                              {allStrings.showD}
                            </span>
                            <img
                              alt=""
                              width="20px"
                              src={require("../../assets/images/icons8_external_link_480px_2.png")}
                            ></img>
                          </div>
                        </MDBCol>
                        <MDBCol md="9" style={{ paddingRight: "30px" }}>
                          {this.state.orders.map((val) => (
                            <div
                              style={{
                                display: "inline-block",
                                margin: "2px",
                                float: "right",
                              }}
                            >
                              <img
                                alt=""
                                className="clientProduct"
                                src={val.product.mainImg}
                              ></img>
                              <span className="clientProductCount">
                                {val.count}
                              </span>
                            </div>
                          ))}
                        </MDBCol>
                      </MDBRow>
                    )}
                    {/* Blocked */}
                    {this.state.requestType == "put" && (
                      <MDBRow
                        style={{
                          borderTop: "1px solid #000",
                          marginTop: "2rem",
                          paddingTop: "1rem",
                        }}
                      >
                        {!this.state.client.user.appBlocked && (
                          <MDBCol
                            lg="6"
                            md="12"
                            style={{ margin: "auto", textAlign: "center" }}
                          >
                            <img
                              src={require("../../assets/images/confirm.svg")}
                            />
                            <span
                              style={{
                                color: "#329832",
                                margin: "auto",
                                fontSize: "20px",
                                fontWeight: "600",
                                textAlign: "center",
                                display: "block",
                              }}
                            >
                              فعال في التطبيق
                            </span>
                          </MDBCol>
                        )}
                        {this.state.client.user.appBlocked && (
                          <MDBCol
                            lg="6"
                            md="12"
                            style={{ margin: "auto", textAlign: "center" }}
                          >
                            <img
                              src={require("../../assets/images/cancel.svg")}
                            />
                            <span
                              style={{
                                color: "red",
                                margin: "auto",
                                fontSize: "20px",
                                fontWeight: "600",
                                textAlign: "center",
                                display: "block",
                              }}
                            >
                              محظور في التطبيق
                            </span>
                          </MDBCol>
                        )}
                        {this.state.blocked && (
                          <MDBCol
                            lg="6"
                            md="12"
                            style={{ margin: "auto", textAlign: "center" }}
                          >
                            <img
                              src={require("../../assets/images/cancel.svg")}
                            />
                            <span
                              style={{
                                color: "red",
                                margin: "auto",
                                fontSize: "20px",
                                fontWeight: "600",
                                textAlign: "center",
                                display: "block",
                              }}
                            >
                              محظور من الشركة
                            </span>
                          </MDBCol>
                        )}
                        {!this.state.blocked && (
                          <MDBCol
                            lg="6"
                            md="12"
                            style={{ margin: "auto", textAlign: "center" }}
                          >
                            <img
                              src={require("../../assets/images/confirm.svg")}
                            />
                            <span
                              style={{
                                color: "#329832",
                                margin: "auto",
                                fontSize: "20px",
                                fontWeight: "600",
                                textAlign: "center",
                                display: "block",
                              }}
                            >
                              فعال في الشركة
                            </span>
                          </MDBCol>
                        )}
                      </MDBRow>
                    )}
                    {/* Buttons */}
                    {this.state.requestType == "put" && (
                      <MDBRow
                        style={{
                          borderTop: "1px solid #000",
                          marginTop: "2rem",
                          paddingTop: "1rem",
                        }}
                      >
                        <MDBCol lg="4" md="12" style={{ padding: "6px" }}>
                          <MDBBtn
                            onClick={() =>
                              this.props.history.push("/collectibles", {
                                client: this.state.client.id,
                              })
                            }
                            className="btn z-depth-1a hash-btn"
                            color="#4F73AC"
                          >
                            {allStrings.delayPremuim}
                          </MDBBtn>
                        </MDBCol>
                        <MDBCol lg="4" md="12" style={{ padding: "6px" }}>
                          <MDBBtn
                            onClick={() =>
                              this.props.history.push("/clientOrders", {
                                data: this.state.client,
                              })
                            }
                            className="btn z-depth-1a hash-btn"
                            color="#4F73AC"
                          >
                            {allStrings.payCash}
                          </MDBBtn>
                        </MDBCol>
                        <MDBCol lg="4" md="12" style={{ padding: "6px" }}>
                          <MDBBtn
                            onClick={() =>
                              this.props.history.push("/collectibles", {
                                client: this.state.client.id,
                              })
                            }
                            className="btn z-depth-1a hash-btn"
                            color="#4F73AC"
                          >
                            {allStrings.collectPremuims}
                          </MDBBtn>
                        </MDBCol>
                      </MDBRow>
                    )}
                    {this.state.requestType == "put" && (
                      <MDBRow>
                        <MDBCol xl="4" lg="6" md="6" style={{ padding: "6px" }}>
                          <MDBBtn
                            onClick={() =>
                              this.props.history.push("/receipts", {
                                client: this.state.client.id,
                              })
                            }
                            className="btn z-depth-1a hash-btn"
                            color="#4F73AC"
                          >
                            {allStrings.printReceitp}
                          </MDBBtn>
                        </MDBCol>
                        <MDBCol xl="4" lg="6" md="6" style={{ padding: "6px" }}>
                          <MDBBtn
                            onClick={this.openNotif}
                            className="btn z-depth-1a hash-btn"
                            color="#4F73AC"
                          >
                            {allStrings.sendNotif}
                          </MDBBtn>
                        </MDBCol>
                        <MDBCol xl="4" lg="6" md="6" style={{ padding: "6px" }}>
                          <MDBBtn
                            onClick={this.openMsg}
                            className="btn z-depth-1a hash-btn"
                            color="#4F73AC"
                          >
                            {allStrings.sendMessage}
                          </MDBBtn>
                        </MDBCol>
                      </MDBRow>
                    )}
                    {this.state.requestType == "put" &&
                      this.props.currentUser.user.type == "ADMIN" && (
                        <MDBRow>
                          {!this.state.client.user.appBlocked && (
                            <MDBCol
                              xl="4"
                              lg="6"
                              md="6"
                              style={{ padding: "6px" }}
                            >
                              <MDBBtn
                                onClick={() => {
                                  this.addBlock(this.state.client.user.id);
                                }}
                                className="btn z-depth-1a hash-btn hzr"
                                color="#4F73AC"
                              >
                                حظر من التطبيق
                              </MDBBtn>
                            </MDBCol>
                          )}
                          {this.state.client.user.appBlocked && (
                            <MDBCol
                              xl="4"
                              lg="6"
                              md="6"
                              style={{ padding: "6px" }}
                            >
                              <MDBBtn
                                onClick={() => {
                                  this.deleteBlock(this.state.client.user.id);
                                }}
                                className="btn z-depth-1a hash-btn unblock"
                                color="#4F73AC"
                              >
                                ازالة حظر التطبيق
                              </MDBBtn>
                            </MDBCol>
                          )}
                          <MDBCol
                            xl="4"
                            lg="6"
                            md="6"
                            style={{ padding: "6px" }}
                          >
                            <MDBBtn
                              onClick={this.openMap}
                              className="btn z-depth-1a hash-btn"
                              color="#4F73AC"
                            >
                              {allStrings.showLocation}
                            </MDBBtn>
                          </MDBCol>
                          {this.state.blocked && (
                            <MDBCol
                              xl="4"
                              lg="6"
                              md="6"
                              style={{ padding: "6px" }}
                            >
                              <MDBBtn
                                onClick={() => {
                                  this.deleteBlockComp(
                                    this.state.client.user.id
                                  );
                                }}
                                className="btn z-depth-1a hash-btn unblock"
                                color="#4F73AC"
                              >
                                ازالة حظر الشركة
                              </MDBBtn>
                            </MDBCol>
                          )}
                          {!this.state.blocked && (
                            <MDBCol
                              xl="4"
                              lg="6"
                              md="6"
                              style={{ padding: "6px" }}
                            >
                              <MDBBtn
                                onClick={() => {
                                  this.addBlockComp(this.state.client.user.id);
                                }}
                                style={{}}
                                className="btn z-depth-1a hash-btn hzr"
                                color="#4F73AC"
                              >
                                حظر من الشركة
                              </MDBBtn>
                            </MDBCol>
                          )}
                        </MDBRow>
                      )}
                    {this.state.requestType == "put" &&
                      this.props.currentUser.user.type != "ADMIN" && (
                        <MDBRow>
                          <MDBCol
                            xl="6"
                            lg="6"
                            md="6"
                            style={{ padding: "6px" }}
                          >
                            <MDBBtn
                              onClick={this.openMap}
                              className="btn z-depth-1a hash-btn"
                              color="#4F73AC"
                            >
                              {allStrings.showLocation}
                            </MDBBtn>
                          </MDBCol>
                          {this.state.blocked && (
                            <MDBCol
                              xl="4"
                              lg="6"
                              md="6"
                              style={{ padding: "6px" }}
                            >
                              <MDBBtn
                                onClick={() => {
                                  this.deleteBlockComp(
                                    this.state.client.user.id
                                  );
                                }}
                                className="btn z-depth-1a hash-btn unblock"
                                color="#4F73AC"
                              >
                                ازالة حظر الشركة
                              </MDBBtn>
                            </MDBCol>
                          )}
                          {!this.state.blocked && (
                            <MDBCol
                              xl="4"
                              lg="6"
                              md="6"
                              style={{ padding: "6px" }}
                            >
                              <MDBBtn
                                style={{}}
                                className="btn z-depth-1a hash-btn hzr"
                                color="#4F73AC"
                              >
                                حظر من الشركة
                              </MDBBtn>
                            </MDBCol>
                          )}
                        </MDBRow>
                      )}

                    <MDBRow>
                      <MDBCol xl="12" lg="12" md="12">
                        <MDBBtn
                          type="submit"
                          style={{
                            margin: "auto",
                            width: "103%",
                            padding: "9px",
                            marginLeft: "-2%",
                          }}
                          className="btn z-depth-1a "
                          color="primary"
                        >
                          {this.state.requestType == "post"
                            ? allStrings.add
                            : allStrings.edit}
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                  <MDBCol md="6" style={{ borderLeft: "1px solid" }}>
                    <MDBRow>
                      <MDBCol md="12">
                        <label
                          htmlFor="defaultFormRegisterNameEx2"
                          style={{
                            float: "right",
                            fontWeight: "800",
                            color: "#43425D",
                            fontSize: "14px",
                            marginBottom: "2rem",
                            marginRight: "4rem",
                          }}
                        >
                          {allStrings.client}
                        </label>
                        {this.state.client.id &&
                          this.props.currentUser.user.type == "ADMIN" && (
                            <span
                              style={{
                                float: "right",
                                margin: "-3px 12px",
                                fontWeight: 600,
                                fontSize: "14px",
                                color: "red",
                              }}
                            >
                              <span>{allStrings.generalCode}</span>
                              <span>{this.state.client.id}</span>
                            </span>
                          )}
                        {this.state.client.id &&
                          this.props.currentUser.user.type == "ADMIN" && (
                            <MDBBtn
                              onClick={this.openChange}
                              style={{
                                margin: "auto",
                                padding: "6px 20px",
                                height: "30px",
                                width: "40%",
                                fontSize: "12px",
                                fontWeight: "600",
                                background: "#4F73AC",
                                color: "#fff",
                              }}
                              className="btn z-depth-1a "
                              color="#4F73AC"
                            >
                              عرض العميل بالنسبة للشركات
                            </MDBBtn>
                          )}
                      </MDBCol>
                    </MDBRow>
                    {this.state.client.idInCompany && (
                      <MDBRow>
                        <MDBCol md="9" sm="9">
                          <input
                            name="#"
                            type="text"
                            id="defaultFormRegisterEx5"
                            disabled
                            className="form-control hash-new"
                            style={{
                              direction: this.props.isRTL ? "rtl" : "ltr",
                            }}
                            defaultValue={this.state.client.idInCompany}
                          />
                        </MDBCol>
                        <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                          <label
                            htmlFor="defaultFormRegisterEx5"
                            className="grey-text formLabel"
                          >
                            {allStrings.clientCode}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    )}
                    <MDBRow>
                      <MDBCol md="9" sm="9">
                        <input
                          name="fullname"
                          onChange={this.changeHandler}
                          type="text"
                          id="defaultFormRegisterNameEx5"
                          required
                          className="form-control hash-new"
                          placeholder={allStrings.enterName}
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                          defaultValue={this.state.client.fullname}
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx5"
                          className="grey-text formLabel"
                        >
                          {allStrings.name}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="9" sm="9">
                        <input
                          name="password"
                          onChange={this.changeHandler}
                          type="text"
                          id="defaultFormRegisterNameEx5"
                          className="form-control hash-new"
                          placeholder={allStrings.enterPassword}
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                          defaultValue={
                            this.state.client.password || "**********"
                          }
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx5"
                          className="grey-text formLabel"
                        >
                          {allStrings.password}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="9" sm="9">
                        {this.state.profileImg == null && (
                          <div className="smallUploadContainer">
                            <img
                              alt=""
                              name="img"
                              className="smallUploderImg"
                              src={require("../../assets/images/Path 343.png")}
                            ></img>
                            <input
                              onChange={this.fileHandlerProfileImg}
                              type="file"
                              id="defaultFormRegisterNameEx6"
                              required
                              className="smallUploader"
                            />
                            <MDBBtn
                              color="primary"
                              size="sm"
                              className="smallUploaderBtn"
                            >
                              {allStrings.chooseFile}
                            </MDBBtn>
                            <p
                              style={{
                                fontSize: "14px",
                                marginTop: "3px 15px",
                                float: "right",
                                paddingTop: "4px",
                              }}
                              className="grey-text"
                            >
                              {allStrings.dropHere}
                            </p>
                          </div>
                        )}
                        <div className="previewRaduis">
                          {this.state.profileImg != null && (
                            <MDBRow>
                              <MDBCol md="3">
                                <div
                                  style={{
                                    paddingTop: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.setState({ isOpen2: true })
                                  }
                                >
                                  <span
                                    style={{
                                      fontSize: "17px",
                                      color: "#478DFF",
                                      fontWeight: "600",
                                      margin: "5px",
                                    }}
                                  >
                                    {allStrings.showD}
                                  </span>
                                  <img
                                    alt=""
                                    className="showImge"
                                    width="20px"
                                    src={require("../../assets/images/icons8_external_link_480px_2.png")}
                                  ></img>
                                </div>
                              </MDBCol>
                              <MDBCol md="9" style={{ paddingRight: "30px" }}>
                                {this.state.profileImg != null && (
                                  <div
                                    style={{
                                      display: "inline-block",
                                      float: "right",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    <img
                                      alt=""
                                      src={
                                        this.state.profileImgType == "data"
                                          ? URL.createObjectURL(
                                              this.state.profileImg
                                            )
                                          : this.state.profileImg
                                      }
                                    />
                                    <MDBIcon
                                      icon="times"
                                      className="mr-2 removeImg"
                                      onClick={() => this.removeProfileImg()}
                                    ></MDBIcon>
                                  </div>
                                )}
                              </MDBCol>
                            </MDBRow>
                          )}
                          {isOpen2 && (
                            <Lightbox
                              mainSrc={profileImg[photoIndex]}
                              nextSrc={
                                profileImg[(photoIndex + 1) % profileImg.length]
                              }
                              prevSrc={
                                profileImg[
                                  (photoIndex + profileImg.length - 1) %
                                    profileImg.length
                                ]
                              }
                              onCloseRequest={() =>
                                this.setState({ isOpen2: false, photoIndex: 0 })
                              }
                              onMovePrevRequest={() =>
                                this.setState({
                                  photoIndex:
                                    (photoIndex + profileImg.length - 1) %
                                    profileImg.length,
                                })
                              }
                              onMoveNextRequest={() =>
                                this.setState({
                                  photoIndex:
                                    (photoIndex + 1) % profileImg.length,
                                })
                              }
                            />
                          )}
                        </div>
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx7"
                          className="grey-text formLabel"
                        >
                          {allStrings.profileImg}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="9" sm="9">
                        {this.state.cardImg == null && (
                          <div className="smallUploadContainer">
                            <img
                              alt=""
                              name="img"
                              className="smallUploderImg"
                              src={require("../../assets/images/Path 343.png")}
                            ></img>
                            <input
                              multiple
                              onChange={this.fileHandlerCardImg}
                              type="file"
                              id="defaultFormRegisterNameE03"
                              className="smallUploader"
                            />
                            <MDBBtn
                              color="primary"
                              size="sm"
                              className="smallUploaderBtn"
                            >
                              {allStrings.chooseFile}
                            </MDBBtn>
                            <p
                              style={{
                                fontSize: "14px",
                                marginTop: "3px 15px",
                                float: "right",
                                paddingTop: "4px",
                              }}
                              className="grey-text"
                            >
                              {allStrings.dropHere}
                            </p>
                          </div>
                        )}

                        {this.state.cardImg != null && (
                          <div className="previewRaduis">
                            <MDBRow>
                              <MDBCol md="3">
                                <div
                                  style={{
                                    paddingTop: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.setState({ isOpen1: true })
                                  }
                                >
                                  <span
                                    style={{
                                      fontSize: "17px",
                                      color: "#478DFF",
                                      fontWeight: "600",
                                      margin: "5px",
                                    }}
                                  >
                                    {allStrings.showD}
                                  </span>
                                  <img
                                    alt=""
                                    className="showImge"
                                    width="20px"
                                    src={require("../../assets/images/icons8_external_link_480px_2.png")}
                                  ></img>
                                </div>
                              </MDBCol>
                              <MDBCol md="9" style={{ paddingRight: "30px" }}>
                                {this.state.cardImgViews.map((val) => (
                                  <div
                                    style={{
                                      display: "inline-block",
                                      float: "right",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    <img
                                      alt=""
                                      src={
                                        this.state.cardImgType == "data"
                                          ? val
                                          : val
                                      }
                                    />
                                    <MDBIcon
                                      icon="times"
                                      className="mr-2 removeImg"
                                      onClick={() => this.removeCardImg(val)}
                                    ></MDBIcon>
                                  </div>
                                ))}
                              </MDBCol>
                            </MDBRow>
                            {isOpen1 && (
                              <Lightbox
                                mainSrc={cardImg[photoIndex]}
                                nextSrc={
                                  cardImg[(photoIndex + 1) % cardImg.length]
                                }
                                prevSrc={
                                  cardImg[
                                    (photoIndex + cardImg.length - 1) %
                                      cardImg.length
                                  ]
                                }
                                onCloseRequest={() =>
                                  this.setState({
                                    isOpen1: false,
                                    photoIndex: 0,
                                  })
                                }
                                onMovePrevRequest={() =>
                                  this.setState({
                                    photoIndex:
                                      (photoIndex + cardImg.length - 1) %
                                      cardImg.length,
                                  })
                                }
                                onMoveNextRequest={() =>
                                  this.setState({
                                    photoIndex:
                                      (photoIndex + 1) % cardImg.length,
                                  })
                                }
                              />
                            )}
                          </div>
                        )}
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx003"
                          className="grey-text formLabel"
                        >
                          {allStrings.cardImg}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    {/* 
                    <MDBRow>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx11"
                          className="grey-text formLabel"
                        >
                          {allStrings.receipts}
                        </label>
                      </MDBCol>
                    </MDBRow> */}
                    <MDBRow style={{}}>
                      <MDBCol md="9" sm="9">
                        <input
                          name="cardNum"
                          onChange={this.changeHandler}
                          type="text"
                          id="defaultFormRegisterNameEx12"
                          className="form-control hash-new"
                          placeholder={allStrings.enterCardNum}
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                          defaultValue={this.state.client.cardNum}
                          required
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx12"
                          className="grey-text formLabel"
                        >
                          {allStrings.cardNum}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow style={{}}>
                      <MDBCol md="9" sm="9">
                        <input
                          name="address"
                          onChange={this.changeHandler}
                          type="text"
                          id="defaultFormRegisterNameEx13"
                          className="form-control hash-new"
                          placeholder={allStrings.enterAddress}
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                          defaultValue={this.state.client.address}
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx13"
                          className="grey-text formLabel"
                        >
                          {allStrings.address}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow style={{}}>
                      <MDBCol md="9" sm="9">
                        <TreeSelect
                          value={this.state.city}
                          showSearch={false}
                          style={{
                            width: "100%",
                            textAlign: this.props.isRTL ? "right" : "left",
                          }}
                          treeCheckable={false}
                          treeNodeFilterProp="title"
                          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                          placeholder={allStrings.chooseCity}
                          onChange={(value) => {
                            console.log(value);
                            this.setState({ city: value });
                          }}
                        >
                          {/*<TreeNode style={{background:"antiquewhite",color:'#fff !important'}} value="newClient" title={allStrings.newClient} key={allStrings.newClient} />*/}
                          {this.state.cities.map((val) => (
                            <TreeNode
                              value={val.id}
                              title={
                                this.props.isRTL
                                  ? val.cityName_ar
                                  : val.cityName_en
                              }
                              key={val.id}
                            />
                          ))}
                        </TreeSelect>
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx14"
                          className="grey-text formLabel"
                        >
                          {allStrings.city}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow style={{}}>
                      <MDBCol md="9" sm="9">
                        <input
                          name="phone"
                          onChange={this.changeHandler}
                          type="text"
                          id="defaultFormRegisterNameEx15"
                          className="form-control hash-new"
                          placeholder={allStrings.enterPhone}
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                          defaultValue={this.state.client.phone}
                          required
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx15"
                          className="grey-text formLabel"
                        >
                          {allStrings.phone}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow style={{}}>
                      <MDBCol md="9" sm="9">
                        <input
                          name="anotherPhone"
                          onChange={this.changeHandler}
                          type="text"
                          id="defaultFormRegisterNameEx16"
                          className="form-control hash-new"
                          placeholder={allStrings.EnterAnotherPhone}
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                          defaultValue={this.state.client.anotherPhone}
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx16"
                          className="grey-text formLabel"
                        >
                          {allStrings.anotherPhone}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow style={{ borderTop: "1px solid #000" }}>
                      <MDBCol md="9" sm="9">
                        <input
                          name="jobArea"
                          onChange={this.changeHandler}
                          type="text"
                          id="defaultFormRegisterNameEx12"
                          className="form-control hash-new"
                          placeholder={allStrings.enterJobArea}
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                            marginTop: "2%",
                          }}
                          defaultValue={this.state.client.jobArea}
                        />
                      </MDBCol>
                      <MDBCol
                        md="3"
                        sm="3"
                        style={{ paddingLeft: "0px", marginTop: "1%" }}
                      >
                        <label
                          htmlFor="defaultFormRegisterNameEx12"
                          className="grey-text formLabel"
                        >
                          {allStrings.jobArea}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow style={{}}>
                      <MDBCol md="9" sm="9">
                        <input
                          name="jobLocation"
                          onChange={this.changeHandler}
                          type="text"
                          id="defaultFormRegisterNameEx12"
                          className="form-control hash-new"
                          placeholder={allStrings.enterJobLocation}
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                          defaultValue={this.state.client.jobLocation}
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx12"
                          className="grey-text formLabel"
                        >
                          {allStrings.jobLocation}
                        </label>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow style={{}}>
                      <MDBCol md="9" sm="9">
                        <input
                          name="job"
                          onChange={this.changeHandler}
                          type="text"
                          id="defaultFormRegisterNameEx12"
                          className="form-control hash-new"
                          placeholder={allStrings.enterJob}
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                          defaultValue={this.state.client.job}
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx12"
                          className="grey-text formLabel"
                        >
                          {allStrings.job}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow style={{}}>
                      <MDBCol md="9" sm="9">
                        <input
                          name="jobLevel"
                          onChange={this.changeHandler}
                          type="text"
                          id="defaultFormRegisterNameEx12"
                          className="form-control hash-new"
                          placeholder={allStrings.enterJobLevel}
                          style={{
                            direction: this.props.isRTL ? "rtl" : "ltr",
                          }}
                          defaultValue={this.state.client.jobLevel}
                        />
                      </MDBCol>
                      <MDBCol md="3" sm="3" style={{ paddingLeft: "0px" }}>
                        <label
                          htmlFor="defaultFormRegisterNameEx12"
                          className="grey-text formLabel"
                        >
                          {allStrings.jobLevel}
                        </label>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBRow>
          </form>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = (state) => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  branch: state.company.branch,
  company: state.company.company,
});

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
};
export default withRouter(
  connect(mapToStateProps, mapDispatchToProps)(ClientForm)
);
