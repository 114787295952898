/* eslint-disable eqeqeq */
import React from "react";
import "./partner.css";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import { ChangeLanguage } from "../../redux/actions/LanguageAction";
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { setUser } from "../../redux/actions/AuthActions";
import { Popconfirm, message, Input } from "antd";
import Pagination from "@material-ui/lab/Pagination";
import LoadCard from "../../component/load card/load card";
import { hasValue } from "../../validations/validations";
import { SearchOutlined } from "@ant-design/icons";
import { Redirect, NavLink } from "react-router-dom";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { ChangeCompany, ChangeBranch } from "../../redux/actions/CompanyAction";

class ComponentToPrint extends React.Component {
  render() {
    console.log("wat is here ", this.props);
    return (
      <form class="col s12">
        <div className="tabelDev">
          <div class="row">
            <table class="table table-borderless">
              <thead class="thead-light">
                <tr>
                  <th>الاسم</th>
                  <th>الهاتف</th>
                  <th>هاتف اخر</th>
                  <th>رقم البطاقه</th>
                  <th>المستحقات</th>
                </tr>
                <tr>
                  <th>Items</th>
                  <th>Phone</th>
                  <th>Phone2</th>
                  <th>Card Num</th>
                  <th>Ration</th>
                </tr>
              </thead>

              <tbody>
                {this.props.data.map((partner) => (
                  <tr>
                    <td>{partner.fullname ? partner.fullname : ""}</td>
                    <td>{partner.phone ? partner.phone : ""}</td>
                    <td>{partner.anotherPhone ? partner.anotherPhone : ""}</td>
                    <td>{partner.cardNum ? partner.cardNum : ""}</td>
                    <td>
                      {partner.capitalRation
                        ? Math.ceil(partner.capitalRation * 100) / 100
                        : 0}{" "}
                      %{" "}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </form>
    );
  }
}
class Partners extends React.Component {
  state = {
    Partners: [],
    loading: true,
    refresh: false,
    page: 1,
    pages: 1,
    selectedPartner: null,
    salesMen: [],
    filterType: null,
    value: "",
    partnersCount: [],
    partners: [],
    totalCapital: 0,
  };
  constructor(props) {
    super(props);
    console.log("isr   ", this.props.history);
    this.getPartners(1, false);
    this.setState({ totalCapital: this.props.currentUser.user.capital });
    this.getParentsWithoutPagenation();
    if (this.props.isRTL) {
      allStrings.setLanguage("ar");
    } else {
      allStrings.setLanguage("en");
    }
    window.scrollTo(0, 0);
  }
  getParentsWithoutPagenation = () => {
    let url = ``;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}withoutPagenation/get?type=PARTNER`;
      } else {
        url = `${BASE_END_POINT}withoutPagenation/get?type=PARTNER&company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}withoutPagenation/get?type=PARTNER&company=${this.props.currentUser.user.id}`;
    }
    axios
      .get(url)
      .then((response) => {
        console.log(response.data.data);
        this.setState({
          partners: response.data.data,
          partnersCount: response.data.totalCount,
        });
      })
      .catch((error) => {
        console.log("ALL salesMen ERROR");
        console.log(error);
      });
  };

  getPartners = (page, refresh, filterType, value) => {
    this.setState({ loading: true });
    let url = ``;

    console.log(filterType);
    console.log(value);
    if (filterType != null && value != "") {
      if (filterType == "cardNum") {
        if (
          this.props.currentUser.user.type == "ADMIN" ||
          this.props.currentUser.user.type == "SUB-ADMIN"
        ) {
          if (this.props.company == false) {
            url = `${BASE_END_POINT}find?type=PARTNER&page=${page}&cardNum=${value}`;
          } else {
            url = `${BASE_END_POINT}find?type=PARTNER&page=${page}&cardNum=${value}&company=${this.props.company}`;
          }
        } else {
          url = `${BASE_END_POINT}find?type=PARTNER&page=${page}&company=${this.props.currentUser.user.id}&cardNum=${value}`;
        }
      }

      if (filterType == "name") {
        if (
          this.props.currentUser.user.type == "ADMIN" ||
          this.props.currentUser.user.type == "SUB-ADMIN"
        ) {
          if (this.props.company == false) {
            url = `${BASE_END_POINT}find?type=PARTNER&page=${page}&name=${value}`;
          } else {
            url = `${BASE_END_POINT}find?type=PARTNER&page=${page}&name=${value}&company=${this.props.company}`;
          }
        } else {
          url = `${BASE_END_POINT}find?type=PARTNER&page=${page}&company=${this.props.currentUser.user.id}&name=${value}`;
        }
      }
    } else {
      if (
        this.props.currentUser.user.type == "ADMIN" ||
        this.props.currentUser.user.type == "SUB-ADMIN"
      ) {
        if (this.props.company == false) {
          url = `${BASE_END_POINT}find?type=PARTNER&page=${page}`;
        } else {
          url = `${BASE_END_POINT}find?type=PARTNER&page=${page}&company=${this.props.company}`;
        }
      } else {
        url = `${BASE_END_POINT}find?type=PARTNER&page=${page}&company=${this.props.currentUser.user.id}`;
      }
    }

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("Partners   ", response.data);
        this.setState({
          loading: false,
          refresh: false,
          Partners: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
      })
      .catch((error) => {
        console.log("error2   ", error);
        this.setState({ loading: false });
      });
  };
  selectPartnerId = (e) => {
    e.preventDefault();
    let id = e.target.id.substring(7);
    console.log(id);
    this.setState({ selectedPartner: id });
  };
  deletePartner = (id) => {
    let l = message.loading("Wait..", 2.5);
    axios
      .delete(`${BASE_END_POINT}${id}/delete`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("Partner DELETED");
        l.then(() => message.success(allStrings.done, 2));
        this.getPartners(this.state.page);
      })
      .catch((error) => {
        console.log(error.response);
        l.then(() => message.error("Error", 2.5));
      });
  };
  OKBUTTON = (e) => {
    this.deletePartner(this.state.selectedPartner);
  };

  render() {
    console.log(this.state.filterType);
    if (
      this.props.currentUser.user.type == "SUB-ADMIN" &&
      !this.props.currentUser.user.pagesPermission.includes("PARTNERS")
    )
      return <Redirect to="/Home" />;

    return (
      <div className="Category">
        <div className="categoryTop" style={{ marginBottom: "3rem" }}>
          <MDBContainer>
            <MDBRow>
              <MDBCol xl="3" lg="3" md="3" sm="12">
                <ReactToPrint
                  documentTitle={
                    "تقرير الشركاء بتاريخ " + moment().format("YYYY/MM/DD")
                  }
                  trigger={() => {
                    return (
                      <div>
                        <img
                          alt=""
                          style={{ cursor: "pointer" }}
                          src={require("../../assets/images/icons8_pdf.png")}
                        ></img>
                        <span
                          style={{
                            color: "#FF3B40",
                            fontWeight: "600",
                            padding: "10px",
                            fontSize: "20px",
                            position: "absolute",
                            cursor: "pointer",
                          }}
                        >
                          {allStrings.print}
                        </span>
                      </div>
                    );
                  }}
                  content={() => this.componentRef}
                />
                <div style={{ display: "none" }}>
                  <ComponentToPrint
                    data={this.state.partners || []}
                    ref={(el) => (this.componentRef = el)}
                  />
                </div>
              </MDBCol>
              <MDBCol
                xl="3"
                lg="3"
                md="3"
                sm="12"
                style={{ paddingTop: "1rem" }}
              >
                <img
                  alt=""
                  width="40px"
                  src={require("../../assets/images/icons8_investment_portfolio.png")}
                ></img>
                <span
                  style={{
                    color: "mediumblue",
                    fontWeight: "600",
                    fontSize: "22px",
                    position: "absolute",
                    padding: "5px 10px",
                  }}
                >
                  {this.state.partnersCount}
                </span>
                <p
                  style={{
                    color: "mediumblue",
                    fontSize: "14px",
                    marginTop: "6px",
                    marginBottom: 0,
                    fontWeight: "600",
                  }}
                >
                  {allStrings.partnersCount}
                </p>
              </MDBCol>
              <MDBCol
                xl="3"
                lg="3"
                md="3"
                sm="12"
                style={{ paddingTop: "1rem" }}
              >
                <img
                  alt=""
                  width="40px"
                  src={require("../../assets/images/icons8_money_bag.png")}
                ></img>
                <span
                  style={{
                    color: "mediumblue",
                    fontWeight: "600",
                    fontSize: "22px",
                    position: "absolute",
                    padding: "5px 10px",
                  }}
                >
                  {this.state.totalCapital}
                </span>
                <p
                  style={{
                    color: "mediumblue",
                    fontSize: "14px",
                    marginTop: "6px",
                    marginBottom: 0,
                    fontWeight: "600",
                  }}
                >
                  {allStrings.totalCapital}
                </p>
              </MDBCol>
              <MDBCol xl="3" lg="3" md="3" sm="12">
                <NavLink to="addPartner">
                  <div className="addButton">
                    <MDBIcon icon="plus" />
                  </div>
                  <span
                    style={{
                      float: "right",
                      color: "#43425D",
                      fontWeight: "600",
                      padding: "11px 2px",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                  >
                    {allStrings.addPartner}
                  </span>
                </NavLink>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <div
                onClick={() => this.getPartners(1, false)}
                style={{
                  width: "100%",
                  textAlign: "end",
                  paddingRight: "18px",
                }}
              >
                <span
                  style={{ color: "cadetblue", cursor: "pointer" }}
                  class="material-icons"
                >
                  undo
                </span>
              </div>
            </MDBRow>
          </MDBContainer>
        </div>
        <div className="filters" style={{ marginBottom: "2rem" }}>
          <MDBContainer>
            <MDBRow>
              <MDBCol xl="5" lg="5" md="5" sm="12">
                <Input
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      if (hasValue("" + e.target.value)) {
                        console.log("ENTER   ", e.target.value);
                        this.setState({
                          filterType: "cardNum",
                          value: e.target.value,
                        });
                        this.getPartners(1, false, "cardNum", e.target.value);
                      } else {
                        message.warning(allStrings.noValue);
                        console.log("ENTER NO VALUE   ", e.target.value);
                      }
                    }
                  }}
                  placeholder={allStrings.cardFilter}
                  size="large"
                  prefix={<SearchOutlined />}
                />
              </MDBCol>
              <MDBCol xl="2" lg="2" md="2" sm="12"></MDBCol>
              <MDBCol xl="5" lg="5" md="5" sm="12">
                <Input
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      if (hasValue("" + e.target.value)) {
                        console.log("ENTER   ", e.target.value);
                        this.setState({
                          filterType: "name",
                          value: e.target.value,
                        });
                        this.getPartners(1, false, "name", e.target.value);
                      } else {
                        message.warning(allStrings.noValue);
                        console.log("ENTER NO VALUE   ", e.target.value);
                      }
                    }
                  }}
                  placeholder={allStrings.nameFilter}
                  size="large"
                  prefix={<SearchOutlined />}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
        <MDBContainer>
          <MDBRow>
            {this.state.loading ? (
              <LoadCard />
            ) : this.state.Partners.length > 0 ? (
              this.state.Partners.map((Partner) => (
                <MDBCol xl="4" lg="6" md="12" sm="12">
                  <div className="categoryCard">
                    <MDBContainer>
                      <MDBRow>
                        <MDBCol xl="2" lg="2" md="2" sm="2">
                          <div className="icons">
                            <Popconfirm
                              title={allStrings.areYouSure}
                              onConfirm={this.OKBUTTON}
                              onCancel={this.fCANCELBUTTON}
                              okText={allStrings.ok}
                              cancelText={allStrings.cancel}
                            >
                              <p
                                style={{
                                  fontSize: "24px",
                                  color: "#A4A3B0",
                                  marginBottom: "8px",
                                  cursor: "pointer",
                                  zIndex: "4",
                                  position: "relative",
                                }}
                                onClick={this.selectPartnerId}
                                id={"Partner" + Partner.id}
                              >
                                <MDBIcon
                                  icon="times"
                                  onClick={this.selectPartnerId}
                                  id={"Partner" + Partner.id}
                                />
                              </p>
                            </Popconfirm>

                            <p
                              onClick={() =>
                                this.props.history.push("/updatePartner", {
                                  data: Partner,
                                })
                              }
                              style={{
                                fontSize: "18px",
                                color: "#A4A3B0",
                                marginBottom: "8px",
                                cursor: "pointer",
                              }}
                            >
                              <MDBIcon icon="pen" />
                            </p>
                          </div>
                        </MDBCol>
                        <MDBCol
                          xl="6"
                          lg="6"
                          md="6"
                          sm="6"
                          className="noPadding"
                        >
                          <div
                            className="categoryInfo"
                            onClick={() =>
                              this.props.history.push("/updatePartner", {
                                data: Partner,
                              })
                            }
                          >
                            <p
                              style={{
                                fontWeight: "600",
                                color: "#43425D",
                                marginBottom: "6px",
                                fontSize: "1.4rem",
                              }}
                            >
                              {Partner.fullname.length > 10
                                ? Partner.fullname.substring(0, 11) + ".."
                                : Partner.fullname}
                            </p>

                            <p
                              style={{
                                color: "#43425D",
                                fontSize: "16px",
                                marginBottom: "8px",
                                display: "inline-block",
                              }}
                            >
                              {Partner.phone}{" "}
                            </p>

                            <p
                              style={{
                                color: "#43425D",
                                fontSize: "16px",
                                margin: 0,
                                marginBottom: "10px",
                              }}
                            >
                              {Partner.anotherPhone
                                ? Partner.anotherPhone
                                : " "}
                            </p>
                            <p
                              style={{
                                color: "#43425D",
                                fontSize: "16px",
                                margin: 0,
                                marginBottom: "10px",
                              }}
                            >
                              {Partner.capitalRation
                                ? Math.ceil(Partner.capitalRation * 100) / 100
                                : 0}{" "}
                              %{" "}
                            </p>
                          </div>
                        </MDBCol>
                        <MDBCol xl="4" lg="4" md="4" sm="4">
                          <div className="categoryImg">
                            <img
                              alt=""
                              style={{
                                width: "85px",
                                height: "85px",
                                borderRadius: "100%",
                              }}
                              src={
                                Partner.img
                                  ? Partner.img
                                  : require("../../assets/images/profileicon.jpg")
                              }
                            ></img>
                          </div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol md="12">
                          <p
                            style={{
                              color: "#43425D",
                              fontSize: "16px",
                              marginBottom: "8px",
                              display: "inline-block",
                            }}
                          >
                            {Partner.partnership
                              ? moment(
                                  Partner.partnership.startDateMillSec
                                ).calendar()
                              : " "}{" "}
                          </p>
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                  </div>
                </MDBCol>
              ))
            ) : (
              <div className="noData">{allStrings.noData}</div>
            )}
          </MDBRow>
          <MDBRow>
            {this.state.Partners.length > 0 && (
              <div
                style={{
                  textAlign: "center",
                  margin: "auto",
                  marginTop: "3rem",
                }}
              >
                <Pagination
                  onChange={(event, page) => {
                    console.log("page   ", page);
                    if (page != this.state.page) {
                      this.getPartners(
                        page,
                        false,
                        this.state.filterType,
                        this.state.value
                      );
                      this.setState({ page: page });
                    }
                  }}
                  defaultPage={1}
                  page={this.state.page}
                  count={this.state.pages}
                  style={{ color: `blue !important` }}
                />
              </div>
            )}
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = (state) => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  branch: state.company.branch,
  company: state.company.company,
});

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
};

export default withRouter(
  connect(mapToStateProps, mapDispatchToProps)(Partners)
);
