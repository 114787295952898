/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React from "react";
import "./collectibles.css";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import { ChangeLanguage } from "../../redux/actions/LanguageAction";
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { setUser } from "../../redux/actions/AuthActions";
import { message, TreeSelect, DatePicker, ConfigProvider } from "antd";
import { hasValue } from "../../validations/validations";
import moment from "moment";
import { Redirect } from "react-router-dom";
import { ChangeCompany, ChangeBranch } from "../../redux/actions/CompanyAction";
import locale_ar from "antd/lib/locale/ar_EG";
import locale_en from "antd/lib/locale/en_GB";
import "moment/locale/ar";
const { TreeNode } = TreeSelect;
let fromDate, toDate;
class Collectibles extends React.Component {
  state = {
    Collectibles: [],
    loading: true,
    loading2: true,
    loading3: true,
    refresh: false,
    page: 1,
    pages: 1,
    selectedCollectibles: null,
    salesMan: this.props.location.state
      ? this.props.location.state.salesMan
        ? this.props.location.state.salesMan
        : []
      : [],
    showPayAll: true,
    from: this.props.isRTL
      ? moment().startOf("month").format("YYYY/MM/DD")
      : moment().startOf("month").locale("en").format("YYYY/MM/DD"),
    to: this.props.isRTL
      ? moment().endOf("month").format("YYYY/MM/DD")
      : moment().endOf("month").locale("en").format("YYYY/MM/DD"),
    month: this.props.isRTL
      ? moment().endOf("month").format("MMMM")
      : moment().endOf("month").locale("en").format("MMMM"),
    monthN: moment().endOf("month").month(),
    clients: [],
    orders: [],
    premiums: [],
    salesMen: [],
    filterType: this.props.location.state
      ? this.props.location.state.filterType
        ? "salesMan"
        : null
      : null,
    value: this.props.location.state
      ? this.props.location.state.salesMan
        ? this.props.location.state.salesMan
        : ""
      : "",
    selectedClient: this.props.location.state
      ? this.props.location.state.client
        ? this.props.location.state.client
        : null
      : null,
    selectedOrder: null,
    selectedPremium: null,
    theDate: "",
    delayOption: "",
    modal: false,
    modalType: "delay",
    newSalesMan: null,
    oldSalesMan: null,
    count: [],
    client: this.props.location.state ? this.props.location.state.client : null,
  };
  constructor(props) {
    super(props);
    console.log("isr   ", this.props.history);
    if (this.props.isRTL) {
      allStrings.setLanguage("ar");
    } else {
      allStrings.setLanguage("en");
    }
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    console.log("OUFF", this.state.client);
    if (this.state.client) {
      this.getClient(this.state.client);
    } else {
      this.getClients();
    }
    this.getSalesMen();
    this.getDuration();
    if (this.state.filterType == "salesMan") {
      this.getCountBySalesMan(this.state.salesMan, fromDate, toDate);
    } else {
      this.getCount(fromDate, toDate);
    }
    let d = new Date();
    let y = d.getFullYear();
    let m = d.getMonth();
    let date = m + "/" + y;
    this.setState({ theDate: date });
  }
  getDuration = () => {
    //first day
    let date = new Date();
    let newDate = date.toISOString().slice(0, 8);
    let firstDay = newDate + "01";
    //get days in month
    function daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    }

    let DaysInCurrentMonth = daysInMonth(
      date.getMonth() + 1,
      date.getFullYear()
    ); //number of day in month
    //last day
    let lastDay = newDate + DaysInCurrentMonth;
    if (this.props.isRTL) {
      this.setState({
        from: moment(firstDay).format("YYYY/MM/DD"),
        to: moment(lastDay).format("YYYY/MM/DD"),
        month: moment(lastDay).format("MMMM"),
        monthN: moment(lastDay).endOf("month").month(),
      });
    } else {
      this.setState({
        from: moment(firstDay).locale("en").format("YYYY/MM/DD"),
        to: moment(lastDay).locale("en").format("YYYY/MM/DD"),
        month: moment(lastDay).locale("en").format("MMMM"),
        monthN: moment(lastDay).endOf("month").month(),
      });
    }

    fromDate = firstDay;
    toDate = lastDay;
  };
  getCount = (start, end) => {
    //${this.props.currentUser.user.id}

    let url = ``;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}${this.props.currentUser.user.id}/companyAccounting?start=${start}&end=${end}`;
      } else {
        url = `${BASE_END_POINT}${this.props.company}/companyAccounting?start=${start}&end=${end}`;
      }
    } else {
      url = `${BASE_END_POINT}${this.props.currentUser.user.id}/companyAccounting?start=${start}&end=${end}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        this.setState({ count: response.data });
      })
      .catch((error) => {
        console.log("ALL count ERROR");
        console.log(error.response);
      });
  };
  getCountBySalesMan = (salesMan, start, end) => {
    //${this.props.currentUser.user.id}

    let url = `${BASE_END_POINT}${salesMan}/salesManAccounting?start=${start}&end=${end}`;

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("based on sales man", response.data);
        this.setState({ count: response.data });
      })
      .catch((error) => {
        console.log("ALL count ERROR");
        console.log(error.response);
      });
  };
  getSalesMen = () => {
    let url = ``;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN`;
      } else {
        url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN&company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN&company=${this.props.currentUser.user.id}`;
    }
    axios
      .get(url)
      .then((response) => {
        console.log(response.data.users);
        this.setState({ salesMen: response.data.users });
      })
      .catch((error) => {
        console.log("ALL salesMen ERROR");
        console.log(error.response);
      });
  };

  getClients = (filterType, value) => {
    this.setState({ loading: true });
    let url = ``;
    if (!value) {
      filterType = this.state.filterType;
      value = this.state.salesMan;
    }
    console.log("FILTER", filterType);
    console.log("First Value", value, this.state.value);
    if (filterType != null && value != "") {
      if (filterType == "salesMan") {
        if (value != "all") {
          if (
            this.props.currentUser.user.type == "ADMIN" ||
            this.props.currentUser.user.type == "SUB-ADMIN"
          ) {
            if (this.props.company == false) {
              url = `${BASE_END_POINT}clients/findAllForMobile?salesMan=${value}&limit=100`;
            } else {
              url = `${BASE_END_POINT}clients/findAllForMobile?salesMan=${value}&company=${this.props.company}&limit=100`;
            }
          } else {
            url = `${BASE_END_POINT}clients/findAllForMobile?company=${this.props.currentUser.user.id}&salesMan=${value}&limit=100`;
          }
        } else {
          if (
            this.props.currentUser.user.type == "ADMIN" ||
            this.props.currentUser.user.type == "SUB-ADMIN"
          ) {
            if (this.props.company == false) {
              url = `${BASE_END_POINT}clients/withoutPagenation/get`;
            } else {
              url = `${BASE_END_POINT}clients/withoutPagenation/get?company=${this.props.company}`;
            }
          } else {
            url = `${BASE_END_POINT}clients/withoutPagenation/get?company=${this.props.currentUser.user.id}`;
          }
        }
      }
    } else {
      if (
        this.props.currentUser.user.type == "ADMIN" ||
        this.props.currentUser.user.type == "SUB-ADMIN"
      ) {
        if (this.props.company == false) {
          url = `${BASE_END_POINT}clients/withoutPagenation/get`;
        } else {
          url = `${BASE_END_POINT}clients/withoutPagenation/get?company=${this.props.company}`;
        }
      } else {
        url = `${BASE_END_POINT}clients/withoutPagenation/get?company=${this.props.currentUser.user.id}`;
      }
    }

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("clients   ", response.data);
        console.log("Value", value, this.state.value);
        this.setState({
          loading: false,
          refresh: false,
          clients: response.data.data,
          orders: [],
          premiums: [],
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading: false, orders: [], premiums: [] });
      });
  };
  getClient = (id) => {
    let url = ``;
    if (
      this.props.currentUser.user.type === "ADMIN" ||
      this.props.currentUser.user.type === "SUB-ADMIN"
    ) {
      if (this.props.company === false) {
        url = `${BASE_END_POINT}clients/${id}`;
      } else {
        url = `${BASE_END_POINT}clients/${id}?company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}clients/${id}?company=${this.props.currentUser.user.id}`;
    }
    console.log("URLLL", url);
    axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("WOW HERE", response);
        this.setState({
          loading: false,
          refresh: false,
          clients: [response.data.data],
          orders: [],
          premiums: [],
        });
      })
      .catch((error) => {
        console.log("Client Error");
        console.log(error);
      });
  };
  getClientOrders = (client) => {
    console.log(client);
    this.setState({ loading2: true });
    let url = `${BASE_END_POINT}orders/withoutPagenation/get?client=${client}&accept=true&paymentSystem=installment`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("orders   ", response.data);
        this.setState({
          loading2: false,
          orders: response.data.orders,
          premiums: [],
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        this.setState({ loading2: false });
      });
  };
  getPremiums = (order) => {
    console.log(order);
    this.setState({ loading3: true });
    let url = `${BASE_END_POINT}premiums/withoutPagenation/get?type=INSTALLMENT&order=${order}&orderByReceiptNum=true`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("premiums   ", response.data.data);
        this.setState({ loading3: false, premiums: response.data.data });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        this.setState({ loading3: false });
      });
  };
  payPremium = () => {
    const { selectedPremium } = this.state;

    if (selectedPremium != null) {
      let l = message.loading("Wait..", 2.5);

      axios
        .put(
          `${BASE_END_POINT}premiums/${selectedPremium}/paid`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.currentUser.token}`,
            },
          }
        )
        .then((response) => {
          l.then(() => message.success(allStrings.done, 2.5));
          this.setState({ selectedPremium: null });
        })
        .catch((error) => {
          console.log(error.response);
          l.then(() => message.error("Error", 2.5));
        });
    }
  };
  delayPremium = () => {
    const { selectedPremium, delayOption } = this.state;

    if (selectedPremium != null && hasValue(delayOption)) {
      let l = message.loading("Wait..", 2.5);
      let data = {
        option: delayOption,
      };
      console.log(data);
      axios
        .put(`${BASE_END_POINT}premiums/${selectedPremium}/delay`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        })
        .then((response) => {
          l.then(() => message.success(allStrings.done, 2.5));
          this.setState({ selectedPremium: null });
          this.toggle();
        })
        .catch((error) => {
          console.log(error.response);
          l.then(() => message.error("Error", 2.5));
        });
    }
  };
  changeSalesMan = () => {
    const { newSalesMan, oldSalesMan, selectedClient } = this.state;
    console.log("Client", selectedClient);
    if (newSalesMan != null && oldSalesMan != null) {
      let l = message.loading("Wait..", 2.5);
      let data = {
        client: selectedClient,
      };
      console.log(data);
      axios
        .put(
          `${BASE_END_POINT}premiums/${oldSalesMan}/oldSalesMan/${newSalesMan}/newsalesMan/replaceSome`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.currentUser.token}`,
            },
          }
        )
        .then((response) => {
          l.then(() => message.success(allStrings.done, 2.5));
          this.toggle();
        })
        .catch((error) => {
          console.log(error);
          l.then(() => message.error("Error", 2.5));
        });
    }
  };
  payMonthPremiums = (filterType, value) => {
    let l = message.loading("Wait..", 0);
    this.setState({ showPayAll: false });
    //company
    let company;
    if (this.props.currentUser.user.type == "ADMIN") {
      if (this.props.company == false) {
        company = this.props.currentUser.user.company[0].id;
      } else {
        company = this.props.company;
      }
    }
    if (this.props.currentUser.user.type == "SUB-ADMIN") {
      if (this.props.currentUser.user.gineralSubAdmin == true) {
        if (this.props.company == false) {
          company = this.props.currentUser.user.company[0].id;
        } else {
          company = this.props.company;
        }
      } else {
        company = this.props.currentUser.user.company[0].id;
      }
    }
    if (this.props.currentUser.user.type == "COMPANY") {
      company = this.props.currentUser.user.id;
    }
    let data = { start: fromDate, end: toDate };
    if (filterType == "salesMan") {
      if (value != "all") {
        data.salesMan = value;
      }
    }
    axios
      .put(`${BASE_END_POINT}premiums/${company}/paidAllInMonth`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("done");
        message.destroy();
        let msg = " تم تحصيل مبلغ " + response.data.collectedCost;
        message.success(msg, 2.5);
        this.setState({ selectedPremium: null, showPayAll: true });
        // this.props.history.goBack();
      })
      .catch((error) => {
        console.log(error.response);
        message.error("Error", 2.5);
      });
  };
  delaySubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.delayPremium();
  };
  changeSalesManSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.changeSalesMan();
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  openChange = () => {
    this.setState({ modalType: "changeSalesMan" });
    this.toggle();
  };
  openDelay = () => {
    this.setState({ modalType: "delay" });
    this.toggle();
  };
  openAllPrim = () => {
    this.setState({ modalType: "all" });
    this.toggle();
  };
  modal = () => (
    <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
      <MDBModalHeader toggle={this.toggle}>
        {this.state.modalType != "all" && (
          <span>{allStrings.completeData}</span>
        )}
        {this.state.modalType == "all" && (
          <span
            style={{
              color: "red",
              fontWeight: "700",
            }}
          >
            أنتبه
          </span>
        )}
      </MDBModalHeader>
      <MDBModalBody>
        <MDBContainer>
          <MDBRow>
            {this.state.modalType == "delay" && (
              <MDBCol md="12">
                <form
                  style={{ marginLeft: "15%" }}
                  className="needs-validation"
                  onSubmit={this.delaySubmitHandler}
                  noValidate
                >
                  <MDBContainer>
                    <p
                      style={{
                        textAlign: this.props.isRTL ? "right" : "left",
                        margin: "0rem 4rem 3rem 0rem",
                        color: "red",
                      }}
                    >
                      <span>{allStrings.Notice}</span>
                      {allStrings.DelayDetails}
                    </p>
                    <MDBRow>
                      <MDBCol md="9">
                        <TreeSelect
                          showSearch={false}
                          style={{
                            width: "100%",
                            textAlign: this.props.isRTL ? "right" : "left",
                          }}
                          treeCheckable={false}
                          treeNodeFilterProp="title"
                          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                          placeholder={allStrings.delayOption}
                          onChange={(value) => {
                            console.log(value);
                            this.setState({ delayOption: value });
                          }}
                        >
                          <TreeNode
                            value="newMonth"
                            title={allStrings.newMonth}
                          />
                          <TreeNode
                            value="nextMonth"
                            title={allStrings.nextMonth}
                          />
                        </TreeSelect>
                      </MDBCol>
                      <MDBCol md="3">
                        <label
                          htmlFor="defaultFmRegisterNameEx1"
                          className="grey-text formLabel"
                        >
                          {allStrings.delayOption}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBBtn
                        type="submit"
                        style={{
                          margin: "3rem 1rem ",
                          width: "170px",
                          padding: "11px 20px",
                          height: "35px",
                        }}
                        className="btn z-depth-1a "
                        color="primary"
                      >
                        {allStrings.done}
                      </MDBBtn>
                    </MDBRow>
                  </MDBContainer>
                </form>
              </MDBCol>
            )}
            {this.state.modalType == "changeSalesMan" && (
              <MDBCol md="12">
                <form
                  style={{ marginLeft: "15%" }}
                  className="needs-validation"
                  onSubmit={this.changeSalesManSubmitHandler}
                  noValidate
                >
                  <MDBContainer>
                    <p
                      style={{
                        textAlign: this.props.isRTL ? "right" : "left",
                        margin: "0rem 4rem 3rem 0rem",
                        color: "red",
                      }}
                    >
                      <span>{allStrings.Notice}</span>
                      {allStrings.changeSomeDetails}
                    </p>
                    <MDBRow>
                      <MDBCol md="9">
                        <TreeSelect
                          showSearch={true}
                          style={{
                            width: "100%",
                            textAlign: this.props.isRTL ? "right" : "left",
                          }}
                          treeCheckable={false}
                          treeNodeFilterProp="title"
                          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                          placeholder={allStrings.chooseSalesMan}
                          onChange={(value) => {
                            console.log(value);
                            this.setState({ newSalesMan: value });
                          }}
                        >
                          {this.state.salesMen.map((val) => (
                            <TreeNode
                              value={val.id}
                              title={val.fullname}
                              key={val.id}
                            />
                          ))}
                        </TreeSelect>
                      </MDBCol>
                      <MDBCol md="3">
                        <label
                          htmlFor="defaultFmRegisterNameEx1"
                          className="grey-text formLabel"
                        >
                          {allStrings.newSalesMan}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="9">
                        <TreeSelect
                          showSearch={true}
                          style={{
                            width: "100%",
                            textAlign: this.props.isRTL ? "right" : "left",
                          }}
                          treeCheckable={false}
                          treeNodeFilterProp="title"
                          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                          placeholder={allStrings.chooseSalesMan}
                          onChange={(value) => {
                            console.log(value);
                            this.setState({ oldSalesMan: value });
                          }}
                        >
                          {this.state.salesMen.map((val) => (
                            <TreeNode
                              value={val.id}
                              title={val.fullname}
                              key={val.id}
                            />
                          ))}
                        </TreeSelect>
                      </MDBCol>
                      <MDBCol md="3">
                        <label
                          htmlFor="defaultFmRegisterNameEx1"
                          className="grey-text formLabel"
                        >
                          {allStrings.oldSalesMan}
                        </label>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBBtn
                        type="submit"
                        style={{
                          margin: "3rem 1rem ",
                          width: "170px",
                          padding: "11px 20px",
                          height: "35px",
                        }}
                        className="btn z-depth-1a "
                        color="primary"
                      >
                        {allStrings.done}
                      </MDBBtn>
                    </MDBRow>
                  </MDBContainer>
                </form>
              </MDBCol>
            )}
            {this.state.modalType == "all" && (
              <MDBCol md="12">
                <form className="needs-validation" noValidate>
                  <MDBContainer>
                    <p
                      style={{
                        textAlign: "center",
                        margin: "auto",
                        color: "black",
                        fontSize: "25px",
                        fontWeight: "600",
                        marginTop: "-10px",
                      }}
                    >
                      هل أنت متأكد من تحصيل شهر {this.state.month} (
                      {this.state.monthN + 1}) ؟
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                        margin: "auto",
                        color: "black",
                        fontSize: "25px",
                        fontWeight: "600",
                        marginTop: "5px",
                      }}
                    >
                      سيتم إلغاء جميع إيصالات العملاء
                    </p>

                    <MDBRow
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <MDBCol xl="6" lg="6" md="6" sm="6">
                        <MDBBtn
                          onClick={this.payMonthPremiums}
                          style={{
                            margin: "auto",
                            width: "100%",
                            padding: "5px",
                            height: "35px",
                            fontSize: "18px",
                          }}
                          className="btn z-depth-1a "
                          color="danger"
                        >
                          نعم
                        </MDBBtn>
                      </MDBCol>
                      <MDBCol xl="6" lg="6" md="6" sm="6">
                        <MDBBtn
                          onClick={this.toggle}
                          style={{
                            margin: "auto",
                            width: "100%",
                            padding: "5px",
                            height: "35px",
                            fontSize: "18px",
                          }}
                          className="btn z-depth-1a "
                          color="success"
                        >
                          لا
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </form>
              </MDBCol>
            )}
          </MDBRow>
        </MDBContainer>
      </MDBModalBody>
    </MDBModal>
  );
  onChangeFrom = (date, dateString) => {
    if (this.props.isRTL) {
      this.setState({
        from: moment(dateString).format("YYYY/MM/DD"),
        month: moment(dateString).format("MMMM"),
        monthN: moment(dateString).endOf("month").month(),
      });
    } else {
      this.setState({
        from: moment(dateString).locale("en").format("YYYY/MM/DD"),
        month: moment(dateString).locale("en").format("MMMM"),
        monthN: moment(dateString).endOf("month").month(),
      });
    }
    fromDate = moment(dateString).locale("en").format("YYYY-MM-DD");
  };
  onChangeTo = (date, dateString) => {
    if (this.props.isRTL) {
      this.setState({ to: moment(dateString).format("YYYY/MM/DD") });
    } else {
      this.setState({
        to: moment(dateString).locale("en").format("YYYY/MM/DD"),
      });
    }
    toDate = moment(dateString).locale("en").format("YYYY-MM-DD");
    if (this.state.filterType == "salesMan" && this.state.value != "all") {
      this.getCountBySalesMan(this.state.value, fromDate, toDate);
    } else {
      this.getCount(fromDate, toDate);
    }
  };

  changeMan = (value) => {
    console.log("Chosee", value);
    this.setState({
      filterType: "salesMan",
      value: value,
      selectedClient: null,
      salesMan: value,
      client: null,
    });
    if (value == "all") {
      this.getCount(fromDate, toDate);

      this.getClients("salesMan", value);
    } else {
      this.getCountBySalesMan(value, fromDate, toDate);

      this.getClients("salesMan", value);
    }
  };
  render() {
    if (
      this.props.currentUser.user.type == "COMPANY" &&
      this.props.currentUser.user.buyType == "CASH"
    )
      return <Redirect to="/Home" />;
    if (
      this.props.currentUser.user.type == "SUB-ADMIN" &&
      !this.props.currentUser.user.pagesPermission.includes("COLLECTIBLES")
    )
      return <Redirect to="/Home" />;

    return (
      <div className="Category">
        <div className="categoryTop" style={{ marginBottom: "3rem" }}>
          <MDBContainer>
            {this.modal()}
            <MDBRow>
              <MDBCol xl="3" lg="3" md="3" sm="6">
                {/*<img alt=""  src={require('../../assets/images/icons8_pdf.png')}></img>
            <span
            style={{
              color: '#FF3B40',
              fontWeight: '600',
              padding: '10px',
              fontSize: '20px',
              position: 'absolute',
              padding: '10px 10px'
            }}>{allStrings.print}</span>*/}
              </MDBCol>
              <MDBCol
                xl="3"
                lg="3"
                md="3"
                sm="6"
                style={{ textAlign: "center", marginBottom: "2rem" }}
              >
                <p
                  style={{
                    fontSize: "30px",
                    fontWeight: "700",
                    color: "#aaa",
                    display: "inline-block",
                  }}
                ></p>
              </MDBCol>
              <MDBCol xl="3" lg="3" md="3" sm="6" style={{ paddingTop: "5px" }}>
                <TreeSelect
                  value={this.state.salesMan}
                  showSearch={true}
                  style={{
                    width: "100%",
                    textAlign: this.props.isRTL ? "right" : "left",
                  }}
                  treeCheckable={false}
                  treeNodeFilterProp="title"
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder={allStrings.chooseSalesMan}
                  onChange={(value) => this.changeMan(value)}
                >
                  <TreeNode value="all" title={allStrings.all} />
                  {this.state.salesMen.map((val) => (
                    <TreeNode
                      value={val.id}
                      title={val.fullname}
                      key={val.id}
                    />
                  ))}
                </TreeSelect>
              </MDBCol>
              <MDBCol xl="3" lg="3" md="3" sm="6" style={{ paddingTop: "5px" }}>
                <div style={{ paddingtop: "15px" }}>
                  <div style={{ marginBottom: "10px", textAlign: "center" }}>
                    <span
                      className="fromSpan"
                      style={{ float: this.props.isRTL ? "right" : "left" }}
                    >
                      {allStrings.from}
                    </span>
                    <div
                      style={{
                        display: "inline-block",
                        textAlign: "center",
                        marginLeft: this.props.isRTL ? "0px" : "-70px",
                      }}
                    >
                      <ConfigProvider
                        locale={this.props.isRTL ? locale_ar : locale_en}
                      >
                        <DatePicker
                          defaultValue={moment(this.state.from, "YYYY/MM/DD")}
                          onChange={this.onChangeFrom}
                        />
                      </ConfigProvider>
                      <span
                        className="newSpan"
                        style={{ left: this.props.isRTL ? "40px" : "70px" }}
                      >
                        <img
                          alt=""
                          style={{ marginRight: "4px" }}
                          src={require("../../assets/images/icons8_edit_property.png")}
                        ></img>
                        {this.state.from}
                      </span>
                    </div>
                  </div>
                  <div style={{ marginBottom: "10px", textAlign: "center" }}>
                    <span
                      className="fromSpan"
                      style={{ float: this.props.isRTL ? "right" : "left" }}
                    >
                      {allStrings.to}
                    </span>
                    <div
                      style={{
                        display: "inline-block",
                        textAlign: "center",
                        marginLeft: this.props.isRTL ? "0px" : "-70px",
                      }}
                    >
                      <ConfigProvider
                        locale={this.props.isRTL ? locale_ar : locale_en}
                      >
                        <DatePicker
                          defaultValue={moment(this.state.to, "YYYY/MM/DD")}
                          onChange={this.onChangeTo}
                        />
                      </ConfigProvider>
                      <span
                        className="newSpan"
                        style={{ left: this.props.isRTL ? "40px" : "70px" }}
                      >
                        <img
                          alt=""
                          style={{ marginRight: "4px" }}
                          src={require("../../assets/images/icons8_edit_property.png")}
                        ></img>
                        {this.state.to}
                      </span>
                    </div>
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
        <MDBContainer>
          <MDBRow>
            <MDBCol xl="3" lg="3" md="6" sm="12" style={{ paddingTop: "5px" }}>
              {!this.state.client && (
                <div>
                  <p
                    style={{
                      marginTop: "50px",
                      fontSize: "28px",
                      fontWeight: "700",
                      color: "green",
                      textAlign: "center",
                    }}
                  >
                    <p>{allStrings.finishedPremiums}</p>
                    {this.state.count.finishedPremiums}
                  </p>
                  <p
                    style={{
                      fontSize: "28px",
                      fontWeight: "700",
                      color: "crimson",
                      textAlign: "center",
                    }}
                  >
                    <p>{allStrings.pendingPremiums}</p>
                    {this.state.count.pendingPremiums}
                  </p>
                </div>
              )}

              <MDBBtn
                onClick={this.payPremium}
                style={{
                  margin: "1rem auto",
                  padding: "11px 20px",
                  height: "35px",
                  width: "98%",
                  fontSize: "12px",
                  fontWeight: "600",
                  background: "#4F73AC",
                  color: "#fff",
                }}
                className="btn z-depth-1a "
                color="#4F73AC"
                disabled={this.state.selectedPremium == null ? true : false}
              >
                {allStrings.collectSelectedPremuim}
              </MDBBtn>
              <MDBBtn
                onClick={this.openDelay}
                style={{
                  margin: "1rem auto",
                  padding: "11px 20px",
                  height: "35px",
                  width: "98%",
                  fontSize: "12px",
                  fontWeight: "600",
                  background: "#4F73AC",
                  color: "#fff",
                }}
                className="btn z-depth-1a "
                color="#4F73AC"
                disabled={this.state.selectedPremium == null ? true : false}
              >
                {allStrings.delaySelectedPremuim}
              </MDBBtn>
              {!this.state.client && (
                <MDBBtn
                  onClick={this.openAllPrim}
                  disabled={this.state.showPayAll == false ? true : false}
                  style={{
                    margin: "1rem auto",
                    padding: "11px 20px",
                    height: "35px",
                    width: "98%",
                    fontSize: "12px",
                    fontWeight: "600",
                    background: "#4F73AC",
                    color: "#fff",
                  }}
                  className="btn z-depth-1a "
                  color="#4F73AC"
                >
                  {allStrings.collectAllMonthPremuim}
                </MDBBtn>
              )}
              <MDBBtn
                onClick={this.openChange}
                style={{
                  margin: "1rem auto",
                  padding: "11px 20px",
                  height: "35px",
                  width: "98%",
                  fontSize: "12px",
                  fontWeight: "600",
                  background: "#4F73AC",
                  color: "#fff",
                }}
                className="btn z-depth-1a "
                color="#4F73AC"
                disabled="true"
                // disabled={this.state.selectedClient == null ? true : false}
              >
                {allStrings.changeSalesMan}
              </MDBBtn>
            </MDBCol>
            <MDBCol xl="3" lg="3" md="6" sm="12" style={{ paddingTop: "5px" }}>
              <p className="clientTitle">{allStrings.installment}</p>
              <p
                className="clientTitle"
                style={{ width: "40%", display: "inline-block" }}
              >
                {allStrings.date}
              </p>
              <p
                className="clientTitle"
                style={{ width: "30%", display: "inline-block" }}
              >
                {allStrings.cost}
              </p>
              <p
                className="clientTitle"
                style={{ width: "30%", display: "inline-block" }}
              >
                {allStrings.number}
              </p>
              {this.state.premiums.length > 0 ? (
                this.state.premiums.map((val, index) => (
                  <p
                    onClick={(e) => {
                      this.setState({ selectedPremium: val.id });
                    }}
                    className="clientDetails"
                    style={{
                      background:
                        this.state.selectedPremium == val.id
                          ? "#c3d7f28f"
                          : "#fff",
                    }}
                  >
                    {val.status == "FINISHED" && (
                      <MDBIcon
                        far
                        icon="check-circle"
                        style={{
                          float: "left",
                          position: "relative",
                          top: "4px",
                          left: "0px",
                          color: "lawngreen",
                        }}
                      />
                    )}
                    {val.status == "DELAY" && (
                      <MDBIcon
                        far
                        icon="clock"
                        style={{
                          float: "left",
                          position: "relative",
                          top: "4px",
                          left: "0px",
                          color: "red",
                        }}
                      />
                    )}
                    <p
                      style={{
                        width: val.status != "PENDING" ? "35%" : "40%",
                        display: "inline-block",
                        marginBottom: 0,
                      }}
                    >
                      {moment(val.paidDate).format("YYYY/MM/DD")}
                    </p>
                    <p
                      style={{
                        width: val.status != "PENDING" ? "28%" : "30%",
                        display: "inline-block",
                        marginBottom: 0,
                      }}
                    >
                      {val.costPerMonth}
                    </p>
                    <p
                      style={{
                        width: val.status != "PENDING" ? "28%" : "30%",
                        display: "inline-block",
                        marginBottom: 0,
                      }}
                    >
                      {val.receiptNum}
                    </p>
                  </p>
                ))
              ) : (
                <p></p>
              )}
            </MDBCol>
            <MDBCol xl="3" lg="3" md="6" sm="12" style={{ paddingTop: "5px" }}>
              <p className="clientTitle">{allStrings.products}</p>
              {this.state.orders.length > 0 ? (
                this.state.orders.map((val, index) => (
                  <p
                    onClick={(e) => {
                      this.setState({
                        selectedOrder: val.id,
                        premiums: [],
                        selectedPremium: null,
                      });
                      this.getPremiums(val.id);
                    }}
                    className="clientDetails"
                    style={{
                      background:
                        this.state.selectedOrder == val.id
                          ? "#c3d7f28f"
                          : "#fff",
                    }}
                  >
                    {this.props.isRTL
                      ? val.product.name_ar
                      : val.product.name_en}
                  </p>
                ))
              ) : (
                <p></p>
              )}
            </MDBCol>

            <MDBCol xl="3" lg="3" md="6" sm="12" style={{ paddingTop: "5px" }}>
              <p className="clientTitle">{allStrings.client}</p>
              {this.state.clients.length > 0 ? (
                this.state.clients.map((val, index) => (
                  <p
                    onClick={(e) => {
                      this.setState({
                        selectedClient: val.id,
                        premiums: [],
                        selectedPremium: null,
                        orders: [],
                        selectedOrder: null,
                      });
                      this.getClientOrders(val.user.id);
                    }}
                    className="clientDetails"
                    style={{
                      background:
                        this.state.selectedClient == val.id
                          ? "#c3d7f28f"
                          : "#fff",
                    }}
                  >
                    {val.fullname}
                  </p>
                ))
              ) : (
                <p></p>
              )}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = (state) => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  branch: state.company.branch,
  company: state.company.company,
});

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
};

export default withRouter(
  connect(mapToStateProps, mapDispatchToProps)(Collectibles)
);
