/* eslint-disable eqeqeq */
import React from "react";
import "./calculations info.css";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { setUser } from "../../redux/actions/AuthActions";
import { DatePicker, ConfigProvider } from "antd";
import { NavLink } from "react-router-dom";
import { ChangeLanguage } from "../../redux/actions/LanguageAction";
import { ChangeCompany, ChangeBranch } from "../../redux/actions/CompanyAction";

import Skeleton from "react-loading-skeleton";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import locale_ar from "antd/lib/locale/ar_EG";
import locale_en from "antd/lib/locale/en_GB";
import "moment/locale/ar";
let fromDate;
let toDate;
class CalculationInfo extends React.Component {
  state = {
    tableData: [],
    count: this.props.history.location.state
      ? this.props.history.location.state.data
      : [],
    specialData: this.props.history.location.state
      ? this.props.history.location.state.specialData
      : [],
    rightType: this.props.history.location.state
      ? this.props.history.location.state.rightType
      : "main",
    tableType: this.props.history.location.state
      ? this.props.history.location.state.tableType
      : "first",
    type: this.props.history.location.state
      ? this.props.history.location.state.type
      : "order",
    loading: true,
    refresh: false,
    page: 1,
    pages: 1,
    from: this.props.isRTL
      ? moment().startOf("month").format("YYYY/MM/DD")
      : moment().startOf("month").locale("en").format("YYYY/MM/DD"),
    to: this.props.isRTL
      ? moment().endOf("month").format("YYYY/MM/DD")
      : moment().endOf("month").locale("en").format("YYYY/MM/DD"),
  };

  constructor(props) {
    super(props);
    this.getDuration();
    console.log("isr   ", this.props.history);
    if (this.props.history.location.state.type == "additionCollection") {
      this.getAddition(1, false);
    }
    if (this.props.history.location.state.type == "order") {
      this.getOrders(1, false);
    }
    if (this.props.history.location.state.type == "firstPaid") {
      this.getFirstPaid(1, false);
    }
    if (this.props.history.location.state.type == "cash") {
      this.getCash(1, false);
    }
    if (this.props.history.location.state.type == "pendingPremiums") {
      this.getPendingCollect(1, false);
    }
    if (this.props.history.location.state.type == "finishedPremiums") {
      this.getFinishCollect(1, false);
    }
    if (this.props.history.location.state.type == "transactionHistory") {
      this.getTransactions(1, false);
    }
    if (this.props.history.location.state.type == "expenses") {
      this.getExpenses(1, false);
    }
    if (this.props.history.location.state.type == "recieveReceivables") {
      this.getReceive(1, false);
    }
    if (this.props.history.location.state.type == "salesMenDues") {
      this.getDues(1, false);
    }
    if (this.props.history.location.state.type == "all") {
      this.getAllPayments(1, false);
    }

    if (this.props.isRTL) {
      allStrings.setLanguage("ar");
      moment.locale("ar");
    } else {
      allStrings.setLanguage("en");
      moment.locale("en");
    }
    window.scrollTo(0, 0);
  }
  getDuration = () => {
    //first day
    let date = new Date();
    let newDate = date.toISOString().slice(0, 8);
    let firstDay = newDate + "01";
    //get days in month
    function daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    }

    let DaysInCurrentMonth = daysInMonth(
      date.getMonth() + 1,
      date.getFullYear()
    ); //number of day in month
    //last day
    let lastDay = newDate + DaysInCurrentMonth;
    console.log(firstDay);
    console.log(lastDay);
    if (this.props.isRTL) {
      this.setState({
        from: moment(firstDay).format("YYYY/MM/DD"),
        to: moment(lastDay).format("YYYY/MM/DD"),
      });
    } else {
      this.setState({
        from: moment(firstDay).locale("en").format("YYYY/MM/DD"),
        to: moment(lastDay).locale("en").format("YYYY/MM/DD"),
      });
    }

    fromDate = firstDay;
    toDate = lastDay;
  };
  componentDidMount() {
    //this.props.ChangeLanguage(false)
    this.getDuration();
  }

  getAddition = (page, refresh) => {
    this.setState({ loading: true, page: 1 });
    let url = ``;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}additionalCollection?page=${page}`;
      } else {
        url = `${BASE_END_POINT}additionalCollection?page=${page}&company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}additionalCollection?company=${this.props.currentUser.user.id}&page=${page}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("addition Collect   ", response.data);
        this.setState({
          loading: false,
          refresh: false,
          tableData: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading: false });
      });
  };
  getOrders = (page, refresh) => {
    this.setState({ loading: true });
    let url = ``;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}orders?page=${page}`;
      } else {
        url = `${BASE_END_POINT}orders?page=${page}&company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}orders?company=${this.props.currentUser.user.id}&page=${page}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("orders   ", response.data);
        this.setState({
          loading: false,
          refresh: false,
          tableData: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading: false });
      });
  };
  getCash = (page, refresh) => {
    this.setState({ loading: true });
    let url = ``;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}orders?paymentSystem=cash&page=${page}&start=${fromDate}&end=${toDate}`;
      } else {
        url = `${BASE_END_POINT}orders?paymentSystem=cash&page=${page}&start=${fromDate}&end=${toDate}&company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}orders?paymentSystem=cash&company=${this.props.currentUser.user.id}&page=${page}&start=${fromDate}&end=${toDate}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("cash   ", response.data);
        this.setState({
          loading: false,
          refresh: false,
          tableData: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading: false });
      });
  };
  getAllPayments = (page, refresh) => {
    this.setState({ loading: true });
    let url = ``;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}orders?page=${page}&start=${fromDate}&end=${toDate}`;
      } else {
        url = `${BASE_END_POINT}orders?page=${page}&start=${fromDate}&end=${toDate}&company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}orders?company=${this.props.currentUser.user.id}&page=${page}&start=${fromDate}&end=${toDate}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("All   ", response.data);
        this.setState({
          loading: false,
          refresh: false,
          tableData: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading: false });
      });
  };
  getFirstPaid = (page, refresh) => {
    this.setState({ loading: true });
    let url = ``;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}orders?paymentSystem=installment&page=${page}&start=${fromDate}&end=${toDate}`;
      } else {
        url = `${BASE_END_POINT}orders?paymentSystem=installment&page=${page}&start=${fromDate}&end=${toDate}&company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}orders?paymentSystem=installment&company=${this.props.currentUser.user.id}&page=${page}&start=${fromDate}&end=${toDate}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("first paid   ", response.data);
        this.setState({
          loading: false,
          refresh: false,
          tableData: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading: false });
      });
  };
  getExpenses = (page, refresh) => {
    this.setState({ loading: true });
    let url = ``;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}expenses?page=${page}&start=${fromDate}&end=${toDate}`;
      } else {
        url = `${BASE_END_POINT}expenses?page=${page}&start=${fromDate}&end=${toDate}&company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}expenses?company=${this.props.currentUser.user.id}&page=${page}&start=${fromDate}&end=${toDate}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("expenses   ", response.data);
        this.setState({
          loading: false,
          refresh: false,
          tableData: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading: false });
      });
  };
  getPendingCollect = (page, refresh) => {
    this.setState({ loading: true, page: 1 });
    let url = ``;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}premiums?status=PENDING&page=${page}&start=${fromDate}&end=${toDate}&type=INSTALLMENT`;
      } else {
        url = `${BASE_END_POINT}premiums?status=PENDING&page=${page}&start=${fromDate}&end=${toDate}&company=${this.props.company}&type=INSTALLMENT`;
      }
    } else {
      url = `${BASE_END_POINT}premiums?company=${this.props.currentUser.user.id}&type=INSTALLMENT&status=PENDING&page=${page}&start=${fromDate}&end=${toDate}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("pendingCollect   ", response.data);
        this.setState({
          loading: false,
          refresh: false,
          tableData: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading: false });
      });
  };
  getFinishCollect = (page, refresh) => {
    this.setState({ loading: true, page: 1 });
    let url = ``;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}premiums?status=FINISHED&page=${page}&start=${fromDate}&end=${toDate}&type=INSTALLMENT`;
      } else {
        url = `${BASE_END_POINT}premiums?status=FINISHED&page=${page}&start=${fromDate}&end=${toDate}&company=${this.props.company}&type=INSTALLMENT`;
      }
    } else {
      url = `${BASE_END_POINT}premiums?company=${this.props.currentUser.user.id}&status=FINISHED&page=${page}&start=${fromDate}&end=${toDate}&type=INSTALLMENT`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("finishCollect   ", response.data);
        this.setState({
          loading: false,
          refresh: false,
          tableData: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading: false });
      });
  };
  getTransactions = (page, refresh) => {
    this.setState({ loading: true, page: 1 });
    let url = ``;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}transactions?company=${this.props.currentUser.user.id}&page=${page}&start=${fromDate}&end=${toDate}`;
      } else {
        url = `${BASE_END_POINT}transactions?company=${this.props.company}&page=${page}&start=${fromDate}&end=${toDate}`;
      }
    } else {
      url = `${BASE_END_POINT}transactions?company=${this.props.currentUser.user.id}&page=${page}&start=${fromDate}&end=${toDate}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log(" data   ", response.data);
        this.setState({
          loading: false,
          refresh: false,
          tableData: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading: false });
      });
  };
  getReceive = (page, refresh) => {
    this.setState({ loading: true, page: 1 });
    let url = ``;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}transactions?type=RECEIPT-OF-RECEIVABLES&page=${page}&start=${fromDate}&end=${toDate}`;
      } else {
        url = `${BASE_END_POINT}transactions?type=RECEIPT-OF-RECEIVABLES&page=${page}&start=${fromDate}&end=${toDate}&company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}transactions?company=${this.props.currentUser.user.id}&type=RECEIPT-OF-RECEIVABLES&page=${page}&start=${fromDate}&end=${toDate}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("data   ", response.data);
        this.setState({
          loading: false,
          refresh: false,
          tableData: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading: false });
      });
  };
  getDues = (page, refresh) => {
    this.setState({ loading: true, page: 1 });
    let url = ``;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}transactions?type=DUES&page=${page}&start=${fromDate}&end=${toDate}`;
      } else {
        url = `${BASE_END_POINT}transactions?type=DUES&page=${page}&start=${fromDate}&end=${toDate}&company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}transactions?company=${this.props.currentUser.user.id}&type=DUES&page=${page}&start=${fromDate}&end=${toDate}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("data   ", response.data);
        this.setState({
          loading: false,
          refresh: false,
          tableData: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        });
      })
      .catch((error) => {
        console.log("error   ", error.response);
        console.log("error2   ", error);
        this.setState({ loading: false });
      });
  };

  onChangeFrom = (date, dateString) => {
    if (this.props.isRTL) {
      this.setState({ from: moment(dateString).format("YYYY/MM/DD") });
    } else {
      this.setState({
        from: moment(dateString).locale("en").format("YYYY/MM/DD"),
      });
    }
    fromDate = moment(dateString).locale("en").format("YYYY-MM-DD");
  };
  onChangeTo = (date, dateString) => {
    if (this.props.isRTL) {
      this.setState({ to: moment(dateString).format("YYYY/MM/DD") });
    } else {
      this.setState({
        to: moment(dateString).locale("en").format("YYYY/MM/DD"),
      });
    }
    toDate = moment(dateString).locale("en").format("YYYY-MM-DD");
    if (this.props.history.location.state.type == "additionCollection") {
      this.getAddition(1, false);
    }
    if (this.props.history.location.state.type == "order") {
      this.getOrders(1, false);
    }
    if (this.props.history.location.state.type == "firstPaid") {
      this.getFirstPaid(1, false);
    }
    if (this.props.history.location.state.type == "cash") {
      this.getCash(1, false);
    }
    if (this.props.history.location.state.type == "pendingPremiums") {
      this.getPendingCollect(1, false);
    }
    if (this.props.history.location.state.type == "finishedPremiums") {
      this.getFinishCollect(1, false);
    }
    if (this.props.history.location.state.type == "transactionHistory") {
      this.getTransactions(1, false);
    }
    if (this.props.history.location.state.type == "expenses") {
      this.getExpenses(1, false);
    }
    if (this.props.history.location.state.type == "recieveReceivables") {
      this.getReceive(1, false);
    }
    if (this.props.history.location.state.type == "salesMenDues") {
      this.getDues(1, false);
    }
  };

  render() {
    console.log(this.state.tableType);
    return (
      <div className="CalculationInfo">
        <MDBContainer>
          <MDBRow>
            <MDBCol xl="4" lg="4" md="12" sm="12" style={{ paddingRight: "0" }}>
              <div
                className="top"
                style={{ width: "100%", textAlign: "center" }}
              >
                <NavLink to="/calculations">
                  <MDBIcon
                    icon="times"
                    className="closeIcon"
                    style={{ marginLeft: 0 }}
                  />
                </NavLink>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol xl="8" lg="8" md="8" sm="8">
              <table class="table table-borderless">
                <thead class="thead-light">
                  {this.state.tableType == "first" && (
                    <tr>
                      <th scope="col">{allStrings.cost}</th>
                      <th scope="col">{allStrings.product}</th>
                    </tr>
                  )}
                  {this.state.tableType == "order" && (
                    <tr>
                      <th scope="col">{allStrings.date}</th>
                      <th scope="col">{allStrings.cost}</th>
                      <th scope="col">{allStrings.client}</th>
                      <th scope="col">{allStrings.product}</th>
                    </tr>
                  )}
                  {this.state.tableType == "collected" && (
                    <tr>
                      <th scope="col">{allStrings.date}</th>
                      <th scope="col">{allStrings.cost}</th>
                      <th scope="col">{allStrings.theCollectable}</th>
                      <th scope="col">{allStrings.client}</th>
                      <th scope="col">{allStrings.product}</th>
                    </tr>
                  )}
                  {this.state.tableType == "salesMan" && (
                    <tr>
                      <th scope="col">{allStrings.date}</th>
                      <th scope="col">{allStrings.procces}</th>
                      <th scope="col">{allStrings.salesMan}</th>
                      <th scope="col">{allStrings.cost}</th>
                    </tr>
                  )}
                  {this.state.tableType == "procces" && (
                    <tr>
                      <th scope="col">{allStrings.date}</th>
                      <th scope="col">{allStrings.procces}</th>
                      <th scope="col">{allStrings.cost}</th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {this.state.loading ? (
                    <tr style={{ height: "200px" }}>
                      <th
                        colspan={
                          this.state.tableType == "collected" ? "5" : "4"
                        }
                        className="noData"
                      >
                        <Skeleton height={400} />
                      </th>
                    </tr>
                  ) : this.state.tableData.length > 0 ? (
                    this.state.tableData.map((val) =>
                      this.state.tableType == "first" ? (
                        <tr>
                          <td>{val.total ? val.total : "--"}</td>
                          <td>
                            {val.product
                              ? this.props.isRTL
                                ? val.product.name_ar
                                : val.product.name_en
                              : ""}
                          </td>
                        </tr>
                      ) : this.state.tableType == "order" ? (
                        <tr>
                          <td>{val.createdAt.substring(0, 10)}</td>
                          <td>
                            {this.state.type == "firstPaid"
                              ? val.firstPaid
                              : val.total
                              ? val.total
                              : "--"}
                          </td>
                          <td>{val.client.fullname}</td>
                          <td>
                            {val.product
                              ? this.props.isRTL
                                ? val.product.name_ar
                                : val.product.name_en
                              : ""}
                          </td>
                        </tr>
                      ) : this.state.tableType == "collected" ? (
                        <tr>
                          <td>{val.createdAt.substring(0, 10)}</td>
                          <td>{val.costPerMonth ? val.costPerMonth : "--"}</td>
                          <td>{val.salesMan ? val.salesMan.fullname : ""}</td>
                          <td>{val.client ? val.client.fullname : ""}</td>
                          <td>
                            {val.product
                              ? this.props.isRTL
                                ? val.product.name_ar
                                : val.product.name_en
                              : ""}
                          </td>
                        </tr>
                      ) : this.state.tableType == "salesMan" ? (
                        <tr>
                          <td>{val.createdAt.substring(0, 10)}</td>
                          <td>
                            {val.description_en
                              ? this.props.isRTL
                                ? val.description_ar
                                : val.description_en
                              : "بدون نص"}
                          </td>
                          <td>
                            {val.user
                              ? val.user.fullname
                              : val.salesMan
                              ? val.salesMan.fullname
                              : ""}
                          </td>
                          <td>{val.cost ? val.cost : "--"}</td>
                        </tr>
                      ) : this.state.tableType == "procces" ? (
                        <tr>
                          <td>{val.createdAt.substring(0, 10)}</td>
                          <td>
                            {this.state.type == "expenses"
                              ? val.description
                              : val.description_en
                              ? this.props.isRTL
                                ? val.description_ar
                                : val.description_en
                              : "بدون نص"}
                          </td>
                          <td>{val.cost ? val.cost : "--"}</td>
                        </tr>
                      ) : (
                        <tr></tr>
                      )
                    )
                  ) : (
                    <tr style={{ height: "200px" }}>
                      <th
                        colspan={
                          this.state.tableType == "collected" ? "5" : "4"
                        }
                        className="noData"
                      >
                        <p style={{ marginTop: "4rem" }}>{allStrings.noData}</p>
                      </th>
                    </tr>
                  )}
                  <th colspan={this.state.tableType == "collected" ? "5" : "4"}>
                    <MDBRow>
                      {this.state.tableData.length > 0 && (
                        <div
                          style={{
                            textAlign: "center",
                            margin: "auto",
                            marginTop: "3rem",
                          }}
                        >
                          <Pagination
                            onChange={(event, page) => {
                              console.log("page   ", page);
                              if (page != this.state.page) {
                                if (
                                  this.props.history.location.state.type ==
                                  "additionCollection"
                                ) {
                                  this.getAddition(page, false);
                                }
                                if (
                                  this.props.history.location.state.type ==
                                  "order"
                                ) {
                                  this.getOrders(page, false);
                                }
                                if (
                                  this.props.history.location.state.type ==
                                  "firstPaid"
                                ) {
                                  this.getFirstPaid(page, false);
                                }
                                if (
                                  this.props.history.location.state.type ==
                                  "cash"
                                ) {
                                  this.getCash(page, false);
                                }
                                if (
                                  this.props.history.location.state.type ==
                                  "pendingPremiums"
                                ) {
                                  this.getPendingCollect(page, false);
                                }
                                if (
                                  this.props.history.location.state.type ==
                                  "finishedPremiums"
                                ) {
                                  this.getFinishCollect(page, false);
                                }
                                if (
                                  this.props.history.location.state.type ==
                                  "transactionHistory"
                                ) {
                                  this.getTransactions(page, false);
                                }
                                if (
                                  this.props.history.location.state.type ==
                                  "expenses"
                                ) {
                                  this.getExpenses(page, false);
                                }
                                if (
                                  this.props.history.location.state.type ==
                                  "recieveReceivables"
                                ) {
                                  this.getReceive(page, false);
                                }
                                if (
                                  this.props.history.location.state.type ==
                                  "salesMenDues"
                                ) {
                                  this.getDues(page, false);
                                }
                                this.setState({ page: page });
                              }
                            }}
                            defaultPage={1}
                            page={this.state.page}
                            count={this.state.pages}
                            style={{ color: `blue !important` }}
                          />
                        </div>
                      )}
                    </MDBRow>
                  </th>
                </tbody>
              </table>
            </MDBCol>
            {this.state.rightType == "part" ? (
              <MDBCol xl="4" lg="4" md="4" sm="4">
                <div>
                  <p
                    style={{
                      fontSize: "25px",
                      fontWeight: "700",
                      color: "#aaa",
                      textAlign: "center",
                      marginBottom: 0,
                    }}
                  >
                    {this.state.type == "cash"
                      ? allStrings.cashAccounts
                      : this.state.type == "firstPaid"
                      ? allStrings.firstPaids
                      : this.state.type == "finishedPremiums"
                      ? allStrings.doneCollectibles
                      : this.state.type == "pendingPremiums"
                      ? allStrings.remainCollectibles
                      : this.state.type == "transactionHistory"
                      ? allStrings.TransactionHistory
                      : this.state.type == "expenses"
                      ? allStrings.expenses
                      : this.state.type == "recieveReceivables"
                      ? allStrings.saleMenPaid
                      : this.state.type == "salesMenDues"
                      ? allStrings.saleMenDues
                      : ""}
                  </p>
                  <p
                    style={{
                      fontSize: "66px",
                      fontWeight: "700",
                      color: "#aaa",
                      textAlign: "center",
                    }}
                  >
                    {this.state.specialData != 0 ? this.state.specialData : ""}
                  </p>
                </div>
                <div style={{ paddingtop: "15px" }}>
                  <div style={{ marginBottom: "10px", textAlign: "center" }}>
                    <span
                      className="fromSpan"
                      style={{
                        float: this.props.isRTL ? "right" : "left",
                        marginRight: "25%",
                      }}
                    >
                      {allStrings.from}
                    </span>
                    <div
                      style={{
                        display: "inline-block",
                        textAlign: "center",
                        marginLeft: this.props.isRTL ? "35px" : "-93px",
                      }}
                    >
                      <ConfigProvider
                        locale={this.props.isRTL ? locale_ar : locale_en}
                      >
                        <DatePicker
                          defaultValue={moment(this.state.from, "YYYY/MM/DD")}
                          onChange={this.onChangeFrom}
                        />
                      </ConfigProvider>
                      <span className="newSpan">
                        <img
                          alt=""
                          style={{ marginRight: "4px" }}
                          src={require("../../assets/images/icons8_edit_property.png")}
                        ></img>
                        {this.state.from}
                      </span>
                    </div>
                  </div>
                  <div style={{ marginBottom: "10px", textAlign: "center" }}>
                    <span
                      className="fromSpan"
                      style={{
                        float: this.props.isRTL ? "right" : "left",
                        marginRight: "25%",
                      }}
                    >
                      {allStrings.to}
                    </span>
                    <div
                      style={{
                        display: "inline-block",
                        textAlign: "center",
                        marginLeft: this.props.isRTL ? "35px" : "-93px",
                      }}
                    >
                      <ConfigProvider
                        locale={this.props.isRTL ? locale_ar : locale_en}
                      >
                        <DatePicker
                          defaultValue={moment(this.state.to, "YYYY/MM/DD")}
                          onChange={this.onChangeTo}
                        />
                      </ConfigProvider>
                      <span className="newSpan">
                        <img
                          alt=""
                          style={{ marginRight: "4px" }}
                          src={require("../../assets/images/icons8_edit_property.png")}
                        ></img>
                        {this.state.to}
                      </span>
                    </div>
                  </div>
                </div>
                <br></br>
                <br></br>
                <div style={{ textAlign: "center", paddingtop: "15px" }}>
                  {/*<img alt=""  width="50px" src={require('../../assets/images/icons8_calculate.png')}/>*/}
                </div>
              </MDBCol>
            ) : (
              <MDBCol xl="4" lg="4" md="4" sm="4">
                <div>
                  <p
                    style={{
                      fontSize: "25px",
                      fontWeight: "700",
                      color: "#aaa",
                      textAlign: "center",
                      marginBottom: 0,
                    }}
                  >
                    {allStrings.moneyCapital}
                  </p>
                  <p
                    style={{
                      fontSize: "66px",
                      fontWeight: "700",
                      color: "#aaa",
                      textAlign: "center",
                    }}
                  >
                    {this.state.count.totalCapital}
                  </p>
                  <p
                    style={{
                      fontSize: "25px",
                      fontWeight: "700",
                      color: "#aaa",
                      textAlign: "center",
                      marginBottom: 0,
                    }}
                  >
                    {allStrings.moneyCapitalRation}
                  </p>
                  <p
                    style={{
                      fontSize: "42px",
                      fontWeight: "700",
                      color: "#aaa",
                      textAlign: "center",
                    }}
                  >
                    <span>%</span>
                    {Math.ceil(this.state.count.capitalRation * 100) / 100}
                  </p>
                  <p
                    style={{
                      fontSize: "25px",
                      fontWeight: "700",
                      color: "#aaa",
                      textAlign: "center",
                      marginBottom: 0,
                    }}
                  >
                    {allStrings.stock}
                  </p>
                  <p
                    style={{
                      fontSize: "42px",
                      fontWeight: "700",
                      color: "#aaa",
                      textAlign: "center",
                    }}
                  >
                    {this.state.count.stock ? this.state.count.stock : 0}
                  </p>
                  <p
                    style={{
                      fontSize: "25px",
                      fontWeight: "700",
                      color: "#aaa",
                      textAlign: "center",
                      marginBottom: 0,
                    }}
                  >
                    {allStrings.productsWithSalesMan}
                  </p>
                  <p
                    style={{
                      fontSize: "42px",
                      fontWeight: "700",
                      color: "#aaa",
                      textAlign: "center",
                    }}
                  >
                    {this.state.count.withSalesMan
                      ? this.state.count.withSalesMan
                      : 0}
                  </p>
                  <p
                    style={{
                      fontSize: "25px",
                      fontWeight: "700",
                      color: "#aaa",
                      textAlign: "center",
                      marginBottom: 0,
                    }}
                  >
                    {allStrings.remainCollectibles}
                  </p>
                  <p
                    style={{
                      fontSize: "42px",
                      fontWeight: "700",
                      color: "#aaa",
                      textAlign: "center",
                    }}
                  >
                    {this.state.count.allPendingPremiums}
                  </p>
                </div>
              </MDBCol>
            )}
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = (state) => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  branch: state.company.branch,
  company: state.company.company,
});

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
};
export default withRouter(
  connect(mapToStateProps, mapDispatchToProps)(CalculationInfo)
);
