import React from "react";
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./header.css";
import { MDBIcon } from "mdbreact";
import Avatar from "@material-ui/core/Avatar";
import { NavLink } from "react-router-dom";
import { SearchOutlined, DownOutlined } from "@ant-design/icons";
import { Input, Select, TreeSelect } from "antd";
import { ChangeLanguage } from "../../redux/actions/LanguageAction";
import { ChangeCompany, ChangeBranch } from "../../redux/actions/CompanyAction";
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBBadge,
  MDBBtn,
} from "mdbreact";
import { hasValue } from "../../validations/validations";
import { message } from "antd";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { setUser } from "../../redux/actions/AuthActions";
import { getUnseenMessagesCount } from "../../redux/actions/ChatAction";
import {
  getNotifications,
  getUnreadNotificationsCount,
} from "../../redux/actions/NotificationAction";
import "antd/dist/antd.css";
import moment from "moment";
import "moment/locale/ar";
const { Option } = Select;
const { TreeNode } = TreeSelect;

class Header extends React.Component {
  constructor(props) {
    super(props);
    console.log("isr   ", this.props.isRTL);
    console.log("company   ", this.props.company);
    this.getUnReedOrders();

    if (this.props.isRTL) {
      allStrings.setLanguage("ar");
      moment.locale("ar");
    } else {
      allStrings.setLanguage("en");
      moment.locale("en");
    }
  }

  state = {
    isLogin: true,
    value: "",
    query: {},
    branches: [],
    companies: [],
    company: this.props.company != false ? this.props.company : [],
    branch: this.props.branch == false ? "all" : this.props.branch,
    unseenOrderCount: 0,
    show: false,
  };
  getUnReedOrders = () => {
    let url;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}orders/unreadCount/get`;
      } else {
        url = `${BASE_END_POINT}orders/unreadCount/get?company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}orders/unreadCount/get?company=${this.props.currentUser.user.id}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log("orders   ", response.data);
        this.setState({ unseenOrderCount: response.data.unread });
      })
      .catch((error) => {
        console.log("error   ", error.response);
      });
  };
  getBranches = () => {
    let url = ``;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}branch/withoutPagenation/get`;
      } else {
        url = `${BASE_END_POINT}branch/withoutPagenation/get?company=${this.props.company}`;
      }
    } else {
      url = `${BASE_END_POINT}branch/withoutPagenation/get?company=${this.props.currentUser.user.id}`;
    }

    axios
      .get(url)
      .then((response) => {
        console.log(response.data.Branchs);
        let branch;
        if (this.props.branch == false) {
          branch = "all";
        }
        this.setState({ branches: response.data.Branchs, branch: branch });
      })
      .catch((error) => {
        console.log("ALL branches ERROR");
        console.log(error.response);
      });
  };
  getCompany = () => {
    let url = ``;
    url = `${BASE_END_POINT}withoutPagenation/get?type=COMPANY`;
    axios
      .get(url)
      .then((response) => {
        console.log("CoM", response.data.users[0]);
        let company;
        if (this.props.company == false) {
          company = "false";
        } else {
          company = this.props.company;
        }
        this.setState({ companies: response.data.users, company: company });
      })
      .catch((error) => {
        console.log("ALL salesMen ERROR");
        console.log(error.response);
      });
  };

  componentDidMount() {
    setTimeout(() => {
      this.props.getNotifications(1, true, this.props.currentUser.token);
    }, 1000 * 60 * 3);

    this.props.getUnreadNotificationsCount(this.props.currentUser.token);

    this.getBranches();
    if (this.props.currentUser.user.type != "COMPANY") {
      this.getCompany();
    }
    if (
      this.props.currentUser.user.type == "COMPANY" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      this.setState({ show: true });
    }
  }

  readNotification = (notID) => {
    console.log(notID);
    axios
      .put(
        `${BASE_END_POINT}notif/${notID}/read`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.currentUser.token}`,
          },
        }
      )
      .then((Response) => {
        console.log("notification is red");
        console.log(Response);
        //this.setState({ground:true})
        //this.props.getUnreadNotificationsNumers(this.props.currentUser.token)
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  render() {
    const { Option } = Select;
    console.log(this.state.show);
    console.log(this.props.currentUser.user.fullname);
    return (
      <div style={{ width: "100%", background: "#fff" }}>
        <nav
          class="navbar navbar-expand-lg navbar-light "
          style={{ background: "transparent", color: "#fff" }}
        >
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto ">
              <li class="nav-item active">
                <Input
                  id="headerSearch"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      if (hasValue("" + e.target.value)) {
                        console.log("ENTER   ", e.target.value);
                        this.props.history.push("/search", {
                          data: e.target.value,
                        });
                        document.getElementById("headerSearch").value = "";
                      } else {
                        message.warning(allStrings.noValue);
                        console.log("ENTER NO VALUE   ", e.target.value);
                      }
                    }
                  }}
                  placeholder={allStrings.searchAbout}
                  size="large"
                  prefix={<SearchOutlined />}
                />
              </li>
            </ul>
            <ul class="navbar-nav mr-auto rightUl">
              {/* {this.state.show==false &&

    <li>
      
      <TreeSelect
        showSearch={true} 
        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'40px',width:'8rem'}} 
        treeCheckable = {false}         
        treeNodeFilterProp="title"  
        defaultValue={this.state.branch}
        placeholder={allStrings.chooseBranch}                    
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        onChange={(value) => {
          console.log(value)
          this.props.ChangeBranch(value)
          localStorage.setItem("@BRANCH",value)
          setTimeout(function () {
            window.location.reload()
          }, 500);
          
        }} 
      >
        <TreeNode value="all" title={allStrings.all}/>
        {this.state.branches.map(val=>(
         <TreeNode value={val.id} title={val.branchName}  key={val.id} />
      ))}
    </TreeSelect>
    <span style={{color:'#9C9BA7',fontSize:'12px'}}> : {allStrings.branch} </span>
    </li>
    } */}
              {this.state.show === false && (
                <li>
                  <TreeSelect
                    showSearch={true}
                    style={{
                      textAlign: this.props.isRTL ? "right" : "left",
                      height: "40px",
                      width: "8rem",
                    }}
                    treeCheckable={false}
                    treeNodeFilterProp="title"
                    value={this.state.company}
                    placeholder={allStrings.chooseCompany}
                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                    onChange={(value) => {
                      console.log(value);
                      if (value == "false") {
                        this.props.ChangeCompany(false);
                        localStorage.setItem("@COMPANY", false);
                      } else {
                        this.props.ChangeCompany(value);
                        localStorage.setItem("@COMPANY", value);
                      }
                      setTimeout(function () {
                        window.location.reload();
                      }, 500);
                    }}
                  >
                    {/*<TreeNode value="false" title={allStrings.all}/>*/}
                    {this.state.companies.map((val) => (
                      <TreeNode
                        value={val.id}
                        title={val.fullname}
                        key={val.id}
                      />
                    ))}
                  </TreeSelect>
                  <span style={{ color: "#9C9BA7", fontSize: "12px" }}>
                    {" "}
                    : {allStrings.company}
                  </span>
                </li>
              )}
              {this.props.currentUser && (
                <li class="nav-item hasProfile">
                  <MDBDropdown>
                    <MDBDropdownToggle caret className={"customColor-text"}>
                      <Avatar
                        alt="Remy Sharp"
                        src={
                          this.props.currentUser
                            ? this.props.currentUser.user.img
                            : require("../../assets/images/profileicon.jpg")
                        }
                      />
                      <span
                        className="headerUsername"
                        style={{
                          float: "left",
                          padding: "11px",
                          minWidth: "50px",
                          textAlign: "right",
                          display: this.state.show ? "inline-block" : "none",
                        }}
                      >
                        {this.state.show == true
                          ? this.props.currentUser.user.fullname
                          : ""}{" "}
                        <DownOutlined />
                      </span>
                    </MDBDropdownToggle>
                    <MDBDropdownMenu basic>
                      <MDBDropdownItem
                        style={{ fontSize: "14px", color: " rgb(82, 86, 89)" }}
                      >
                        <span>
                          <span style={{ fontWeight: "600", fontSize: "14px" }}>
                            {allStrings.status} :{" "}
                          </span>{" "}
                          <span>
                            {this.props.currentUser.user.status
                              ? allStrings.activeNow
                              : allStrings.activeNow}
                          </span>
                        </span>
                      </MDBDropdownItem>
                      <MDBDropdownItem divider />
                      {/* <MDBDropdownItem style={{ fontSize: '14px', color: ' rgb(82, 86, 89)' }}>
                        <span onClick={() => this.props.history.push('/settings', { data: this.props.currentUser.user })}>{allStrings.accountSettings}</span>
                      </MDBDropdownItem> */}

                      <MDBDropdownItem
                        onClick={() => {
                          localStorage.removeItem("@USERQSAT");
                          localStorage.removeItem("@COMPANY");
                          localStorage.setItem("@COMPANY", false);
                          localStorage.setItem("@BRANCH", false);

                          this.props.history.replace("/Login");
                          this.props.setUser(null);
                        }}
                      >
                        {" "}
                        {allStrings.logout}
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </li>
              )}

              {this.props.currentUser && (
                <li class="nav-item nav-item-icon">
                  <span className="dash"> </span>
                  <NavLink to={"/orders"}>
                    <MDBBtn>
                      <MDBIcon
                        icon="shopping-bag"
                        className={"customColor-text"}
                      />
                      <MDBBadge color="danger" className="ml-2">
                        {this.state.unseenOrderCount}
                      </MDBBadge>
                    </MDBBtn>
                  </NavLink>
                </li>
              )}
              {this.props.currentUser && (
                <li class="nav-item nav-item-icon">
                  <NavLink to={"/chat"}>
                    <MDBBtn>
                      <MDBIcon icon="comments" className={"customColor-text"} />
                      <MDBBadge color="danger" className="ml-2">
                        {this.props.unseenMsgCount}
                      </MDBBadge>
                    </MDBBtn>
                  </NavLink>
                </li>
              )}
              <li class="nav-item nav-item-icon notifs">
                {this.props.currentUser && (
                  <MDBDropdown>
                    <MDBDropdownToggle
                      caret
                      className={"balck-text"}
                      color="blue-grey"
                    >
                      <MDBBtn>
                        <MDBIcon icon="bell" className={"customColor-text"} />
                        <MDBBadge color="danger" className="ml-2">
                          {this.props.unreadNotifCount}
                        </MDBBadge>
                      </MDBBtn>
                    </MDBDropdownToggle>
                    <MDBDropdownMenu basic>
                      {this.props.notifications.map((noti) => (
                        <MDBDropdownItem
                          onClick={() => {
                            if (!noti.read) {
                              this.readNotification(noti.id);
                              this.props.getUnreadNotificationsCount(
                                this.props.currentUser.token
                              );
                            }
                            if (noti.product) {
                              this.props.history.push("/stockForm", {
                                data: noti.product,
                              });
                            }
                            if (noti.order && noti.type != "salesMan") {
                              this.props.history.push("/orders", {
                                data: noti.order,
                                modal: true,
                              });
                            }
                            if (noti.order && noti.type == "salesMan") {
                              console.log(noti.order.client);
                              this.props.history.push("/clientOrders", {
                                data: noti.order.client,
                              });
                            }
                            if (
                              noti.company &&
                              this.props.currentUser.user.type == "ADMIN"
                            ) {
                              this.props.history.push("/companyForm", {
                                data: noti.company,
                              });
                            }
                          }}
                          style={{
                            background: !noti.read ? "#ebedeb" : "white",
                            fontSize: "14px",
                            color: " rgb(82, 86, 89)",
                          }}
                        >
                          <div className="notif">
                            <div className="personPic">
                              <Avatar
                                alt="Remy Sharp"
                                style={{ margin: "5px" }}
                                src={
                                  noti.resource
                                    ? noti.resource.img
                                    : require("../../assets/images/profileicon.jpg")
                                }
                              />
                            </div>
                            <div className="notifData">
                              <p style={{ fontWeight: "400" }}>
                                {noti.resource ? noti.resource.fullname : ""}{" "}
                                <span
                                  style={{
                                    color: "#999",
                                    float: "right",
                                    fontWeight: "300",
                                    fontSize: "9px",
                                  }}
                                >
                                  {"" +
                                    new Date(
                                      noti.createdAt
                                    ).toLocaleDateString()}
                                </span>
                              </p>
                              <p style={{ color: "#999" }}>
                                {noti.description
                                  ? this.props.isRTL
                                    ? noti.arabicDescription
                                    : noti.description
                                  : ""}
                              </p>
                            </div>
                            <hr></hr>
                          </div>
                        </MDBDropdownItem>
                      ))}
                      <NavLink to="notifs">
                        <p
                          style={{
                            textAlign: "center",
                            lineHeight: "initial",
                            paddingTop: "10px",
                            marginBottom: "0px",
                            color: "blue",
                            cursor: "pointer",
                          }}
                        >
                          more
                        </p>
                      </NavLink>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                )}
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

const mapToStateProps = (state) => ({
  isRTL: state.lang.isRTL,
  branch: state.company.branch,
  company: state.company.company,
  currentUser: state.auth.currentUser,
  unseenMsgCount: state.chat.unseenMsgCount,
  notifications: state.notif.notifications,
  notifPage: state.notif.notifPage,
  notifPages: state.notif.notifPages,
  unreadNotifCount: state.notif.unreadNotifCount,
});

const mapDispatchToProps = {
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
  setUser,
  getUnseenMessagesCount,
  getUnreadNotificationsCount,
  getNotifications,
};

export default withRouter(connect(mapToStateProps, mapDispatchToProps)(Header));
