/* eslint-disable eqeqeq */
import React from 'react';
import './home.css'
import { MDBContainer,  MDBRow, MDBCol,MDBIcon} from "mdbreact";

import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import {setUser} from '../../redux/actions/AuthActions'
import { NavLink } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
let fromDate
let toDate
class Home extends React.Component {
  
  state = {
    count:[],
    countPrev:[],
    graph:[],
    tops:[],
    topProducts:[],
    ads:[],
    ordersCount:[],
    adsView:[],
    monthA:[],
    monthE:[],
    fromDate:'',
    toDate:'',
    loading:true,
    graphStep:0,
    maxValue:0,
    topSalesMan:[],
    topCompany:[],
    topProduct:[]
  }
  
   
  constructor(props){
    super(props)
    this.getDuration()
    this.getTops()
    this.getCount()
    this.getCountPrevMonth()
    this.getTopProducts()
    window.scrollTo(0,0)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }
  getDuration = () =>{
    //first day
    let date = new Date()
    let newDate = date.toISOString().slice(0, 8)  
    let firstDay = newDate + '01';
    //get days in month
    function daysInMonth (month, year) {
        return new Date(year, month , 0).getDate();
    }

    let DaysInCurrentMonth = daysInMonth(date.getMonth() + 1,date.getFullYear()); //number of day in month
    //last day
    let lastDay= newDate + DaysInCurrentMonth;
    console.log(firstDay)
    console.log(lastDay)
    this.setState({fromDate:firstDay,toDate:lastDay})
    fromDate = firstDay
    toDate = lastDay
  }
  getCount = () => {
    console.log(this.state.fromDate)
    let company
    if(this.props.currentUser.user.type == "COMPANY"){
      company =this.props.currentUser.user.id 
    }else{
      company =this.props.currentUser.user.company[0].id 
    }
    axios.get(`${BASE_END_POINT}admin/${company}/companyStatistics?fromDate=${fromDate}&toDate=${toDate}`,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.currentUser.token}`
      },
    })
    .then(response=>{
      console.log(response.data)
      this.setState({count:response.data,loading:false})
    })
    .catch(error=>{
      console.log("ALL count ERROR")
      console.log(error.response)
    })
  }
  getCountPrevMonth = () => {
    let date = new Date()
    //let newDate = date.toISOString().slice(0, 8)  
    function theMonth (month, year) {
      const datee = new Date(year, month , 0); 
      return datee.getMonth()
    }
    let month1 = theMonth(date.getMonth() +1,date.getFullYear())
    console.log(month1)
    if(month1 == 0){
      month1 = 12
  }
    let FirstDay = date.getFullYear()+'-'+ month1 + '-01';
    if(month1 > 0 && month1 < 10){
      FirstDay = date.getFullYear()+'-0'+ month1 + '-01';
    }
    console.log(FirstDay)
    //get days in month
    function daysInMonth (month, year) {
        return new Date(year, month , 0).getDate();
    }

    let DaysInCurrentMonth = daysInMonth(date.getMonth() ,date.getFullYear()); //number of day in month
    //last day
    let LastDay = date.getFullYear()+'-'+ month1 +'-'+ DaysInCurrentMonth ;
    if(month1 > 0 && month1 < 10){
      LastDay = date.getFullYear()+'-0'+ month1 +'-'+ DaysInCurrentMonth ;
    }
    console.log(LastDay)
    let company
    if(this.props.currentUser.user.type == "COMPANY"){
      company =this.props.currentUser.user.id 
    }else{
      company =this.props.currentUser.user.company[0].id 
    }
    axios.get(`${BASE_END_POINT}admin/${company}/companyStatistics?fromDate=${FirstDay}&toDate=${LastDay}`,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.currentUser.token}`
      },
    })
    .then(response=>{
      console.log(response.data)
      this.setState({countPrev:response.data,loading:false})
    })
    .catch(error=>{
      console.log("ALL count ERROR")
      console.log(error.response)
    })
  }
  getTopProducts = () => {
    let company
    if(this.props.currentUser.user.type == "COMPANY"){
      company =this.props.currentUser.user.id 
    }else{
      company =this.props.currentUser.user.company[0].id 
    }
    axios.get(`${BASE_END_POINT}orders/topProduct?companyId=${company}&start=${fromDate}&end=${toDate}&product=true`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log(response.data)
      this.setState({topProducts:response.data})
    })
    .catch(error=>{
      console.log("ALL top products ERROR")
      console.log(error.response)
    })
  }
  getTops = () => {
    let company
    if(this.props.currentUser.user.type == "COMPANY"){
      company =this.props.currentUser.user.id 
    }else{
      company =this.props.currentUser.user.company[0].id 
    }
    axios.get(`${BASE_END_POINT}orders/tops?company=${company}&start=${fromDate}&end=${toDate}`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log(response.data)
      this.setState({tops:response.data,topCompany:response.data.topCompany,
        topSalesMan:response.data.topSalesMan,topProduct:response.data.topProduct})
    })
    .catch(error=>{
      console.log("ALL tops ERROR")
      console.log(error.response)
    })
  }
  componentDidMount(){
    //this.props.ChangeLanguage(false)
    
  
  }

  render(){
    let {loading,topProducts} = this.state;
    
  return (
    <div className="Home">
      <MDBContainer> 
        <MDBRow>
          
         <div style={{textAlign:'right',width: '100%',padding: '5px 20px 25px'}}>
          <span  style={{
            display: 'inline-block',
            color: '#43425D',
            fontSize:'22px'}}>{this.props.isRTL?this.state.monthA[0]:this.state.monthE[0]}</span>
          <span  style={{fontFamily:'system-ui',fontWeight:'bold',fontSize:'22px',display: 'inline-block',
            color: '#43425D',margin:'6px'}}>|</span>
          <span style={{fontFamily:'system-ui',fontWeight:'bold',fontSize:'22px',display: 'inline-block',
            color: '#43425D'}}>{allStrings.overView}</span>
         </div>
         
        </MDBRow>
        <MDBRow>
          <MDBCol xl="8" lg="12" md="12" sm="12" style={{marginBottom: '1.5rem'}}>
            <MDBRow>
              <MDBCol xl="6" lg="6" md="12" sm="12" style={{marginBottom: '1.5rem'}}>
                <div className="theCard">
                  <p className="topCard">{allStrings.allSoldProducts}</p>
                    <MDBRow>
                      <MDBCol  md="6" sm="6">
                        <img alt=""  style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart2.png')}></img>
                      </MDBCol>
                      <MDBCol  md="6" sm="6">
                        <p className="count">{loading?<Skeleton height={20} />:this.state.count.ordersCount}</p>
                        <p className="arang" style={{color:this.state.count.ordersCount> this.state.countPrev.ordersCount?'lightGreen':'red'}}>
                        <MDBIcon icon={this.state.count.ordersCount> this.state.countPrev.ordersCount?'long-arrow-alt-up':'long-arrow-alt-down'} />
                        
                          {
                            this.state.count.ordersCount == this.state.countPrev.ordersCount?
                            <span>--</span>
                            :
                            this.state.countPrev.ordersCount > this.state.count.ordersCount?
                            Math.ceil((((this.state.countPrev.ordersCount - this.state.count.ordersCount) / this.state.countPrev.ordersCount) * 100) * 100)/100                     
                            :
                            Math.ceil((((this.state.count.ordersCount - this.state.countPrev.ordersCount) / this.state.count.ordersCount) * 100) * 100)/100                     
                        }
                        </p>
                      </MDBCol>
                    </MDBRow>
                </div>
              </MDBCol>
              <MDBCol xl="6" lg="6" md="12" sm="12" style={{marginBottom: '1.5rem'}}>
                <div className="theCard">
                  <p className="topCard">{allStrings.TotalSalesOfCompany}</p>
                    <MDBRow>
                      <MDBCol  md="6" sm="6">
                        <img alt=""  style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart3.png')}></img>
                      </MDBCol>
                      <MDBCol  md="6" sm="6">
                        <p className="count">{loading?<Skeleton height={20} />:this.state.count.totalOrders}</p>
                        <p className="arang" style={{color:this.state.count.totalOrders> this.state.countPrev.totalOrders?'lightGreen':'red'}}>
                        <MDBIcon icon={this.state.count.totalOrders> this.state.countPrev.totalOrders?'long-arrow-alt-up':'long-arrow-alt-down'} />
                        {
                          this.state.count.totalOrders == this.state.countPrev.totalOrders?
                          <span>--</span>
                          :
                          this.state.countPrev.totalOrders > this.state.count.totalOrders?
                          Math.ceil((((this.state.countPrev.totalOrders - this.state.count.totalOrders) / this.state.countPrev.totalOrders) * 100) * 100)/100                     
                          :
                          Math.ceil((((this.state.count.totalOrders - this.state.countPrev.totalOrders) / this.state.count.totalOrders) * 100) * 100)/100                     

                        }
                        </p>
                      </MDBCol>
                    </MDBRow>
                </div>
              </MDBCol>

              <MDBCol xl="6" lg="6" md="12" sm="12" style={{marginBottom: '1.5rem'}}>
                <div className="theCard">
                  <p className="topCard">{allStrings.allUsersCount}</p>
                    <MDBRow>
                      <MDBCol  md="6" sm="6">
                        <img alt=""  style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart5.png')}></img>
                      </MDBCol>
                      <MDBCol  md="6" sm="6">
                        <p className="count">{loading?<Skeleton height={20} />:this.state.count.usersCount}</p>
                        <p className="arang" style={{color:this.state.count.usersCount> this.state.countPrev.usersCount?'lightGreen':'red'}}>
                        <MDBIcon icon={this.state.count.usersCount> this.state.countPrev.usersCount?'long-arrow-alt-up':'long-arrow-alt-down'}/>
                        {
                          this.state.count.usersCount == this.state.countPrev.usersCount?
                          <span>--</span>
                          :
                          this.state.countPrev.usersCount >= this.state.count.usersCount?
                          Math.ceil((((this.state.countPrev.usersCount - this.state.count.usersCount) / this.state.countPrev.usersCount) * 100) * 100)/100                     
                          :
                          Math.ceil((((this.state.count.usersCount - this.state.countPrev.usersCount) / this.state.count.usersCount) * 100) * 100)/100                     

                          }
                        </p>
                      </MDBCol>
                    </MDBRow>
                </div>
              </MDBCol>
              <MDBCol xl="6" lg="6" md="12" sm="12" style={{marginBottom: '1.5rem'}}>
                <div className="theCard">
                  <p className="topCard">{allStrings.newUsers}</p>
                    <MDBRow>
                      <MDBCol  md="6" sm="6">
                        <img alt=""  style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart6.png')}></img>
                      </MDBCol>
                      <MDBCol  md="6" sm="6">
                        <p className="count">{loading?<Skeleton height={20} />:this.state.count.dailyUsersCount}</p>
                        <p className="arang" style={{color:this.state.count.dailyUsersCount> this.state.countPrev.dailyUsersCount?'lightGreen':'red'}}>
                        <MDBIcon icon={this.state.count.dailyUsersCount> this.state.countPrev.dailyUsersCount?'long-arrow-alt-up':'long-arrow-alt-down'} />
                        {
                          this.state.count.dailyUsersCount == this.state.countPrev.dailyUsersCount?
                          <span>--</span>
                          :
                          this.state.countPrev.dailyUsersCount >= this.state.count.dailyUsersCount?
                          Math.ceil((((this.state.countPrev.dailyUsersCount - this.state.count.dailyUsersCount) / this.state.countPrev.dailyUsersCount) * 100) * 100)/100                     
                          :
                          Math.ceil((((this.state.count.dailyUsersCount - this.state.countPrev.dailyUsersCount) / this.state.count.dailyUsersCount) * 100) * 100)/100                     

                        }
                        </p>
                      </MDBCol>
                    </MDBRow>
                </div>
              </MDBCol>
              <MDBCol xl="6" lg="6" md="12" sm="12" style={{marginBottom: '1.5rem'}}>
                <div className="theCard">
                  <p className="topCard">{allStrings.topProduct}</p>
                    <MDBRow>
                      <MDBCol xl="4" md="4" sm="4">
                        <p className="centerCount">{loading?<Skeleton height={20} />:this.state.topProduct.count?this.state.topProduct.count:0}</p>
                      </MDBCol>
                      <MDBCol  md="4" sm="4" style={{paddingRight:0}}>
                      <p className="centerName">{loading?<Skeleton height={20} />:this.state.topProduct.product?this.props.isRTL?this.state.topProduct.product.name_ar:this.state.topProduct.product.name_en:" "}</p>
                      </MDBCol>
                      <MDBCol  md="4" sm="4">
                      <img alt=""  style={{width: '70px',height: '70px',borderRadius:'100%',marginBottom:'10px',float: 'right'}} src={require('../../assets/images/profileicon.jpg')}></img>
                      </MDBCol>
                    </MDBRow>
                </div>
              </MDBCol>
              <MDBCol xl="6" lg="6" md="12" sm="12" style={{marginBottom: '1.5rem'}}>
              <div className="theCard">
                <p className="topCard">{allStrings.topSalesMan}</p>
                  <MDBRow>
                    <MDBCol  md="4" sm="4">
                      <p className="centerCount">{loading?<Skeleton height={20} />:this.state.topSalesMan.count?this.state.topSalesMan.count:0}</p>
                    </MDBCol>
                    <MDBCol  md="4" sm="4" style={{paddingRight:0}}>
                    <p className="centerName">{loading?<Skeleton height={20} />:this.state.topSalesMan.salesMan?this.state.topSalesMan.salesMan.fullname:" "}</p>
                    </MDBCol>
                    <MDBCol  md="4" sm="4">
                    <img alt=""  style={{width: '70px',height: '70px',borderRadius:'100%',marginBottom:'10px',float: 'right'}} src={require('../../assets/images/profileicon.jpg')}></img>
                    </MDBCol>
                  </MDBRow>
              </div>
            </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol xl="4" lg="12" md="12" sm="12">
            <div className="theCard">
              <NavLink to="stock">
                <span style={{position: 'absolute'}}>{allStrings.showAll}</span>
              </NavLink>
              <p className="topCard"  style={{color: '#43425D'}}>{allStrings.topProducts}</p>
              <img alt=""  width="100%" src={require('../../assets/images/Map.png')}></img>
              <div>
                <span className="rightSpan">{topProducts[0]?this.props.isRTL?topProducts[0].product.name_ar:topProducts[0].product.name_en:' ' }</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{color:'#54D8FF'}}></span>
                  <span className="spanNum">{topProducts[0]?this.props.isRTL?topProducts[0].count:topProducts[0].count:0 }</span>
                </p>
              </div>
              <div>
                <span className="rightSpan">{topProducts[1]?this.props.isRTL?topProducts[1].product.name_ar:topProducts[1].product.name_en:' ' }</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{borderColor:'#5EE2A0'}}></span>
                  <span className="spanNum">{topProducts[1]?this.props.isRTL?topProducts[1].count:topProducts[1].count:0}</span>
                </p>
              </div>
              <div>
                <span className="rightSpan">{topProducts[2]?this.props.isRTL?topProducts[2].product.name_ar:topProducts[2].product.name_en:' ' }</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{borderColor:'#FFA177'}}></span>
                  <span className="spanNum">{topProducts[2]?this.props.isRTL?topProducts[2].count:topProducts[2].count:0}</span>
                </p>
              </div>
              <div>
                <span className="rightSpan">{topProducts[3]?this.props.isRTL?topProducts[3].product.name_ar:topProducts[3].product.name_en:' ' }</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{borderColor:'#A3A1FB'}}></span>
                  <span className="spanNum">{topProducts[3]?this.props.isRTL?topProducts[3].count:topProducts[3].count:0}</span>
                </p>
              </div>
              <div>
                <span className="rightSpan">{topProducts[4]?this.props.isRTL?topProducts[4].product.name_ar:topProducts[4].product.name_en:' ' }</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{borderColor:'#FBA69D'}}></span>
                  <span className="spanNum">{topProducts[4]?this.props.isRTL?topProducts[4].count:topProducts[4].count:0}</span>
                </p>
              </div>
              <div>
                <span className="rightSpan">{topProducts[5]?this.props.isRTL?topProducts[5].product.name_ar:topProducts[5].product.name_en:' ' }</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{borderColor:'#FF7CC3'}}></span>
                  <span className="spanNum">{topProducts[5]?this.props.isRTL?topProducts[5].count:topProducts[5].count:0}</span>
                </p>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
       
        <br></br>
       
      </MDBContainer> 
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(Home)
);
