/* eslint-disable eqeqeq */
import React from 'react';
import './category.css'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import { ChangeLanguage } from '../../redux/actions/LanguageAction'
import { ChangeCompany, ChangeBranch } from '../../redux/actions/CompanyAction'
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { BASE_END_POINT } from '../../urls'
import axios from 'axios'
import { setUser } from '../../redux/actions/AuthActions'
import { Popconfirm, message } from 'antd';
import Pagination from '@material-ui/lab/Pagination';
import LoadCard from '../../component/load card/load card'
import { Redirect } from 'react-router-dom'
import { hasValue } from "../../validations/validations";
class Category extends React.Component {

  state = {
    categories: [],
    category: [],
    loading: true,
    refresh: false,
    page: 1,
    pages: 1,
    modal: false,
    categoryName_ar: '',
    categoryName_en: '',
    img: null,
    requestType: 'post',
    selectedCategory: null,
    imgType: 'data'

  }
  constructor(props) {
    super(props)
    console.log("isr   ", this.props.history)
    if (this.props.isRTL) {
      allStrings.setLanguage('ar')
    } else {
      allStrings.setLanguage('en')
    }
    window.scrollTo(0, 0)
  }

  componentDidMount() {
    this.getCategories(1, false)
    //this.props.ChangeLanguage(false)
  }
  getCategories = (page, refresh) => {
    this.setState({ loading: true })
    axios.get(`${BASE_END_POINT}categories?page=${page}`, {
      headers: {
        'Authorization': `Bearer ${this.props.currentUser.token}`
      }
    })
      .then(response => {
        console.log("Categories   ", response.data)
        this.setState({
          loading: false,
          refresh: false,
          categories: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        })
      })
      .catch(error => {
        console.log("error   ", error.response)
        console.log("error2   ", error)
        this.setState({ loading: false, })
      })

  }
  selectCategoryId = (e) => {
    e.preventDefault();
    let id = e.target.id.substring(8)
    console.log(id)
    this.setState({ selectedCategory: id })
  }
  deleteCategory = (id) => {
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}categories/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.currentUser.token}`
      },
    })
      .then(response => {
        console.log("Category DELETED")
        l.then(() => message.success(allStrings.done, 2))
        this.getCategories(this.state.page)
      })
      .catch(error => {
        console.log(error.response)
        l.then(() => message.error('Error', 2.5))
      })
  }
  updateCategory = () => {
    const { categoryName_ar, categoryName_en, img } = this.state
    if (hasValue(categoryName_ar) && hasValue(categoryName_en)) {
      let l = message.loading('Wait..', 2.5)
      var data = new FormData()
      data.append('categoryName_ar', categoryName_ar)
      data.append('categoryName_en', categoryName_en)
      if (img) {
        data.append('img', img);
      }
      axios.put(`${BASE_END_POINT}categories/${this.state.selectedCategory}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${this.props.currentUser.token}`
        },
      })
        .then(response => {
          l.then(() => message.success(allStrings.done, 2.5))
          this.setState({ loading: true })
          this.toggle()
          this.getCategories(1, false)

        })
        .catch(error => {
          console.log(error.response)
          l.then(() => message.error('Error', 2.5))
        })
    }
  }
  addCategory = () => {
    const { categoryName_ar, categoryName_en, img } = this.state
    console.log(img)
    if (hasValue(categoryName_ar) && hasValue(categoryName_en) && img != null) {
      let l = message.loading('Wait..', 2.5)
      var data = new FormData()
      data.append('categoryName_ar', categoryName_ar)
      data.append('categoryName_en', categoryName_en)
      data.append('img', img);
      console.log(data)

      axios.post(`${BASE_END_POINT}categories`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${this.props.currentUser.token}`
        },
      })
        .then(response => {
          l.then(() => message.success(allStrings.done, 2.5))
          this.setState({ loading: true })
          this.toggle()
          this.getCategories(1, false)

        })
        .catch(error => {
          console.log(error.response)
          l.then(() => message.error('Error', 2.5))
        })
    }
  }

  fileHandler = (event) => {
    this.setState({
      img: event.target.files[0],
      imgType: 'data'
    })

  }
  removeImg = () => {
    this.setState({
      img: null
    });
  }
  changeHandler = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  addSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.addCategory()
  };
  openToAdd = () => {
    this.setState({ requestType: 'post' })
    this.toggle()
  }
  selectCategory = (e) => {

    e.preventDefault();
    let id = e.target.value;
    console.log(e.target.value)
    axios.get(`${BASE_END_POINT}categories/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.currentUser.token}`
      },
    })
      .then(response => {
        console.log("UGGF", response.data)
        this.toggle()
        this.setState({
          selectedCategory: id, requestType: 'put',
          category: response.data.data,
          categoryName_ar: response.data.data.categoryName_ar,
          categoryName_en: response.data.data.categoryName_en,
          img: response.data.data.img, imgType: 'url'
        })
        console.log("ufvc", this.state.category)
      })
      .catch(error => {
        console.log(error.response)
      })
  }
  updateSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.updateCategory()
  };

  OKBUTTON = (e) => {
    this.deleteCategory(this.state.selectedCategory)
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      requestType: 'post',
      category: [],
      categoryName_ar: '',
      categoryName_en: '',
      img: null
    });
  }
  modal = () => (
    <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
      <MDBModalHeader toggle={this.toggle}>{this.state.requestType == "post" ? allStrings.completeData : allStrings.edit}</MDBModalHeader>
      <MDBModalBody>
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12">
              <form
                className="needs-validation"
                onSubmit={this.state.requestType === "post" ? this.addSubmitHandler : this.updateSubmitHandler}
                noValidate
              >
                <MDBContainer>
                  <MDBRow>
                    <MDBCol md="9">
                      <input name="categoryName_ar" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx1" required
                        className="form-control" style={{ direction: this.props.isRTL ? 'rtl' : 'ltr' }} defaultValue={this.state.category.categoryName_ar} />
                    </MDBCol>
                    <MDBCol md="3">
                      <label htmlFor="defaultFormRegisterNameEx1" className="grey-text formLabel">
                        {allStrings.arabicCategoryName}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <br></br><br></br>
                  <MDBRow>
                    <MDBCol md="9">
                      <input name="categoryName_en" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx2" required
                        className="form-control" style={{ direction: this.props.isRTL ? 'rtl' : 'ltr' }} defaultValue={this.state.category.categoryName_en} />
                    </MDBCol>
                    <MDBCol md="3">
                      <label htmlFor="defaultFormRegisterNameEx2" className="grey-text formLabel">
                        {allStrings.englishCategoryName}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <br></br><br></br>
                  <MDBRow>
                    <MDBCol md="9">
                      {this.state.img == null &&
                        <div className="uploadContainer">
                          <img alt="" name="img" className="uploderImg" src={require('../../assets/images/Path 343.png')}></img>
                          <input
                            onChange={this.fileHandler}
                            type="file"
                            id="defaultFormRegisterNameEx3"
                            required className="uploader" />
                          <MDBBtn color="primary" size="sm">{allStrings.chooseFile}</MDBBtn>
                          <p style={{ fontSize: '14px', marginTop: '6px' }} className="grey-text">
                            {allStrings.dropHere}
                          </p>
                        </div>
                      }
                      <div className="preview">
                        {this.state.img != null &&
                          <div style={{ display: "inline-block", border: '1px solid #43425D', float: 'right' }}>
                            <img alt="" width="220px" src={this.state.imgType == "data" ? URL.createObjectURL(this.state.img) : this.state.img} />
                            <MDBIcon icon="times" className="mr-2 removeImg" onClick={() => this.removeImg()}></MDBIcon>
                          </div>
                        }
                      </div>
                    </MDBCol>
                    <MDBCol md="3">
                      <label style={{ marginTop: '2rem' }} htmlFor="defaultFormRegisterNameEx3" className="grey-text formLabel">
                        {allStrings.categoryImg}
                      </label>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBBtn type="submit" style={{ margin: '4rem auto', width: '170px' }}
                      className="btn z-depth-1a "
                      color="primary"
                    >
                      {this.state.requestType == "post" ? allStrings.add : allStrings.edit}
                    </MDBBtn>
                  </MDBRow>
                </MDBContainer>
              </form>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBModalBody>
      {/*<MDBModalFooter>
        <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn>
        <MDBBtn color="primary">Save changes</MDBBtn>
      </MDBModalFooter>*/}
    </MDBModal>
  );


  render() {
    console.log(this.state.requestType)
    if (this.props.currentUser.user.type == "COMPANY") return <Redirect to='/Home' />
    if (this.props.currentUser.user.type == "SUB-ADMIN" && !this.props.currentUser.user.pagesPermission.includes('CATEGORIES')) return <Redirect to='/Home' />

    return (
      <div className="Category">
        {this.modal()}
        <div className="categoryTop">
          <div className="addButton" onClick={this.toggle}>
            <MDBIcon icon="plus" />
          </div>
          <span onClick={this.openToAdd}
            style={{
              float: 'right',
              color: '#43425D',
              fontWeight: '600',
              padding: '11px 2px',
              fontSize: '20px',
              cursor: 'pointer',
            }}>{allStrings.addCategory}</span>
        </div>
        <MDBContainer>
          <MDBRow>
            {this.state.loading ?
              <LoadCard />
              :
              this.state.categories.length > 0 ?
                this.state.categories.map(category => (

                  <MDBCol xl="4" lg="6" md="12" sm="12">

                    <div className="categoryCard">
                      <button className="hiddenButton" onClick={this.selectCategory} value={category.id}></button>
                      <MDBContainer>
                        <MDBRow>

                          <MDBCol xl="2" lg="2" md="2" sm="2">
                            <div className="icons">
                              <Popconfirm
                                title={allStrings.areYouSure}
                                onConfirm={this.OKBUTTON}
                                onCancel={this.fCANCELBUTTON}
                                okText={allStrings.ok}
                                cancelText={allStrings.cancel}
                              >
                                <p style={{
                                  fontSize: '22px',
                                  color: '#A4A3B0',
                                  marginBottom: '4px',
                                  cursor: 'pointer',
                                  zIndex: '4',
                                  position: 'relative'

                                }} onClick={this.selectCategoryId} id={"category" + category.id}>
                                  <MDBIcon icon="times" onClick={this.selectCategoryId} id={"category" + category.id} />
                                </p>

                              </Popconfirm>

                              <p style={{
                                fontSize: '16px',
                                color: '#A4A3B0',
                                marginBottom: '4px',
                                cursor: 'pointer',
                              }}>
                                <MDBIcon icon="pen" />
                              </p>
                              <p style={{ fontWeight: '700', color: '#43425D' }}>{category.productCount}</p>
                            </div>
                          </MDBCol>
                          <MDBCol xl="6" lg="6" md="6" sm="6" className="noPadding">
                            <div className="categoryInfo">
                              <p style={{
                                fontWeight: '600',
                                color: '#43425D',
                                marginBottom: '6px',
                                fontSize: '22px'

                              }}>{category.categoryName_ar}</p>
                              <p style={{
                                color: '#A4A3B0',
                                fontSize: '14px'
                              }}>{category.categoryName_en}</p>
                            </div>
                          </MDBCol>
                          <MDBCol xl="4" lg="4" md="4" sm="4">

                            <div className="categoryImg">
                              <img alt="" src={category.img}></img>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBContainer>
                    </div>
                  </MDBCol>
                ))
                :
                <div className="noData">{allStrings.noData}</div>
            }
          </MDBRow>
          <MDBRow>
            {this.state.categories.length > 0 &&
              <div style={{ textAlign: 'center', margin: 'auto', marginTop: '3rem' }}>
                <Pagination
                  onChange={(event, page) => {
                    console.log("page   ", page)
                    if (page != this.state.page) {
                      this.getCategories(page)
                      this.setState({ page: page })
                    }

                  }}
                  defaultPage={1} page={this.state.page} count={this.state.pages} style={{ color: `blue !important` }} />
              </div>
            }

          </MDBRow>
        </MDBContainer>
      </div>
    )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
}

export default withRouter(
  connect(mapToStateProps, mapDispatchToProps)(Category)
);
