/* eslint-disable eqeqeq */
import React from "react";
import "./calculations.css";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { ChangeCompany, ChangeBranch } from "../../redux/actions/CompanyAction";
import { ChangeLanguage } from "../../redux/actions/LanguageAction";
import allStrings from "../../assets/strings";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { BASE_END_POINT } from "../../urls";
import axios from "axios";
import { setUser } from "../../redux/actions/AuthActions";
import { Redirect } from "react-router-dom";
import moment from "moment";
import { DatePicker, ConfigProvider } from "antd";
import locale_ar from "antd/lib/locale/ar_EG";
import locale_en from "antd/lib/locale/en_GB";
import "moment/locale/ar";
let fromDate;
let toDate;
class Calculations extends React.Component {
  state = {
    count: [],
    fromDate: "",
    toDate: "",
    loading: true,
    from: this.props.isRTL
      ? moment().startOf("month").format("YYYY/MM/DD")
      : moment().startOf("month").locale("en").format("YYYY/MM/DD"),
    to: this.props.isRTL
      ? moment().endOf("month").format("YYYY/MM/DD")
      : moment().endOf("month").locale("en").format("YYYY/MM/DD"),
  };

  constructor(props) {
    super(props);
    this.getDuration();
    this.getCount(fromDate, toDate);
    window.scrollTo(0, 0);
    if (this.props.isRTL) {
      allStrings.setLanguage("ar");
      moment.locale("ar");
    } else {
      allStrings.setLanguage("en");
      moment.locale("en");
    }
  }
  getCount = (start, end) => {
    //${this.props.currentUser.user.id}
    let url = ``;
    if (
      this.props.currentUser.user.type == "ADMIN" ||
      this.props.currentUser.user.type == "SUB-ADMIN"
    ) {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}${this.props.currentUser.user.company[0].id}/companyAccounting?start=${start}&end=${end}`;
      } else {
        url = `${BASE_END_POINT}${this.props.company}/companyAccounting?start=${start}&end=${end}`;
      }
    } else {
      url = `${BASE_END_POINT}${this.props.currentUser.user.id}/companyAccounting?start=${start}&end=${end}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        this.setState({ count: response.data, loading: false });
      })
      .catch((error) => {
        console.log("ALL count ERROR");
        console.log(error.response);
      });
  };
  getDuration = () => {
    //first day
    let date = new Date();
    let newDate = date.toISOString().slice(0, 8);
    let firstDay = newDate + "01";
    //get days in month
    function daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    }

    let DaysInCurrentMonth = daysInMonth(
      date.getMonth() + 1,
      date.getFullYear()
    ); //number of day in month
    //last day
    let lastDay = newDate + DaysInCurrentMonth;
    console.log(firstDay);
    console.log(lastDay);
    if (this.props.isRTL) {
      this.setState({
        from: moment(firstDay).format("YYYY/MM/DD"),
        to: moment(lastDay).format("YYYY/MM/DD"),
      });
    } else {
      this.setState({
        from: moment(firstDay).locale("en").format("YYYY/MM/DD"),
        to: moment(lastDay).locale("en").format("YYYY/MM/DD"),
      });
    }

    fromDate = firstDay;
    toDate = lastDay;
  };

  componentDidMount() {
    //this.props.ChangeLanguage(false)
  }
  onChangeFrom = (date, dateString) => {
    if (this.props.isRTL) {
      this.setState({ from: moment(dateString).format("YYYY/MM/DD") });
    } else {
      this.setState({
        from: moment(dateString).locale("en").format("YYYY/MM/DD"),
      });
    }
    fromDate = moment(dateString).locale("en").format("YYYY-MM-DD");
  };
  onChangeTo = (date, dateString) => {
    if (this.props.isRTL) {
      this.setState({ to: moment(dateString).format("YYYY/MM/DD") });
    } else {
      this.setState({
        to: moment(dateString).locale("en").format("YYYY/MM/DD"),
      });
    }
    toDate = moment(dateString).locale("en").format("YYYY-MM-DD");

    this.getCount(fromDate, toDate);
  };

  render() {
    if (
      this.props.currentUser.user.type == "SUB-ADMIN" &&
      !this.props.currentUser.user.pagesPermission.includes("CALCULATIONS")
    )
      return <Redirect to="/Home" />;

    return (
      <div className="Home">
        <MDBContainer>
          <MDBRow>
            <MDBCol xl="3" lg="2" md="6" sm="12">
              {/*<ReactToPrint
              trigger={() => {
                return (
                  <div>
                    <img alt=""  style={{cursor:'pointer'}} src={require('../../assets/images/icons8_pdf.png')}></img>
                    <span
                    style={{
                      color: '#FF3B40',
                      fontWeight: '600',
                      padding: '10px',
                      fontSize: '20px',
                      position: 'absolute',
                      padding: '10px 10px',
                      cursor:'pointer'
                    }}>{allStrings.print}</span>
                  </div>
                ) ;
              }}
              content={() => this.componentRef}
            />
            <div style={{display:'none'}}>
              <ComponentToPrint  data={this.state.count}  ref={el => (this.componentRef = el)} />
              
            </div>*/}
            </MDBCol>

            <MDBCol
              xl="3"
              lg="2"
              md="6"
              sm="12"
              style={{ paddingBottom: "2rem" }}
            >
              <div style={{ textAlign: "right", paddingtop: "15px" }}></div>
            </MDBCol>
            <MDBCol xl="3" lg="4" md="6" sm="12">
              <div style={{ marginBottom: "10px", textAlign: "center" }}>
                <span
                  className="fromSpan"
                  style={{
                    float: this.props.isRTL ? "right" : "left",
                    marginRight: "0%",
                  }}
                >
                  {allStrings.to}
                </span>
                <div
                  style={{
                    display: "inline-block",
                    textAlign: "center",
                    marginLeft: this.props.isRTL ? "-80px" : "-60px",
                  }}
                >
                  <ConfigProvider
                    locale={this.props.isRTL ? locale_ar : locale_en}
                  >
                    <DatePicker
                      defaultValue={moment(this.state.to, "YYYY/MM/DD")}
                      onChange={this.onChangeTo}
                    />
                  </ConfigProvider>
                  <span
                    className="newSpan"
                    style={{ left: this.props.isRTL ? "16px" : "60px" }}
                  >
                    <img
                      alt=""
                      style={{ marginRight: "4px" }}
                      src={require("../../assets/images/icons8_edit_property.png")}
                    ></img>
                    {this.state.to}
                  </span>
                </div>
              </div>
            </MDBCol>
            <MDBCol xl="3" lg="4" md="6" sm="12">
              <div style={{ marginBottom: "10px", textAlign: "center" }}>
                <span
                  className="fromSpan"
                  style={{
                    float: this.props.isRTL ? "right" : "left",
                    marginRight: "0%",
                  }}
                >
                  {allStrings.from}
                </span>
                <div
                  style={{
                    display: "inline-block",
                    textAlign: "center",
                    marginLeft: this.props.isRTL ? "-80px" : "-60px",
                  }}
                >
                  <ConfigProvider
                    locale={this.props.isRTL ? locale_ar : locale_en}
                  >
                    <DatePicker
                      defaultValue={moment(this.state.from, "YYYY/MM/DD")}
                      onChange={this.onChangeFrom}
                    />
                  </ConfigProvider>
                  <span
                    className="newSpan"
                    style={{ left: this.props.isRTL ? "16px" : "70px" }}
                  >
                    <img
                      alt=""
                      style={{ marginRight: "4px" }}
                      src={require("../../assets/images/icons8_edit_property.png")}
                    ></img>
                    {this.state.from}
                  </span>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow style={{ marginTop: "4rem" }}>
            <MDBCol xl="6" lg="6" md="12" sm="12">
              <div
                style={{
                  background: "#43425d",
                  padding: "30px 13px",
                  borderRadius: "6px",
                  minHeight: "180px",
                  marginTop: "1rem",
                }}
              >
                <MDBRow>
                  <MDBCol xl="6" lg="6" md="6" sm="12">
                    <div>
                      <p
                        style={{
                          color: " #fff",
                          fontWeight: "600",
                          fontSize: "24px",
                          textAlign: "center",
                        }}
                      >
                        {allStrings.appRatio}
                      </p>
                      <p
                        style={{
                          color: " #fff",
                          fontWeight: "600",
                          fontSize: "45px",
                          textAlign: "center",
                          marginBottom: "0",
                        }}
                      >
                        {this.state.count.contractType == "RATIO" && (
                          <span>%</span>
                        )}
                        {this.state.count.contractCost}
                      </p>
                    </div>
                  </MDBCol>
                  <MDBCol xl="6" lg="6" md="6" sm="12">
                    <div>
                      <p
                        style={{
                          color: " #fff",
                          fontWeight: "600",
                          fontSize: "24px",
                          textAlign: "center",
                        }}
                      >
                        {allStrings.netProfit}
                      </p>
                      <p
                        style={{
                          color: " #fff",
                          fontWeight: "600",
                          fontSize: "45px",
                          textAlign: "center",
                          marginBottom: "0",
                        }}
                      >
                        {this.state.count.netProfit}
                      </p>
                    </div>
                  </MDBCol>
                </MDBRow>
              </div>
            </MDBCol>
            <MDBCol
              xl="6"
              lg="6"
              md="12"
              sm="12"
              onClick={() =>
                this.props.history.push("/calculationsInfo", {
                  data: this.state.count,
                  rightType: "main",
                  tableType: "first",
                  specialData: this.state.count,
                  type: "order",
                })
              }
            >
              <div
                style={{
                  background: "#43425d",
                  padding: "20px 13px",
                  borderRadius: "6px",
                  minHeight: "180px",
                  marginTop: "1rem",
                  cursor: "pointer",
                }}
              >
                <MDBRow>
                  <MDBCol xl="6" lg="6" md="6" sm="12">
                    <MDBRow>
                      <MDBCol xl="4" lg="5" md="6" sm="12">
                        <img
                          alt=""
                          className="moreBtn"
                          src={require("../../assets/images/More Button.png")}
                        ></img>
                      </MDBCol>
                      <MDBCol xl="8" lg="7" md="6" sm="12">
                        <p
                          style={{
                            color: " #fff",
                            fontWeight: "600",
                            fontSize: "22px",
                            textAlign: "center",
                            marginBottom: 0,
                          }}
                        >
                          {allStrings.moneyCapitalRation}{" "}
                        </p>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="12" sm="12">
                        <p
                          style={{
                            color: " #fff",
                            fontWeight: "600",
                            fontSize: "45px",
                            textAlign: "center",
                            marginBottom: "0",
                          }}
                        >
                          %
                          {Math.floor(this.state.count.capitalRation * 100) /
                            100}
                        </p>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                  <MDBCol xl="6" lg="6" md="6" sm="12">
                    <div>
                      <p
                        style={{
                          color: " #fff",
                          fontWeight: "600",
                          fontSize: "24px",
                          textAlign: "center",
                          width: "50%",
                          marginLeft: " 27%",
                          marginBottom: 0,
                        }}
                      >
                        {allStrings.moneyCapital}
                      </p>
                      <p
                        style={{
                          color: " #fff",
                          fontWeight: "600",
                          fontSize: "45px",
                          textAlign: "center",
                          marginBottom: "0",
                        }}
                      >
                        {this.state.count.totalCapital}
                      </p>
                    </div>
                  </MDBCol>
                </MDBRow>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol
              xl="3"
              lg="3"
              md="12"
              sm="12"
              onClick={() =>
                this.props.history.push("/calculationsInfo", {
                  data: this.state.count,
                  rightType: "part",
                  tableType: "order",
                  specialData: this.state.count.firstPaidCount,
                  type: "firstPaid",
                })
              }
            >
              <div
                style={{
                  background: "#43425d",
                  padding: "13px",
                  borderRadius: "6px",
                  minHeight: "180px",
                  marginTop: "1rem",
                  cursor: "pointer",
                }}
              >
                <div>
                  <img
                    alt=""
                    className="moreBtn"
                    src={require("../../assets/images/More Button.png")}
                  ></img>
                  <img
                    alt=""
                    className="calcImg"
                    src={require("../../assets/images/icons8_money.png")}
                  ></img>
                </div>
                <p
                  style={{
                    color: " #fff",
                    fontWeight: "600",
                    fontSize: "36px",
                    textAlign: "center",
                    marginBottom: "0",
                  }}
                >
                  {this.state.count.firstPaidCount}
                </p>
                <p
                  style={{
                    color: " #fff",
                    fontWeight: "600",
                    fontSize: "22px",
                    textAlign: "center",
                    marginBottom: "0",
                  }}
                >
                  {allStrings.firstPaids}
                </p>
              </div>
            </MDBCol>
            <MDBCol
              xl="3"
              lg="3"
              md="12"
              sm="12"
              onClick={() =>
                this.props.history.push("/calculationsInfo", {
                  data: this.state.count,
                  rightType: "part",
                  tableType: "order",
                  specialData: this.state.count.cashCount,
                  type: "cash",
                })
              }
            >
              <div
                style={{
                  background: "#43425d",
                  padding: "13px",
                  borderRadius: "6px",
                  minHeight: "180px",
                  marginTop: "1rem",
                  cursor: "pointer",
                }}
              >
                <div>
                  <img
                    alt=""
                    className="moreBtn"
                    src={require("../../assets/images/More Button.png")}
                  ></img>
                  <img
                    alt=""
                    className="calcImg"
                    src={require("../../assets/images/icons8_receive_dollar_2.png")}
                  ></img>
                </div>
                <p
                  style={{
                    color: " #fff",
                    fontWeight: "600",
                    fontSize: "36px",
                    textAlign: "center",
                    marginBottom: "0",
                  }}
                >
                  {this.state.count.cashCount}
                </p>
                <p
                  style={{
                    color: " #fff",
                    fontWeight: "600",
                    fontSize: "22px",
                    textAlign: "center",
                    marginBottom: "0",
                  }}
                >
                  {allStrings.cashAccounts}
                </p>
              </div>
            </MDBCol>
            <MDBCol
              xl="3"
              lg="3"
              md="12"
              sm="12"
              onClick={() =>
                this.props.history.push("/calculationsInfo", {
                  data: this.state.count,
                  rightType: "part",
                  tableType: "collected",
                  specialData: this.state.count.pendingPremiums,
                  type: "pendingPremiums",
                })
              }
            >
              <div
                style={{
                  background: "#43425d",
                  padding: "13px",
                  borderRadius: "6px",
                  minHeight: "180px",
                  marginTop: "1rem",
                  cursor: "pointer",
                }}
              >
                <div>
                  <img
                    alt=""
                    className="moreBtn"
                    src={require("../../assets/images/More Button.png")}
                  ></img>
                  <img
                    alt=""
                    className="calcImg"
                    src={require("../../assets/images/icons8_initiate_money_transfer.png")}
                  ></img>
                </div>
                <p
                  style={{
                    color: " #fff",
                    fontWeight: "600",
                    fontSize: "36px",
                    textAlign: "center",
                    marginBottom: "0",
                  }}
                >
                  {this.state.count.pendingPremiums}
                </p>
                <p
                  style={{
                    color: " #fff",
                    fontWeight: "600",
                    fontSize: "22px",
                    textAlign: "center",
                    marginBottom: "0",
                  }}
                >
                  {allStrings.remainCollectibles}
                </p>
              </div>
            </MDBCol>
            <MDBCol
              xl="3"
              lg="3"
              md="12"
              sm="12"
              onClick={() =>
                this.props.history.push("/calculationsInfo", {
                  data: this.state.count,
                  rightType: "part",
                  tableType: "collected",
                  specialData: this.state.count.finishedPremiums,
                  type: "finishedPremiums",
                })
              }
            >
              <div
                style={{
                  background: "#43425d",
                  padding: "13px",
                  borderRadius: "6px",
                  minHeight: "180px",
                  marginTop: "1rem",
                  cursor: "pointer",
                }}
              >
                <div>
                  <img
                    alt=""
                    className="moreBtn"
                    src={require("../../assets/images/More Button.png")}
                  ></img>
                  <img
                    alt=""
                    className="calcImg"
                    src={require("../../assets/images/icons-27.png")}
                  ></img>
                </div>
                <p
                  style={{
                    color: " #fff",
                    fontWeight: "600",
                    fontSize: "36px",
                    textAlign: "center",
                    marginBottom: "0",
                  }}
                >
                  {this.state.count.finishedPremiums}
                </p>
                <p
                  style={{
                    color: " #fff",
                    fontWeight: "600",
                    fontSize: "22px",
                    textAlign: "center",
                    marginBottom: "0",
                  }}
                >
                  {allStrings.doneCollectibles}
                </p>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol
              xl="6"
              lg="6"
              md="12"
              sm="12"
              onClick={() =>
                this.props.history.push("/calculationsInfo", {
                  data: this.state.count,
                  rightType: "part",
                  tableType: "salesMan",
                  specialData: this.state.count.finishedPremiums,
                  type: "additionCollection",
                })
              }
            >
              <div
                style={{
                  background: "#43425d",
                  padding: "13px",
                  borderRadius: "6px",
                  minHeight: "180px",
                  marginTop: "1rem",
                  cursor: "pointer",
                }}
              >
                <div>
                  <img
                    alt=""
                    className="moreBtn"
                    src={require("../../assets/images/More Button.png")}
                  ></img>
                  <img
                    alt=""
                    style={{ marginLeft: "41%" }}
                    className="calcImg"
                    src={require("../../assets/images/icons8_money_bag.png")}
                  ></img>
                </div>
                <p
                  style={{
                    color: " #fff",
                    fontWeight: "600",
                    fontSize: "36px",
                    textAlign: "center",
                    marginBottom: "0",
                  }}
                >
                  {this.state.count.additionCollect}
                </p>
                <p
                  style={{
                    color: " #fff",
                    fontWeight: "600",
                    fontSize: "22px",
                    textAlign: "center",
                    marginBottom: "0",
                  }}
                >
                  {allStrings.additionCollect}
                </p>
              </div>
            </MDBCol>
            <MDBCol
              xl="6"
              lg="6"
              md="12"
              sm="12"
              onClick={() =>
                this.props.history.push("/calculationsInfo", {
                  data: this.state.count,
                  rightType: "part",
                  tableType: "salesMan",
                  specialData: this.state.count.totalCollectedGain,
                  type: "all",
                })
              }
            >
              <div
                style={{
                  background: "#43425d",
                  padding: "13px",
                  borderRadius: "6px",
                  minHeight: "180px",
                  marginTop: "1rem",
                  cursor: "pointer",
                }}
              >
                <div>
                  <img
                    alt=""
                    className="moreBtn"
                    src={require("../../assets/images/More Button.png")}
                  ></img>
                  <img
                    alt=""
                    style={{ marginLeft: "41%" }}
                    className="calcImg"
                    src={require("../../assets/images/icons8_money_bag.png")}
                  ></img>
                </div>
                <p
                  style={{
                    color: " #fff",
                    fontWeight: "600",
                    fontSize: "36px",
                    textAlign: "center",
                    marginBottom: "0",
                  }}
                >
                  {this.state.count.totalCollectedGain}
                </p>
                <p
                  style={{
                    color: " #fff",
                    fontWeight: "600",
                    fontSize: "22px",
                    textAlign: "center",
                    marginBottom: "0",
                  }}
                >
                  التحصيل الكلي
                </p>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol
              xl="3"
              lg="3"
              md="12"
              sm="12"
              onClick={() =>
                this.props.history.push("/calculationsInfo", {
                  data: this.state.count,
                  rightType: "part",
                  tableType: "procces",
                  specialData: 0,
                  type: "transactionHistory",
                })
              }
            >
              <div
                style={{
                  background: "#43425d",
                  padding: "13px",
                  borderRadius: "6px",
                  minHeight: "180px",
                  marginTop: "1rem",
                  cursor: "pointer",
                }}
              >
                <div>
                  <img
                    alt=""
                    className="moreBtn"
                    src={require("../../assets/images/More Button.png")}
                  ></img>
                  <img
                    alt=""
                    className="calcImg"
                    src={require("../../assets/images/icons8_activity_history.png")}
                  ></img>
                </div>
                <br></br>
                <br></br>
                <p
                  style={{
                    color: " #fff",
                    fontWeight: "600",
                    fontSize: "22px",
                    textAlign: "center",
                    marginBottom: "0",
                    marginTop: "10px",
                  }}
                >
                  {allStrings.TransactionHistory}
                </p>
              </div>
            </MDBCol>
            <MDBCol
              xl="3"
              lg="3"
              md="12"
              sm="12"
              onClick={() =>
                this.props.history.push("/calculationsInfo", {
                  data: this.state.count,
                  rightType: "part",
                  tableType: "procces",
                  specialData: this.state.count.expenses,
                  type: "expenses",
                })
              }
            >
              <div
                style={{
                  background: "#43425d",
                  padding: "13px",
                  borderRadius: "6px",
                  minHeight: "180px",
                  marginTop: "1rem",
                  cursor: "pointer",
                }}
              >
                <div>
                  <img
                    alt=""
                    className="moreBtn"
                    src={require("../../assets/images/More Button.png")}
                  ></img>
                  <img
                    alt=""
                    className="calcImg"
                    src={require("../../assets/images/icons8_maintenance.png")}
                  ></img>
                </div>
                <p
                  style={{
                    color: " #fff",
                    fontWeight: "600",
                    fontSize: "36px",
                    textAlign: "center",
                    marginBottom: "0",
                  }}
                >
                  {this.state.count.expenses}
                </p>
                <p
                  style={{
                    color: " #fff",
                    fontWeight: "600",
                    fontSize: "22px",
                    textAlign: "center",
                    marginBottom: "0",
                  }}
                >
                  {allStrings.expenses}
                </p>
              </div>
            </MDBCol>
            <MDBCol
              xl="3"
              lg="3"
              md="12"
              sm="12"
              onClick={() =>
                this.props.history.push("/calculationsInfo", {
                  data: this.state.count,
                  rightType: "part",
                  tableType: "salesMan",
                  specialData: this.state.count.recieveReceivables,
                  type: "recieveReceivables",
                })
              }
            >
              <div
                style={{
                  background: "#43425d",
                  padding: "13px",
                  borderRadius: "6px",
                  minHeight: "180px",
                  marginTop: "1rem",
                  cursor: "pointer",
                }}
              >
                <div>
                  <img
                    alt=""
                    className="moreBtn"
                    src={require("../../assets/images/More Button.png")}
                  ></img>
                  <img
                    alt=""
                    className="calcImg"
                    src={require("../../assets/images/icons8_initiate_money_transfer.png")}
                  ></img>
                </div>
                <p
                  style={{
                    color: " #fff",
                    fontWeight: "600",
                    fontSize: "36px",
                    textAlign: "center",
                    marginBottom: "0",
                  }}
                >
                  {this.state.count.recieveReceivables}
                </p>
                <p
                  style={{
                    color: " #fff",
                    fontWeight: "600",
                    fontSize: "22px",
                    textAlign: "center",
                    marginBottom: "0",
                  }}
                >
                  {allStrings.saleMenPaid}
                </p>
              </div>
            </MDBCol>
            <MDBCol
              xl="3"
              lg="3"
              md="12"
              sm="12"
              onClick={() =>
                this.props.history.push("/calculationsInfo", {
                  data: this.state.count,
                  rightType: "part",
                  tableType: "salesMan",
                  specialData: this.state.count.salesMenDues,
                  type: "salesMenDues",
                })
              }
            >
              <div
                style={{
                  background: "#43425d",
                  padding: "13px",
                  borderRadius: "6px",
                  minHeight: "180px",
                  marginTop: "1rem",
                  cursor: "pointer",
                }}
              >
                <div>
                  <img
                    alt=""
                    className="moreBtn"
                    src={require("../../assets/images/More Button.png")}
                  ></img>
                  <img
                    alt=""
                    className="calcImg"
                    src={require("../../assets/images/icons-27.png")}
                  ></img>
                </div>
                <p
                  style={{
                    color: " #fff",
                    fontWeight: "600",
                    fontSize: "36px",
                    textAlign: "center",
                    marginBottom: "0",
                  }}
                >
                  {this.state.count.salesMenDues}
                </p>
                <p
                  style={{
                    color: " #fff",
                    fontWeight: "600",
                    fontSize: "22px",
                    textAlign: "center",
                    marginBottom: "0",
                  }}
                >
                  {allStrings.saleMenDues}
                </p>
              </div>
            </MDBCol>
          </MDBRow>
          {this.props.currentUser.user.type != "COMPANY" && (
            <MDBRow>
              <MDBCol
                xl="6"
                lg="6"
                md="6"
                sm="12"
                onClick={() => this.props.history.push("/ads")}
              >
                <div
                  style={{
                    background: "#43425d",
                    padding: "13px",
                    borderRadius: "6px",
                    minHeight: "180px",
                    marginTop: "1rem",
                    cursor: "pointer",
                  }}
                >
                  <div>
                    <img
                      alt=""
                      className="moreBtn"
                      src={require("../../assets/images/More Button.png")}
                    ></img>
                    <img
                      alt=""
                      className="calcImg"
                      src={require("../../assets/images/(4).png")}
                    ></img>
                  </div>
                  <p
                    style={{
                      color: " #fff",
                      fontWeight: "600",
                      fontSize: "36px",
                      textAlign: "center",
                      marginBottom: "0",
                    }}
                  >
                    {this.state.count.adsIncome}
                  </p>
                  <p
                    style={{
                      color: " #fff",
                      fontWeight: "600",
                      fontSize: "22px",
                      textAlign: "center",
                      marginBottom: "0",
                    }}
                  >
                    {allStrings.adsIncome}
                  </p>
                </div>
              </MDBCol>
              <MDBCol
                xl="6"
                lg="6"
                md="6"
                sm="12"
                onClick={() => this.props.history.push("/company")}
              >
                <div
                  style={{
                    background: "#43425d",
                    padding: "13px",
                    borderRadius: "6px",
                    minHeight: "180px",
                    marginTop: "1rem",
                    cursor: "pointer",
                  }}
                >
                  <div>
                    <img
                      alt=""
                      className="moreBtn"
                      src={require("../../assets/images/More Button.png")}
                    ></img>
                    <img
                      alt=""
                      className="calcImg"
                      src={require("../../assets/images/debt.png")}
                    ></img>
                  </div>
                  <p
                    style={{
                      color: " #fff",
                      fontWeight: "600",
                      fontSize: "36px",
                      textAlign: "center",
                      marginBottom: "0",
                    }}
                  >
                    {this.state.count.appIncomeFromCompanied}
                  </p>
                  <p
                    style={{
                      color: " #fff",
                      fontWeight: "600",
                      fontSize: "22px",
                      textAlign: "center",
                      marginBottom: "0",
                    }}
                  >
                    {allStrings.companiesIncom}
                  </p>
                </div>
              </MDBCol>
            </MDBRow>
          )}
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = (state) => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  branch: state.company.branch,
  company: state.company.company,
});

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
};

export default withRouter(
  connect(mapToStateProps, mapDispatchToProps)(Calculations)
);
