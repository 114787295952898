/* eslint-disable eqeqeq */
import React from "react";
import "./updatePartner.css";
import {MDBContainer,MDBRow,MDBCol,MDBBtn,MDBIcon,MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {setUser} from '../../redux/actions/AuthActions'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import {ChangeCompany,ChangeBranch} from '../../redux/actions/CompanyAction'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import {hasValue} from '../../validations/validations'
import {message,DatePicker,ConfigProvider} from 'antd';
import {NavLink} from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment'
import Pagination from '@material-ui/lab/Pagination';
import locale_ar from 'antd/lib/locale/ar_EG';
import locale_en from 'antd/lib/locale/en_GB';
import 'moment/locale/ar';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

let fromDate
let toDate
class updatePartner extends React.Component {
  state = {
    fullname: this.props.location.state?this.props.location.state.data.fullname:"",
    password: this.props.location.state?this.props.location.state.data.password:"",
    phone: this.props.location.state?this.props.location.state.data.phone:"",
    anotherPhone: this.props.location.state?this.props.location.state.data.anotherPhone:"",
    address: this.props.location.state?this.props.location.state.data.address:"",
    notes: this.props.location.state?this.props.location.state.data.notes:"",
    cardNum: this.props.location.state?this.props.location.state.data.cardNum:"",

    profileImg:this.props.location.state?this.props.location.state.data.img !=""? this.props.location.state.data.img:null:null,
    cardImg:this.props.location.state?this.props.location.state.data.cardImg.length > 0?this.props.location.state.data.cardImg:null:null,
    cardImgViews:this.props.location.state?this.props.location.state.data.cardImg:[],
    profileImgType:this.props.location.state?'url':'data',
    cardImgType:this.props.location.state?'url':'data',

    ratio:this.props.location.state?this.props.location.state.data.capitalRation:0,
    money:this.props.location.state?this.props.location.state.data.capital:'',
    partner:this.props.location.state?this.props.location.state.data:[],
    requestType:"put",
    loading:true,
    loading2:true,
    count:[],
    refresh:false,
    page:1,
    pages:1,
    filterType:'',
    total:'',
    from:this.props.isRTL?moment().startOf('month').format("YYYY/MM/DD"):moment().startOf('month').locale('en').format("YYYY/MM/DD"),
    to:this.props.isRTL?moment().endOf('month').format("YYYY/MM/DD"):moment().endOf('month').locale('en').format("YYYY/MM/DD"),
  
    tableData:[],
    tableType:'',
    num:'',
    totalCapital:0,
    photoIndex: 0,
    isOpen1: false,
    isOpen2: false,
  };

  constructor(props){
    super(props)
    this.getDuration()
    this.getTotalCapital()
    console.log("isr   ",this.props.history)
    window.scrollTo(0,0)
    this.getCounts()
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
      moment.locale('ar');
    }else{
      allStrings.setLanguage('en')
      moment.locale('en');
    } 
   
  }
  getTotalCapital = () => {
    //${this.props.currentUser.user.id}
    let url =``
    if(this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN"){
      if(this.props.company == false ){
        url = `${BASE_END_POINT}${this.props.currentUser.user.company[0].id}/companyAccounting`
      }else{
        url = `${BASE_END_POINT}${this.props.company}/companyAccounting`
      }
      
    } else{
      url = `${BASE_END_POINT}${this.props.currentUser.user.id}/companyAccounting`
    }
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log(response.data)
      this.setState({totalCapital:response.data.totalCapital})
    })
    .catch(error=>{
      console.log("ALL count ERROR")
      console.log(error.response)
    })
  }
  getDuration = () =>{
    //first day
    let date = new Date()
    let newDate = date.toISOString().slice(0, 8)  
    let firstDay = newDate + '01';
    //get days in month
    function daysInMonth (month, year) {
        return new Date(year, month , 0).getDate();
    }

    let DaysInCurrentMonth = daysInMonth(date.getMonth() + 1,date.getFullYear()); //number of day in month
    //last day
    let lastDay= newDate + DaysInCurrentMonth;
    console.log(firstDay)
    console.log(lastDay)
    if(this.props.isRTL){
      this.setState({from:moment(firstDay).format("YYYY/MM/DD") ,to:moment(lastDay).format("YYYY/MM/DD") })
    }else{
      this.setState({from:moment(firstDay).locale('en').format("YYYY/MM/DD") ,to:moment(lastDay).locale('en').format("YYYY/MM/DD") })

    }

    fromDate = firstDay
      toDate = lastDay
    
  }
  componentDidMount(){
    this.getDuration()
  }
  getCounts = () =>{
    this.setState({loading:true})
    axios.get(`${BASE_END_POINT}${this.state.partner.id}/partnerAccounting`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("count   ",response.data)
      this.setState({loading:false,count:response.data})
    })
    .catch(error=>{
      console.log("error   ",error.response)
      this.setState({loading:false,})
    })

  }
  
  
  updatepartner = () => {
    const {fullname,password,phone,anotherPhone,profileImg,
           cardNum,address,notes,cardImg} = this.state
   
    if(hasValue(fullname)&&hasValue(phone)&&hasValue(cardNum)
      ){
        let l = message.loading('Wait..', 2.5)
        var data = new FormData()
        data.append('fullname',fullname)
        if(cardImg != null){
          //if(cardImgType == 'data'){
            for(var i=0 ; i<= cardImg.length-1 ; i++){
               data.append('cardImg',cardImg[i]); 
            }
          //}
        }
       
        
        if(password != ""){
          data.append('password',password)
        }
        if(cardNum != ""){
          data.append('cardNum',cardNum)
        }
        
        data.append('type','SALES-MAN')
        if(phone != ""){
          data.append('phone',phone); 
        }
        //if(this.state.profileImgType == 'data'){
          if(profileImg != null){
            data.append('img',profileImg); 
          }
        //}
         
        if(anotherPhone !==""){
          data.append('anotherPhone',anotherPhone); 
        }
        if(notes !==""){
          data.append('notes',notes); 
        }
        if(address !==""){
          data.append('address',address); 
        }
        //////
        
        console.log(Array.from(data))
        
        axios.put(`${BASE_END_POINT}user/${this.state.partner.id}/updateInfo`,data,{
        headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization':`Bearer ${this.props.currentUser.token}`
        },
        })
        .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.push('/partners')
        })
        .catch(error=>{
            console.log(error.response)
            if(error.response.data.errors[0].msg =="cardNum duplicated"){
              l.then(() => message.error(allStrings.duplicatedCardNum, 2.5))
            }
            if(error.response.data.errors[0].msg =="phone duplicated"){
              l.then(() => message.error(allStrings.duplicatedPhone, 2.5))
            }
        })
    }
  }
  
  getExpenses = (page,refresh) =>{
    this.setState({loading:true})
    let url=``;
    if(this.props.currentUser.user.type=="ADMIN"){
      url = `${BASE_END_POINT}expenses?page=${page}&start=${fromDate}&end=${toDate}`
    } else{
      url=`${BASE_END_POINT}expenses?company=${this.state.partner.company.id}&page=${page}&start=${fromDate}&end=${toDate}`
    }
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("expenses   ",response.data)
      this.setState({
        loading:false,
        refresh:false,
        tableData:response.data.data,
        page:response.data.page,
        pages:response.data.pageCount,
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })

  }
  getPendingCollect = (page,refresh) =>{
    this.setState({loading:true,page:1})
    let url=``;
    if(this.props.currentUser.user.type=="ADMIN"){
      url = `${BASE_END_POINT}premiums?status=PENDING&page=${page}&start=${fromDate}&end=${toDate}`
    } else{
      url=`${BASE_END_POINT}premiums?company=${this.state.partner.company.id}&status=PENDING&page=${page}&start=${fromDate}&end=${toDate}`
    }  
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("pendingCollect   ",response.data)
      this.setState({
        loading:false,
        refresh:false,
        tableData:response.data.data,
        page:response.data.page,
        pages:response.data.pageCount,
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false})
    })

  }
  getFinishCollect = (page,refresh) =>{
    this.setState({loading:true,page:1})
    let url=``;
    if(this.props.currentUser.user.type=="ADMIN"){
      url = `${BASE_END_POINT}premiums?status=FINISHED&page=${page}&start=${fromDate}&end=${toDate}`
    } else{
      url=`${BASE_END_POINT}premiums?company=${this.state.partner.company.id}&status=FINISHED&page=${page}&start=${fromDate}&end=${toDate}`
    }
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("finishCollect   ",response.data)
      this.setState({
        loading:false,
        refresh:false,
        tableData:response.data.data,
        page:response.data.page,
        pages:response.data.pageCount,
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false})
    })

  }
  getTransactions = (page,refresh) =>{
    this.setState({loading:true,page:1})
    let url=``
      url=`${BASE_END_POINT}transactions?user=${this.state.partner.id}&page=${page}&start=${fromDate}&end=${toDate}`
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log(" data   ",response.data)
      this.setState({
        loading:false,
        refresh:false,
        tableData:response.data.data,
        page:response.data.page,
        pages:response.data.pageCount,
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false})
    })

  }
  fileHandlerProfileImg = (event) => {
    this.setState({
      profileImg: event.target.files[0],
      profileImgType:'data'
    })
    
  }
  removeProfileImg = () => {
    this.setState({
      profileImg:null
    });
  }

  //card img
  fileHandlerCardImg = (event) => {
    let imgs =[];
    for(var i=0 ; i<= event.target.files.length-1 ; i++){
      imgs.push(URL.createObjectURL(event.target.files[i]))
    }
    console.log(imgs)
    this.setState({
      cardImg: event.target.files,
      cardImgViews:imgs,
      cardImgType:'data'
    })
    
  }
  removeCardImg = (url) => {
    let arr = this.state.cardImgViews;
    let arr2 =  Array.from(this.state.cardImg);
    console.log(arr2)
    var index = arr.indexOf(url);
    if (index !== -1) arr.splice(index, 1);
    if (index !== -1) arr2.splice(index, 1);
    console.log(arr2)
    this.setState({
      cardImgViews:arr,
      cardImg:arr2,
    });
    if(arr2.length == 0){
      this.setState({
        cardImg:null,
      });
    }
  };


  changeHandler = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };

  updateSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.updatepartner()

  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  }
  changeMoney = (event) => {
    let ratio = (event.target.value * 100) /  this.state.totalCapital;
    console.log(ratio)
    this.setState({ [event.target.name]: event.target.value,ratio:ratio });
    
  }
  onChangeFrom = (date,dateString) => { 
    console.log(dateString) 
    if(this.props.isRTL){
      this.setState({from:moment(dateString).format("YYYY/MM/DD")})
    }else{
      this.setState({from:moment(dateString).locale('en').format("YYYY/MM/DD") })
    }
    fromDate = moment(dateString).locale('en').format("YYYY-MM-DD")
  }
  onChangeTo = (date,dateString) => {
    if(this.props.isRTL){
      this.setState({to:moment(dateString).format("YYYY/MM/DD") })
    }else{
      this.setState({to:moment(dateString).locale('en').format("YYYY/MM/DD") })

    }
    toDate = moment(dateString).locale('en').format("YYYY-MM-DD")
    if(this.state.filterType == 'withdrawable'){
      this.getWithdrawable(1,false)
    }
    if(this.state.filterType == 'pendingPremiums'){
      this.getPendingCollect(1,false)
    }
    if(this.state.filterType == 'finishedPremiums'){
      this.getFinishCollect(1,false)
    }
    if(this.state.filterType == 'transactionHistory'){
      this.getTransactions(1,false)
    }
    if(this.state.filterType == 'expenses'){
      this.getExpenses(1,false)
    }
  }
  /*chooseWithdrawable = (value) => {
    this.setState({filterType:'withdrawable',title:allStrings.allPremiums,tableType:'withdrawable',modal:true,num:value})
    this.getWithdrawable(1,false)
  }*/
  choosePendingPremiums = (value) => {
    this.getDuration()
    this.setState({filterType:'pendingPremiums',title:allStrings.remainCollectibles,tableType:'collected',modal:true,num:value})
    this.getPendingCollect(1,false)

  }
  chooseFinishedPremiums = (value) => {
    this.getDuration()
    this.setState({filterType:'finishedPremiums',title:allStrings.doneCollectibles,tableType:'collected',modal:true,num:value})
    this.getFinishCollect(1,false)
  }
  chooseTransactions = () => {
    this.getDuration()
    this.setState({filterType:'transactionHistory',title:allStrings.TransactionHistory,tableType:'process',modal:true,num:''})
    this.getTransactions(1,false)
  }
  chooseExpenses = (value) => {
    this.getDuration()
    this.setState({filterType:'expenses',title:allStrings.expenses,tableType:'process',modal:true,num:value})
    this.getExpenses(1,false)
  }
 
  modal = () =>(
    <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
      <MDBModalHeader toggle={this.toggle}>{this.state.title}</MDBModalHeader>
      <MDBModalBody>
        <MDBContainer>
          <MDBRow>
            <MDBCol md="4" sm="4">
              <p style={{fontSize: '66px',fontWeight: '700',color: '#aaa'}}>{this.state.num}</p>
            </MDBCol>
            <MDBCol md="5" sm="5">
           
              <div style={{paddingtop: '15px'}}>
                <div style={{marginBottom:'10px',textAlign:'center'}}>
                  <span className="fromSpan" style={{float:this.props.isRTL? 'right':'left'}}>{allStrings.from}</span>
                  <div style={{display:'inline-block',textAlign:'center',marginLeft:this.props.isRTL? '35px':"-93px"}}>
                  <ConfigProvider locale={this.props.isRTL?locale_ar:locale_en}>
                  <DatePicker  defaultValue={moment(this.state.from, 'YYYY/MM/DD')}  onChange={this.onChangeFrom}/>
                  </ConfigProvider>
                  <span className="newSpan"><img alt=""  style={{marginRight:'4px'}} src={require('../../assets/images/icons8_edit_property.png')}></img>{this.state.from}</span>
                  </div>
                </div>
                <div style={{marginBottom:'10px',textAlign:'center'}}>
                  <span className="fromSpan" style={{float:this.props.isRTL? 'right':'left'}}>{allStrings.to}</span>
                  <div style={{display:'inline-block',textAlign:'center',marginLeft: this.props.isRTL? '35px':"-93px"}}>
                  <ConfigProvider locale={this.props.isRTL?locale_ar:locale_en}>
                  <DatePicker  defaultValue={moment(this.state.to, 'YYYY/MM/DD')}  onChange={this.onChangeTo}/>
                  </ConfigProvider>
                  <span className="newSpan"><img alt=""  style={{marginRight:'4px'}} src={require('../../assets/images/icons8_edit_property.png')}></img>{this.state.to}</span>
                  </div>
                </div>
              </div>
            </MDBCol>
            <MDBCol md="3" sm="3">
              <div style={{textAlign: 'center',paddingtop: '15px'}}>
                <img alt=""  width="50px" src={require('../../assets/images/icons8_calculate.png')}/>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="12">
            <table class="table table-borderless">
                <thead class="thead-light">
                {this.state.tableType == "withdrawable" &&
                 <tr>
                 <th scope="col">{allStrings.cost}</th>
                 <th scope="col">{allStrings.description}</th>
               </tr>
               }
                {this.state.tableType == "collected" &&
                 <tr>
                 <th scope="col">{allStrings.date}</th>
                 <th scope="col">{allStrings.cost}</th>
                 <th scope="col">{allStrings.theCollectable}</th>
                 <th scope="col">{allStrings.client}</th>
                 <th scope="col">{allStrings.product}</th>
               </tr>
                }
               {this.state.tableType == "procces" &&
                 <tr>
                 <th scope="col">{allStrings.date}</th>
                 <th scope="col">{allStrings.procces}</th>
                 <th scope="col">{allStrings.cost}</th>
                 </tr>
               }
               
                 
                </thead>
                <tbody>
                {this.state.loading?
                <tr style={{height: '200px'}}>
                    <th colspan={this.state.tableType == "collected" ?"5":"4"} className="noData">
                    <Skeleton height={400}/> 
                    </th>
                </tr>
                :
                this.state.tableData.length > 0 ?
                this.state.tableData.map(val =>(
                  this.state.tableType == "withdrawable" ?
                  <tr>
                    <td>{val.cost?val.cost:'--'}</td>
                    <td>{val.description}</td>
                  </tr>
                :
                this.state.tableType == "collected" ?
                  <tr>
                    <td>{val.paidDate.substring(0, 10)}</td>
                    <td>{val.costPerMonth?val.costPerMonth:'--'}</td>
                    <td>{val.salesMan.fullname}</td>
                    <td>{val.client.fullname}</td>
                    <td>{val.product?this.props.isRTL?val.product.name_ar:val.product.name_en:''}</td>
                  </tr>
                :
                this.state.tableType == "procces" ?
                  <tr>
                    <td>{val.createdAt.substring(0, 10)}</td>
                    <td>{ this.state.type == "expenses"?val.description:val.description_en?this.props.isRTL?val.description_ar:val.description_en:'بدون نص'}</td>
                    <td>{val.cost?val.cost:'--'}</td>
                  </tr>
                :
                <tr></tr>
                ))
               
                  :
                  <tr style={{height: '200px'}}>
                      <th colspan={this.state.tableType == "collected" ?"5":"4"} className="noData">
                       <p style={{marginTop:'4rem'}}>{allStrings.noData}</p> 
                      </th>
                  </tr>
                }
                <th colspan={this.state.tableType == "collected" ?"5":"4"}>
                <MDBRow>
                  {this.state.tableData.length > 0 &&
                    <div style={{textAlign:'center',margin:'auto',marginTop:'3rem'}}>
                      <Pagination
                        onChange={(event,page)=>{
                        console.log("page   ",page)
                        if(page!=this.state.page){
                          if(this.state.filterType == 'withdrawable'){
                            this.getWithdrawable(page,false)
                          }
                          if(this.state.filterType == 'pendingPremiums'){
                            this.getPendingCollect(page,false)
                          }
                          if(this.state.filterType == 'finishedPremiums'){
                            this.getFinishCollect(page,false)
                          }
                          if(this.state.filterType == 'transactionHistory'){
                            this.getTransactions(page,false)
                          }
                          if(this.state.filterType == 'expenses'){
                            this.getExpenses(page,false)
                          }
                         
                          this.setState({page:page})
                        }
                        
                        }} 
                        defaultPage={1} page={this.state.page}  count={this.state.pages} style={{color:`blue !important`}} />
                    </div>
                  }
                  
                </MDBRow>
                </th>
                </tbody>
              </table>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBModalBody>
    </MDBModal>
   );

  render() {
    let {cardImg,profileImg, photoIndex, isOpen1,isOpen2} = this.state;
     profileImg = [profileImg]
    console.log(this.state.title)
    console.log(this.state.tableType)
    return (
      <div className="stockForm">
         {this.modal()}
        <MDBContainer>
          <MDBRow>
            <div className="top" style={{width:'100%',textAlign:'right'}}>
              <NavLink to="/partners">
                <MDBIcon icon="times" className="closeIcon"/>
              </NavLink>
              <span className="newTitle" style={{margin: '1rem 0rem 1rem .5rem'}}>{this.state.partner.lastSeen?moment().calendar(this.state.partner.lastSeen):moment().calendar()}</span>
              <span className="newTitle">
                {allStrings.lastSeen}
              </span>
            </div>
          </MDBRow>
          <form
              className="needs-validation"
              onSubmit={this.updateSubmitHandler}
              noValidate
              style={{marginLeft:'2rem'}}
          >
          <MDBRow>
            <MDBContainer>
              <MDBRow>
                <MDBCol lg="6" md="12" style={{paddingRight:'20px'}}>
                 <MDBRow>
                    <MDBCol md ="12" sm="12" style={{padding:'5px',paddingTop:0}}>
                      <div style={{background: '#4f73AC',padding: '13px', borderRadius: '4px' ,marginTop: '0px',cursor:'pointer', minHeight:'140px'}}>
                        <MDBRow>
                          <MDBCol xl="6" lg="6" md="6" sm="6">
                          <MDBRow>
                            <MDBCol md="12" sm="12">
                            <p style={{color: 'rgb(255, 255, 255)',fontWeight: '600',fontSize: '24px',textAlign: 'center',
                              display: 'block'}}>{allStrings.moneyCapitalRation} </p>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol md="12" sm="12">
                            <p style={{color:' #fff',fontWeight: '600',fontSize: '40px',textAlign: 'center',marginBottom: '0',
                               }}>%{ Math.ceil(this.state.count.capitalRation * 100)/100 }</p>
                            </MDBCol>
                          </MDBRow>
                          </MDBCol>
                          <MDBCol xl="6" lg="6" md="6" sm="6" >
                            <div>
                              <p style={{color: 'rgb(255, 255, 255)',fontWeight: '600',fontSize: '24px',textAlign: 'center',
                              display: 'block'}}>{allStrings.partnerShipCapital}</p>
                              <p style={{color: 'rgb(255, 255, 255)',fontWeight: '600',fontSize: '40px',textAlign: 'center',
                              }}>{this.state.count.totalPartnerShip}</p>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </div>
                    </MDBCol>
                 </MDBRow>
                 <MDBRow>
                    <MDBCol xl ="6" lg ="12" sm="12" style={{padding:'5px'}}>
                      <div style={{background: 'darkred',padding: '13px', borderRadius: '4px' ,marginTop: '0px',cursor:'pointer', minHeight:'140px'}}>
                      <MDBRow>
                        <MDBCol xl="6" lg ="12" sm="6" style={{paddingTop:'14px'}}>
                        <p style={{marginTop:'35%',color:' #fff',fontWeight: '500',fontSize: '20px',textAlign: 'center', marginBottom: '5px',
                          }}>{Math.ceil(this.state.count.outstandingBalance * 100)/100}</p>
                          <p style={{color:' #fff',fontWeight: '600',fontSize: '15px',textAlign: 'center',marginBottom: '0px',
                          }}>{allStrings.outstandingBalance}</p>
                        </MDBCol>
                        <MDBCol xl ="6" lg ="12" sm="6" style={{paddingTop:'14px'}}>
                          <div style={{textAlign:'center'}}>
                            <img alt=""  className="calcImg2" src={require('../../assets/images/icons8_money.png')}></img>
                          </div>
                        </MDBCol>
                      </MDBRow>
                      </div>
                    </MDBCol>
                    <MDBCol xl ="6" lg ="12" sm="12" style={{padding:'5px'}}>
                      <div 
                        style={{background: 'darkgreen',padding: '13px', borderRadius: '4px' ,marginTop: '0px',cursor:'pointer', minHeight:'140px'}}>
                      <MDBRow>
                        <MDBCol xl="6" lg ="12" sm="6" style={{paddingTop:'14px'}}>
                        <img alt=""  className="moreBtn2" src={require('../../assets/images/More Button.png')}></img>
                        <p style={{color:' #fff',fontWeight: '500',fontSize: '20px',textAlign: 'center', marginBottom: '5px',
                          }}>{Math.ceil(this.state.count.withdrawableBalance * 100)/100}</p>
                          <p style={{color:' #fff',fontWeight: '600',fontSize: '15px',textAlign: 'center',marginBottom: '0px',
                          }}>{allStrings.withdrawableBalance}</p>
                        </MDBCol>
                        <MDBCol xl ="6" lg ="12" sm="6" style={{paddingTop:'14px'}}>
                          <div style={{textAlign:'center'}}>
                            <img alt=""  className="calcImg2" src={require('../../assets/images/icons8_receive_dollar_2.png')}></img>
                          </div>
                        </MDBCol>
                      </MDBRow>
                      </div>
                    </MDBCol>
                 </MDBRow>
                 <MDBRow>
                    <MDBCol md ="12" sm="12" style={{padding:'5px'}}>
                      <div style={{background: '#4f73AC',padding: '13px', borderRadius: '4px' ,marginTop: '0px',cursor:'pointer', minHeight:'140px'}}>
                        <MDBRow>
                          <MDBCol xl="6" lg="6" md="6" sm="6">
                          <MDBRow>
                            <MDBCol md="12" sm="12">
                            <p style={{color: 'rgb(255, 255, 255)',fontWeight: '600',fontSize: '40px',textAlign: 'center',
                              display: 'block',paddingTop:'14%'}}>{this.state.count.totalGain} </p>
                            </MDBCol>
                          </MDBRow>
                          </MDBCol>
                          <MDBCol xl="6" lg="6" md="6" sm="6" >
                            <div>
                              <div style={{textAlign:'center',marginTop:'10px'}}>
                                <img alt=""  className="calcImg2" src={require('../../assets/images/icons8_money_bag_480px.png')}></img>
                              </div>
                              <p style={{color: 'rgb(255, 255, 255)',fontWeight: '600',fontSize: '15px',textAlign: 'center',
                              display: 'block'}}>{allStrings.totalGain}</p>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </div>
                    </MDBCol>
                 </MDBRow>
                 <MDBRow>
                   <MDBCol xl ="6" lg ="12" sm="12" style={{padding:'5px'}}>
                      <div 
                      onClick={()=>this.choosePendingPremiums(Math.ceil(this.state.count.pendingPremiums * 100)/100)}
                      style={{background: '#4f73AC',padding: '13px', borderRadius: '4px' ,marginTop: '0px',cursor:'pointer', minHeight:'140px'}}>
                      <MDBRow>
                        <MDBCol xl="6" lg ="12" sm="6" style={{paddingTop:'14px'}}>
                        <img alt=""  className="moreBtn2" src={require('../../assets/images/More Button.png')}></img>
                        <p style={{color:' #fff',fontWeight: '500',fontSize: '20px',textAlign: 'center', marginBottom: '5px',
                          }}>{Math.ceil(this.state.count.pendingPremiums * 100)/100}</p>
                          <p style={{color:' #fff',fontWeight: '600',fontSize: '15px',textAlign: 'center',marginBottom: '0px',
                          }}>{allStrings.remainCollectibles}</p>
                        </MDBCol>
                        <MDBCol xl ="6" lg ="12" sm="6" style={{paddingTop:'14px'}}>
                          <div style={{textAlign:'center'}}>
                            <img alt=""  className="calcImg2" src={require('../../assets/images/icons8_initiate_money_transfer.png')}></img>
                          </div>
                        </MDBCol>
                      </MDBRow>
                      </div>
                    </MDBCol>
                    <MDBCol xl ="6" lg ="12" sm="12" style={{padding:'5px'}}>
                      <div onClick={()=>this.chooseFinishedPremiums(Math.ceil(this.state.count.finishedPremiums * 100)/100)}
                      style={{background: '#4f73AC',padding: '13px', borderRadius: '4px' ,marginTop: '0px',cursor:'pointer', minHeight:'140px'}}>
                      <MDBRow>
                        <MDBCol xl="6" lg ="12" sm="6" style={{paddingTop:'14px'}}>
                        <img alt=""  className="moreBtn2" src={require('../../assets/images/More Button.png')}></img>
                        <p style={{color:' #fff',fontWeight: '500',fontSize: '20px',textAlign: 'center', marginBottom: '5px',
                          }}>{Math.ceil(this.state.count.finishedPremiums * 100)/100}</p>
                          <p style={{color:' #fff',fontWeight: '600',fontSize: '15px',textAlign: 'center',marginBottom: '0px',
                          }}>{allStrings.doneCollectibles}</p>
                        </MDBCol>
                        <MDBCol xl ="6" lg ="12" sm="6" style={{paddingTop:'14px'}}>
                          <div style={{textAlign:'center'}}>
                            <img alt=""  className="calcImg2" src={require('../../assets/images/icons-27.png')}></img>
                          </div>
                        </MDBCol>
                      </MDBRow>
                      </div>
                    </MDBCol>
                </MDBRow>
                 <MDBRow>
                   <MDBCol xl ="6" lg ="12" sm="12" style={{padding:'5px'}}>
                      <div 
                      onClick={()=>this.chooseTransactions()}
                      style={{background: '#4f73AC',padding: '13px', borderRadius: '4px' ,marginTop: '0px',cursor:'pointer', minHeight:'140px'}}>
                      <MDBRow>
                        <MDBCol xl="6" lg ="12" sm="6" style={{paddingTop:'14px'}}>
                        <img alt=""  className="moreBtn2" src={require('../../assets/images/More Button.png')}></img>
                          <p style={{marginTop: '33%',color:' #fff',fontWeight: '600',fontSize: '15px',textAlign: 'center',marginBottom: '0px',
                          }}>{allStrings.TransactionHistory}</p>
                        </MDBCol>
                        <MDBCol xl ="6" lg ="12" sm="6" style={{paddingTop:'14px'}}>
                          <div style={{textAlign:'center'}}>
                            <img alt=""  className="calcImg2" src={require('../../assets/images/icons8_activity_history.png')}></img>
                          </div>
                        </MDBCol>
                      </MDBRow>
                      </div>
                    </MDBCol>
                    <MDBCol xl ="6" lg ="12" sm="12" style={{padding:'5px'}}>
                      <div 
                      onClick={()=>this.chooseExpenses(Math.ceil(this.state.count.expenses * 100)/100)}
                      style={{background: '#4f73AC',padding: '13px', borderRadius: '4px' ,marginTop: '0px',cursor:'pointer', minHeight:'140px'}}>
                      <MDBRow>
                        <MDBCol xl="6" lg ="12" sm="6" style={{paddingTop:'14px'}}>
                        <img alt=""  className="moreBtn2" src={require('../../assets/images/More Button.png')}></img>
                        <p style={{color:' #fff',fontWeight: '500',fontSize: '20px',textAlign: 'center', marginBottom: '5px',
                          }}>{Math.ceil(this.state.count.expenses * 100)/100}</p>
                          <p style={{color:' #fff',fontWeight: '600',fontSize: '15px',textAlign: 'center',marginBottom: '0px',
                          }}>{allStrings.expenses}</p>
                        </MDBCol>
                        <MDBCol xl ="6" lg ="12" sm="6" style={{paddingTop:'14px'}}>
                          <div style={{textAlign:'center'}}>
                            <img alt=""  className="calcImg2" src={require('../../assets/images/icons8_maintenance.png')}></img>
                          </div>
                        </MDBCol>
                      </MDBRow>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBBtn type="submit" 
                    style={{ margin: '1rem',width:'150px',padding:'9px',height:'35px',marginBottom: '80px',marginTop: '80px' }}
                        className="btn z-depth-1a "
                        color="primary"
                      >
                        {allStrings.edit}
                    </MDBBtn>
                  </MDBRow>
                </MDBCol>
                <MDBCol lg="6" md="12" style={{borderLeft:'1px solid'}}>
                  <MDBRow>
                    <MDBCol md="9" sm="9">
                      <input name="fullname" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx5" required
                        className="form-control" placeholder={allStrings.enterName} 
                        style={{direction:this.props.isRTL?'rtl':'ltr',marginBottom: '5px'}} defaultValue={this.state.partner.fullname} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx5" className="grey-text formLabel">
                      {allStrings.name}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="9" sm="9">
                      <input name="password" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx5" 
                        className="form-control" placeholder={allStrings.enterPassword} 
                        style={{direction:this.props.isRTL?'rtl':'ltr',marginBottom: '5px'}} defaultValue={this.state.partner.password} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx5" className="grey-text formLabel">
                      {allStrings.password}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="9" sm="9">
                    {this.state.profileImg == null&&
                      <div className="smallUploadContainer">
                        <img alt=""  name="img" className="smallUploderImg" src={require('../../assets/images/Path 343.png')}></img>
                        <input 
                          onChange={this.fileHandlerProfileImg}
                          type="file"
                          id="defaultFormRegisterNameEx6"
                          required className="smallUploader" />
                        <MDBBtn color="primary" size="sm" className="smallUploaderBtn">{allStrings.chooseFile}</MDBBtn>
                        <p style={{fontSize: '14px',marginTop: '3px 15px',float:'right',paddingTop: '4px'}}  className="grey-text">
                          {allStrings.dropHere}
                        </p>
                      </div>
                    }
                      <div className="previewRaduis">
                      {this.state.profileImg!= null&&
                      <MDBRow>
                        
                        <MDBCol md = "3">
                        <div style={{paddingTop:'5px',cursor:'pointer'}}  onClick={() => this.setState({ isOpen2: true })}>
                          <span style={{
                            fontSize: '17px',
                            color: '#478DFF',
                            fontWeight: '600',
                            margin: '5px'
                          }}>{allStrings.showD}</span>
                          <img alt=""  className="showImge" width="20px" src={require('../../assets/images/icons8_external_link_480px_2.png')}></img>
                        </div>
                        </MDBCol>
                        <MDBCol md="9" style={{paddingRight:'30px'}}  >
                          {this.state.profileImg!= null&&
                            <div style={{display:"inline-block",float:'right',marginBottom:'1rem'}}>
                                <img alt=""    src={this.state.profileImgType=="data"?URL.createObjectURL(this.state.profileImg):this.state.profileImg} />
                                <MDBIcon icon="times" className="mr-2 removeImg" onClick={()=> this.removeProfileImg()}></MDBIcon>
                            </div>
                          }
                        </MDBCol>
                      </MDBRow>
                      }
                      {isOpen2 && (
                      <Lightbox
                        mainSrc={profileImg[photoIndex]}
                        nextSrc={profileImg[(photoIndex + 1) % profileImg.length]}
                        prevSrc={profileImg[(photoIndex + profileImg.length - 1) % profileImg.length]}
                        onCloseRequest={() => this.setState({ isOpen2: false,photoIndex:0  })}
                        onMovePrevRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + profileImg.length - 1) % profileImg.length,
                          })
                        }
                        onMoveNextRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + 1) % profileImg.length,
                          })
                        }
                      />
                    )}
                    </div>
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx7" className="grey-text formLabel">
                      {allStrings.profileImg}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="9" sm="9">
                    {this.state.cardImg == null&&
                      <div className="smallUploadContainer">
                        <img alt=""  name="img" className="smallUploderImg" src={require('../../assets/images/Path 343.png')}></img>
                        <input 
                          multiple
                          onChange={this.fileHandlerCardImg}
                          type="file"
                          id="defaultFormRegisterNameE03"
                           className="smallUploader" />
                        <MDBBtn color="primary" size="sm" className="smallUploaderBtn">{allStrings.chooseFile}</MDBBtn>
                        <p style={{fontSize: '14px',marginTop: '3px 15px',float:'right',paddingTop: '4px'}}  className="grey-text">
                          {allStrings.dropHere}
                        </p>
                      </div>
                    }
                     
                    {this.state.cardImg!= null&&
                      <div className="previewRaduis">
                      <MDBRow>
                        <MDBCol md = "3">
                        <div style={{paddingTop:'5px',cursor:'pointer'}}  onClick={() => this.setState({ isOpen1: true })}>
                          <span style={{
                            fontSize: '17px',
                            color: '#478DFF',
                            fontWeight: '600',
                            margin: '5px'
                          }}>{allStrings.showD}</span>
                          <img alt=""  className="showImge" width="20px" src={require('../../assets/images/icons8_external_link_480px_2.png')}></img>
                        </div>
                        </MDBCol>
                        <MDBCol md="9" style={{paddingRight:'30px'}}  >
                         {this.state.cardImgViews.map((val) => (
                          <div style={{display:"inline-block",float:'right',marginBottom:'1rem'}}>
                              <img alt=""    src={this.state.cardImgType=="data"?val:val} />
                              <MDBIcon icon="times" className="mr-2 removeImg" onClick={()=> this.removeCardImg(val)}></MDBIcon>
                          </div>
                          ))}
                        </MDBCol>
                      </MDBRow>
                    {isOpen1 && (
                      <Lightbox
                        mainSrc={cardImg[photoIndex]}
                        nextSrc={cardImg[(photoIndex + 1) % cardImg.length]}
                        prevSrc={cardImg[(photoIndex + cardImg.length - 1) % cardImg.length]}
                        onCloseRequest={() => this.setState({ isOpen1: false,photoIndex:0  })}
                        onMovePrevRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + cardImg.length - 1) % cardImg.length,
                          })
                        }
                        onMoveNextRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + 1) % cardImg.length,
                          })
                        }
                      />
                    )}
                    </div>
                    }

                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx003" className="grey-text formLabel">
                      {allStrings.cardImg}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input name="cardNum" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx12" 
                        className="form-control" placeholder={allStrings.enterCardNum} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.partner.cardNum} required/>
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                      {allStrings.cardNum}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input name="address" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx13" 
                        className="form-control" placeholder={allStrings.enterAddress} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.partner.address} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx13" className="grey-text formLabel">
                      {allStrings.address}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input name="phone" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx15" 
                        className="form-control" placeholder={allStrings.enterPhone} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.partner.phone} required />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx15" className="grey-text formLabel">
                      {allStrings.phone}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input name="anotherPhone" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx16" 
                        className="form-control" placeholder={allStrings.EnterAnotherPhone} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.partner.anotherPhone} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx16" className="grey-text formLabel">
                      {allStrings.anotherPhone}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input name="money" onChange={this.changeMoney} type="number" min={0} id="defaultFormRegisterNameEx17" 
                        className="form-control" placeholder={allStrings.EnterPrice} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.money}/>
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx17" className="grey-text formLabel">
                      {allStrings.receiveMoney}
                      </label> 
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input disabled name="ration" type="number" min={0} id="defaultFormRegisterNam17" 
                        className="form-control"  style={{color:'red',border:'none',direction:this.props.isRTL?'rtl':'ltr'}} value ={Math.ceil(this.state.ratio * 100)/100}/>
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx17" className="grey-text formLabel">
                      {allStrings.ratio}
                      </label> 
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol lg="4" md="12" >
                    </MDBCol>
                    <MDBCol lg="4" md="12">
                      <MDBBtn  
                        style={{ margin: '1rem auto',padding:'11px 20px',height:'35px',width:'98%',fontSize: '12px',
                        fontWeight: '600',background:'#4F73AC',color:'#fff' }}
                        className="btn z-depth-1a "
                        color='#4F73AC'
                      >
                          {allStrings.printContract}
                      </MDBBtn>
                    </MDBCol>
                    <MDBCol lg="4" md="12">
                     
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                   
                    <MDBCol xl="4" lg="4" md="12"  >
                      <MDBBtn 
                        style={{ margin: '1rem auto',padding:'11px 20px',height:'35px',width:'98%',fontSize: '12px',
                        fontWeight: '600',background:'#4F73AC',color:'#fff' }}
                        className="btn z-depth-1a "
                        color='#4F73AC'
                      >
                          {allStrings.sendNotif}
                      </MDBBtn>
                    </MDBCol>
                    <MDBCol xl="4" lg="4" md="12"  >
                      <MDBBtn 
                        style={{ margin: '1rem auto',padding:'11px 20px',height:'35px',width:'98%',fontSize: '12px',
                        fontWeight: '600',background:'#4F73AC',color:'#fff' }}
                        className="btn z-depth-1a "
                        color='#4F73AC'
                      >
                          {allStrings.sendMessage}
                      </MDBBtn>
                    </MDBCol>
                    <MDBCol xl="4" lg="4" md="12"  >
                      <MDBBtn
                        style={{ margin: '1rem auto',padding:'11px 20px',height:'35px',width:'98%',fontSize: '12px',
                        fontWeight: '600',background:'#4F73AC',color:'#fff' }}
                        className="btn z-depth-1a "
                        color='#4F73AC'
                      >
                          {allStrings.receiveMoney}
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                
                </MDBCol>
              
              </MDBRow>
            </MDBContainer>
          </MDBRow>
          
          </form>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = state => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
    branch: state.company.branch,
    company: state.company.company,
  })
  
  const mapDispatchToProps = {
    setUser,
    ChangeLanguage,
    ChangeCompany,
    ChangeBranch,
  }
  
  export default  withRouter(
    connect(mapToStateProps,mapDispatchToProps)(updatePartner)
  );
  
