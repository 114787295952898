import {CHAT} from "../actions/types"

const initialState = {
    unseenMsgCount:0,
}

const ChatReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHAT:
            console.log('in Chat reducer =>'+action.payload)
            return { ...state, unseenMsgCount: action.payload };            
        default:
            return state;
    }

}

export default ChatReducer;