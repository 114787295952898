/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React from 'react';
import moment from 'moment'
import locale_ar from 'antd/lib/locale/ar_EG';
import locale_en from 'antd/lib/locale/en_GB';
import 'moment/locale/ar';
import { connect } from 'react-redux'
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import "./receipt1.css";
import allStrings from '../../assets/strings'
import { ChangeLanguage } from '../../redux/actions/LanguageAction'
import { ChangeCompany, ChangeBranch } from '../../redux/actions/CompanyAction'
import { setUser } from '../../redux/actions/AuthActions'

class PrintReceipt extends React.Component {
  state = {
  }
  constructor(props) {
    super(props)
    if (this.props.isRTL) {
      allStrings.setLanguage('ar')
    } else {
      allStrings.setLanguage('en')
    }

  }
  componentDidMount() {
    console.log("data", this.props)

  }

  render() {
    const { data, marginBottom, index, theLength } = this.props
    console.log("data2", marginBottom)
    return (

      <div style={{ textAlign: 'center', background: '#FFFFFF', fontWeight: 600, fontFamily: 'Arial' }}>
        {data &&
          <MDBContainer>
            <MDBRow>
              <MDBCol size="12">
                <div style={{ marginBottom: marginBottom, padding: '2px', height: '450px', fontWeight: 600, marginTop: '10px', fontFamily: 'Arial' }}>
                  <MDBRow>
                    <MDBCol size="4" style={{ padding: '0rem', marginBottom: '0rem', fontWeight: 600 }}>
                      <MDBRow>
                        <MDBCol size="12" style={{ paddingTop: '0rem', marginBottom: '0rem', fontWeight: 600 }}>
                          <div style={{ fontWeight: "600", textAlign: 'center', border: '4px solid', padding: '6px', borderRight: 0 }}>
                            <p style={{ marginBottom: 0, fontSize: '18px', fontWeight: 600 }}>
                              <span>{data.order ? data.order.paymentSystem == "cash" ? data.order.total : data.order.firstPaid + (parseInt(data.receiptNum) * data.costPerMonth) : ''}</span>
                              <span> : المدفوع </span>
                            </p>
                          </div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol size="12" style={{ paddingTop: '0rem', marginBottom: '0rem', fontWeight: 600 }}>
                          <div style={{ fontWeight: "600", textAlign: 'center', border: '4px solid', padding: '6px', borderTop: 0, borderRight: 0 }}>
                            <p style={{ marginBottom: 0, fontSize: '18px', fontWeight: 600 }}>
                              <span>{data.order ? data.order.paymentSystem == "cash" ? '0' : ((data.order.monthCount * data.costPerMonth) + data.order.firstPaid) - (data.order.firstPaid + (parseInt(data.receiptNum) * data.costPerMonth)) : ''}</span>
                              <span> : المتبقى </span>
                            </p>
                          </div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol size="12" style={{ paddingTop: '0rem', marginBottom: '0rem', fontWeight: 600 }}>
                          <div style={{ fontWeight: "600", textAlign: 'center', border: '4px solid', padding: '6px', borderTop: 0, borderRight: 0 }}>
                            <p style={{ marginBottom: 0, fontSize: '18px', fontWeight: 600 }}>
                              <span>{data.order.premuimsDesc ? data.order.premuimsDesc : "---"} </span>
                              <span> : عدد الاقساط</span>
                            </p>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    <MDBCol size="4" style={{ padding: '0rem', marginBottom: '0rem', fontWeight: 600 }}>
                      <div style={{ fontWeight: "600", textAlign: 'center', border: '4px solid', padding: '26.3px' }}>
                        <p style={{ fontSize: '18px', fontWeight: 700 }}>{data.company ? data.company.fullname.substring(0, 20) : "لايوجد"}</p>
                        <p style={{ marginBottom: 0, fontSize: '18px', fontWeight: 600 }}>{data.company.companySpecialization.substring(0, 40) ? data.company.companySpecialization.substring(0, 40) : "شاشات - لابات - اجهزه كهربائيه - موبايلات"}</p>
                      </div>
                    </MDBCol>
                    <MDBCol size="4" style={{ padding: '0rem', marginBottom: '0rem', fontWeight: 600 }}>
                      <MDBRow>
                        <MDBCol size="12" style={{ paddingTop: '0rem', marginBottom: '0rem', fontWeight: 600 }}>
                          <div style={{ fontWeight: "600", textAlign: 'center', border: '4px solid', padding: '6px', borderLeft: 0 }}>
                            <p style={{ marginBottom: 0, fontSize: '18px', fontWeight: 600 }}>
                              <span>
                                {/*data.client.id?data.client.id:''*/}
                                {data.client.clientCompanyIds.find(e => e.company === data.company.id) ? data.client.clientCompanyIds.find(e => e.company === data.company.id).value : ""}
                              </span>
                              <span> : رقم العميل</span>
                            </p>
                          </div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol size="12" style={{ paddingTop: '0rem', marginBottom: '0rem', fontWeight: 600 }}>
                          <div style={{ fontWeight: "600", textAlign: 'center', border: '4px solid', padding: '6px', borderTop: 0, borderLeft: 0 }}>
                            <p style={{ marginBottom: 0, fontSize: '18px', fontWeight: 600 }}><span> المنطقه : </span> <span>{data.client.jobArea ? data.client.jobArea.substring(0, 20) : "لايوجد"}</span> </p>
                          </div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol size="12" style={{ paddingTop: '0rem', marginBottom: '0rem', fontWeight: 600 }}>
                          <div style={{ fontWeight: "600", textAlign: 'center', border: '4px solid', padding: '6px', borderTop: 0, borderLeft: 0 }}>
                            <p style={{ marginBottom: 0, fontSize: '18px', fontWeight: 600 }}>

                              {/* <span>{data.product.name_ar?data.product.name_ar.substring(0, 20):''}</span> */}
                              <span>
                                {data.order.paymentSystem === "installment" && data.order.processType != "MULTI" ?
                                  <span style={{ flexDirection: 'row', display: 'inline-flex', justifyContent: 'center', marginLeft: '2px', marginRight: '2px' }}>
                                    <span style={{ flexDirection: 'row', display: 'inline-flex', justifyContent: 'center', marginLeft: '2px', marginRight: '2px' }}>
                                      {data.product ? data.product.name_ar.substring(0, 20) : ""}
                                    </span>
                                    <span>{" - " + data.order.count + "  "}</span>
                                  </span>

                                  :
                                  data.product ? data.product.name_ar : ""
                                }
                              </span>
                              <span> : نوع المنتج  </span>
                            </p>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol size="4" style={{ padding: '0rem', marginBottom: '0rem', fontWeight: 600 }}>
                      <div style={{ fontWeight: "600", textAlign: 'center', border: '4px solid', padding: '6px', borderRight: 0, borderTop: 0 }}>
                        <p style={{ marginBottom: 0, fontSize: '18px', fontWeight: 600 }}>
                          <span>  القسط رقم : </span>
                          <span>{data.receiptNum}</span>
                          <span> من </span>
                          <span>{data.order.paymentSystem == "cash" ? "1" : data.order.monthCount} </span>
                        </p>
                      </div>
                    </MDBCol>
                    <MDBCol size="4" style={{ padding: '0rem', marginBottom: '0rem', fontWeight: 600 }}>
                      <div style={{ fontWeight: "600", textAlign: 'center', border: '4px solid', padding: '6px', borderTop: 0, borderRight: 0 }}>
                        <p style={{ marginBottom: 0, fontSize: '18px', fontWeight: 600 }}><span>{data.client.phone ? data.client.phone : ''}</span> <span> : رقم الموبايل </span> </p>
                      </div>
                    </MDBCol>

                    <MDBCol size="4" style={{ padding: '0rem', marginBottom: '0rem', fontWeight: 600 }}>
                      <div style={{ fontWeight: "600", textAlign: 'center', border: '4px solid', padding: '6px', borderTop: 0 }}>
                        <p style={{ marginBottom: 0, fontSize: '18px', fontWeight: 600 }}>
                          <span>  اسم العميل : </span><span>{data.client ? data.client.fullname.substring(0, 20) : "لايوجد"}</span>
                        </p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol size="3" style={{ padding: '0rem', marginBottom: '0rem', fontWeight: 600 }}>
                      <div style={{ fontWeight: "600", textAlign: 'center', border: '4px solid', padding: '6px', borderRight: 0, borderTop: 0 }}>
                        <p style={{ marginBottom: 0, fontSize: '18px', fontWeight: 600 }}>  <span> {data.costPerMonth ? data.costPerMonth : ''} </span> <span> : قيمه القسط  </span></p>
                      </div>
                    </MDBCol>
                    <MDBCol size="5" style={{ padding: '0rem', marginBottom: '0rem', fontWeight: 600 }}>
                      <div style={{ fontWeight: "600", textAlign: 'center', border: '4px solid', padding: '6px', borderTop: 0, borderRight: 0 }}>
                        <p style={{ marginBottom: 0, fontSize: '18px', fontWeight: 600 }}><span>{data.client.cardNum ? data.client.cardNum.substring(0, 20) : ''}</span> <span> :  الرقم القومى </span> </p>
                      </div>
                    </MDBCol>
                    <MDBCol size="4" style={{ padding: '0rem', marginBottom: '0rem', fontWeight: 600 }}>
                      <div style={{ fontWeight: "600", textAlign: 'center', border: '4px solid', padding: '6px', borderTop: 0 }}>
                        <p style={{ marginBottom: 0, fontSize: '18px', fontWeight: 600 }}><span>العنوان : </span> <span>{data.client.address ? data.client.address.substring(0, 20) : "لايوجد"} </span>  </p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol size="3" style={{ padding: '0rem', marginBottom: '0rem', fontWeight: 600 }}>
                      <div style={{ fontWeight: "600", textAlign: 'center', border: '4px solid', padding: '6px', borderRight: 0, borderTop: 0 }}>
                        <p style={{ marginBottom: 0, fontSize: '18px', fontWeight: 600 }}>  <span> {data.order.total ? data.order.total : ''} </span>  <span> : قيمه الشراء  </span></p>
                      </div>
                    </MDBCol>
                    <MDBCol size="5" style={{ padding: '0rem', marginBottom: '0rem', fontWeight: 600 }}>
                      <div style={{ fontWeight: "600", textAlign: 'center', border: '4px solid', padding: '6px', borderTop: 0, borderRight: 0 }}>
                        <p style={{ marginBottom: 0, fontSize: '18px', fontWeight: 600 }}><span>  الوظيفه : </span><span>{data.client.job ? data.client.job.substring(0, 20) : "لايوجد"} </span>  </p>
                      </div>
                    </MDBCol>
                    <MDBCol size="4" style={{ padding: '0rem', marginBottom: '0rem', fontWeight: 600 }}>
                      <div style={{ fontWeight: "600", textAlign: 'center', border: '4px solid', padding: '6px', borderTop: 0 }}>
                        <p style={{ marginBottom: 0, fontSize: '18px', fontWeight: 600 }}><span> العمل : </span> <span> {data.client.jobLocation ? data.client.jobLocation.substring(0, 20) : "لايوجد"} </span> </p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol size="12" style={{ padding: '0rem', marginBottom: '0rem', fontWeight: 600 }}>
                      <div style={{ fontWeight: "600", textAlign: 'center', border: '4px solid', padding: '6px', borderTop: 0 }}>
                        <p style={{ marginBottom: 0, fontSize: '18px', fontWeight: 600 }}>  بموجب هذا العقد اتعهد بدفع <span>{data.costPerMonth ? data.costPerMonth : ""} جنيها </span> علما بان القيمه وصلتنا بضاعه والدفع والتقاضى وفقا لاداره الدائن وللدائن الحق فى تحويل هذه الكمبياله لمن يشاء دون الرجوع الى </p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol size="3" style={{ padding: '0rem', marginBottom: '0rem', fontWeight: 600 }}>
                      <div style={{ fontWeight: "600", textAlign: 'center', border: '4px solid', padding: '6px', borderRight: 0, borderTop: 0 }}>
                        <p style={{ marginBottom: 0, fontSize: '18px', fontWeight: 600 }}> <span> تاريخ التحصيل :  </span> <span> {data.paidDate ? moment(data.paidDate).format('YYYY/MM/DD') : ""}</span>  </p>
                      </div>
                    </MDBCol>
                    <MDBCol size="3" style={{ padding: '0rem', marginBottom: '0rem', fontWeight: 600 }}>
                      <div style={{ fontWeight: "600", textAlign: 'center', border: '4px solid', padding: '6px', borderTop: 0, borderRight: 0 }}>
                        <p style={{ marginBottom: 0, fontSize: '18px', fontWeight: 600 }}><span>  اسم المحصل : </span><span>{data.order.salesMan ? data.order.salesMan.fullname.substring(0, 20) : "لايوجد"}</span>  </p>
                      </div>
                    </MDBCol>
                    <MDBCol size="3" style={{ padding: '0rem', marginBottom: '0rem', fontWeight: 600 }}>
                      <div style={{ fontWeight: "600", textAlign: 'center', border: '4px solid', padding: '6px', borderTop: 0, borderRight: 0 }}>
                        <p style={{ marginBottom: 0, fontSize: '18px', fontWeight: 600 }}><span> اسم البائع : </span> <span> {data.order.buySalesMan ? data.order.buySalesMan.fullname.substring(0, 20) : "لايوجد"} </span> </p>
                      </div>
                    </MDBCol>
                    <MDBCol size="3" style={{ padding: '0rem', marginBottom: '0rem', fontWeight: 600 }}>
                      <div style={{ fontWeight: "600", textAlign: 'center', border: '4px solid', padding: '6px', borderTop: 0 }}>
                        <p style={{ marginBottom: 0, fontSize: '18px', fontWeight: 600 }}> <span> تاريخ الشراء : </span> <span> {data.order.startDate ? moment(data.order.startDate).format('YYYY/MM/DD') : ""} </span>  </p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol size="12" style={{ padding: '0rem', marginBottom: '0rem', fontWeight: 600 }}>
                      <div style={{ fontWeight: "600", textAlign: 'center', border: '4px solid', padding: '6px', borderTop: 0 }}>
                        <p style={{ marginBottom: 0, fontSize: '18px', fontWeight: 600, height: '50px', width: '90%', marginLeft: '20px', marginRight: '10px', wordWrap: 'break-word' }}>
                          {data.company ? data.company.contactDetails ? data.company.contactDetails.substring(0, 280) : "لايوجد" : "لايوجد"}
                        </p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <br></br>
                  {index === theLength - 1 && index % 3 === 1 ?
                    <p style={{ textAlign: 'center', fontWeight: 'bold' }}>{index === 2 ? 1 : index > 0 ? index % 3 === 1 ? Math.ceil(index / 3) : "" : ""}</p>
                    :
                    <p style={{ textAlign: 'center', fontWeight: 'bold' }}>{index === 2 ? 1 : index > 0 ? index % 3 === 2 ? Math.ceil(index / 3) : "" : ""}</p>
                  }
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        }
      </div>
    );
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  branch: state.company.branch,
  company: state.company.company,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
}

export default connect(mapToStateProps, mapDispatchToProps)(PrintReceipt);
